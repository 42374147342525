import React, {Component}             from 'react'
import PropTypes                      from 'prop-types'
import OutsideClickHandler            from 'react-outside-click-handler'
import {warehouseData, warehouses}    from '../common/constants'

export default class DropdownWarehouse extends Component {
  static propTypes = {
    warehouse: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
    this.closeDropdown = this.closeDropdown.bind(this)
  }

  selectWarehouse = (warehouseId) => {
    this.setState({open: false})
    this.props.onChange(warehouseId)
  }

  openDropdown = () => {
    this.setState({open: true})
  }

  closeDropdown = () => {
    this.setState({open: false})
  }

	render () {
    let currentWarehouse = this.props.warehouse ? warehouseData(this.props.warehouse) : false
		return (
		  <div className="warehouse-field">
        <div className="warehouse-field-input" onClick={this.state.open ? this.closeDropdown : this.openDropdown}>
          {currentWarehouse ?
            <div className="warehouse-field-current">
              <img src={`/assets/images/${currentWarehouse.flag}`} alt={currentWarehouse.countryLong} />
              <p>{currentWarehouse.countryLong}</p>
            </div>
            :
            <div className="warehouse-field-default">Select...</div>
          }
        </div>
        {this.state.open &&
          <OutsideClickHandler onOutsideClick={this.closeDropdown}>
            <div className="warehouse-dropdown">
              {warehouses.map(w=>{return (
                <div className="warehouse-dropdown-wh" key={'warehouse-'+w.id} onClick={()=>{this.selectWarehouse(w.id)}}>
                  <img src={`/assets/images/${w.flag}`} alt={w.countryLong} />
                  <p>{w.countryLong}</p>
                </div>
              )})}
            </div>
          </OutsideClickHandler>
        }
      </div>
    )
  }
}


