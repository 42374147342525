export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function captureUTMParameters() {
  const utmId = getParameterByName('utm_id');
  const utmSource = getParameterByName('utm_source');
  const utmMedium = getParameterByName('utm_medium');
  const utmCampaign = getParameterByName('utm_campaign');

  if (utmId) localStorage.setItem('utm_id', utmId);
  if (utmSource) localStorage.setItem('utm_source', utmSource);
  if (utmMedium) localStorage.setItem('utm_medium', utmMedium);
  if (utmCampaign) localStorage.setItem('utm_campaign', utmCampaign);
}