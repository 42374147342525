import React, {Fragment} from 'react'
import {withRouter}                       from 'react-router-dom'
import Checkout from "./Checkout";
const Package = () => {

  return(
    <Fragment>
      <Checkout/>
    </Fragment>
  )
}

export default withRouter(Package)