import React from "react";
import {convertToClientTimestamp, displayDate} from "../../common/utils";
const PlatformInfo = ({onPlatformData}) => {

  return (
    <div className="timeline-content">
      <div className='timeline-title'>
        <h4>Warehouse Tracking</h4>
      </div>
      <div className='timeline-detail timeline'>
        {onPlatformData?.length ?
          onPlatformData.map(event => {
          return (
            <div className='timeline-event'>
              <div className='timeline-event-content'>
                <div className='timeline-event-content-timestamp'>
                  <div>{displayDate(convertToClientTimestamp(event.timestamp) , 'dd MMM yy, h:mm:ssaaa')}</div>
                </div>
                <div>
                  <div className='timeline-event-content-description'>{event.description}</div>
                </div>
              </div>
            </div>
          )
        }) : <div className="card">Tracking information is not available</div>}
      </div>
    </div>
  )
}

export default PlatformInfo