import React, {Component, Fragment} from 'react'
import { Link, withRouter }         from 'react-router-dom'
import connect                      from 'react-redux/es/connect/connect'
import {constants}           from '../common/constants'
import GlobalHelmet                 from './GlobalHelmet'

const pathMaps = [
  {id: 11, url: '/'},
  {id: 11, url: '/dashboard'},
  
  {id: 12, url: '/packages'},
  {id: 13, url: '/shipments'},
  {id: 14, url: '/my-aprs'},
  {id: 14, url: '/my-apr/:id'},
  
  {id: 21, url: '/my-account'},
  {id: 22, url: '/my-addresses'},
  {id: 23, url: '/my-plan'},
  {id: 25, url: '/my-history'},
  {id: 25, url: '/invoice/:id'},
]

const navigation = [
  // {section: 1, id: 11, icon: 'dashboard',         url: '/dashboard',      title: 'Dashboard'},
  // {section: 1, id: 12, icon: 'home',              url: '/packages',    title: 'My Packages' },
  // {section: 1, id: 13, icon: 'flight_takeoff',    url: 'shipments',   title: 'My Shipments'},
  // {section: 1, id: 14, icon: 'add_shopping_cart', url: '/my-aprs',        title: 'Assisted Purchases'},
  
  // {section: 2, id: 21, icon: 'account_circle',    url: '/my-account',     title: 'My Account'},
  // {section: 2, id: 22, icon: 'location_on',       url: '/my-addresses',   title: 'My Addresses'},
  // {section: 2, id: 23, icon: 'credit_card',       url: '/my-plan',        title: 'My Membership'},
  // {section: 2, id: 24, icon: 'vpn_key',           url: '/my-otps',         title: 'Secured Delivery'},
  // {section: 2, id: 25, icon: 'restore',           url: '/my-history',     title: 'Billing History'},
  // {section: 2, id: 26, icon: 'wc',                url: '/my-refers',      title: 'Refer Friends'}
  
  {section: 1, id: 11, icon: 'grid',         url: '/dashboard',      title: 'Dashboard'},
  {section: 1, id: 12, icon: 'package',              url: '/packages',    title: 'Packages' },
  {section: 1, id: 13, icon: 'compass',    url: 'shipments',   title: 'Shipments & Claims'},
  // {section: 1, id: 14, icon: 'add_shopping_cart', url: '/my-aprs',        title: 'Assisted Purchases'},
  
  // {section: 2, id: 21, icon: 'user',    url: '/my-account',     title: 'My Account'},
  {section: 2, id: 22, icon: 'map-pin',       url: '/my-addresses',   title: 'Addresses'},
  {section: 2, id: 23, icon: 'credit-card',       url: '/my-plan',        title: 'Membership'},
  {section: 2, id: 24, icon: 'trello',       url: '/calculator',        title: 'Shipping Calculator'},
  {section: 2, id: 25, icon: 'lock',           url: '/my-otps',         title: 'Secured Delivery'},
  {section: 2, id: 26, icon: 'clock',           url: '/my-history',     title: 'Billing History'},
  {section: 2, id: 27, icon: 'users',                url: '/my-refers',      title: 'Refer Friends'}
]

const mapStateToProps = (globalStore) => {return {store: globalStore}}
const mapDispatchToProps = (dispatch) => {return {
  customerInit: ()=>{dispatch({type: 'CUSTOMER_INIT'})},
  clientInit: (initData)=>{dispatch({type: 'CLIENT_INIT', payload: initData})},
  logout: (logoutData)=>{dispatch({type: 'CUSTOMER_LOGOUT', payload: logoutData })},
}}

class CustomerSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      section: 1,
      active: 1,
      showMenu: false,
      windowWidth: document.documentElement.clientWidth
    }
  }

  componentDidMount() {
    this.getWindowWidth()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.setState({windowWidth: document.documentElement.clientWidth}))
  }

  getWindowWidth = () => {
    window.addEventListener('resize', () => this.setState({windowWidth: document.documentElement.clientWidth}))
  }

  supportUrl = 'https://intercom.help/reship/en'
  
  doLogout = () => {
    const { logout } = this.props
    window.Moengage.destroy_session();
    logout()
  }
  
  render() {
    return (
      <Fragment>
        <GlobalHelmet />
        <div className="side-navbar">
          <a className="sd-logo" href={constants.appHome}>
            {this.state.windowWidth >= 1200 &&<img src="/assets/images/Reship_Logo_Pos.png" alt="logo"/>}
            {this.state.windowWidth <= 1199 &&<img src="/assets/images/Reship_Logo_Pur_Short.png" alt="logo"/>}

          </a>
          <div className="sd-navbar">
            <ul>
              {navigation.filter((n)=>{return n.section===1}).map((n)=>{
                return (<li key={'n'+n.id}><Link className={this.state.active===n.id ? 'active' : 'nav-link'} to={n.url}>
                  {/* <i className="material-icons">{n.icon}</i>  */}
                  <i><svg className="feather"><use href={"/assets/images/icons/feather-sprite.svg#" + n.icon}/></svg></i> 
                  <span>{n.title}</span></Link></li>)
              })}
            </ul>
          </div>
          <div className="sd-navbar sd-settings">
            <ul>
              <li><div className="header"><span>Settings</span></div></li>
              {navigation.filter((n)=>{return n.section===2}).map((n)=>{
                return (<li key={'n'+n.id}><Link className={this.state.active===n.id ? 'active' : 'nav-link'} to={n.url}>
                  {/* <i className="material-icons">{n.icon}</i>  */}
                  <i><svg className="feather"><use href={"/assets/images/icons/feather-sprite.svg#" + n.icon}/></svg></i> 
                  <span>{n.title}</span></Link></li>)
              })}
            </ul>
          </div>
          <div className="sd-navbar">
            <ul>
              <li key="n21">
                <Link className={this.state.active==='n21' ? 'active' : 'nav-link'} to="/my-account">
                  <i><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#user"/></svg></i> 
                  <span>My Account</span>
                </Link>
              </li>
              <li><Link onClick={this.doLogout} to="#" className="nav-link">
                {/* <i className="material-icons">exit_to_app</i>  */}
                <i><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#log-out"/></svg></i> 
                <span>Logout</span></Link></li>
            </ul>
          </div>
        </div>
        <div className="navbar navbar-expand-md">
          <div className="navbar-bar">
            <a className="navbar-brand d-block d-md-none" href={constants.appHome}>
              <img src="/assets/images/Reship_Logo_Pur.png" alt="logo"/>
            </a>
            <div className={"navbar-toggler"+(this.state.showMenu ? " active" : '')} onClick={()=>{this.setState({showMenu: !this.state.showMenu})}}>
              <span className="navbar-toggler-icon">
                <svg className="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30" focusable="false">
                  <title>Menu</title>
                  <path stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M4 7h22M4 15h22M4 23h22" />
                </svg>
              </span>
            </div>
          </div>
          <div className={"collapse navbar-collapse"+(this.state.showMenu ? ' show' : '')} id="navbarSupportedContent">
            
          {/*<ul className="sd-navbar d-block d-md-none">*/}
          {/*    <li><a href={constants.appHome+"/about.html"}>About</a></li>*/}
          {/*    <li className="hide-900"><a href={constants.appHome+"/business-to-business.html"}>B2B</a></li>*/}
          {/*    <li><a href={constants.appHome+"/services.html"}>Services</a></li>*/}
          {/*    <li><Link to="/my-plan">Pricing</Link></li>*/}
          {/*    <li><Link to="/calculator">Shipping calculator</Link></li>*/}
          {/*    <li><a href={this.supportUrl}>Support</a></li>*/}
          {/*    <li className="hide-900"><a href={constants.appHome+"/info/"}>Info</a></li>*/}
          {/*    <li className="active"><Link to="/">My account</Link></li>*/}
          {/*  </ul>*/}
            <div className="sd-navbar d-block d-md-none">
              <ul>
                {navigation.filter((n)=>{return n.section===1}).map((n)=>{
                  return (<li key={'m'+n.id}><Link className={this.state.active===n.id ? 'active' : ''} to={n.url}><i><svg className="feather"><use href={"/assets/images/icons/feather-sprite.svg#" + n.icon}/></svg></i> {n.title}</Link></li>)
                })}
              </ul>
            </div>
            <div className="sd-navbar d-block d-md-none">
              <ul>
                {navigation.filter((n)=>{return n.section===2}).map((n)=>{
                  return (<li key={'m'+n.id}><Link className={this.state.active===n.id ? 'active' : ''} to={n.url}><i><svg className="feather"><use href={"/assets/images/icons/feather-sprite.svg#" + n.icon}/></svg></i> {n.title}</Link></li>)
                })}
                <li><div className="clickable" onClick={this.doLogout}><i><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#log-out"/></svg></i>  Logout</div></li>
              </ul>
            </div>
          </div>
          {/* <ul className="nav navbar-right">
            <li><a href={constants.appHome+"/about.html"}>About</a></li>
            <li className="hide-900"><a href={constants.appHome+"/business-to-business.html"}>B2B</a></li>
            <li><a href={constants.appHome+"/services.html"}>Services</a></li>
            <li><Link to="/my-plan">Pricing</Link></li>
            <li><Link to="/calculator">Shipping calculator</Link></li>
            <li><a href={this.supportUrl}>Support</a></li>
            <li className="hide-900"><a href={constants.appHome+"/info/"}>Info</a></li>
            <li className="active"><Link to="/">My account</Link></li>
          </ul> */}
        </div>
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerSidebar))