import React, { useState } from 'react';
import { Modal } from 'antd';

// Closes itself after promise asyncOperation is finished.
function ModalAsync({
  title, text, asyncOperation, children,footer, visible, setVisible
}) {
  const [confirmLoading, setConfirmLoading] = useState(false)

  const handleOk = () => {
    setConfirmLoading(true)
    asyncOperation()
      .finally(() => {
        setVisible(false)
      });
  };

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <div className="global-overlay">
      <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={
          <div className="modal-close-button">
            {footer}
          </div>
      }
      >
        <p>{text}</p>
        {children}
      </Modal>
    </div>
  );
}

export default ModalAsync;
