import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import axios from 'axios/index'
import DropdownCountry from '../components/DropdownCountry'
import {countryText, filterValidCountry} from '../common/handleAddress'
import {debug} from '../common/constants'
import GlobalFooter from '../components/GlobalFooter'
import GlobalError from '../components/GlobalError'
import GlobalNotices from '../components/GlobalNotices'
import CustomerSidebar from '../components/CustomerSidebar'
import CustomerLoading from '../components/CustomerLoading'
import ButtonLoading from '../components/ButtonLoading'
import agent from '../api/agent'
import {InputPhoneNumber} from "../components/InputPhoneNumber";


const mapStateToProps = (globalStore) => {
  return {store: globalStore}
}
const mapDispatchToProps = (dispatch) => {
  return {
    login: (loginData) => {
      dispatch({type: 'CUSTOMER_LOGIN', payload: loginData})
    },
    logout: () => {
      dispatch({type: 'CUSTOMER_LOGOUT'})
    }
  }
}

class Account extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      initialLoading: 0,
      initialLoadingError: '',

      loading: false,
      message: '',
      edit: false,
      customer: {},

      customerId: '',
      name: '',
      email: '',
      phone: '',
      country: '',
      // add eori number by Kelly Feb 21, 2023
      eori: '',

      oldPassword: '',
      password1: '',
      password2: '',

      //Added by Lewis May 31, 2022
      currentOpenModal: '',
    }
  }
  

  componentDidMount() {
    if(this.props.store.isLoggedIn && this.state.initialLoading===0)
      this.getCustomerData()
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.store.isLoggedIn && this.state.initialLoading===0)
      this.getCustomerData()
  }

  getCustomerData = () => {
    this.setState({initialLoading: 1})

    axios.get('/customer/getCustomer')
      .then((response) => {

        this.setState({
          initialLoading: 2,
          initialLoadingError: '',
          customer: response.data.customer,
          customerId: response.data.customer.customerId,
          name: response.data.customer.name,
          email: response.data.customer.email,
          phone: response.data.customer.phone || '',
          country: filterValidCountry(response.data.customer.country),
          eori: response.data.customer.eori || '',
        })
      })
      .catch((error) => {
        this.setState({
          initialLoading: 3,
          initialLoadingError: error.response ? error.response.data.message : error.message
        })
      })
  }

  updateProfile = async () => {
    if (!this.state.email.length) {
      this.setState({message: 'Email is a required field.'})
      return;
    }

    if (!this.state.country === '') {
      this.setState({message: 'Country is a required field.'})
      return;
    }

    if (this.state.email === this.state.customer.email
      && this.state.phone === this.state.customer.phone
      && this.state.country === this.state.customer.country) {
      this.closeEdit()
      return;
    }

    try {
      const updateCustomerObject = {
        email: this.state.email,
        phone: this.state.phone,
        country: this.state.country
      }

      const response = await axios.post('/customer/updateCustomer', updateCustomerObject)

      if (response.status === 200) {
        window.Moengage.update_unique_user_id(this.state.email);
        this.setState({
          customer: {
            ...this.state.customer,
            ...response.data.newCustomerData
          }
        })

        this.getCustomerData()
      }
      this.closeEdit()
    } catch (e) {
      this.setState({
        loading: false,
        message: e.response ? e.response.data.message : e.message
      })
    }
  }

  closeEdit = () => {
    this.setState({
      edit: false,
      name: this.state.customer.name,
      email: this.state.customer.email,
      phone: this.state.customer.phone,
      country: this.state.customer.country,
      eori: this.state.customer.eori,
      loading: false,
      message: '',
    })
  }

  closeChangePassword = () => {
    this.setState({
      oldPassword: '',
      password1: '',
      password2: '',
      changePassword: false,
      loading: false,
      message: '',
    })
  }

  closeChangePassword = () => {
    this.setState({
      oldPassword: '',
      password1: '',
      password2: '',
      currentOpenModal: '',
      loading: false,
      message: '',
    })
  }

  doChangePassword = () => {
    if (!this.state.password1)
      this.setState({message: 'Please enter a password.'})
    else if (this.state.password1.length < 6)
      this.setState({message: 'Your password needs to be at least 6 characters long.'})
    else if (this.state.password1.search(/\d/) === -1 || this.state.password1.search(/[a-zA-Z]/))
      this.setState({message: 'Your password needs at least 1 number and 1 letter.'})
    else if (this.state.password1 !== this.state.password2)
      this.setState({message: 'Passwords do not match'})
    else {
      this.setState({loading: true})
      let postData = {
        oldPassword: this.state.oldPassword,
        newPassword: this.state.password1,
      }
      axios.post('/customer/updatePassword', postData)
      .then((response) => {
        if(!response.data.success)
          this.setState({loading: false, message: response.data.message})
        else {
          this.closeChangePassword()
        }
      })
      .catch((error) => {this.setState({loading: false, message: error.response ? error.response.data.message : error.message})})
    }
  }

  doLogout = () => {
    const {logout} = this.props
    logout()
  }

  submitDeleteRequest = async () => {
    try {
      this.setState({loading: true})

      await axios.post('/customer/submitDeleteRequest')
      await this.notifyStaffOfAccDeletion()

      this.setState({loading: false, currentOpenModal: 'delete-account-confirmation'})
    } catch (err) {
      this.setState({
        loading: false,
        currentOpenModal: 'delete-account-confirmation',
        message: err.response ? err.response.data.message : err.message
      })
    }
  }

  notifyStaffOfAccDeletion = async () => {
    try {
      this.setState({loading: true})
      
      const postData = {
        customerId: this.state.customerId
      }

      await agent.email.customerAccountDeleteRequest(postData)

      this.setState({loading: false, currentOpenModal: 'delete-account-confirmation'})
    } catch (err) {
      this.setState({
        loading: false,
        currentOpenModal: 'delete-account-confirmation',
        message: err.response ? err.response.data.message : err.message
      })
    }
  }

  render() {
    if (this.state.initialLoading < 2)
      return (
        <div className="body">
          <CustomerSidebar/>
          <CustomerLoading/>
        </div>
      )
    
    return (
      <div className="body body-customer" id="customer-profile">
        <CustomerSidebar/>
        <div className="content-area">
          <GlobalNotices {...this.props}/>
          <div className="page-title"><h2>Account</h2></div>
          {this.state.edit ?
            <div className="customer-main">
              <div className="card customer-account-form">
                <div className="customer-account-form-row">
                  <div className="customer-account-form-label"><i className="material-icons">person</i> Name</div>
                  <div className="customer-account-form-text">{this.state.customer.name}</div>
                </div>
                <div className="customer-account-form-row">
                  <div className="customer-account-form-label"><i className="material-icons">email</i> Email Address
                  </div>
                  <div className="customer-account-form-input"><input type="email" className="form-control" placeholder="Email Address" value={this.state.email} required={true} onChange={(e) => this.setState({email: e.target.value})}/></div>
                </div>
                <div className="customer-account-form-row">
                  <div className="customer-account-form-label"><i className="material-icons">outlined_flag</i> Country
                  </div>
                  <div className="customer-account-form-input"><DropdownCountry className="form-control" value={this.state.country} onChange={(val) => this.setState({country: val})}/></div>
                </div>
                <div className="customer-account-form-row">
                  <div className="customer-account-form-label"><i className="material-icons">local_phone</i> Phone Number</div>
                  <div className="customer-account-form-input">
                    <InputPhoneNumber value={this.state.phone} doUpdate={(v)=>{this.setState({phone: v.value})}} countryCode={this.state.country} removeLabel={true}/>
                  </div>
                </div>
                {this.state.message && <div className="text-center login-message">{this.state.message}</div>}
                <div className="button-row">
                  <button className="btn btn-sm mb-2 btn-primary" onClick={this.state.loading ? () => {} : this.updateProfile}>{this.state.loading ? <ButtonLoading/> : <Fragment><i className="material-icons">check</i> Save</Fragment>}</button>
                  <button className="btn btn-sm mb-2 ml-sm-3 btn-light" onClick={this.closeEdit}><i className="material-icons">close</i> Cancel</button>
                </div>
              </div>
            </div>
            :
            <div className="customer-main">
              <div className="card customer-account-form">
                <div className="customer-account-form-row">
                  <div className="customer-account-form-label"><i className="material-icons">person</i> Name</div>
                  <div className="customer-account-form-text">{this.state.customer.name}</div>
                </div>
                <div className="customer-account-form-row">
                  <div className="customer-account-form-label"><i className="material-icons">email</i> Email Address</div>
                  <div className="customer-account-form-text">{this.state.customer.email}</div>
                </div>
                <div className="customer-account-form-row">
                  <div className="customer-account-form-label"><i className="material-icons">outlined_flag</i> Country</div>
                  <div className="customer-account-form-text">{countryText(this.state.customer.country) || 'Not specified'}</div>
                </div>
                <div className="customer-account-form-row">
                  <div className="customer-account-form-label"><i className="material-icons">local_phone</i> Phone Number</div>
                  <div className="customer-account-form-text">{!!this.state.customer.phone ? this.state.customer.phone : <span style={{color: 'rgba(0,0,0,0.4)'}}>None</span>}</div>
                </div>
                <div className="button-row-wrap">
                  <button className="btn btn-sm mb-2 btn-danger" onClick={() => {this.setState({currentOpenModal: 'delete-account-warning'})}}><i className="material-icons">delete</i> Delete Account</button>
                  <button className="btn btn-sm mb-2 btn-primary" onClick={() => {this.setState({edit: true})}}><i className="material-icons">edit</i> Edit Profile</button>
                  <button className="btn btn-sm mb-2 ml-sm-3 btn-primary" onClick={() => {this.setState({currentOpenModal: 'change-password'})}}><i className="material-icons">lock</i> Change Password</button>
                </div>
              </div>
            </div>
          }
        </div>
        <GlobalFooter/>
        {this.state.currentOpenModal === 'change-password' &&
          <div className="global-overlay">
            <div className="global-overlay-tapcapture global-overlay-tapcapture-grey" onClick={this.closeChangePassword}>&nbsp;</div>
            <div className="global-overlay-modal card card-base" id="changepassword">
              <div className="modal-exit" onClick={this.closeChangePassword}><i className="material-icons">clear</i></div>
              <div className="modal-title">Change Password</div>
              <div className="modal-inner">
                <div className="form-group">
                  <input type="password" required={true} className="form-control" placeholder="Current Password" value={this.state.oldPassword} onChange={(e) => {this.setState({oldPassword: e.target.value})}}/>
                </div>
                <div className="form-group">
                  <input type="password" required={true} className="form-control" placeholder="New Password" value={this.state.password1} onChange={(e) => {this.setState({password1: e.target.value})}}/>
                </div>
                <div className="form-group">
                  <input type="password" required={true} className="form-control" placeholder="Repeat New Password" value={this.state.password2} onChange={(e) => {this.setState({password2: e.target.value})}}/>
                </div>
                <div className="form-group">
                  {this.state.message && <div className="text-center login-message">{this.state.message}</div>}
                  <div className="text-center">Password must consist of at least 6 characters, at least one letter and at least one number.</div>
                </div>
                <div className="btn btn-block btn-primary" onClick={this.state.loading ? () => {} : this.doChangePassword}>{this.state.loading ? <ButtonLoading/> : 'Save'}</div>
                <div className="btn btn-block btn-light" onClick={this.closeChangePassword}>Cancel</div>
              </div>
            </div>
          </div>
        }

        {this.state.currentOpenModal === 'delete-account-warning' &&
          <div className="global-overlay">
            <div className="global-overlay-tapcapture global-overlay-tapcapture-grey" onClick={() => this.setState({currentOpenModal: ''})}>&nbsp;</div>
            <div className="global-overlay-modal card card-base" id="changepassword">
              <div className="modal-exit" onClick={this.closeChangePassword}><i className="material-icons">clear</i></div>
              <div className="modal-title">Delete Account</div>
              <div className="modal-inner">
                <div className="text-center spacing-medium"><strong>Warning: </strong> All outstanding packages will be forfeited.</div>
                <div className="text-center spacing-medium"><strong>Please be completely sure before proceeding.</strong></div>
                <div className="spacing-xlarge"/>
                <div className="btn btn-block btn-danger" onClick={this.state.loading ? () => {} : this.submitDeleteRequest}>{this.state.loading ? <ButtonLoading/> : 'Request Deletion'}</div>
                <div className="btn btn-block btn-light" onClick={() => this.setState({currentOpenModal: ''})}>Cancel</div>
              </div>
            </div>
          </div>
        }

        {this.state.currentOpenModal === 'delete-account-confirmation' &&
          <div className="global-overlay">
            <div className="global-overlay-tapcapture global-overlay-tapcapture-grey" onClick={this.doLogout}>&nbsp;</div>
            <div className="global-overlay-modal card card-base" id="changepassword">
              <div className="modal-exit" onClick={this.doLogout}><i className="material-icons">clear</i></div>
              <div className="modal-title">Request successfully submitted.</div>
              <div className="modal-inner">

                <div className="text-center spacing-medium">To reverse this action, please email customerservice@reship.com.</div>
                <div className="spacing-xlarge"/>
                <div className="btn btn-block btn-light" onClick={this.doLogout}>Logout</div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Account))
