import React from 'react';
import { BrowserRouter, Switch, Route }
  from 'react-router-dom';
import { Provider } from 'react-redux';

import Login from './Login';
import Recover from './Recover';
import Calculator from './Calculator';
import Dashboard from './Dashboard';
import Outcomes from './Outcomes';
import Account from './Account';
import Addresses from './Addresses';
import Instructions from './Instructions';

import Plan from './Plan';
import PublicPlan from './PublicPlan';
import EmailUnsubscribe from './EmailUnsubscribe';

import Billing from './Billing';
import Invoice from './Invoice';
import Refer from './Refer';
import Packages from './Packages';
import Shipments from './Shipments';
import MobileCheckout from './MobileCheckout';

import PublicRoute from '../components/PublicRoute';
import PrivateRoute from '../components/PrivateRoute';

import withInitData from '../hocs/withInitData';
import Package from './Package';
import Shipment from './Shipment';
import RedirectToAppStore from '../components/RedirectToAppStore';

function Root({ store }) {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <PublicRoute restricted component={RedirectToAppStore} path="/redirectToAppStore" exact />
          <PublicRoute restricted={false} component={EmailUnsubscribe} path="/email/unsubscribe" exact />
          <PublicRoute restricted component={withInitData(Login)} path="/register" exact />
          <PublicRoute restricted component={withInitData(Login)} path="/register/:planCode" exact />
          <PublicRoute restricted component={withInitData(Login)} path="/login" exact />
          <PublicRoute restricted component={withInitData(Login)} path="/forgot" exact />
          <PublicRoute restricted={false} component={withInitData(Login)} path="/verify/:email/:token" exact />
          <PublicRoute restricted={false} component={withInitData(Recover)} path="/recover/:recoverCode" exact />
          <PublicRoute restricted={false} component={withInitData(Calculator)} path="/calculator" exact />
          <PublicRoute restricted={false} component={withInitData(PublicPlan)} path="/plans" exact />

          <PublicRoute restricted={false} component={withInitData(MobileCheckout)} path="/mobile/pay/:mobileToken" exact />
          <PublicRoute restricted={false} component={withInitData(MobileCheckout)} path="/mobile/success" exact />
          <PublicRoute restricted={false} component={withInitData(MobileCheckout)} path="/mobile/close" exact />
          <PublicRoute restricted={false} component={withInitData(MobileCheckout)} path="/mobile/error/:errorCode" exact />
          <PublicRoute restricted={false} component={withInitData(PublicPlan)} path="/plans" exact />

          <PrivateRoute restricted component={withInitData(Dashboard)} path="/" exact />
          <PrivateRoute restricted component={withInitData(Dashboard)} path="/dashboard" exact />
          <PrivateRoute restricted component={withInitData(Account)} path="/my-account" exact />
          <PrivateRoute restricted component={withInitData(Addresses)} path="/my-addresses" exact />
          <PrivateRoute restricted component={withInitData(Plan)} path="/my-plan" exact />
          <PrivateRoute restricted component={withInitData(Refer)} path="/my-refers" exact />
          <PrivateRoute restricted component={withInitData(Instructions)} path="/my-otps" exact />
          <PrivateRoute restricted component={withInitData(Billing)} path="/my-history" exact />
          <PrivateRoute restricted component={withInitData(Invoice)} path="/invoice/:id" exact />

          <PrivateRoute restricted component={withInitData(Packages)} path="/packages" exact />
          <PrivateRoute restricted component={withInitData(Package)} path="/package" exact />
          <PrivateRoute restricted component={withInitData(Shipments)} path="/shipments" exact />
          <PrivateRoute restricted component={withInitData(Shipment)} path="/shipment" exact />
          <PrivateRoute restricted component={withInitData(Outcomes)} path="/done/:type" exact />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default Root;
