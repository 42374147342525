import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import ErrorBoundary from './ErrorBoundary';

const mapStateToProps = (state) => ({ store: state });

function PrivateRoute({ component: Component, store, ...rest }) {
  return (
    <ErrorBoundary>
      <Route
        {...rest}
        render={(props) => (
          store.isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />
        )}
      />
    </ErrorBoundary>
  );
}

export default connect(mapStateToProps)(PrivateRoute);
