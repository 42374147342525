import React, {Fragment, useState, useEffect} from 'react'
import {withRouter}         from 'react-router-dom'
import axios from 'axios'
import { Skeleton } from 'antd'


const CustomerAddresses = () => {

  const [ barOpen, setBarOpen ] = useState(true)
  const [ customer, setCustomer ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)

  const handleBarOpen = () => {
    setBarOpen(!barOpen)
  }

  const handleGetCustomerInformation = async () => {
    try {
      setIsLoading(true)

      const response = await axios.get('/customer')
      const data = response.data.data

      setCustomer({
        ...data
      })

      setIsLoading(false)

    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetCustomerInformation()
  }, [])
  
  if(isLoading && !customer) {
    return (
      <div className="card card-white card-address">
        <Skeleton />
      </div>
    )
  }

  return (
      <div>
        <div className="card card-white card-address">
          <h5 className="left-ctr cursor-pointer" onClick={handleBarOpen}><i className="material-icons">{barOpen ? 'arrow_drop_down' : 'arrow_right'}</i> Your Reship addresses</h5>
          { barOpen &&
            <Fragment>
              <div className="row mt-4">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-4"><img src="/assets/images/flag-usa.svg" srcSet="/assets/images/flag-usa.svg" className="img-fluid mb-2" alt="flag"/></div>
                  <div className="col-8 address">
                    <div className="address-name">{customer?.name}</div>
                    <div className="address-address">
                      <div className="address-address-line">
                        <span className="address-address-element">13820 NE Airport Way
                          <div className="address-address-highlight" />
                          <div className="address-address-label">Address line 1</div>
                        </span>
                      </div>
                      <div className="address-address-line">
                        <span className="address-address-element">Suite #K{customer?.customerId.toString().padStart(5,'0')}
                          <div className="address-address-highlight" />
                          <div className="address-address-label">Address line 2</div>
                        </span>
                      </div>
                      <div className="address-address-line">
                        <span className="address-address-element">Portland
                          <div className="address-address-highlight" />
                          <div className="address-address-label">City</div>
                        </span>,&nbsp;
                        <span className="address-address-element">OR
                          <div className="address-address-highlight" />
                          <div className="address-address-label">State: Oregon</div>
                        </span>
                      </div>
                      <div className="address-address-line">
                        <span className="address-address-element">97251-1158
                          <div className="address-address-highlight" />
                          <div className="address-address-label">Zip code</div>
                        </span>
                      </div>
                      <div className="address-address-line">United States</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-4"><img src="/assets/images/flag-canada.svg" srcSet="/assets/images/flag-canada.svg" className="img-fluid mb-2" alt="flag"/></div>
                  <div className="col-8 address">
                    <div className="address-name">{customer?.name}</div>
                    <div className="address-address">
                      <div className="address-address-line">
                        <span className="address-address-element">214 - 19138 26th Ave
                          <div className="address-address-highlight" />
                          <div className="address-address-label">Address line 1</div>
                        </span>
                      </div>
                      <div className="address-address-line">
                        <span className="address-address-element">Suite #N{customer?.customerId.toString().padStart(5,'0')}
                          <div className="address-address-highlight" />
                          <div className="address-address-label">Address line 2</div>
                        </span>
                      </div>
                      <div className="address-address-line">
                        <span className="address-address-element">Surrey
                          <div className="address-address-highlight" />
                          <div className="address-address-label">City</div>
                        </span>,&nbsp;
                        <span className="address-address-element">BC
                          <div className="address-address-highlight" />
                          <div className="address-address-label">Province: British Columbia</div>
                        </span>,&nbsp;
                        <span className="address-address-element">V3Z 3V7
                          <div className="address-address-highlight" />
                          <div className="address-address-label">Postal code</div>
                        </span>
                      </div>
                      <div className="address-address-line">Canada</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-4"><img src="/assets/images/flag-uk.svg" srcSet="/assets/images/flag-uk.svg" className="img-fluid mb-2" alt="flag"/></div>
                  <div className="col-8 address">
                    <div className="address-name">{customer?.name}</div>
                    <div className="address-address">
                      <div className="address-address-line">
                        <span className="address-address-element">68 Tanners Drive
                          <div className="address-address-highlight" />
                          <div className="address-address-label">Address line 1</div>
                        </span>
                      </div>
                      <div className="address-address-line">
                        <span className="address-address-element">Suite #D{customer?.customerId.toString().padStart(5,'0')}
                          <div className="address-address-highlight" />
                          <div className="address-address-label">Address line 2</div>
                        </span>
                      </div>
                      <div className="address-address-line">
                        <span className="address-address-element">Blakelands
                          <div className="address-address-highlight" />
                          <div className="address-address-label">City</div>
                        </span>,&nbsp;
                        <span className="address-address-element">MK
                          <div className="address-address-highlight" />
                          <div className="address-address-label">County/State: Milton Keynes (optional)</div>
                        </span>
                      </div>
                      <div className="address-address-line">
                        <span className="address-address-element">MK14 5BP
                          <div className="address-address-highlight" />
                          <div className="address-address-label">Postal code</div>
                        </span>
                      </div>
                      <div className="address-address-line">United Kingdom</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div className="address-notice">Certain goods may have country and carrier shipping restrictions. Please check before ordering!</div>
              <div className="notice notice-info">
                <i className="material-icons">info</i>
                <div className="notice-content">Customers getting "we couldn't verify your address" on Amazon: double-check and click "Add address" a second time to proceed.
                  <br />Amazon is aware of an issue with their address checker and is working to fix it.
                </div>
              </div>
            </Fragment>
          }
        </div>
      </div>
  )

}

export default withRouter(CustomerAddresses)