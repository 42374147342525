import React from 'react';

export const brokerageCharges = [
  {
    methodIds: [102030301], // UPS Standard USA to Canada ... extry preparation fee (scaling chart) + bond fee (2.7% min $6) + COD fee ($5)
    destination: 'CA',
    methodName: 'UPS Standard',
    url: 'https://www.ups.com/ca/en/shipping/zones-and-rates/customs-clearance.page',
    minValue: 40.01,
    charges: [
      { min: 40.01, max: 60.00, charge: 21.75 },
      { min: 60.01, max: 100.00, charge: 24.95 },
      { min: 100.01, max: 150.00, charge: 31.60 },
      { min: 150.01, max: 200.00, charge: 35.40 },
      { min: 200.01, max: 350.00, charge: 60.65 },
      { min: 350.01, max: 500.00, charge: 66.90 },
      { min: 500.01, max: 750.00, charge: 78.75 },
      { min: 750.01, max: 1000.00, charge: 88.35 },
      { min: 1000.01, max: 1600.00, charge: 103.40 },
      { min: 1600.01, max: 2500.00, charge: 116.90 },
    ],
    scaleRate: 7.75,
    bondRate: 2.7,
    bondMin: 6,
  },
  {
    methodIds: [102030320, 102030330, 102030331, 102030332], // UPS Expedited, Express .. bond fee (2.7% min $10) + COD fee ($5)
    destination: 'CA',
    methodName: 'UPS Expedited/Express',
    url: 'https://www.ups.com/ca/en/shipping/zones-and-rates/customs-clearance.page',
    minValue: 40.01,
    charges: [
      { min: 40.01, max: 9999.99, charge: 5.00 },
    ],
    scaleRate: 0,
    bondRate: 2.7,
    bondMin: 10,
  },
  {
    methodIds: [302020201], // UPS Standard Canada to USA
    destination: 'US',
    methodName: 'UPS Standard',
    url: 'https://www.ups.com/us/en/shipping/zones-and-rates/us-customs-brokerage-fees.page',
    minValue: 800,
    charges: [
      { min: 800.01, max: 1250.00, charge: 28.75 },
      { min: 1250.01, max: 2500.00, charge: 40.50 },
    ],
    scaleRate: 0,
    bondMin: 0,
    bondRate: 0,
  },
  {
    methodIds: [102030202, 102030221, 102030222, 102030223], // DHL all service USA to Canada
    destination: 'CA',
    methodName: 'DHL Express',
    url: 'http://international.dhl.ca/en/express/customs_support/brokerage_infomation.html',
    minValue: 40.01,
    charges: [
      { min: 40.01, max: 9999.99, charge: 5.25 },
    ],
    scaleRate: 0,
    bondMin: 11.25,
    bondRate: 2.5,
  },
  {
    methodIds: [102030401], // FedEx Ground USA to Canada ... brokerage fee (table) + COD ($10) + Advancement Fee (2.5% min $10)
    destination: 'CA',
    methodName: 'FedEx Ground',
    url: 'https://www.fedex.com/en-ca/ancillary-clearance-service.html',
    minValue: 40.01,
    charges: [
      { min: 40.01, max: 60.00, charge: 25.45 },
      { min: 60.01, max: 100.00, charge: 29.30 },
      { min: 100.01, max: 150.00, charge: 35.50 },
      { min: 150.01, max: 200.00, charge: 39.00 },
      { min: 200.01, max: 500.00, charge: 55.35 },
      { min: 500.01, max: 1000.00, charge: 60.75 },
      { min: 1000.01, max: 1600.00, charge: 70.10 },
      { min: 1600.01, max: 3300.00, charge: 80.00 },
    ],
    scaleRate: 6.03,
    bondMin: 10,
    bondRate: 2.5,
  },
  {
    methodIds: [102030402, 102030430], // FedEx Priority USA to Canada ... COD ($10) + Advancement Fee (2.5% min $10)
    destination: 'CA',
    methodName: 'FedEx Priority',
    url: 'https://www.fedex.com/en-ca/ancillary-clearance-service.html',
    minValue: 40.01,
    charges: [
      { min: 40.01, max: 9999.99, charge: 10.00 },
    ],
    scaleRate: 0,
    bondMin: 10,
    bondRate: 2.5,
  },
  {
    methodIds: [102030101, 102030120, 102030131], // All postal mail
    destination: 'CA',
    methodName: 'The postal service',
    url: 'https://www.canadapost.ca/tools/pg/manual/PGcustoms-e.asp#1382719',
    minValue: 20.01,
    charges: [
      { min: 20.01, max: 9999.99, charge: 9.95 },
    ],
    scaleRate: 0,
    bondMin: 0,
    bondRate: 0,
  },
];

export const getBrokerageMessage = (methodId, countryCode, declaredValue) => {
  const brokerEntry = brokerageCharges.find((m) => m.methodIds.includes(methodId) && m.destination === countryCode && declaredValue >= m.minValue);
  if (!brokerEntry) return false;

  let totalCharge = 0;
  const tableChargeRow = brokerEntry.charges.find((c) => declaredValue >= c.min && declaredValue <= c.max);
  const tableChargeMaxRow = brokerEntry.charges[brokerEntry.charges.length - 1];

  if (tableChargeRow) totalCharge += tableChargeRow.charge;
  else if (declaredValue > tableChargeMaxRow.max) totalCharge += tableChargeMaxRow.charge + Math.ceil((declaredValue - tableChargeMaxRow.max) / 1000) * brokerEntry.scaleRate;

  totalCharge += Math.max((declaredValue * brokerEntry.bondRate / 100), brokerEntry.bondMin);

  let text = `${brokerEntry.methodName} may collect import/brokerage fees {fee} when delivering this package`;

  if (totalCharge) text = text.replace('{fee} ', `(~$${totalCharge.toFixed(2)}) `);
  else text = text.replace('{fee} ', '');

  return (
    <div className="ship-option-message">
      <span className="ship-option-message-preamble">† Delivery charges:</span>
      <span className="ship-option-message-text">{text}</span>
      <a href={brokerEntry.url} target="_blank" rel="noopener noreferrer" className="ship-option-message-link">More info</a>
    </div>
  );
};
