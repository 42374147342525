import React, {Fragment, Component}   from 'react'
import {connect}                      from 'react-redux'
import {withRouter}                   from 'react-router-dom'
import PropTypes                      from 'prop-types'
import Script                         from 'react-load-script'
import {withGoogleReCaptcha}          from 'react-google-recaptcha-v3'
import axios                          from 'axios/index'
import {constants}                    from '../common/constants'
import GlobalError                    from '../components/GlobalError'
import GlobalFooter                   from '../components/GlobalFooter'
import GlobalHeader                   from '../components/GlobalHeader'
import CustomerLoading                from '../components/CustomerLoading'
import ButtonLoading                  from '../components/ButtonLoading'
import DropdownCountry                from '../components/DropdownCountry'
import DropdownRegion                 from '../components/DropdownRegion'
import '../assets/css/recurly.css'
import { convertToClientTimestamp, displayDate } 
                                      from '../common/utils'
import Plans                          from '../components/Plans'
import TagManager from "react-gtm-module";

const mapStateToProps = (globalStore) => {return {store: globalStore}}

class PaymentForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    message: PropTypes.string
  }
  constructor(props) {
    super(props)
    this.state = {
      payMessage: this.props.message || '',
      payLoading: true,
      country: '',
      state: ''
    }
  }
  
  componentDidMount(){
    window.recurly.configure({
      publicKey: constants.recurlyPublicKey
    })
    this.setState({payLoading: false})
  }
  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.message){
      this.setState({payMessage: nextProps.message})
    }
  }
  
  doSubmit = () => {
    this.setState({
      payLoading: true,
      payMessage: '',
    })
    
    window.recurly.token(document.querySelector('#recurlyForm'), (err, token) => {
      if (err){
        this.setState({
          payLoading: false,
          payMessage: err.message ? err.message : 'Declined - please check your input'
        })
      }
      else{
        this.setState({
          payLoading: false,
          payMessage: ''
        })
        this.props.onSubmit(token.id)
      }
    })
  }

  onChange = () => {
    if(this.state.payMessage)
      this.setState({
        payMessage: ''
      })
  }
  
  render () {
    
    return (
      <div className="plan-payment-form">
        <form id="recurlyForm" /*className={this.state.payLoading ? 'display-none' : ''}*/>
          <div data-recurly="card" />
          <div className="plan-payment-spacer">&nbsp;</div>
          <input type="text" data-recurly="first_name" className="form-control plan-payment-input" placeholder="First Name" onChange={this.onChange} />
          <input type="text" data-recurly="last_name"  className="form-control plan-payment-input"  placeholder="Last Name" onChange={this.onChange} />
          <input type="text" data-recurly="address1"  className="form-control plan-payment-input"  placeholder="Street Address" onChange={this.onChange} />
          <input type="text" data-recurly="city" className="form-control plan-payment-input" placeholder="City" onChange={this.onChange} />
          <input type="text" data-recurly="postal_code"  className="form-control plan-payment-input" placeholder="Postal Code" onChange={this.onChange} />
          <DropdownCountry data-recurly="country" className="form-control plan-payment-input" autoComplete="country" onChange={(value)=>{if(this.state.country!==value) this.setState({country: value, state: ''}); this.onChange()}} value={this.state.country} />
          <DropdownRegion data-recurly="state" className="form-control plan-payment-input" autoComplete="address-level1" country={this.state.country} value={this.state.state} onChange={(val)=>{this.setState({state: val}); this.onChange()}} />
          <div className="plan-payment-text">Membership subscriptions renew automatically until cancellation by re-activating the free plan. When cancelled, the membership will end on the next renewal date. No refunds are provided for early cancellations.</div>
          <input type="hidden" name="recurly-token" data-recurly="token" />
          {!!this.state.payMessage && <div className="error-text">{this.state.payMessage}</div>}
          <div className="mt-3 mb-2">
            { <div className="btn btn-primary" onClick={this.state.payLoading ? ()=>{} : this.doSubmit}>{this.state.payLoading ? <ButtonLoading/> : 'Submit'}</div> }
            <div className="plan-payment-text text-center mt-1">By submitting, you agree to our <a target="_blank" rel="noopener noreferrer" href={constants.appHome+'/terms-conditions.html'}>terms & conditions</a></div>
          </div>
        </form>
      </div>
    )
  }
}

class PublicPlan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialLoading: 0,
      initialLoadingError: '',
      paymentScriptLoaded: false,
      
      plans: [],
      visiblePlans: [],
      currentPlan: {},
      pendingPlan: {},
      subscription: {},
      selectedPlan: false,
      
      doPrompt: 0, // 1 - switch prompt, 2 - payment form, 3 - processing, 4 - thank you, 5 - cancel prompt, 6 - changes processed

      paymentIntent: false, // 1 - new subscription, 2 - change payment information
      paymentToken: '',
      paymentLoading: false,
      paymentResponse: '',
      paymentMessage: '',
      isAnnualView: true
    }
  }
  
  componentDidMount() {
    if (this.props.googleReCaptchaProps?.executeRecaptcha) {
      this.getPlanData()
    }
    this.handleCjPageType()
  }
  
  componentDidUpdate(prevProps) {
    if (!prevProps.googleReCaptchaProps?.executeRecaptcha && this.props.googleReCaptchaProps?.executeRecaptcha) {
      this.getPlanData()
    }
  }

  handleCjPageType = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'cj_page_view',
        pagePath: this.props.location.pathname,
        pageType: 'productDetail',
      },
      dataLayerName: 'dataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)
  }
  getPlanData = async () => {
    const recaptchaV3Response = await this.props.googleReCaptchaProps.executeRecaptcha('plans')

    const searchParams = new URLSearchParams(this.props.location.search)
    const cjEvent = searchParams.get('cjevent')

    const requestBody = {
      recaptchaV3Response: recaptchaV3Response,
      cjEvent: cjEvent
    }

    const response = await axios.post(`/web/plans`, requestBody)

    const plans = response.data.data.plans

    this.setState({
      initialLoading: 2,
      initialLoadingError: '',
      plans: plans,
      visiblePlans: plans,
    })
    
    return response.data.data
  }

  selectPlan = (planId) => {
    const selectedPlan = this.state.plans.find(item => item.planId === planId) 
    this.setState({
      selectedPlan: selectedPlan
    })
    
    if(['free','FREE'].includes(selectedPlan.planCode)) {

      const registerObject = {
        registerFromPlan: true, 
        plan: selectedPlan
      }

      // Construct the new route with the plan code and register object
      const newRoute = `/register/${selectedPlan.planCode}`;

      // Retrieve current query parameters
      const queryParams = this.props.location.search;

      // Push the new route along with the current query parameters
      this.props.history.push(newRoute + queryParams, registerObject);    
      return;
    }

    this.loadPaymentForm()      
  }

  handleSubmitPaymentForm = async (tokenId) => {
    this.setState({
      paymentFormToken: tokenId,
      paymentLoading: true
    })

    const registerObject = {
      registerFromPlan: true, 
      paymentToken: tokenId, 
      plan: this.state.selectedPlan
    }

    // Construct the new route with the plan code and register object
    const newRoute = `/register/${this.state.selectedPlan.planCode}`;

    // Retrieve current query parameters
    const queryParams = this.props.location.search;

    // Push the new route along with the current query parameters
    this.props.history.push(newRoute + queryParams, registerObject);     
  }

  
  cancelPrompt = () => {
    this.setState({
      paymentLoading: false,
      paymentMessage: '',
      
      doPrompt: false,
      selectedPlan: false,
    })
  }
  
  loadPaymentForm = () => {
    this.setState({
      loading: true,
      doPrompt: 2,
      paymentIntent: 1
    })
  }
  paymentScriptLoaded = () => {
    this.setState({
      paymentScriptLoaded: true
    })
  }
  
  render() {

    if(this.state.initialLoading < 2)
      return (
        <div className="body">
          <CustomerLoading />
        </div>
      )

    else if (this.state.initialLoading > 2)
      return (
        <div className="body">
          <GlobalError />
        </div>
      )

    return (
      <div>
        {this.state.doPrompt ?
          <div className="global-overlay">
            <div className="global-overlay-tapcapture global-overlay-tapcapture-grey" onClick={this.cancelPrompt}>&nbsp;</div>
            <div className="global-overlay-modal">
              {this.state.doPrompt===1 ? // Switch prompt
                <div className="plan-prompt">
                  <div className="plan-prompt-title">Confirm plan change</div>
                  <div className="plan-prompt-p">You have chosen to switch from <span className="font-bold">{this.state.currentPlan.planName}</span> to <span className="font-bold">{this.state.selectedPlan.planName}</span>.</div>
                  {this.state.selectedPlan.planCode==='FREE' &&
                    <Fragment>
                      <div className="plan-prompt-p">Your current plan will continue until it expires on {displayDate(convertToClientTimestamp(this.state.subscription.subscriptionExpiry), 'dd MMMM yyyy')} and will not renew. No further charges will be made.</div>
                      <div className="plan-prompt-p">Are you sure you wish to end your subscription?</div>
                    </Fragment>
                  }
                  {(this.state.selectedPlan.planPriority < this.state.currentPlan.planPriority && this.state.selectedPlan.planId!==1) &&
                    <div className="plan-prompt-p">The new plan will take effect at the end of your current billing cycle, at which point you will be billed the new rate. Are you sure you wish to switch?</div>
                  }
                  {this.state.selectedPlan.planPriority >= this.state.currentPlan.planPriority &&
                    <Fragment>
                      <div className="plan-prompt-p">The switch will take effect immediately. If the new plan costs more than the existing plan, you will be charged the difference in costs, pro-rated to the amount of days left in your billing cycle.</div>
                      <div className="plan-prompt-p">Do you wish to continue?</div>
                    </Fragment>
                  }
                  <div className="button-row">
                    <div className="btn btn-sm tn-primary btn-green" onClick={this.switchPlan}>Confirm</div>
                    <div className="btn btn-sm btn-outline-primary" onClick={this.cancelPrompt}>Cancel</div>
                  </div>
                </div> : null
              }
              {this.state.doPrompt===2 ? // Payment Form - for new or changing payment info
                <div className="plan-prompt">
                  <div className="plan-payment-top">
                    <div className="plan-payment-title">Billing Information</div>
                  </div>
                  <div className="plan-payment-info">
                    <div className="ppi-col">
                      <div className="ppi-label">Plan</div>
                      <div className="ppi-value">{this.state.selectedPlan.planName}</div>
                    </div>
                    <div className="ppi-col">
                      <div className="ppi-label">Begins on</div>
                      <div className="ppi-value">{displayDate(new Date(), 'dd MMMM yyyy')}</div>
                    </div>
                    <div className="ppi-col">
                      <div className="ppi-label">Total</div>
                      <div className="ppi-value">${this.state.selectedPlan.planRate ?
                        (this.state.selectedPlan.planRate.toFixed(2)+(this.state.selectedPlan.planInterval===1 ? ' monthly' : ' annually'))
                        :
                        'FREE'
                      }</div>
                    </div>
                  </div>
                  <PaymentForm onSubmit={this.handleSubmitPaymentForm} message={this.state.paymentMessage} />
                  <div className="plan-payment-cancel btn btn-light btn-primary mar-t-10" onClick={this.cancelPrompt}>cancel</div>
                </div> : null
              }
              {this.state.doPrompt===5 ?
                <div className="plan-prompt">
                  <div className="plan-prompt-title">Your subscription has been set to expire on {displayDate(convertToClientTimestamp(this.state.subscription.subscriptionExpiry), 'dd MMMM yyyy')}, at which point you will revert to the free plan. Thanks for being a subscriber with us!</div>
                  <div className="button-row">
                    <div className="btn btn-sm btn-primary btn-green" onClick={this.cancelPrompt}>Confirm</div>
                  </div>
                </div> : null
              }
            </div>
          </div> : null
        }
        <Script url="https://js.recurly.com/v4/recurly.js" onLoad={this.paymentScriptLoaded.bind(this)} />
        <GlobalHeader />
          <div className="content-area" id="customer-membership">
            <div className="plans-wrapper" style={{ width: "95%", display: 'flex', margin: '0 auto', flexDirection: 'column' }}>
              <div className="page-title" style={{margin: '25px 0px 0px 0px' }}><h2>Membership Plans</h2></div>
              <Plans
                plans={this.state.plans}
                selectPlan={this.selectPlan}
              />
            </div>
          </div>
        <GlobalFooter />
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(withGoogleReCaptcha(PublicPlan)))
