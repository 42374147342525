import React, {useState} from "react";
import TrackingInfo from "./TrackingInfo";
import PlatformInfo from "./PlatformInfo";
import ModalAsync from "../ui/ModalAsync";

const TimelineModal = ({timelineData}) => {
  const [showModal, setShowModal] = useState(false)
  const {inbound, outbound, onPlatform} = timelineData

  const footer = (
      <button className="btn btn-primary" onClick={() => setShowModal(false)}>Close</button>
  )

  const content = (
    <div className="package-tracking-info">
      <TrackingInfo trackingData={inbound} title={'Arrival Tracking Info'}/>
      <PlatformInfo onPlatformData={onPlatform}/>
      <TrackingInfo trackingData={outbound} title={'Shipment Tracking Info'}/>
    </div>
  )

  return (
    <>
      <div className="timeline-button packages-buttons" onClick={() => setShowModal(true)}>
        <button className="btn btn-primary mr-4">
          Tracking Package
        </button>
      </div>
      {showModal &&
        <ModalAsync
          title="Package Tracking Information"
          visible={showModal}
          setVisible={setShowModal}
          footer={footer}
          children={content}
        />
      }
    </>
  )
}

export default TimelineModal