import React from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';
import TagManager from 'react-gtm-module';

import { GoogleReCaptchaProvider }
  from 'react-google-recaptcha-v3';
import Root from './containers/Root';
import configureStore from './redux/configureStore';
import configureAxios from './utils/configureAxios';
import { constants } from './common/constants';

import './assets/css/fonts.css';
import './assets/css/main.css';
import './assets/css/ext.css';
import 'antd/dist/antd.min.css';

import 'url-search-params-polyfill';

import { captureUTMParameters } from './utils/utm';

axios.defaults.headers['x-api-client'] = 'web';

//development - staging/dev
TagManager.initialize({
  gtmId: 'GTM-5ZK6XDHZ',
  dataLayerName: 'dataLayer',
});

if(process.env.NODE_ENV === 'production') {
  //development - production 
  TagManager.initialize({
    gtmId: 'GTM-MSN7WW3F',
    dataLayerName: 'dataLayer',
  });

  //production - legacy
  TagManager.initialize({
    gtmId: 'GTM-5B5X7BF',
    dataLayerName: 'dataLayer',
  });
}

if (window.Intercom) {
  window.Intercom('boot', {
    app_id: 'bdj2salv',
  });
}

const store = configureStore();
configureAxios(store);

captureUTMParameters();

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey={constants.recaptchaThreeKey}>
    <Root store={store} />
  </GoogleReCaptchaProvider>,
  document.getElementById('root'),
);
