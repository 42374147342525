import React from 'react'
import ModalAsync from "./ui/ModalAsync";
import {convertToClientTimestamp, displayDate} from "../common/utils";

const CancelSubscriptionModal = ({doPrompt, subscription, cancelPrompt}) => {

  const content = (
    <div className="plan-prompt">
      <div className="plan-prompt-title">Your subscription has been set to expire on {displayDate(convertToClientTimestamp(subscription.subscriptionExpiry), 'dd MMMM yyyy')}, at which point you will revert to the free plan. Thanks for being a subscriber with us!</div>
    </div>
  )

  const footer = (
    <div className="btn btn-sm btn-primary" onClick={cancelPrompt}>Confirm</div>
  )

  return(
    <>
      {doPrompt === 5 &&
        <ModalAsync
          title="Confirm plan change"
          visible={doPrompt === 5}
          setVisible={cancelPrompt}
          children={content}
          footer={footer}
        />
      }
    </>
  )
}

export default CancelSubscriptionModal