import React 							 from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } 					 from 'react-redux';
import ErrorBoundaryPublic from './ErrorBoundaryPublic';

const mapStateToProps = (state) => ({ store: state });

function PublicRoute({
  component: Component, restricted, store, ...rest
}) {
  return (
    <ErrorBoundaryPublic>
      <Route
        {...rest}
        render={(props) => (
			  store.isLoggedIn && restricted ? <Redirect to="/dashboard" /> : <Component {...props} />
        )}
      />
    </ErrorBoundaryPublic>
  );
}

export default connect(mapStateToProps)(PublicRoute);
