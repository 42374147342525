import React, {Component}	  from 'react'
import PropTypes						from 'prop-types'
import {defaultPostalCodeData, getPostalCodeData, sanitizePostalCode}
                            from '@monarkit/postal-code-data'
import {addressCharLimits}  from '../common/handleAddress'

// TODO - server side needs to coerce and update type 0 and 3 on server-side ... or, blanket update in mysql all undeleted addresses in specified countries to fixed postal codes

export default class InputPostalCode extends Component {
	static propTypes = {
    value: PropTypes.string.isRequired,
		doUpdate: PropTypes.func.isRequired,
		countryCode: PropTypes.string.isRequired,
		showLabel: PropTypes.bool
  }
	
	constructor(props) {
		super(props)
    this.state = {
			fieldReady: false,
			postalSetting: defaultPostalCodeData,
			inputValue: '',
			inputValueIsValid: false,
		}
  }
  
  componentDidMount() {
		this.ingestProps()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.countryCode!==prevProps.countryCode || this.props.value!==this.state.inputValue)
			this.ingestProps()
	}

	ingestProps = () => {
	  let postalSetting = this.state.postalSetting
		let newValue = sanitizePostalCode(this.props.value)
		let validity = this.state.inputValueIsValid
		
		if(this.props.countryCode){
			postalSetting = getPostalCodeData(this.props.countryCode)
			this.setState({fieldReady: true})
		}
		else {
			this.setState({fieldReady: false})
		}
		
    if([0,3].includes(this.state.postalSetting.postalCodeType))
      newValue = ''
    
		if(postalSetting.postalCodeType===0){
			newValue = '00000'
			validity = true
		}
		else if(postalSetting.postalCodeType===3){
			newValue = postalSetting.pattern
			validity = true
		}
		else if(postalSetting.postalCodeType===1){
			validity = postalSetting.pattern.test(newValue)
		}
		
		this.setState({
			postalSetting: postalSetting,
			inputValue: newValue,
			inputValueIsValid: validity,
		})
		
		if(newValue!==this.props.value || validity!==this.state.inputValueIsValid){
			this.props.doUpdate({
				value: newValue,
				valid: validity
			})
		}
	}

	inputOnChange = (e) => {
		let newValue = sanitizePostalCode(e.target.value)
		
		if(this.state.fieldReady){
			let validity = this.state.postalSetting.pattern.test(newValue)
		
			this.setState({
				inputValue: newValue,
				inputValueIsValid: validity
			})
			
			this.props.doUpdate({
				value: newValue,
				valid: validity
			})
		}
		else {
			this.setState({
				inputValue: newValue,
				inputValueIsValid: true
			})
			
			this.props.doUpdate({
				value: newValue,
				valid: false
			})
		}
	}
 
	render () {
		if([0,3].includes(this.state.postalSetting.postalCodeType))
		  return false
    else {
      return (
				<div className="address-form-group">
          {!!this.props.showLabel && <div className="address-form-label">Postal Code</div>}
          <input className={"address-form-input"+((!this.state.inputValueIsValid && this.state.fieldReady && !!this.state.inputValue) ? ' form-control-invalid' : '')} autoComplete="postal-code" name="postal" value={this.state.inputValue} placeholder={this.state.postalSetting.placeholder} onChange={this.inputOnChange} maxLength={addressCharLimits.postalCode} />
					{(!this.state.inputValueIsValid && this.state.fieldReady && !!this.state.inputValue) && <div className="address-form-validation">Please enter a postal code with format: {this.state.postalSetting.placeholder}</div>}
        </div>
      )
    }
  }
}