import React, {Component} from 'react'
import PropTypes          from 'prop-types'
import {debug, constants}            from '../common/constants'
import BraintreeWebDropIn from 'braintree-web-drop-in'

// Docs for braintree web drop-in module is found at https://braintree.github.io/braintree-web-drop-in/docs/current/Dropin.html

class BraintreeDropIn extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      message: '',
      loading: false,
      selected: false,
      savePrompt: false,
      saved: false,
    }
  }

  componentDidMount(){
    this.initBraintreeDropin()
  }

  componentWillUnmount() {
    if(this.btInstance)
      this.btInstance.teardown()
  }

  initBraintreeDropin = () => {
    const braintreeDropinParams = {
      authorization: this.props.params.clientToken,
      container: '#ship-braintree-dropin',
      dataCollector: true,
      vaultManager: true,
       // 2023/03/23 - removed 3DS verification due to merchant account switch to reshipUSD
      // 2024/05/30 - re-enabled 3DS verification 
      threeDSecure: '3ds', 

      card: { 
        cardholderName: {required: true},
        vault: {
          vaultCard: false // Have a separate checkbox that instructs server whether to vault card on success or no. This option actually vaults cards before success
        }
      },
      paypal: {
        flow: 'checkout',
        intent: 'capture',
        displayName: 'Reship',
        shippingAddressEditable: false,
        landingPageType: 'billing',
        amount: this.props.params.amount,
        currency: 'USD'
      },
      applePay: {
        displayName: "Reship",
        paymentRequest: {
          total: {
            label: 'Reship',
            amount: this.props.params.amount.toFixed(2),
          }
        }
      },
      //commented out on oct 15 2021 - waiting for google web integration review and approval 
      // googlePay: {
      //   merchantId: constants.googlePayMerchantId,
      //   transactionInfo: {
      //     currencyCode: 'USD',
      //     totalPriceStatus: 'FINAL',
      //     totalPrice: this.props.params.amount.toFixed(2),
      //   }
      // }
    }
    

    if(this.props.params.shippingAddress)
      braintreeDropinParams.paypal.shippingAddressOverride = this.props.params.shippingAddress

    BraintreeWebDropIn.create(braintreeDropinParams, (err, instance)=>{
      if(err)
        this.props.handleError(err)
      else{
        this.btInstance = instance
        if(this.btInstance.isPaymentMethodRequestable()){
          this.setState({
            selected: true,
            message: ''
          })
        }
        this.btInstance.on('paymentMethodRequestable', (event)=>{
          this.setState({
            selected: true,
            message: ''
          })
          if(event.paymentMethodIsSelected)
            this.setState({savePrompt: false})
        })
        this.btInstance.on('noPaymentMethodRequestable', ()=>{
          this.setState({
            selected: false
          })
        })
        this.btInstance.on('paymentOptionSelected', ()=>{
          this.setState({
            message: '',
            savePrompt: true
          })
        })
      }
    })
  }

  processPayment = () => {
    this.setState({
      loading: true,
      savePrompt: false
    })
    const paymentParams = {
      // 2023/03/23 - removed 3DS verification due to merchant account switch to reshipUSD
      // 2024/05/30 - re-enabled 3DS verification
      threeDSecure: {
        amount: this.props.params.amount,
        billingAddress: this.props.params.billingAddress,
        collectDeviceData: true,
      }

    }
    this.btInstance.requestPaymentMethod(paymentParams, (err, payload)=>{
      this.setState({loading: false})

      if(err)
        this.props.handleError(err)
      // 2023/03/23 - removed 3DS verification due to merchant account switch to reshipUSD
      // 2024/05/30 - re-enabled 3DS verification
      else if(payload.type==='CreditCard' && !payload.liabilityShifted) {
        this.setState({
          message: 'Card verification failed, please use a different method'
        })
        this.btInstance.clearSelectedPaymentMethod()
      }
      else
        this.props.handleSubmit(payload, this.state.saved)
    })
  }

  toggleSavePaymentMethod = () => {
    this.btInstance.updateConfiguration('paypal', 'requestBillingAgreement', !this.state.saved)
    this.setState({saved: !this.state.saved})
  }

  render() {
    return (
      <>
        <div id="ship-braintree-dropin" />
        {this.state.savePrompt &&
          <div className="payment-option" onClick={this.toggleSavePaymentMethod}>
            <div className={"payment-option-"+(this.state.saved ? "on" : "off")}><i className="material-icons">{this.state.saved ? 'check_box' : 'check_box_outline_blank'}</i></div>
            <div className="payment-option-text">Save new payment method for future checkout</div>
          </div>
        }
        {!!this.state.message && <div className="payment-message-bt">{this.state.message}</div>}
        {(this.state.selected && !this.state.loading) && <button className="btn btn-sm btn-primary btn-block payment-btn" onClick={this.processPayment}>Complete checkout</button>}
      </>
    )
  }
}

export default BraintreeDropIn