import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { constants, debug } from '../common/constants';
import GlobalHeader from '../components/GlobalHeader';
import GlobalFooter from '../components/GlobalFooter';
import GlobalNotices from '../components/GlobalNotices';
import PublicLogin from '../components/PublicLogin';
import PublicRegister from '../components/PublicRegister';
import PublicForgot from '../components/PublicForgot';
import PublicVerify from '../components/PublicVerify';

const mapStateToProps = (globalStore) => ({ store: globalStore });

class Login extends Component {
  render() {
    const planData = this.props.history.location.state;

    return (
      <div className="fullbody" id="login">
        <GlobalHeader />
        <div id="login-container">
          <div className="left-area halfscreen d-flex">
            <div id="login-area">
              <GlobalNotices {...this.props} />
              <div className="card card-base">
                { this.props.location.pathname === '/login' && <PublicLogin /> }
                { this.props.location.pathname.includes('/register') && <PublicRegister planData={planData} /> }
                { this.props.location.pathname === '/forgot' && <PublicForgot /> }
                { this.props.match.path === '/verify/:email/:token' && <PublicVerify /> }
              </div>
            </div>
          </div>
          {
            planData ? (
              <div className="right-area halfscreen d-flex">
                <div className="half-container-content">
                  <div className="half-container">
                    <h3>Summary</h3>
                    <div className="card card-package card-lightGrey">
                      <div>
                        <div className="login-text">
                          {' '}
                          <span>Membership: </span> <code>{ planData.plan.planCode }</code>
                        </div>
                        {' '}
                      </div>
                    </div>
                    <div className="card card-package card-lightGrey">
                      <div>
                        <div className="login-text">
                          {' '}
                          <span>Type: </span> <code>
                          {' '}
                          { planData.plan.planInterval === 2 ? 'Yearly Subscription' : 'Monthly Subscription' }
                          {' '}
                        </code>
                        </div>
                        {' '}
                      </div>
                    </div>
                    <div className="card card-package card-lightGrey">
                      <div>
                        <div className="login-text">
                          {' '}
                          <span>Subtotal:</span>  <code> USD ${ planData.plan.planRate ? planData.plan.planRate : '0.00' }</code>
                        </div>
                        {' '}
                      </div>
                    </div>
                    <div className="card card-package card-lightGrey">
                      <div>
                        <div className="login-text">
                          {' '}
                          <span>Total:</span> <code>
                          USD $
                          { planData.plan.planRate ? planData.plan.planRate : '0.00' }
                        </code>
                        </div>
                        {' '}
                      </div>
                    </div>
                    <div />
                  </div>
                </div>

              </div>
            ) : (
              <div className="right-area halfscreen d-flex">
                <div className="half-container-content">
                  <h2>DISCOVER FREEDOM</h2>
                  <div>Shipping and shopping without limits.</div>
                </div>
              </div>
            )
          }
        </div>
        <GlobalFooter />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(Login));
