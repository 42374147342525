import React, { useState, useMemo } from 'react'
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";

const withAddressAutocomplete = WrappedComponent => {
  const HOC = (props) => {
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [finalSelectedAddress, setFinalSelectedAddress] = useState({})

    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        types: ['address']
      },
      debounce: 300
    })
  
    const handleInput = (e) => {
      setValue(e.target.value);

      if (!showSuggestions) {
        setShowSuggestions(true);
      }
    }
  
    const handleSelect = (address) => async () => {
      const results = await getGeocode({ placeId: address.place_id });
  
      const parsedAddress = {
        street1: '',
        street2: '',
        city: '',
        stateCode: '',
        postalCode: '',
        countryCode: '',
      };
  
      
      for (const {short_name, types} of results[0].address_components) {
        if (types.includes('street_number')) {
          const current = parsedAddress.street1 
          parsedAddress.street1 = short_name + ' ' + current 
        }
  
        if (types.includes('route')) {
          parsedAddress.street1 += short_name
        }
  
        if (types.includes('subpremise')) {
          parsedAddress.street2 = short_name
        }
  
        if (types.includes('locality')) {
          parsedAddress.city = short_name
        }
  
        if (types.includes('administrative_area_level_1')) {
          parsedAddress.stateCode = short_name
        }
  
        if (types.includes('country')) {
          parsedAddress.countryCode = short_name
        }
  
        if (types.includes('postal_code')) {
          parsedAddress.postalCode = short_name
        }
      }
      
      setValue(parsedAddress, false);
      setFinalSelectedAddress(parsedAddress)
      clearSuggestions();
      setShowSuggestions(false);
    }

    const renderedSuggestions = useMemo(() => {
      if (status === 'OK' && showSuggestions) {
        const suggestions = data.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion;
  
          return (
            <li key={place_id} onMouseDown={handleSelect(suggestion)}>
              <strong style={{ marginRight: '6px' }}>{main_text}</strong>
              <small style={{ display: 'block', color: '#666' }}>{secondary_text}</small>
            </li>
            )
        })

        return (
          <ul style={{
            position: 'absolute',
            zIndex: 1000,
            backgroundColor: 'white',
            boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
            width: '100%',
          }}>
            {suggestions}
            <li style={{ textAlign: "right", padding: "0.25rem 0.5rem" }}>
              <img
                src={require('../assets/icons/powered_by_google_on_white.png')}
                alt="Powered by Google"
              />
            </li>
          </ul>
        )
      }
      }, [status, showSuggestions, data]);
    
    const clearAutocompleteValues = () => {
      setValue({})
      setShowSuggestions(false)
      setFinalSelectedAddress({})
    }

    return (
      <WrappedComponent 
        {...props } 
        ready={ready} 
        value={value} 
        finalSelectedAddress={finalSelectedAddress}
        setValue={setValue}
        suggestions={data}
        handleInput={handleInput} 
        showSuggestions={showSuggestions} 
        setShowSuggestions={setShowSuggestions}
        renderedSuggestions={renderedSuggestions}
        clearAutocompleteValues={clearAutocompleteValues}
      />
    )
  }

  return HOC;
};

export default withAddressAutocomplete;