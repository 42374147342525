import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class CustomerLoading extends Component {
  render() {
    return (
      <div className="loading-fill">
        <div>
          <div className="loading-icon-circle">
            <img src="/assets/images/Reship_Logo_Pur.png" className="loading-icon" alt="Loading Reship" />
          </div>
          <h4 className="loading-text">
            { this.props.text ? this.props.text : 'LOADING' }
            {' '}
          </h4>
        </div>
      </div>
    );
  }
}

export default withRouter(CustomerLoading);
