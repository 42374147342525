import React, { Component } from 'react';

class ButtonLoading extends Component {
  render() {
    return (
      <div className="spinner spinner-compact">
        <div className="rect1">&nbsp;</div>
        <div className="rect2">&nbsp;</div>
        <div className="rect3">&nbsp;</div>
        <div className="rect4">&nbsp;</div>
        <div className="rect5">&nbsp;</div>
      </div>
    );
  }
}

export default ButtonLoading;
