import React, {Component}                 from 'react'
import {withRouter}                       from 'react-router-dom'
import {connect}                          from 'react-redux'
import axios                              from 'axios/index'
import PropTypes                          from 'prop-types'
import {warehouseData, shortDate, shortTime}    
                                          from '../common/constants'
import GlobalFooter                       from '../components/GlobalFooter'
import GlobalError                        from '../components/GlobalError'
import GlobalNotices                      from '../components/GlobalNotices'
import CustomerSidebar                    from '../components/CustomerSidebar'
import CustomerLoading                    from '../components/CustomerLoading'
import DropdownWarehouse                  from '../components/DropdownWarehouse'
import ButtonLoading                      from '../components/ButtonLoading'


const mapStateToProps = (globalStore) => {return {store: globalStore}}

class CustomerInstruction extends Component {
  static propTypes = {
    origin: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      message: '',
      edit: false,

      origin: false,

      editWarehouse: 0,
      editTracking: '',
      editPassword: '',
    }
  }

  componentDidMount() {
    this.setState({
      origin: this.props.origin
    })
  }
  
  openEdit = () => {
    this.setState({
      loading: false,
      message: '',
      edit: true,

      editWarehouse: this.state.origin.warehouse,
      editTracking: this.state.origin.tracking,
      editPassword: this.state.origin.otp
    })
  }

  submitEdit = () => {
    if(!this.state.editWarehouse)
      this.setState({message: 'Country required'})
    else if(!this.state.editTracking)
      this.setState({message: 'Tracking required'})
    else if(!this.state.editPassword)
      this.setState({message: 'Password required'})
    else if(this.state.editWarehouse===this.state.origin.warehouse && this.state.editTracking===this.state.origin.tracking && this.state.editPassword===this.state.origin.otp)
      this.closeEdit()
    else {
      this.setState({
        loading: true,
        message: '',
      })
      let postData = {
        instructionId: this.state.origin.instructionId,
        warehouse: this.state.editWarehouse,
        tracking: this.state.editTracking,
        otp: this.state.editPassword
      }
      axios.post('/customer/instructions/edit', postData)
      .then(() => {
        this.setState({
          loading: false,
          message: '',
          origin: {
            ...this.state.origin,
            warehouse: postData.warehouse,
            tracking: postData.tracking,
            otp: postData.otp,
          }
        })
        this.closeEdit()
      })
      .finally(() => {
        this.setState({ loading: false })
      })
    }
  }

  closeEdit = () => {
    this.setState({
      loading: false,
      message: '',
      edit: false,

      editWarehouse: 0,
      editTracking: '',
      editPassword: ''
    })
  }

  deleteSelf = () => {
    let postData = {
      instructionId: this.state.origin.instructionId,
    }

    axios.post('/customer/instructions/delete', postData)
    .then(() => {
      this.props.onDelete()
    })
    .finally(() => {
      this.setState({ loading: false })
    })
  }

  render(){
    if(!this.state.origin)
      return false
    else
      return (
        <div className="instruction-wrapper">
          {this.state.edit ?
            <div className="instruction instruction-edit">
              <div className="instruction-field instruction-field-warehouse">
                <div className="instruction-field-label">Country</div>
                <DropdownWarehouse warehouse={this.state.editWarehouse} onChange={(value)=>{this.setState({editWarehouse: value})}} />
              </div>
              <div className="instruction-field">
                <div className="instruction-field-label">OTP</div>
                <div className="instruction-field-input">
                  <input className="form-control" value={this.state.editPassword} onChange={(e)=>{this.setState({editPassword: e.target.value})}} maxLength={45} />
                </div>
              </div>
              <div className="instruction-field">
                <div className="instruction-field-label">Tracking #</div>
                <div className="instruction-field-input">
                  <input className="form-control" value={this.state.editTracking} onChange={(e)=>{this.setState({editTracking: e.target.value})}} maxLength={45} />
                </div>
              </div>
              {this.state.loading ?
                <div className="btn btn-primary instruction-submit"><ButtonLoading /></div>
                :
                <div className="btn btn-primary instruction-submit" onClick={this.submitEdit}>Save</div>
              }
            </div>
            :
            <div className="instruction">
              <div className="instruction-flag"><img src={`assets/images/${warehouseData(this.state.origin.warehouse).flag}`} alt="flag" /></div>
              <div className="instruction-date">{shortDate(this.state.origin.createdOn)}<br />Added {shortTime(this.state.origin.createdOn)}</div>
              <div className="instruction-otp">{this.state.origin.otp}</div>
              <div className="instruction-tracking">{this.state.origin.tracking}</div>
              {this.state.origin.expired && <div className="instruction-expired">Expired</div>}
              {this.state.origin.used && <div className="instruction-used">Received</div>}
              {(!this.state.edit && !this.state.origin.expired && !this.state.origin.used) &&
                <div className="instruction-action" onClick={this.openEdit}>
                  <i className="material-icons">edit</i>
                </div>
              }
              {!this.state.edit &&
              <div className="instruction-action" onClick={this.deleteSelf}>
                <i className="material-icons">delete_outline</i>
              </div>
              }
            </div>
          }
        </div>
      )
  }
}

class Instructions extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      initialLoading: 0,
      initialLoadingError: '',
      loading: false,
      message: '',

      instructions: [],

      addWarehouse: 0,
      addTracking: '',
      addPassword: '',
    }

    this.instructionDeleted = this.instructionDeleted.bind(this)
  }
  
  componentDidMount() {
    if(this.props.store.isLoggedIn && this.state.initialLoading===0)
      this.getInstructionData()
  }
  componentDidUpdate(){
    if(this.props.store.isLoggedIn && this.state.initialLoading===0)
      this.getInstructionData()
  }
  
  getInstructionData = () => {
    this.setState({initialLoading: 1})
    
    axios.get('/customer/instructions/get')
    .then((response) => {
      this.setState({
        initialLoading: 2,
        initialLoadingError: '',
        instructions: response.data.data,
      })
    })
    .catch((error) => {
      this.setState({
        initialLoading: 3,
        initialLoadingError: error.response ? error.response.data.message : error.message
      })
    })
  }

  instructionDeleted = () => {
    this.getInstructionData()
  }

  submitNewInstruction = () => {
    if(!this.state.addWarehouse)
      this.setState({message: 'Country required'})
    else if(!this.state.addTracking)
      this.setState({message: 'Tracking required'})
    else if(!this.state.addPassword)
      this.setState({message: 'Password required'})
    else {
      this.setState({
        loading: true,
        message: '',
      })
      let postData = {
        warehouse: this.state.addWarehouse,
        tracking: this.state.addTracking,
        otp: this.state.addPassword
      }
      axios.post('/customer/instructions/add', postData)
      .then(() => {
        this.setState({
          loading: false,
          message: ''
        })
        this.getInstructionData()
      })
      .finally(() => {
        this.setState({ loading: false })
      })
    }
  }
  
  render() {
    let currentInstructions = this.state.instructions.filter(i=>{return !i.expired && !i.used})
    let archivedInstructions = this.state.instructions.filter(i=>{return i.expired || i.used})
    
    if(this.state.initialLoading < 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <CustomerLoading />
        </div>
      )
    else if (this.state.initialLoading > 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <GlobalError />
        </div>
      )
    
    return (
      <div className="body body-customer" id="customer-instructions">
        <CustomerSidebar />
        <div className="content-area">
          <GlobalNotices { ...this.props }/>
          <div className="page-title">
            <h2>Secured Delivery</h2>
          </div>
          <div className="card">
            <div className="instructions-wrapper">
              <div className="instructions-information">
                Secured delivery is an <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202122140" target="_blank" rel="noopener noreferrer">Amazon (US & UK) delivery feature</a> that requires a password to be given to the delivery driver when a package is delivered to us. If you have received an email from Amazon with a one-time password, you can provide it to us here so that we can recieve your package.
                <br /><br />This is a free courtesy service for Reship customers. Please ensure the correct country, tracking and password is supplied to us before Amazon attempts delivery. In case of delivery issues due to missing, late or incorrectly entered information, please request they re-deliver, and submit the new OTP here in a timely fashion.
              </div>
              <h5 className="instructions-title">Add new OTP</h5>
              <div className="instructions-add">
                <div className="instruction-field instruction-field-warehouse">
                  <div className="instruction-field-label">Country</div>
                  <DropdownWarehouse warehouse={this.state.addWarehouse} onChange={(value)=>{this.setState({addWarehouse: value})}} />
                </div>
                <div className="instruction-field">
                  <div className="instruction-field-label">OTP</div>
                  <div className="instruction-field-input">
                    <input className="form-control" value={this.state.addPassword} onChange={(e)=>{this.setState({addPassword: e.target.value})}} maxLength={45} />
                  </div>
                </div>
                <div className="instruction-field">
                  <div className="instruction-field-label">Tracking #</div>
                  <div className="instruction-field-input">
                    <input className="form-control" value={this.state.addTracking} onChange={(e)=>{this.setState({addTracking: e.target.value})}} maxLength={45} />
                  </div>
                </div>
                {this.state.loading ?
                  <div className="btn btn-primary instruction-submit"><ButtonLoading /></div>
                  :
                  <div className="btn btn-primary instruction-submit" onClick={this.submitNewInstruction}>Submit</div>
                }
              </div>
              {!!this.state.message && <div className="instructions-message">{this.state.message}</div>}
              <h5 className="instructions-title">Active OTPs</h5>
              {currentInstructions.length ?
                currentInstructions.map(instruction=>{
                  return (<CustomerInstruction origin={instruction} key={'instr-'+instruction.instructionId} onDelete={this.instructionDeleted} />)
                })
                :
                <div className="instructions-empty">None yet</div>
              }
              <h5 className="instructions-title">Recent Expired/Used OTPs</h5>
              {archivedInstructions.length ?
                archivedInstructions.map(instruction=>{
                  return (<CustomerInstruction origin={instruction} key={'instr-'+instruction.instructionId} onDelete={this.instructionDeleted} />)
                })
                :
                <div className="instructions-empty">None yet</div>
              }
            </div>
          </div>

        </div>
        <GlobalFooter />
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Instructions))
