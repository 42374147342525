import React, {useEffect, useState} from 'react'
import ButtonLoading from './ButtonLoading'
import {warehouseData, customsTypes, RESHIP_CONVERTABLE_CURRENCIES} from '../common/constants'
import {remove} from 'lodash'
import axios from 'axios'
import {Spin, message} from 'antd'
import {SwapOutlined} from '@ant-design/icons'
import { round } from '../common/utils'
import {useSelector} from "react-redux";

const ItemsDeclarationForm = ({pkg, saveAction, setEdit, type, saveValue, closeAction, pkgCustomsType, saveCustoms, onFinish}) => {
  const [declaredItems, setDeclaredItems] = useState([])
  const [customsType, setCustomsType] = useState(!!!pkgCustomsType ? (pkg.outgoingCustomsType ? pkg.outgoingCustomsType : 1 ): pkgCustomsType)
  const [forexRates, setForexRates] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const itemDeclarationRules = useSelector(state => state.itemDeclarationRules)

  const CONVERTED_TOTAL = round(declaredItems.reduce((acc, val) => acc += val.declaredFxValue * 1, 0))

  const DEFAULT_NEW_ITEM = {
    itemId: 'new',
    declaredName: '',
    declaredDescription: '',
    declaredValue: 0,
    declaredQuantity: 0,
    declaredOrigin: warehouseData(pkg.warehouse).countryCode,
    declaredFromCurrency: warehouseData(pkg.warehouse).currency,
    declaredToCurrency: warehouseData(pkg.warehouse).currency,
    declaredFxRate: 1,
    declaredFxValue: 0,
    state: 'pending'
  }

  const [item, setItem] = useState(DEFAULT_NEW_ITEM)

  const IS_DELETE_BUTTON_DISABLED = [8].includes(pkg.disposition)

  useEffect(() => {
  }, [item, declaredItems])

  useEffect(() => {
    getCustomsDeclarationData()
  }, [])

  const getCustomsDeclarationData = async () => {
    try {
      setIsLoading(true)
      const items = await getItemsData()
      const forexRates = await getForexData()
      // const invoices = await getCustomsInvoices()

      const sanitizedItems = await insertInitialForexRates(items, forexRates)

      if (!sanitizedItems.length) {
        sanitizedItems.push(DEFAULT_NEW_ITEM)
      }

      setDeclaredItems(sanitizedItems)
      setForexRates(forexRates)
      // setFiles(invoices)
      saveAction(items)

      if (saveValue) {
        const total = items.reduce((acc, val) => {
          return acc += val.declaredFxValue * 1
        }, 0)
        saveValue(round(total))
      }

    } finally {
      setIsLoading(false)
    }
  }

  const insertInitialForexRates = (items, forexRates) => {
    if (items && !items.length) {
      return []
    }

    if (forexRates && !forexRates.length) {
      return []
    }

    const declaredItemsClone = [...items]

    const sanitizedItems = declaredItemsClone.map(item => {

      const selectedForexRate = getForexRate(forexRates, item.declaredToCurrency, item.declaredFromCurrency)

      const rate = handleFXConversion(selectedForexRate)
      const fxValue = round((item.declaredValue * rate))
      return {
        ...item,
        declaredFromCurrency: item.declaredFromCurrency ? item.declaredFromCurrency : warehouseData(pkg.warehouse).currency,
        declaredToCurrency: warehouseData(pkg.warehouse).currency,
        declaredFxRate: rate,
        declaredFxValue: fxValue
      }
    })
    return sanitizedItems
  }

  const getForexRate = (forexRates, toCurrency, fromCurrency) => {
    const selectedForexRate = forexRates.find(item => {
      const fxFromCurrency = fromCurrency ? fromCurrency : warehouseData(pkg.warehouse).currency
      const fxToCurrency = toCurrency ? toCurrency : warehouseData(pkg.warehouse).currency

      return item.fromCurrency === fxToCurrency && item.toCurrency === fxFromCurrency
    })
    return selectedForexRate
  }

  const getItemsData = async () => {
    const response = await axios.get(`/package/items/${pkg.packageId}?disposition=${pkg.disposition}&type=${pkg.type}`)
    const items = response.data.data

    return items
  }

  const getForexData = async () => {
    const response = await axios.get(`/forex/rates/${warehouseData(pkg.warehouse).currency}`)
    const forexRates = response.data.data

    return forexRates
  }

  /**
   * Disables all other inputs if the declared items input is currently in a 'adding-item' state.
   *
   * @param {object} declaredItem
   * @returns boolean
   */
  const isInputDisabled = (declaredItem) => {
    return declaredItems.filter(item => item.state === 'pending' || !!item.lockedForProcessing).length > 0 && declaredItem.state !== 'pending'
  }

  /**
   * Handles all string inputs in the declaration form.
   * If itemId is present, then the number input is associated to 'editing' an existing item,
   * else it is processing input for a new item (add-item state)
   *
   * @param {event} e
   * @returns
   */
  const handleItemStringInput = (e) => {
    try {
      const declaredItemsClone = [...declaredItems]
      const itemId = parseInt(e.target.name.split('-')[0])
      const name = e.target.name.split('-')[1]
      const value = e.target.value.replace(/[^A-Za-z0-9 _]/, '').slice(0, 45)
      const indexOfItemToUpdate = declaredItemsClone.findIndex(item => itemId === item.itemId*1)
      const itemToUpdate = declaredItemsClone[indexOfItemToUpdate]

      itemToUpdate[name] = value

      declaredItemsClone[indexOfItemToUpdate] = itemToUpdate
      setDeclaredItems(declaredItemsClone)

    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Handles all number inputs in the declaration form.
   * If itemId is present, then the number input is associated to 'editing' an existing item,
   * else it is processing input for a new item (add-item state)
   *
   * @param {event} e
   * @returns
   */
  const handleItemNumberInput = (e) => {

    try {
      const declaredItemsClone = [...declaredItems]
      const itemId = parseInt(e.target.name.split('-')[0])
      const name = e.target.name.split('-')[1]
      let value = e.target.value * 1

      if (isNaN(value)) {
        value = 0
      }

      const indexOfItemToUpdate = declaredItemsClone.findIndex(item => itemId === item.itemId*1)
      const itemToUpdate = declaredItemsClone[indexOfItemToUpdate]
      itemToUpdate[name] = value

      declaredItemsClone[indexOfItemToUpdate] = itemToUpdate

      const fxValue = round(declaredItemsClone[indexOfItemToUpdate].declaredValue * declaredItemsClone[indexOfItemToUpdate].declaredFxRate)

      declaredItemsClone[indexOfItemToUpdate].declaredFxValue = fxValue

      setDeclaredItems(declaredItemsClone)

    } catch (e) {
      console.log(e)
    }
  }

  const handleDropdownInput = (e) => {
    const declaredItemsClone = [...declaredItems]

    const itemId = parseInt(e.target.name.split('-')[0])
    const name = e.target.name.split('-')[1]
    const value = e.target.value.replace(/[^A-Za-z0-9 _]/, '').slice(0, 45)
    const indexOfItemToUpdate = declaredItemsClone.findIndex(item => itemId === item.itemId*1)
    const itemToUpdate = declaredItemsClone[indexOfItemToUpdate]

    itemToUpdate[name] = value

    declaredItemsClone[indexOfItemToUpdate] = itemToUpdate

    const toCurrency = declaredItemsClone[indexOfItemToUpdate].declaredToCurrency
    const fromCurrency = declaredItemsClone[indexOfItemToUpdate].declaredFromCurrency

    const selectedForexRate = getForexRate(forexRates, toCurrency, fromCurrency)
    const rate = handleFXConversion(selectedForexRate)
    const fxValue = round((declaredItemsClone[indexOfItemToUpdate].declaredValue * rate))
    declaredItemsClone[indexOfItemToUpdate].declaredFxRate = rate
    declaredItemsClone[indexOfItemToUpdate].declaredFxValue = fxValue
    setDeclaredItems(declaredItemsClone)
  }


  /**
   * Since ForexRates only contain conversion of values from CAD,USD,GBP to our supported currencies, to get the proper rate when we want to get the rate
   * for our supported currency converted to CAD,USD,GBP we need to divide the rate by 1 to get the inverted rate
   * @param {} fromCurrency
   * @param {*} toCurrency
   */
  const handleFXConversion = (selectedForexRate) => {
    const rate = 1 / selectedForexRate.rate * 1
    return round(rate)
  }

  /**
   * Locally adds items to the declaredItems array.
   *
   * @returns void
   */
  const addItems = () => {
    const declaredItemsClone = [...declaredItems]

    //control the input to prevent users from adding multiple item rows at one time 
    if (declaredItemsClone.filter(item => item.state === 'pending').length > 0) {
      message.error('Please save your items before trying to add another one')
      return;
    }

    declaredItemsClone.push(DEFAULT_NEW_ITEM)
    setDeclaredItems(declaredItemsClone)
  }


  /**
   * Updates any changes to the declared items
   *
   * @param {array} declaredItems
   */
  const updateDeclaredItem = async (declaredItems) => {
    const requestObject = {
      packageId: pkg.packageId,
      items: declaredItems,
      customsType
    }
    const savedDeclaredItems = declaredItems.map(item => ({
      ...item,
      state: null
    }))

    try {
      await axios.put('/package/item/update', requestObject)
      setDeclaredItems(savedDeclaredItems)
      setItem(DEFAULT_NEW_ITEM)
      setIsLoading(false)
      await getCustomsDeclarationData()

    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Adds the declared item
   *
   * @param {array} declaredItems
   */
  const addDeclaredItem = async (declaredItems) => {

    const addedItem = {
      ...declaredItems[declaredItems.length - 1],
    }

    delete addedItem.state
    delete addedItem.itemId

    const requestObject = {
      packageId: pkg.packageId,
      item: addedItem,
      customsType
    }

    try {
      const response = await axios.post('/package/item/add', requestObject)
      await getCustomsDeclarationData()
      if (response.status === 200) {
        closeAction()
      } else return

    } catch (e) {

    } finally {
      onFinish && onFinish()
      setIsLoading(false)
    }
  }

  /**
   * If the declaration form is in an 'add-item' state, then saving will mean adding a new item,
   * if the declaration form is not in an 'add-item' state, then saving will mean updating the items
   * @returns void
   */
  const saveDeclaredItems = async () => {
    try {
      const declaredItemsClone = [...declaredItems]
      //Prevept saving items if there are no declared items to save
      if (!declaredItemsClone.length) {
        setIsLoading(false)
        return;
      }

      //Prevept saving items if there are no declared items to save
      if (!customsType) {
        message.error('Please declare and save the type of items in your package.')
        return;
      }

      setIsLoading(true)

      const addedItem = {...declaredItems[declaredItems.length - 1],}
      if (addedItem.itemId === 'new') {
        await addDeclaredItem(declaredItemsClone)
        setIsLoading(false)
        return;
      }

      else  {
        await updateDeclaredItem(declaredItemsClone)
        closeAction()
      }

    } finally {
      onFinish && onFinish()
      setIsLoading(false)
    }
  }

  /**
   * Deletes the declaredItem
   *
   * @param {object} declaredItem
   * @returns
   */
  const deleteDeclaredItem = async (declaredItem) => {

    const declaredItemsClone = [...declaredItems]

    if (IS_DELETE_BUTTON_DISABLED) {
      return;
    }

    if (declaredItemsClone.length === 1 && declaredItemsClone[0].state === 'pending') {
      return;
    }

    if (pkg.locked === 1 || pkg.locked) {
      return
    }

    if (declaredItems.filter(item => item.state === 'pending').length > 0 && declaredItem.state !== 'pending') {
      message.error('Please finish adding the new item entry before deleting previous items')
      return;
    }

    //Removes the item currently queued in the 'add-item' state
    if (!declaredItem.itemId || declaredItem.itemId === 'new') {
      remove(declaredItemsClone, (item) => item.state === 'pending')
      setDeclaredItems(declaredItemsClone)
      return;
    }

    setIsLoading(true)

    const [removedItem] = remove(declaredItemsClone, (item) => {
      return item.itemId === declaredItem.itemId
    })

    const requestObject = {
      packageId: pkg.packageId,
      itemId: removedItem.itemId
    }

    try {

      await axios.post('/package/item/delete', requestObject)
      getCustomsDeclarationData()
      setIsLoading(false)

    } finally {
      onFinish && onFinish()
      setIsLoading(false)
    }
  }

  const getItemsTotal = () => {
    const total = declaredItems.reduce((acc, val) => {
      return acc += val.declaredValue * 1
    }, 0)

    return round(total)
  }

  const updateCustomsType = async (value) => {

    if (pkg.locked === 1 || pkg.locked) {
      return
    }

    setCustomsType(value)
    saveCustoms(value)
    await axios.post(`/package/customsType/${pkg.packageId}`, {outgoingCustomsType: value})

    onFinish && onFinish()
  }

  // used for decide display add button or not
  const initItem = declaredItems.find(item => item.itemId === 'new')


  return (
    <div className={`card ${type !== 'checkout' ? `card-white` : `` }`}>
      <div className="package-declaration">
        {type !== 'checkout' && <h5>Package Contents/Declaration</h5>}
        <div className='trans-dec'>
          {type !== 'checkout' &&  <div className="ship-card-instructions">
              Reship will submit your customs declaration in <strong>{warehouseData(pkg.warehouse).currency}</strong>.
              Simply enter the value of each item in your package in the currency in which you paid, and our currency
              conversion tool will convert the value of your items
              to <strong>{warehouseData(pkg.warehouse).currency}</strong>.
            </div>}
            {/* <div>
          <Tooltip placement="topLeft" title="Upload your commercial invoice along with your customs declaration form for a more accurate customs declaration.">  <Upload {...uploadProps}> <Button icon={<UploadOutlined />}>Upload Invoice </Button> </Upload> </Tooltip>
        </div> */}
          {type !== 'checkout' && <hr/>}
          {/*{(!pkg.locked && type === 'checkout') &&*/}
          {/*  <>*/}
          {/*    <div className="flex justify-content-between mb-3">*/}
          {/*      <h3>Edit Form</h3>*/}
          {/*    </div>*/}
          {/*  </>*/}
          {/*}*/}
          {/*{type === 'checkout' && <div className='items-declaration-icon' onClick={closeAction}><i className="material-icons">close</i></div>}*/}
            {!!declaredItems.length &&
              <div>
                {!pkg.locked &&
                  <span className='ship-card-instructions'>1. Which of the following best describes the contents of your package? </span>}
                <table style={{marginTop: 10}}>
                  <tbody>
                  <tr>
                    <td className="btn-toggle">
                      {customsTypes.map(type => {
                        return (
                          <div className={"btn " + (customsType === type.value ? ' btn-selected' : '')}
                               key={'customs-' + type.value} onClick={() => updateCustomsType(type.value)}>{type.label}
                          </div>
                        )
                      })}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            }
            <br/>
            <div className="package-declaration-container">
              <Spin spinning={isLoading}>
                <div className="table-responsive">
                  <div className={'flex justify-content-sm-between'}>
                    {!pkg.locked &&
                      <>
                        <span className='ship-card-instructions'>2. Please complete the following for customs declaration purposes, ensuring the accuracy before checkout. </span>
                        {(type !== 'checkout' && !!!initItem && itemDeclarationRules?.enableAddItem) && <div className="flex justify-content-sm-between">
                          <button className="btn-outline-primary" style={{padding: '.375rem .75rem'}} onClick={addItems}>
                            <i className="material-icons">add</i> Add Item
                          </button>
                        </div>}
                      </>
                    }
                  </div>

                  <table className="table" onMouseEnter={() => {setEdit(true)}} onMouseLeave={() => {setEdit(false)}}>
                    {
                      declaredItems.length ? (
                        <thead>
                        <tr>
                          {/* <th scope="col" width="120">Item Name<span style={{color: 'red'}}>*</span></th> */}
                          <th scope="col" width="120">Description<span style={{color: 'red'}}>*</span></th>
                          <th scope="col" width="80">Qty<span style={{color: 'red'}}>*</span></th>
                          <th scope="col" width="100">Value<span style={{color: 'red'}}>*</span></th>
                          <th scope="col" width="15"> Currency</th>
                          {/*<th scope="col" width="15"> To Currency </th>*/}
                          {/*<th scope="col" width="100"> FX Rate </th>*/}
                          {/*<th scope="col" width="100"> FX Value </th>*/}
                          <th className="text-center" scope="col" width="50">
                            {/* Delete */}
                          </th>
                        </tr>
                        </thead>
                      ) : null
                    }
                    <tbody>
                    {
                      declaredItems.map((declaredItem, index) => {
                        return (
                          <tr key={index}>
                            {/* <td>
                              <span className="mob-thead">Name<span style={{color: 'red'}}>*</span></span>
                              <input disabled={pkg.locked === 1 || pkg.locked || !itemDeclarationRules?.enableEditName} style={{border: '1px solid #DCDCDC'}} className="package-item-description-col package-item-input" value={declaredItem.declaredName} name={`${declaredItem.itemId}-declaredName`} onChange={handleItemStringInput}/>
                            </td> */}
                            <td>
                              <span className="mob-thead">Desc<span style={{color: 'red'}}>*</span></span>
                              <input disabled={pkg.locked === 1 || pkg.locked || !itemDeclarationRules?.enableEditDescription} style={{border: '1px solid #DCDCDC'}} className="package-item-description-col package-item-input" value={declaredItem.declaredDescription} name={`${declaredItem.itemId}-declaredDescription`} onChange={event => handleItemStringInput(event)}/>
                            </td>
                            <td>
                              <span className="mob-thead">Qty<span style={{color: 'red'}}>*</span></span>
                              <input disabled={pkg.locked === 1 || pkg.locked || !itemDeclarationRules?.enableEditQty} style={{border: '1px solid #DCDCDC'}} className="package-item-value-col  package-item-input" name={`${declaredItem.itemId}-declaredQuantity`} value={declaredItem.declaredQuantity || ''} onChange={event => handleItemNumberInput(event)}/></td>
                            <td>
                              <span className="mob-thead">Value<span style={{color: 'red'}}>*</span></span>
                              <input disabled={pkg.locked === 1 || pkg.locked || !itemDeclarationRules?.enableEditValue} style={{border: '1px solid #DCDCDC'}} className="package-item-value-col package-item-input" name={`${declaredItem.itemId}-declaredValue`} value={declaredItem.declaredValue || ''} onChange={event => handleItemNumberInput(event)}/></td>
                            <td>
                              <select style={{border: '1px solid #DCDCDC'}}
                                      className="package-item-value-col package-item-input"
                                      defaultValue={declaredItem.declaredFromCurrency ? declaredItem.declaredFromCurrency : warehouseData(pkg.warehouse).currency}
                                      name={`${declaredItem.itemId}-declaredFromCurrency`}
                                      disabled={pkg.locked === 1 || pkg.locked || !itemDeclarationRules?.enableEditCurrency}
                                      onChange={handleDropdownInput}>
                                {
                                  RESHIP_CONVERTABLE_CURRENCIES.map((item, i) => {
                                    return (
                                      <option value={item} key={`fromCurrency-${item}-${i}`}> {item} </option>
                                    )
                                  })
                                }
                              </select>
                            </td>
                            {/*<td>*/}
                            {/*  <strong name={`toCurrency`}>{ warehouseData(pkg.warehouse).currency } </strong>*/}
                            {/*</td>*/}
                            {/*<td>*/}
                            {/*  <span className="mob-thead">FX Rate</span>*/}
                            {/*  <strong name={`${declaredItem.itemId}-declaredFxRate`}>{ declaredItem.declaredFxRate } </strong>*/}
                            {/*</td>*/}
                            {/*<td>*/}
                            {/*  <span className="mob-thead">FX Value</span>*/}
                            {/*  <strong name={`${declaredItem.itemId}-declaredFxValue`}>{ declaredItem.declaredFxValue } </strong>*/}
                            {/*</td>*/}
                            <td className="text-center"><span className="mob-thead">Delete</span>
                              <button className="package-item-icon-col" disabled={!itemDeclarationRules?.enableDeleteItem} onClick={() => deleteDeclaredItem(declaredItem)}><i><svg class="feather"><use href="/assets/images/icons/feather-sprite.svg#trash-2"/></svg></i></button>
                            </td>
                          </tr>
                        )
                      })
                    }
                    {(type === 'checkout' && !!!initItem && itemDeclarationRules?.enableAddItem) &&
                      <tr>
                      <td colspan="6" className="text-center">
                        <div className="btn-add" onClick={addItems}><i><svg class="feather"><use href="/assets/images/icons/feather-sprite.svg#plus-circle"/></svg></i> Add Item</div>
                      </td>
                    </tr>}
                    <tr>
                      <td colspan="6">
                        <table className="package-total">
                          <tr>
                            <td style={{border: 'none'}} className="ship-item-total-title">Total Value</td>
                            <td style={{border: 'none'}}>{getItemsTotal() || ''}</td>
                            <td style={{textAlign: 'center', border: 'none'}}><SwapOutlined/></td>
                            <td style={{border: 'none'}}> {CONVERTED_TOTAL} {(warehouseData(pkg.warehouse).currency)}  </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </Spin>
            </div>
            {/*{ !!declaredItems.length && */}
            {/*  <table style={{ marginTop: 10 }}>*/}
            {/*    <tbody>*/}
            {/*      <tr>*/}
            {/*        <td className="ship-item-type">*/}
            {/*          {customsTypes.map(type=>{return (*/}
            {/*            <div className={"ship-item-type-option"+(customsType === type.value ? ' selected' : '')} key={'customs-' + type.value} onClick={()=>updateCustomsType(type.value)}>*/}
            {/*              <i className="material-icons">{customsType === type.value ? 'radio_button_checked' : 'radio_button_unchecked'}</i>{type.label}*/}
            {/*            </div>*/}
            {/*          )})}*/}
            {/*        </td>*/}
            {/*      </tr>*/}
            {/* */}
            {/*    </tbody>*/}
            {/*  </table> */}
            {/*}*/}
            {(!pkg.locked && type !== 'checkout') &&
              <>
                <div className="flex justify-content-end mt-4">
                  {/*<button className="btn btn-outline-primary mr-4" onClick={addItems}><i className="material-icons">add</i> Add Item  </button>*/}
                  <button className={`btn btn-outline-primary`} onClick={saveDeclaredItems}><i
                    className="material-icons">save</i> {isLoading ? <ButtonLoading/> : 'Save'} </button>
                </div>
              </>
            }
          {(!pkg.locked && type === 'checkout') &&
            <>
              <div className="flex justify-content-end mt-4">
                <button className="btn" onClick={saveDeclaredItems}>{isLoading ? <ButtonLoading/> : `I've confirmed the information above is correct, SAVE`} </button>
              </div>
            </>
          }

          </div>
      </div>
    </div>
  )
}

export default ItemsDeclarationForm

