import React, {Component}     from 'react'
import {withRouter}           from 'react-router-dom'
import axios                  from 'axios'
import {debug}                from '../common/constants'
import {cleanBillingAddress}  from '../common/handleAddress'
import DropdownCountry        from '../components/DropdownCountry'
import DropdownRegion         from '../components/DropdownRegion'
import LoadingSpinner         from '../components/LoadingSpinner'
import BraintreeDropIn        from '../components/BraintreeDropIn'


// This is a standalone page for a mobile webview that does not connect to the global state store
class MobileCheckout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      apiKey: '',
      customerToken: '',
      braintreeToken: '',

      customerId: 0,
      packageId: 0,
      aprId: 0,
      invoiceId: 0,
      invoiceAmount: 0,

      paymentScreen: 0, // 0 = loading/completed, show spinner... 1 = billing, 2 = bt
      paymentLoading: false, // Not for initial, only for payment processing
      paymentMessage: '',
      paymentParams: {},

      addresses: [],
      billingAddressId: 0,
      billingAddressLine1: '',
      billingAddressLine2: '',
      billingAddressCity: '',
      billingAddressStateCode: '',
      billingAddressCountryCode: '',
      billingAddressPostalCode: '',
      billingAddressMessage: ''
    }
  }

  componentDidMount() {    
    if(this.props.match.path==='/mobile/pay/:mobileToken')
      this.startPaymentFlow()
    else if (this.props.location.pathname ==='/mobile/error/1') {
      axios.post('/error', {
        error: 'Mobile Webview Checkout Error - Mobile payment token is missing',
        pathname: this.props.location.pathname,
      })
      this.setState({paymentScreen: 0})
    }
    else if (this.props.location.pathname === '/mobile/error/2') {
      axios.post('/error', {
        error: 'Mobile Webview Checkout Error - Mobile payment token is malformed',
        pathname: this.props.location.pathname
      })
      this.setState({paymentScreen: 0})
    }
    else
      this.setState({paymentScreen: 0})
  }

  startPaymentFlow = () => {
    const paramToken = this.props.match.params.mobileToken
    if(!paramToken) {
      window.location.replace('/mobile/error/1')
    }
    else {
      axios.post('/payment-mobile', {paymentToken: paramToken})
      .then((response) => {
        // document.getElementById("launcher").setAttribute("class", "hide")
        this.setState({
          paymentScreen: 1,
          customerToken: response.data.customerToken,
          braintreeToken: response.data.btToken,

          customerId: response.data.customerId,
          packageId: response.data.packageId, // No aprId flow because mobile does not have APR service
          invoiceId: response.data.invoiceId,
          invoiceAmount: response.data.invoiceAmount,
          addresses: response.data.addresses,
        })
        
        axios.defaults.headers['Authorization'] = 'Bearer '+response.data.customerToken
        
        if(response.data.shipAddress){
          let shipAddress = response.data.shipAddress
          this.setState({
            shippingAddress: {
              recipientName: shipAddress.name,
              line1: shipAddress.street1,
              line2: shipAddress.street2,
              city: shipAddress.city,
              state: shipAddress.stateCode,
              postalCode: shipAddress.postalCode,
              countryCode: shipAddress.countryCode,
            },
            billingAddressId: shipAddress.addressId
          })
          this.selectBillingAddress(shipAddress.addressId)
        }
      })
      .catch((e) => {
        window.location.replace('/mobile/error/2')
      })
    }
  }
  selectBillingAddress = (addressId) => {
    if(addressId > 0){
      let selectedAddress = this.state.addresses.filter(a=>{return +a.addressId===+addressId})[0]
      
      this.setState({
        billingAddressId: addressId,
        billingAddressLine1: selectedAddress.street1.substr(0,44),
        billingAddressLine2: selectedAddress.street2.substr(0,44) || '',
        billingAddressCity: selectedAddress.city.substr(0,44),
        billingAddressStateCode: selectedAddress.stateCode,
        billingAddressCountryCode: selectedAddress.countryCode,
        billingAddressPostalCode: selectedAddress.postalCode.substr(0,15),
        billingAddressMessage: ''
      })
    }
    this.setState({
      billingAddressId: addressId,
      billingAddressMessage: ''
    })
  }
  proceedFromBilling = () => {
    if(!cleanBillingAddress(this.state.billingAddressLine1)
      || !cleanBillingAddress(this.state.billingAddressCity)
      || !cleanBillingAddress(this.state.billingAddressPostalCode)
      || !this.state.billingAddressCountryCode)
      this.setState({billingAddressMessage: 'Address is incomplete'})
    else {
      this.setState({
        paymentScreen: 2,
        paymentLoading: false,
        paymentMessage: '',

        paymentParams: {
          clientToken: this.state.braintreeToken,
          amount: this.state.invoiceAmount,
          billingAddress: {
            streetAddress:    this.state.billingAddressLine1,
            extendedAddress:  this.state.billingAddressLine2,
            locality:         this.state.billingAddressCity,
            region:           this.state.billingAddressStateCode,
            postalCode:       this.state.billingAddressPostalCode,
            countryCodeAlpha2:this.state.billingAddressCountryCode,
          },
          shippingAddress: this.state.shippingAddress
        }
      })
    }
  }
  
  braintreeSubmit = (transaction, save) => {

    this.setState({
      paymentLoading: true,
      paymentMessage: ''
    })

    const postData = {
      transaction: transaction,
      paymentStore: !!save,
      paymentNonce: transaction.nonce,
      deviceData: transaction.deviceData,
      invoiceId: this.state.invoiceId,
      packageId: this.state.packageId,
      billingAddress: {
        street1: this.state.billingAddressLine1,
        street2: this.state.billingAddressLine2,
        city: this.state.billingAddressCity,
        stateCode: this.state.billingAddressStateCode,
        countryCode: this.state.billingAddressCountryCode,
        postalCode: this.state.billingAddressPostalCode,
      }
    }
    axios.post('/customer/checkout/processBraintree', postData)
    .then(()=>{
      // if(transaction.type === 'AndroidPayCard') {
      //   this.props.history.push('/mobile/google/success')
      // } else {
      //   this.paymentSuccess()
      // }
      this.paymentSuccess()
    })
    .catch((error)=>{
      this.setState({
        paymentScreen: 3,
        paymentLoading: false,
        paymentMessage: error.response ? error.response.data.message : error.message
      })
    })
  }
  braintreeError = (err)=>{
    this.setState({
      paymentScreen: 3,
      paymentLoading: false,
      paymentMessage: 'Sorry, this payment method was declined. Please try another.'
    })
  }
  
  paymentRetry = () => {
    this.setState({
      paymentScreen: 2,
      paymentMessage: ''
    })
  }
  paymentSuccess = () => {
    window.location.replace('/mobile/success')
  }
  paymentClose = () => {
    window.location.replace('/mobile/close')
  }

  render() {
    return (
      <div className="fullbody" id="mobile-checkout">
        {this.state.paymentScreen===0 &&
          <div className="mobile-middle">
            <div className="modal-loading">
              <LoadingSpinner icon={'lock_outline'} />
            </div>
          </div>
        }
        {this.state.paymentScreen===1 &&
          <div className="payment-content">
            <div className="modal-address-form-title">Billing Address</div>
            <div className="modal-address-form">
              <input className="modal-address-form-input form-control" placeholder="Street address" value={this.state.billingAddressLine1} onChange={(e)=>{this.setState({billingAddressLine1: e.target.value, billingAddressId: 0})}} maxLength={44} />
              <input className="modal-address-form-input form-control" value={this.state.billingAddressLine2} onChange={(e)=>{this.setState({billingAddressLine2: e.target.value, billingAddressId: 0})}} maxLength={44} />
              <input className="modal-address-form-input form-control" placeholder="City" value={this.state.billingAddressCity} onChange={(e)=>{this.setState({billingAddressCity: e.target.value, billingAddressId: 0})}} maxLength={40} />
              <input className="modal-address-form-input form-control" placeholder="Postal/zip code" value={this.state.billingAddressPostalCode} onChange={(e)=>{this.setState({billingAddressPostalCode: e.target.value, billingAddressId: 0})}} maxLength={15} />
              <div className="modal-address-form-label">Country</div>
              <DropdownCountry className="address-form-select form-control" value={this.state.billingAddressCountryCode} onChange={(val)=>{if(val!==this.state.billingAddressCountryCode) this.setState({billingAddressCountryCode: val, billingAddressStateCode: '', billingAddressId: 0})}} />
              <DropdownRegion className="address-form-select form-control" country={this.state.billingAddressCountryCode} value={this.state.billingAddressStateCode} onChange={(val)=>this.setState({billingAddressStateCode: val, billingAddressId: 0})} showLabel={true} />
            </div>
            <p className="txt-center mar-b-5">OR</p>
            <div className="modal-address-select">
              <div className="modal-address-form-title">Select from a saved address</div>
              <select className="modal-address-select-actual form-control" value={this.state.billingAddressId} onChange={(event)=>{this.selectBillingAddress(event.target.value)}}>
                <option className="modal-address-select-option" value={0}>Select...</option>
                {this.state.addresses.map(a=>{
                  return (<option className="modal-address-select-option" value={a.addressId} key={a.addressId}>{a.street1}{a.street2 && ', '+a.street2}, {a.city}, {a.stateCode}, {a.postalCode}, {a.countryCode}</option>)
                })}
              </select>
            </div>
            {this.state.billingAddressMessage && <div className="payment-message">{this.state.billingAddressMessage}</div>}
            <div className="modal-address-button-row">
              <div className="btn btn-primary" onClick={this.proceedFromBilling}>Next</div>
            </div>
          </div>
        }
        {this.state.paymentScreen===2 &&
          (this.state.paymentLoading ?
            <div className="mobile-middle">
              <div className="modal-loading">
                <LoadingSpinner icon={'lock_outline'} />
              </div>
            </div>
            :
            <div className="payment-content">
              <BraintreeDropIn params={this.state.paymentParams} handleSubmit={this.braintreeSubmit.bind(this)} handleError={this.braintreeError.bind(this)} />
              <button className="btn btn-sm btn-primary btn-block btn-grey" onClick={this.paymentClose}>Cancel</button>
            </div>
          )
        }
        {this.state.paymentScreen===3 &&
          <div className="payment-content">
            <div className="payment-message">{this.state.paymentMessage}</div>
            <button className="btn btn-sm btn-primary btn-block btn-blue" onClick={this.paymentRetry}>Retry</button>
            <button className="btn btn-sm btn-primary btn-block btn-grey" onClick={this.paymentClose}>Cancel</button>
          </div>
        }
      </div>
    )
  }
}

export default withRouter(MobileCheckout)