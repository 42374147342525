import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { constants } from '../common/constants';

const mapStateToProps = (globalStore) => ({ store: globalStore });
const mapDispatchToProps = (dispatch) => ({
  customerInit: () => { dispatch({ type: 'CUSTOMER_INIT' }); },
  clientInit: (initData) => { dispatch({ type: 'CLIENT_INIT', payload: initData }); },
});

// GlobalHeader contains header for non-logged-in pages. Header for logged in pages is integrated in CustomerSidebar.
class GlobalHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showMenu: false,
    };
    this.supportUrl = 'https://intercom.help/reship/en';
  }

  render() {
    return (
      <div className="navbar navbar-expand-md" style={{ borderBottom: '1px solid #F0F0F0' }}>
        <a className="navbar-brand" href={constants.appHome}><img style={{ height: 35, width: 190 }} src="/assets/images/Reship_Logo_Pur.png" alt="logo" /></a>
        <div className="navbar-bar gh">
          <div className={`navbar-toggler${this.state.showMenu ? ' active' : ''}`} onClick={() => { this.setState({ showMenu: !this.state.showMenu }); }}>
            <span className="navbar-toggler-icon">
              <svg className="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30" focusable="false">
                <title>Menu</title>
                <path stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M4 7h22M4 15h22M4 23h22" />
              </svg>
            </span>
          </div>
        </div>
        <div className={`collapse navbar-collapse${this.state.showMenu ? ' show' : ' hide'}`}>
          <ul className="sd-navbar d-block d-md-none">
            <li><a href={`${constants.appHome}/about.html`}>About</a></li>
            <li className="hide-900"><a href={`${constants.appHome}/business-to-business.html`}>B2B</a></li>
            <li><a href={`${constants.appHome}/services.html`}>Services</a></li>
            <li><Link to="/plans">Pricing</Link></li>
            <li><Link to="/calculator">Shipping calculator</Link></li>
            <li><a href={this.supportUrl}>Support</a></li>
            <li className="hide-900"><a href={`${constants.appHome}/info/`}>Info</a></li>
            {
              this.props.store.isLoggedIn
                ? null
                : <li className={window.location.pathname === '/plans' ? 'active' : undefined}><Link to="/plans">Sign Up</Link></li>
            }
            {this.props.store.isLoggedIn
              ? <li className={window.location.pathname === '/' ? 'active' : undefined}><Link to="/">My Account</Link></li>
              : <li className={window.location.pathname === '/login' ? 'active' : undefined}><Link to="/login">Log in</Link></li>}
          </ul>
        </div>
        <ul className="nav navbar-right">
          <li><a href={`${constants.appHome}/about.html`}>About</a></li>
          <li className="hide-900"><a href={`${constants.appHome}/business-to-business.html`}>B2B</a></li>
          <li><a href={`${constants.appHome}/services.html`}>Services</a></li>
          <li><Link to="/plans">Pricing</Link></li>
          <li><Link to="/calculator">Shipping calculator</Link></li>
          <li><a href={this.supportUrl}>Support</a></li>
          <li className="hide-900"><a href={`${constants.appHome}/info/`}>Info</a></li>
          {
            this.props.store.isLoggedIn
              ? null
              : <li className={window.location.pathname === '/plans' ? 'active' : undefined}><Link to="/plans">Sign Up</Link></li>
          }
          { this.props.store.isLoggedIn
            ? <li className={window.location.pathname === '/' ? 'active' : undefined}><Link to="/">My Account</Link></li>
            : <li className={window.location.pathname === '/login' ? 'active' : undefined}><Link to="/login">Log in</Link></li>}
        </ul>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GlobalHeader));
