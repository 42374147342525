import React, {Component, Fragment}   from 'react'
import { withRouter, Link }           from 'react-router-dom'
import {connect}                      from 'react-redux'
import axios                          from 'axios/index'
import {message, Tooltip, Badge, Table} from 'antd'

import {warehouses, parseStatus, dimensionsImperial, weightImperial}
                                      from '../common/constants'
import {addressCharLimits}            from '../common/handleAddress'
import GlobalFooter                   from '../components/GlobalFooter'
import GlobalError                    from '../components/GlobalError'
import GlobalNotices                  from '../components/GlobalNotices'
import CustomerSidebar                from '../components/CustomerSidebar'
import CustomerLoading                from '../components/CustomerLoading'
import ButtonLoading                  from '../components/ButtonLoading'

import { convertToClientTimestamp, displayDate } from '../common/utils'
import PrereceiveModal from "../components/Prereceiving/PrereceiveModal";
import ConsolidationModal from "../components/ConsolidationModal";


const mapStateToProps = (globalStore) => {return {store: globalStore}}
const mapDispatchToProps = (dispatch) => {return {
  logout: ()=>{dispatch({type: 'CUSTOMER_LOGOUT'})},
  setWarehouse: (w)=>{dispatch({type: 'SET_WAREHOUSE', payload: w})}
}}

class Packages extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      initialLoading: 0,
      initialLoadingError: '',
      
      loading: false,
      message: '',
      
      fees: {
        consolidation: 0,
        giftWrap: 0,
        removeInvoice: 0,
        addPadding: 0
      },

      warehouse: 1,

      soloPackages: [],
      consolidationPackages: [],
      undeclaredPackages: [],
      warehouses: [],
      viewPackageArray: [],
      canConsolidate: false,
      
      consolidationMode: false, // 1 = consolidate selection, 2 = popup with cost total and note, 3 = popup with error
      consolidation: [],
      conDiscard: true,
      conPlainBox: false,
      conPadding: [],
      conRemoveInvoice: [],
      conGiftWrap: [],
      conCost: 0,
      conOptionsCost: 0,
      conRequest: '',
      
      deconsolidationMode: false,
      deconsolidationIds: [],
      
      prefPhotos: null,
      errorNotification: false,
      selectedConsPkgArray: [],
      addFailedOne: {}
    }
  }
  
  componentDidMount() {
    if(this.props.store.isLoggedIn && this.state.initialLoading===0) {
      this.getPackagesData(this.props.store.customerSessionData.warehouse)      
    }
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.store.isLoggedIn && this.state.initialLoading===0)
      this.getPackagesData(this.props.store.customerSessionData.warehouse)
  }
  
  getPackagesData = (targetWarehouse=0) => {
    this.setState({initialLoading: 1})
    
    axios.get('/customer/packages/getPackages')
    .then((response) => {
      
      let warehouseArray = warehouses.map(w=>{
        let soloPackages = response.data.soloPackages.filter(p=>{return p.warehouse===w.id})
        let consolidationPackages = response.data.consolidationPackages.filter(cp=>{return cp.warehouse===w.id})
        let packages = consolidationPackages.map(p=>{return {...p, consolidated: 1}}).concat(soloPackages.map(p=>{return {...p, consolidated: 0}})).sort((a, b) => b.packageId - a.packageId).sort((a, b) => a.warehouse - b.warehouse)
        let canConsolidate = packages.filter(pkg=>{return !(pkg.locked || ![13,14,15,21,24,26,27,28,99].includes(pkg.restricted))}).length > 1

        return {
          id: w.id,
          name: w.name,
          countryCode: w.countryCode,
          countryLong: w.countryLong,
          countryLonger: w.countryLonger,
          flag: w.flag,
          count: packages.length,
          packages,
          canConsolidate
        }
      })

      if(targetWarehouse===0){
        // Unspecified, treat as fresh load, go to first inbox with packages, or US if no pkgs anywhere
        let validWarehouse = warehouseArray.find(w=>w.count > 0)
        targetWarehouse = validWarehouse ? validWarehouse.id : 1
      }

      this.setState({
        fees: response.data.fees,
        soloPackages: response.data.soloPackages,
        consolidationPackages: response.data.consolidationPackages,
        undeclaredPackages: response.data.undeclaredPackages,
        warehouses: warehouseArray,
        prefPhotos: response.data.prefPhotos,
        bypassRepackWeightLimit: response.data.bypassRepackWeightLimit
      })

      this.loadWarehouse(targetWarehouse, warehouseArray)
    })
    .catch((error) => {
      if(error.response && error.response.status===403){
        this.props.logout()
        this.props.history.push('/login')
      }
      else {
        this.setState({
          initialLoading: 3,
          initialLoadingError: error.response ? error.response.data.message : error.message
        })
      }
    })
  }
  
  consolidateModeCancel = () => {
    this.setState({
      consolidationMode: false,
      message: '',
      consolidation: [],
      conDiscard: true,
      conPlainBox: false,
      conPadding: [],
      conRemoveInvoice: [],
      conGiftWrap: [],
      conOptionsCost: 0,
      conCost: 0,
      conRequest: '',
    })
  }
  consolidationAdd = (packageId) => {
    let selectedForConsolidation = this.state.consolidation
    let selectedConsPkgArray = this.state.selectedConsPkgArray
    const newAddedPkg = this.state.soloPackages.find(p => p.packageId*1 === packageId*1)
    const newAddedCon = this.state.consolidationPackages.find(p => p.packageId*1 === packageId*1)
    const newAddedOne = newAddedPkg ? newAddedPkg : newAddedCon

    if (selectedForConsolidation.length === 0) {
      selectedForConsolidation.push(packageId)
      selectedConsPkgArray.push(newAddedOne)
    } else {
      const selectedHazmatArray = selectedConsPkgArray.map(p => p.restrictUNCode)
      const withHazmat = selectedHazmatArray.find(i => !!i)

      if (!newAddedOne.restrictUNCode || !withHazmat) {
        selectedForConsolidation.push(packageId)
        selectedConsPkgArray.push(newAddedOne)
      } else {
        if (selectedHazmatArray.includes(newAddedOne.restrictUNCode)){
          selectedForConsolidation.push(packageId)
          selectedConsPkgArray.push(newAddedOne)
        } else {
          this.setState({errorNotification: true, addFailedOne: newAddedOne})
        }
      }
    }
    this.setState({consolidation: selectedForConsolidation, selectedConsPkgArray: selectedConsPkgArray})
  }
  consolidationRemove = (packageId) => {
    this.setState({
      consolidation: this.state.consolidation.filter(p=>{return p!==packageId}),
      selectedConsPkgArray: this.state.selectedConsPkgArray.filter(p=>{return p.packageId!==packageId})
    })
  }

  consolidateModePrompt = () => {
    let selectedSolo = this.state.soloPackages.filter(pkg=>{return this.state.consolidation.includes(pkg.packageId)})
    let selectedCons = this.state.consolidationPackages.filter(pkg=>{return this.state.consolidation.includes(pkg.packageId)})
    
    let weight = (selectedSolo.length ? selectedSolo.map(pkg=>{return +weightImperial(pkg.physical.weight)}).reduce((a, val)=>{return a+val}) : 0) +
      (selectedCons.length ? selectedCons.map(pkg=>{return +weightImperial(pkg.physical.weight)}).reduce((a, val)=>{return a+val}) : 0)

    if(this.state.consolidation.length < 2)
      this.setState({consolidationMode: 3, message: 'You need to select two or more packages to consolidate.'})
    else if(!this.state.bypassRepackWeightLimit && weight > 140)
      this.setState({consolidationMode: 3, message: 'Sorry, we can only consolidate packages up to a combined maximum of 140 lbs / 63.5 kg. Please remove some items from your consolidation.'})
    else {
      this.setState({
        loading: false,
        message: '',
        consolidationMode: 2,
        conCost: this.state.fees.consolidation * this.state.consolidation.length
      })
    }
  }

  handleSubmitConsolidationRequest = async () => {
    try {
      this.setState({loading: true})
      await this.handleRepackRequestCheck()
      await this.doConsolidation()
      this.setState({loading: false})
    } finally {
      this.setState({loading: false})
    }
  }

  handleRepackRequestCheck = async () => {
    let requestBody = {
      packages: this.state.consolidation,
    }

    await axios.post('/customer/packages/consolidate/check', requestBody)
  }


  doConsolidation = async () => {
    this.setState({loading: true})
    let postData= {
      packages: this.state.consolidation,
      reqGiftWrap: this.state.conGiftWrap,
      reqRemoveInvoice: this.state.conRemoveInvoice,
      reqPadding: this.state.conPadding,
      conRequest: this.state.conRequest,
      conDiscard:  this.state.conDiscard
      // conPlainBox:  this.state.conPlainBox
    }
    await axios.post('/customer/packages/consolidate', postData)
    .then((response) => {
      this.props.setWarehouse(this.state.warehouse)
      this.props.history.replace('/done/packageConsolidation')
    })
    .catch((error) => {
      this.setState({loading: false, message: error.response ? `${error.response.data.message}` : error.message})
    })
  }
  
  togglePhotoPref = (val) => {
    let postData= {
      prefPhotos: !!val
    }
    axios.post('/customer/updatePrefPhotos', postData)
    .then((response) => {
      if(response.data.success){
        this.setState({
          prefPhotos: postData.prefPhotos
        })
      }
    })
    .catch((e) => {
      message.error(e.response.data.message)
    })
  }

  canGiftWrap = (packageId) => {
    let packageData = this.state.viewPackageArray.find(p=>p.packageId===packageId)
    return packageData ? (packageData.physical.height < 60 && packageData.physical.width < 60 && packageData.physical.length < 60) : false
  }

  addOption = (pkg, opt) => {
    if(opt==='pad'){
      let newPad = [...new Set([...this.state.conPadding, pkg])]
      this.setState({conPadding: newPad}, this.calcOptions)
    }
    else if(opt==='rem'){
      let newRem = [...new Set([...this.state.conRemoveInvoice, pkg])]
      this.setState({conRemoveInvoice: newRem}, this.calcOptions)
    }
    else if(opt==='gift'){
      let newGift = [...new Set([...this.state.conGiftWrap, pkg])]
      this.setState({conGiftWrap: newGift}, this.calcOptions)
    }
  }
  remOption = (pkg, opt) => {
    if(opt==='pad'){
      let newPad = this.state.conPadding.filter(p=>p!==pkg)
      this.setState({conPadding: newPad}, this.calcOptions)
    }
    else if(opt==='rem'){
      let newRem = this.state.conRemoveInvoice.filter(p=>p!==pkg)
      this.setState({conRemoveInvoice: newRem}, this.calcOptions)
    }
    else if(opt==='gift'){
      let newGift = this.state.conGiftWrap.filter(p=>p!==pkg)
      this.setState({conGiftWrap: newGift}, this.calcOptions)
    }
  }
  calcOptions = () => {
    let totalOptions = this.state.conPadding.length * this.state.fees.addPadding +
      this.state.conRemoveInvoice.length * this.state.fees.removeInvoice +
      this.state.conGiftWrap.length * this.state.fees.giftWrap
    this.setState({
      conOptionsCost: totalOptions
    })
  }

  loadWarehouse = (targetWarehouse, warehouses=this.state.warehouses) => {
    let warehouse = warehouses.filter(w=>w.id===targetWarehouse)[0]

    this.setState({
      initialLoading: 2,
      warehouse: targetWarehouse,
      viewPackageArray: warehouse.packages,
      canConsolidate: warehouse.canConsolidate,
      consolidation: []
    })

    this.props.setWarehouse(targetWarehouse)
  }

  failedPackage = () => {
    return [this.state.addFailedOne]
  }

  selectedDataSource = () => {
    return this.state.selectedConsPkgArray.map((pkg, i) => ({
      ...pkg,
      hazmatName: pkg.hazmatName ? pkg.hazmatName : `No Hazmat`,
      key: i
    }))
  }

  updateModalState = (newState) => {
    this.setState(newState)
  }

  columns = [
    {
      title: 'PackageId',
      dataIndex: 'packageId',
      key: 'packageId',
    },
    {
      title: 'Hazmat Materials',
      dataIndex: 'hazmatName',
      key: 'hazmatName',
    },
  ]

  render() {
    if(this.state.initialLoading < 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <CustomerLoading />
        </div>
      )
    else if (this.state.initialLoading > 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <GlobalError />
        </div>
      )
    
    return (
      <div className="body body-customer" id="customer-profile">
        <CustomerSidebar />
        <div className="content-area">
          <GlobalNotices { ...this.props }/>
          <div className="page-title"><h2>My Packages</h2></div>
          <div className="packages-buttons-wrapper">
            <div className="packages-warehouses">
              {this.state.warehouses.map(w=>{
                return (
                  <div className={`packages-warehouse ${w.id===this.state.warehouse ? 'packages-warehouse-active': ''}`} onClick={()=>{this.loadWarehouse(w.id)}} key={'select'+w.id}>
                    <img className="packages-warehouse-icon" src={`assets/images/${w.flag}`} alt="flag" />
                    <div className="packages-warehouse-name">{w.countryLong}</div>
                    <div className="packages-warehouse-counter">{w.count}</div>
                  </div>
                )
              })}
            </div>
            {this.props.store.allowPreReceiving && <PrereceiveModal getPackage={this.getPackagesData} warehouse={this.state.warehouse} carrierList={this.props.store.epCarrierList}/>}
          </div>
          {this.state.viewPackageArray.length===0 ?
            <div className="card card-border p-0">
              <div className="blank-area">
                <img src="assets/images/group.png" alt="groups"/>
                <strong>No packages here!</strong>
              </div>
            </div>
            :
            <div className="packages-list">
              {this.state.viewPackageArray.map(pkg=>{
                let selected = this.state.consolidation.filter(p=>{return p===pkg.packageId}).length
                let status = parseStatus(pkg)

                const packageCard = (
                  <div className={`card card-package card-white ${selected ? 'card-selected' : ''}`} key={pkg.packageId}>
                    {this.state.canConsolidate &&
                      ((pkg.locked || ![13,14,15,21,24,26,27,28,99].includes(pkg.restricted) || pkg.physical.size >= 20 || (this.state.undeclaredPackages.includes(pkg.packageId))) ?

                          this.state.undeclaredPackages.includes(pkg.packageId) ?
                            <Tooltip title={ <span> <i className="material-icons color-grey">info</i> Please declare the items inside the package before consolidating </span>}>
                              <div className="packages-package-select">
                                <i className="material-icons color-grey">indeterminate_check_box</i>
                              </div>
                            </Tooltip>
                            :
                            <div className="packages-package-select">
                              <i className="material-icons color-grey">indeterminate_check_box</i>
                            </div>
                          :
                          <div className="packages-package-select" onClick={()=>{selected ? this.consolidationRemove(pkg.packageId) : this.consolidationAdd(pkg.packageId)}}>
                            <i className="material-icons">{selected ? 'check_box' : 'check_box_outline_blank'}</i>
                          </div>
                      )}


                    <div className="packages-package-icon">{pkg.consolidated ? <i className="fas fa-boxes" /> : <i className="fas fa-box" />}</div>
                    <Link className="packages-package-info-wrapper" to={'/package?pkg='+pkg.packageId}>
                      <div className="packages-package-info">
                        <code>{pkg.consolidated ? 'Consolidation' : 'Package' } #{pkg.packageId.toString().padStart(6, '0')}</code>
                        <div className="packages-package-desc">
                          {displayDate(convertToClientTimestamp(pkg.date), 'dd MMM yyyy')}
                          {!!pkg.physical.weight && <Fragment> | {weightImperial(pkg.physical.weight)} lb | {dimensionsImperial(pkg.physical.length)} x {dimensionsImperial(pkg.physical.width)} x {dimensionsImperial(pkg.physical.height)} in</Fragment>}
                          {pkg.physical.size >= 20 && <> | {pkg.physical.size===20 ? 'Oversized' : 'Pallet'}</>}
                        </div>
                        <div className="packages-package-desc">{pkg.customerTitle ? pkg.customerTitle : pkg.consolidated ? 'Click to view packages in consolidation' : (pkg.incoming.vendor+' via '+pkg.incoming.carrier+' ('+pkg.incoming.tracking+')')}</div>
                        {(pkg.restricted > 21 && pkg.restricted < 24) && <div className="packages-package-desc">This package contains lithium batteries and must be shipped separately.</div>}
                        {([1, 2, 11, 12].includes(pkg.restricted)) && <div className="packages-package-desc">Special handling required / please contact us</div>}
                      </div>
                      <div className="packages-package-progress-wrapper">
                        <div className={"package-process"+(this.state.consolidationMode===1 ? ' cons':'')} >
                          <div className="package-process-row form-row">
                            <div className="col"><div className={`package-step ${status.status > 5 ? (status.status > 10 ? 'step-success-1' : 'step-complete') : 'step-incomplete'}`}>&nbsp;</div></div>
                            <div className="col"><div className={`package-step ${status.status > 11 ? (status.status > 19 ? 'step-success-2' : 'step-incomplete') : ''}`}>&nbsp;</div></div>
                            <div className="col"><div className={`package-step ${status.status > 20 ? (status.status > 29 ? 'step-complete' : 'step-incomplete') : ''}`}>&nbsp;</div></div>
                          </div>
                          <small>{status.text}</small>
                        </div>
                      </div>
                    </Link>
                    <div className="pkg-next"><img src="assets/images/right.svg" alt="right"/></div>
                  </div>
                )

                return pkg.hazmatName ? (
                  <Badge.Ribbon text={`${pkg.hazmatName} Contained`} color="#ffa908">
                    {packageCard}
                  </Badge.Ribbon>
                ) : (packageCard)
              })}
            </div>
          }
          {!!this.state.consolidation.length && <div className="consolidation-sticky">
            {this.state.canConsolidate ?
              <div className="consolidation-sticky-content">
                {this.state.consolidation.length > 1 && <div className="consolidation-sticky-content-title">
                  <span>{this.state.consolidation.length} packages </span>selected
                </div>}
                <div className="packages-buttons">
                  {this.state.consolidation.length===0 && <button className="btn btn-primary  mr-4" disabled={true}>Select Packages to Consolidate</button>}
                  {this.state.consolidation.length===1 && <button className="btn btn-primary  mr-4" disabled={true}>Select 1 more Package to Consolidate</button>}
                  {this.state.consolidation.length > 1 && <button className="btn btn-primary  mr-4" onClick={this.consolidateModePrompt}>Consolidate Packages</button>}
                  {<button className="btn btn-outline-primary" onClick={()=>{this.setState({consolidation: []})}}>Reset</button>}
                </div>
              </div>

              :
              <div className="packages-buttons">
                <button className="btn btn-primary  mr-4" disabled={true}><img src="assets/images/connects.svg" alt="icon" /> Not enough eligible packages to consolidate</button>
              </div>
            }
          </div>}
        </div>
        <GlobalFooter />
        {this.state.consolidationMode===2 &&
          <ConsolidationModal
            consolidationMode={this.state.consolidationMode}
            conDiscard={this.state.conDiscard}
            consolidation={this.state.consolidation}
            conPadding={this.state.conPadding}
            consolidationPackages={this.state.consolidationPackages}
            conRequest={this.state.conRequest}
            conCost={this.state.conCost}
            conOptionsCost={this.state.conOptionsCost}
            message={this.state.message}
            loading={this.state.loading}
            setState={this.updateModalState}
            remOption={this.remOption}
            addOption={this.addOption}
            handleSubmitConsolidationRequest={this.handleSubmitConsolidationRequest}
            cancelConsolidation={this.consolidateModeCancel}
          />
        }
        {this.state.consolidationMode===3 &&
          <div className="global-overlay">
            <div className="global-overlay-tapcapture global-overlay-tapcapture-grey" onClick={this.consolidateModeCancel}>&nbsp;</div>
            <div className="global-overlay-modal">
              <div className="modal-info">
                <div className="modal-p">{this.state.message}</div>
              </div>
              <div className="modal-buttons mb-3">
                <button className="btn btn-outline-primary" onClick={this.consolidateModeCancel}>Close</button>
              </div>
            </div>
          </div>
        }

        {this.state.errorNotification &&
          <div className="global-overlay">
            <div className="global-overlay-tapcapture global-overlay-tapcapture-grey" onClick={() => this.setState({errorNotification: false})}>&nbsp;</div>
            <div className="global-overlay-modal">
              <div className="modal-info">
                <div className="flex justify-content-between">
                  <div className="consolidation-error-modal-title">Cannot consolidate</div>
                  {/*<i className="icon" onClick={() => this.setState({errorNotification: false})}><svg className="feather feather-black"><use href="/assets/images/icons/feather-sprite.svg#x" /></svg></i>*/}
                </div>
                <p className="text-secondary">
                  packages with different types of hazmat materials are not allowed for consolidation
                </p>
                <div>
                  <div>Selected packages for consolidation:</div>
                  <Table
                    dataSource={this.selectedDataSource()}
                    columns={this.columns}
                    size="small"
                    pagination={false}
                  />
                  <br/>
                  <div>Package that cannot be consolidated: </div>
                  <Table
                    dataSource={this.failedPackage()}
                    columns={this.columns}
                    size="small"
                    pagination={false}
                  />
                </div>

              </div>
              <div className="modal-buttons mb-3">
                <button className="btn btn-outline-primary" onClick={() => this.setState({errorNotification: false})}>Close</button>
              </div>
            </div>
          </div>}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Packages))
