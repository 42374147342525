import React, {Component, Fragment}   from 'react'
import {withRouter}                   from 'react-router-dom'
import {connect}                      from 'react-redux'
import axios                          from 'axios/index'
import {CopyToClipboard}              from 'react-copy-to-clipboard'
import {shortDate, debug}             from '../common/constants'
import GlobalFooter                   from '../components/GlobalFooter'
import GlobalError                    from '../components/GlobalError'
import GlobalNotices                  from '../components/GlobalNotices'
import CustomerSidebar                from '../components/CustomerSidebar'
import CustomerLoading                from '../components/CustomerLoading'
import ButtonLoading                  from '../components/ButtonLoading'
import agent from '../api/agent'


const mapStateToProps = (globalStore) => {return {store: globalStore}}

class Refer extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      initialLoading: 0,
      initialLoadingError: '',
      loading: false,
      message: '',
      messageSuccess: false,
      
      code: '',
      wasReferred: 0,
      signedUp: 0,
      lastSignup: null,
      creditEarned: 0,
      
      referName: '',
      referEmail: '',
      referSuccess: '',
      
      copied: false,
    }
  }
  
  componentDidMount() {
    if(this.props.store.isLoggedIn && this.state.initialLoading===0)
      this.getReferData()
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.store.isLoggedIn && this.state.initialLoading===0)
      this.getReferData()
  }
  
  getReferData = () => {
    this.setState({initialLoading: 1})

    axios.get('/customer/getRefers')
    .then((response) => {
      this.setState({
        initialLoading: 2,
        initialLoadingError: '',
        code: response.data.code,
        wasReferred: response.data.wasReferred,
        signedUp: response.data.signedUp,
        lastSignup: response.data.lastSignup,
        creditEarned: response.data.creditEarned
      })
    })
    .catch((error) => {
      this.setState({
        initialLoading: 3,
        initialLoadingError: error.response ? error.response.data.message : error.message
      })
    })
  }
  
  sendReferEmail = async () => {
    if(!this.state.referName)
      this.setState({message: 'Please include your friend’s name so they don’t think it’s spam!'})
    else if(!this.state.referEmail)
      this.setState({message: 'Please enter an email address.'})
    else {

      try {
        this.setState({
          loading: true,
          message: '',
          messageSuccess: false,
        })
        
        let postData = {
          referName: this.state.referName,
          referEmail: this.state.referEmail,
        }
  
        await agent.customer.referralUsed(postData)
        await agent.email.customerReferralUsed(postData)
  
        this.setState({
          loading: false,
          message: 'Email to '+this.state.referEmail+' sent!',
          messageSuccess: true,
          referName: '',
          referEmail: ''
        })
        
      } catch(error) {
        this.setState({
          loading: false,
          message: error.response ? error.response.data.message : error.message,
          messageSuccess: false,
        })
      }
    }
  }
  
  render() {
    if(this.state.initialLoading < 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <CustomerLoading />
        </div>
      )
    else if (this.state.initialLoading > 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <GlobalError />
        </div>
      )
    
    return (
      <div className="body">
        <CustomerSidebar />
        <div className="content-area">
          <GlobalNotices { ...this.props }/>
          <div className="page-title"><h2>Refer Friends</h2></div>
          <div className="referral-link">
            <img src="assets/images/stars.png" className="stars" alt="start"/>
            <img src="assets/images/children.svg" className="children" alt="children"/>
            <h4>Your Referral Code</h4>
            <p>Copy the referral code below and send it to your friends and family. When they register with your code and ship their first order, both of you will receive a US$10 credit towards future shipments!</p>
            {(!!this.state.signedUp || !!this.state.wasReferred) &&
              <div className="referral-info">
                {!!this.state.signedUp &&
                  <Fragment>
                    <p>So far <span className="font-weight-bold">{this.state.signedUp}</span> {this.state.signedUp===1 ? 'person has' : 'people have'} signed up using your referral code. Thanks for spreading the word!</p>
                    {!!this.state.creditEarned ?
                      <p>You've earned ${this.state.creditEarned.toFixed(2)} credit so far through referring friends & family, good stuff!</p>
                      :
                      <p>When they make their first shipment, both of you will receive a $10 credit.</p>
                    }
                  </Fragment>
                }
                {this.state.lastSignup && <p>The last person who signed up using your referral code was on {shortDate(this.state.lastSignup)}.</p>}
                {this.state.wasReferred===1 && <p>You were referred by a friend and will receive a $10 credit after your first shipment!</p>}
                {this.state.wasReferred===2 && <p>You were referred by a friend and have received your $10 credit for your first shipment!</p>}
              </div>
            }
            <div className="referral-code">
              <div className="referral-code-actual">{this.state.code}</div>
              <CopyToClipboard text={this.state.code} onCopy={()=>{this.setState({copied: true})}}><button className="btn btn-rounded">{this.state.copied ? 'Copied!' : 'Copy'}</button></CopyToClipboard>
            </div>
          </div>
          <div className="invitation sub-title pt-3">
            <h4>OR Invite via Email</h4>
            <div className="card card-outline padd-50">
              <div className="row mb-1">
                <div className="form-group col-sm-5">
                  <label>Friend’s name</label>
                  <input type="text" className="form-control" placeholder="Enter friend's name" value={this.state.referName} onChange={(e)=>{this.setState({referName: e.target.value})}} />
                </div>
                <div className="form-group col-sm-5">
                  <label>Friend’s email address</label>
                  <input type="email" className="form-control" placeholder="Enter friend's email address" value={this.state.referEmail} onChange={(e)=>{this.setState({referEmail: e.target.value})}} />
                </div>
              </div>
              <button className="btn btn-primary width-300px" onClick={this.state.loading ? ()=>{} : this.sendReferEmail}>{this.state.loading ? <ButtonLoading/> : <Fragment><i className="material-icons">email</i>Send Invitation</Fragment>}</button>
              {!!this.state.message && <div className={`${this.state.messageSuccess ? 'success-text' : 'error-text'} text-left ml-0`}>{this.state.message}</div>}
            </div>
          </div>
        </div>
        <GlobalFooter />
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Refer))
