import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

const RedirectToAppStore = ({ location }) => {
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referralCode = searchParams.get('referralCode');
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    let appStoreURL, playStoreURL;

    if (isIOS) {
      appStoreURL = "https://apps.apple.com/app/id1511596518?ref=" + referralCode;
      window.location.href = appStoreURL;
    } else {
      playStoreURL = "https://play.google.com/store/apps/details?id=com.reship&referrer=" + referralCode;
      window.location.href = playStoreURL;
    }
  }, [location.search]);  

  return null;  
};

export default withRouter(RedirectToAppStore);
