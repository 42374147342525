import React, {Component, Fragment} from 'react'
import PropTypes              from 'prop-types'
import {debug}                from '../common/constants'

// Uncontrolled component to manage iframe independent of entire rest of DOM
class HppIframe extends Component {
  static propTypes = {
    rxpUrl: PropTypes.string.isRequired,
    onResult: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  }
  shouldComponentUpdate() {
    return false;
  }
  componentDidMount(){
    window.addEventListener("message", this.handleResult);
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.handleResult);
  }
  handleResult = (e) => {
    let origin = (new URL(e.origin)).hostname
    if(origin==='pay.sandbox.realexpayments.com' || origin==='pay.realexpayments.com')
      this.props.onResult(e.data)
  }
  render () {
    return (
      <Fragment>
        <iframe title="GlobalPayments Hosted Payment Page" id="ship-rxp-iframe" src={this.props.rxpUrl} ref={(f) => this.ifr = f}>Payment loading</iframe>
      </Fragment>
    )
  }
}

export default HppIframe