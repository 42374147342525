import axios from 'axios';
import jwtDecode from 'jwt-decode';
import * as ReactGA from 'react-ga';


const initialState = {
  clientInit: false,
  clientError: false,
  clientToken: '',
  clientRefreshAt: 0,
  clientData: {
    announcements: [],
    plans: [],
  },
  isLoggedIn: null,
  isFromRegister: false,
  customerSessionData: {
    warehouse: 0,
    customerId: null
  },
  itemDeclarationRules: {},
  planRules: {},
  allowPreReceiving: false,
  epCarrierList: [],
  transactionRecord: {},
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLIENT_INIT':
      return {
        ...clientInit(state, action),
      };
    case 'CLIENT_INIT_FAIL':
      axios.defaults.headers['x-api-key'] = '';
      return {
        ...initialState,
      };
    case 'CUSTOMER_LOGIN':
      return {
        ...customerLogin(state, action),
      };
    case 'CUSTOMER_LOGOUT':
      return {
        ...customerLogout(state, action),
      };
    case 'UPDATE_USER_CONFIG':
      return {
        ...state,
        itemDeclarationRules: action.payload?.itemDeclarationRules,
        planRules: action.payload?.planRules,
      }
    case 'HIDE_NOTICE':
      return {
        ...hideNotice(state, action),
      };
    case 'SET_WAREHOUSE':
      return {
        ...state,
        customerSessionData: {
          ...state.customerSessionData,
          warehouse: action.payload,
        },
      };
    case 'CUSTOMER_REGISTERED':
      return {
        ...state,
        isFromRegister: false,
      };
    case 'RESET':
      return {
        ...initialState,
      };
    case 'CUSTOMER_SUBSCRIPTION' :
      return {
        ...state,
        transactionRecord: action.payload,
      }
    default:
      return state;
  }
};

const clientInit = (state, action = null) => {
  const initData = action.payload;

  if (!initData) {
    return { ...state };
  }

  const token = jwtDecode(initData.token);

  const clientData = {
    announcements: initData.announcements,
    forex: initData.forex,
  };

  axios.defaults.headers['x-api-key'] = initData.token;

  return {
    ...state,
    clientInit: true,
    clientRefreshAt: token.exp * 1000,
    clientToken: initData.token,
    clientData,
  };
};

const customerLogin = (state, action = null) => {
  if (!action || !action.payload) {
    throw new Error("Invalid action: Please try again later.");
  }

  const { customer, isFromRegister, userConfig, transactionRecord } = action.payload;

  ReactGA.initialize('UA-21428965-1');
  ReactGA.pageview(window.location.pathname);

  return {
    ...state,
    isLoggedIn: true,
    isFromRegister,
    itemDeclarationRules: userConfig?.itemDeclarationRules || {},
    planRules: userConfig?.planRules || {},
    transactionRecord: transactionRecord || {},
    allowPreReceiving: userConfig?.allowPreReceiving || false,
    epCarrierList: userConfig?.epCarrierList || [],
    customerSessionData: {
      ...state.customerSessionData,
      customerId: customer.customerId,
    }
  };
};

const customerLogout = (state, action = null) => {

  localStorage.clear();

  const logoutState = {
    ...initialState,
  };

  if (action.payload && action.payload.isAuthExpired) {
    logoutState.clientReturnTo = action.payload.isAuthExpired;
  }

  return logoutState;
};

const hideNotice = (state, action = null) => {
  const { noticeId } = action.payload;

  return {
    ...state,
    clientData: {
      ...state.clientData,
      announcements: state.clientData.announcements.filter(((announcement) => announcement.noticeId !== noticeId)),
    },
  };
};

export default sessionReducer;