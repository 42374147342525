import React from 'react';
import RecaptchaV2 from './recaptchaV2';

function Index({ ...props }) {
  return (
    <RecaptchaV2 onVerify={props.onVerify} />
  );
}

export default Index;
