import React, {Component, Fragment} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios/index'
import {addDays} from 'date-fns'
import { isDecimal } from '../utils/number'
import {validatePostalCode} from '@monarkit/postal-code-data'
import GlobalFooter from '../components/GlobalFooter'
import GlobalError from '../components/GlobalError'
import CustomerSidebar from '../components/CustomerSidebar'
import ButtonLoading from '../components/ButtonLoading'
import CustomerLoading from '../components/CustomerLoading'
import HppIframe from '../components/HppIframe'
import LoadingSpinner from '../components/LoadingSpinner'
import DropdownCountry from '../components/DropdownCountry'
import DropdownRegion from '../components/DropdownRegion'
import InputPostalCode from '../components/InputPostalCode'
import BraintreeDropIn from '../components/BraintreeDropIn'
import {getBrokerageMessage} from '../common/data/brokerageCharges'
import {convertToClientTimestamp, displayDate, parseNumber, tallyInputTotal, handleError, round}
  from '../common/utils'

import {
  constants,
  warehouseData,
  debug,
  ICS2_REQUIRED_COUNTRY_CODES,
  weightImperial,
  dimensionsImperial,
  carrierLabel,
  trackingLink, GREEN_STATUS, YELLOW_STATUS, RED_STATUS, restrictionStatus
}
  from '../common/constants'
import {
  countryText,
  regionText,
  validateCountry,
  validateRegion,
  filterValidCountry,
  filterValidRegion,
  validatePhone,
  filterStr,
  filterNotes,
  specialCharacterMessage,
  addressCharLimits,
  displayPostalCode,
  cleanBillingAddress
}
  from '../common/handleAddress'
import {getShipLineLabel} from '../common/data/lineItemInfo'
import { Skeleton, PageHeader, Tooltip} from 'antd'
import EoriForm from '../components/Eori'
import ItemsDeclarationForm from "../components/ItemsDeclarationForm";
import PhotoModal from "../components/PhotoModal";
import RequestPhotoModal from "../components/RequestPhotoModal";
import MobileDeclarationForm from "../components/MobileDeclarationForm";
import CancelConsolidationModal from "../components/CancelConsolidationModal";
import withAddressAutocomplete from '../hocs/withAddressAutocomplete'
import TrackingLink from '../components/TrackingLink'
import ConsigneeIDForm from "../components/ConsigneeIDForm";
import TimelineModal from "../components/Timeline/TimelineModal";
import CancelPrereceiveModal from "../components/Prereceiving/CancelPrereceiveModal";
import TagManager from 'react-gtm-module'
import {InputPhoneNumber} from "../components/InputPhoneNumber";
import agent from '../api/agent'

const mapStateToProps = (globalStore) => {
  return {store: globalStore}
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch({type: 'CUSTOMER_LOGOUT'})
    },
    setWarehouse: (w) => {
      dispatch({type: 'SET_WAREHOUSE', payload: w})
    },
  }
}

class Checkout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      initialLoading: 0,
      initialLoadingError: '',

      initialPackageLoaded: false,
      initialAddressesLoaded: false,

      loading: false,
      insuranceQuoteLoading: false, 

      message: '',
      page: 1, // 1 - Item declaration w incoming/subpackage display, 2 - Address selection, 3 - Shipping quote + TOS, 4 - payment dropin, 5 - Shipped

      package: {},

      addresses: [],
      addressAdd: false,
      addressEdit: false,
      activeAddressId: 0,
      activeAddress: {},
      addressName: '',
      addressStreet1: '',
      addressStreet2: '',
      addressCity: '',
      addressStateCode: '',
      addressCountryCode: '',
      addressPostalCode: '',
      addressPostalCodeValid: false,
      addressPhone: '',
      addressPhoneValid: false,
      addressPhoneFormatted: '',
      addressNotes: '',
      selectedAddressId: 0,
      selectedAddressData: {},
      addressMessageSpecial: false,

      items: [],
      itemDeclarationRequired: false,
      itemsComplete: false,
      itemsDeclaredTotal: 0,
      itemDescription: '',
      itemQuantity: '',
      itemValue: '',
      itemWeight: '',
      customsType: 1,

      ddp: '',
      isDdpEnabled: false,

      shippingOptions: [],
      UPSShippingOptions: [],
      shippingNotes: '',
      shippingDeliveryNotes: '',

      shippingInsuranceSelected: true,
      selectedQuoteInsuranceCost: null,
      selectedQuoteId: 0,
      selectedQuote: {},
      shippingSortPrice: true,

      fees: {
        giftWrap: null,
        invoiceRemoval: null,
        padding: null,
      },
      requestGiftWrap: false,
      requestInvoiceRemoval: false,
      requestPadding: false,
      requestSpecial: false,
      requestSpecialNote: '',

      invoiceId: 0,
      checkoutInvoice: {},
      checkoutPackage: {},
      checkoutAddress: {},
      checkoutQuote: {},

      creditBalance: 0,
      creditApplyPending: 0,
      creditApplyPendingBalance: 0,
      creditApplyInput: 0,
      creditLoading: false,
      creditMessage: '',

      couponCode: '',
      couponLoading: false,
      couponMessage: '',

      paymentScreen: false,
      paymentFlow: null,
      paymentLoading: false,
      paymentMessage: '',

      paypalPaymentId: '',
      paypalMessage: '',
      rxpUrl: '',
      rxpMessage: '',
      rxpNewTrans: true,
      braintreeToken: '',
      braintreeParams: '',

      billingAddressId: 0,
      billingAddressLine1: '',
      billingAddressLine2: '',
      billingAddressCity: '',
      billingAddressStateCode: '',
      billingAddressCountryCode: '',
      billingAddressPostalCode: '',

      plan: null,
      showEoriForm: false,
      eoriNumber: '',

      editDeclarationForm: true, // true: form open; false: form close
      customer: null,
      shipNotesState: false,
      totalDeclarationValue: null,
      shippingAddrState: 0, // 0: default color theme & component closed; 1: component open; 2: complete color them & component closed
      shippingMethodState: 0, //  0: default color theme & component closed; 1: component open; 2: complete color them & component closed
      returnOption: false,

      dimensionInInch: true,
      weightInLb: true,

      modalScreen: false,

      windowWidth: document.documentElement.clientWidth,

      titleEdit: false,
      packageTitle: '',

      hasProvidedReleaseAuthority: true,
      showReleaseAuthModal: false,
      timeline: {}
    }
  }

  componentDidMount() {
    this.getPackageData()
    this.getAddresses()
    this.getWindowWidth()
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.setState({windowWidth: document.documentElement.clientWidth}))
  }

  getWindowWidth = () => {
    window.addEventListener('resize', () => this.setState({windowWidth: document.documentElement.clientWidth}))
  }

  goBack = () => {
    if (this.state.page > 3)
      this.setState({
        page: 3
      })
    else
      this.props.history.push('/package?pkg=' + this.state.package.packageId)
  }

  setDeclarationState = () => {
    this.setState({editDeclarationForm: true, shippingAddrState: 0})
  }

  allItemsDeclared = (items) => {
    const zeroValueIndex = items.findIndex(item => item.itemValue === 0 || item.declaredValue === 0)
    const itemListLength = items.length
    if (zeroValueIndex === -1 && itemListLength > 0)
      return true
    else
      return false
  }

  getItemsData = async (pkg) => {
    try {
      this.setState({loading: true})

      const response = await axios.get(`/package/items/${pkg.packageId}?disposition=${pkg.disposition}&type=${pkg.type}`)

      if (response.status === 200) {
        const items = response.data.data
        const total = items.reduce((acc, item) => acc + item.declaredFxValue, 0)
        this.setDeclarationState()
        this.setState({
          items: items,
          totalDeclarationValue: round(total),
          loading: false
        })
      }

    } catch (e) {
      this.setState({
        loading: false,
        message: (e.response ? e.response.data.message : e.message) || 'Could not get items data. Please reload the page and try again.'
      })
    }
  }

  getPackageData = () => {
    this.setState({initialLoading: 1})
    const params = new URLSearchParams(this.props.location.search)
    const packageId = params.get('pkg')

    axios.get('/customer/packages/getPackage/' + packageId)
      .then((response) => {

        if (response.data.package.disposition === 1) {
          if (response.data.package.shipment.system === 99)
            this.props.history.replace('/done/packagePickup')
          else
            this.props.history.replace('/done/packageShipping')
        } else {
          if (response.data.package.consolidation) {
            this.getItemsData(response.data.package)
          } else {
            const total = response.data.items.reduce((acc, item) => acc + item.itemFxValue, 0)
            this.setState({
              items: response.data.items,
              totalDeclarationValue: round(total)
            })
            const items = response.data.items
            items.length ? this.setDeclarationState() : this.setState({editDeclarationForm: false})
          }
          this.setState({
            initialPackageLoaded: true,
            initialLoading: this.state.initialAddressesLoaded ? 2 : 1,

            package: response.data.package,
            customsType: response.data.package.outgoingCustomsType,
            fees: response.data.fees,
            plan: response.data.plan,
            eoriNumber: response.data.package.eori,
            timeline: response.data.timeline
          })
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          this.props.logout()
          this.props.history.push('/login')
        } else {
          this.setState({
            initialLoadingError: true,
            message: error.response ? error.response.data.message : error.message
          })
        }
      })
  }

  getAddresses = () => {
    const params = new URLSearchParams(this.props.location.search)
    const pkgId = params.get('pkg')


    const requestBody = {
      packageId: pkgId
    }

    axios.post('/v2/customer/addresses/getAddresses', requestBody)
      .then((response) => {
        if (!response.data.success)
          this.setState({initialLoading: false, message: response.data.message})
        else {
          this.setState({
            initialAddressesLoaded: true,
            initialLoading: this.state.initialPackageLoaded ? 2 : 1,
            addresses: response.data.addresses
          })
        }
      })
      .catch((error) => {
        this.setState({
          initialLoading: 3,
          initialLoadingError: error.response ? error.response.data.message : error.message
        })
      })
  }

  updateAddressField = (e, field) => {
    let input = e.target.value
    let filteredInput = filterStr(input)

    if (field === 'name')
      this.setState({addressName: filteredInput})
    else if (field === 'street1')
      this.setState({addressStreet1: filteredInput})
    else if (field === 'street2')
      this.setState({addressStreet2: filteredInput})
    else if (field === 'city')
      this.setState({addressCity: filteredInput})

    if (filteredInput !== input)
      this.setState({addressMessageSpecial: ['street1', 'street2'].includes(field) ? 'street' : field})
    else
      this.checkAddressSpecial(field)
  }

  checkAddressSpecial = (field) => {
    // This adds the warning to the next field needing the warning, or removes the warning. Passing field in as a bypass to prevent the warning from triggering for a field which already has no special characters but is still flagged because the state has not been updated
    let special = false
    if (filterStr(this.state.addressName) !== this.state.addressName && field !== 'name')
      special = 'name'
    else if (filterStr(this.state.addressStreet1) !== this.state.addressStreet1 && field !== 'street1')
      special = 'street'
    else if (filterStr(this.state.addressStreet2) !== this.state.addressStreet2 && field !== 'street2')
      special = 'street'
    else if (filterStr(this.state.addressCity) !== this.state.addressCity && field !== 'city')
      special = 'city'

    this.setState({
      addressMessageSpecial: special
    })
  }

  isAddressInvalid = (a) => {
    let checks = [
      !a.name,
      filterStr(a.name, addressCharLimits.name) !== a.name,
      !a.street1,
      filterStr(a.street1, addressCharLimits.streets) !== a.street1,
      (a.street2 && filterStr(a.street2, addressCharLimits.streets) !== a.street2),
      !a.city,
      filterStr(a.city, addressCharLimits.city) !== a.city,
      !validateCountry(a.countryCode),
      !validateRegion(a.countryCode, a.stateCode),
      !validatePostalCode(a.postalCode, a.countryCode, false),
      !validatePhone(a.phone, a.countryCode)
    ]

    return checks.includes(true)
  }

  openAddAddress = () => {
    this.setState({
      loading: false,
      message: '',
      addressAdd: true,
      addressEdit: false,

      addressName: '',
      addressStreet1: '',
      addressStreet2: '',
      addressCity: '',
      addressStateCode: '',
      addressCountryCode: '',
      addressPostalCode: '',
      addressPostalCodeValid: false,
      addressPhone: '',
      addressPhoneValid: false,
      addressPhoneFormatted: '',
      addressNotes: '',
      selectedAddressId: 0,
      addressMessageSpecial: false,
    }, () => {
      document.querySelector(".address-form").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
    })
  }

  openEditAddress = (addressId) => {
    let selectedAddress = this.state.addresses.filter(a => {
      return a.addressId === addressId
    })[0]

    let name = (selectedAddress.name || '').substr(0, addressCharLimits.name)
    let street1 = (selectedAddress.street1 || '').substr(0, addressCharLimits.streets)
    let street2 = (selectedAddress.street2 || '').substr(0, addressCharLimits.streets)
    let city = (selectedAddress.city || '').substr(0, addressCharLimits.city)

    let special = false
    if (filterStr(name) !== name)
      special = 'name'
    else if (filterStr(city) !== city)
      special = 'city'
    else if (filterStr(street1) !== street1 || filterStr(street2) !== street2)
      special = 'street'

    this.props.setValue({
      street1 
    })
    this.setState({
      loading: false,
      message: '',
      addressMessageSpecial: special,

      addressAdd: false,
      addressEdit: true,

      activeAddress: selectedAddress,
      activeAddressId: selectedAddress.addressId,
      addressName: name,
      addressStreet1: street1,
      addressStreet2: street2,
      addressCity: city,
      addressStateCode: filterValidRegion(selectedAddress.countryCode, selectedAddress.stateCode),
      addressCountryCode: filterValidCountry(selectedAddress.countryCode),
      addressPostalCode: (selectedAddress.postalCode || ''),
      addressPostalCodeValid: false,
      addressPhone: (selectedAddress.phone || '').substr(0, addressCharLimits.phone),
      addressPhoneValid: false,
      addressPhoneFormatted: '',
      addressNotes: (selectedAddress.notes || '')
    }, () => {
      document.querySelector(".address-form").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
    })
  }

  addAddress = () => {
    if (this.state.addressName === '')
      this.setState({message: 'Name required'})
    else if (this.state.addressStreet1 === '')
      this.setState({message: 'Street address line 1 required'})
    else if (this.state.addressName.replace(/[^a-zA-Z]/g, '').length < 5)
      this.setState({message: 'Name must be at least 5 letters long'})
    else if (this.state.addressStreet1.length < 5 || !/[a-zA-Z]/g.test(this.state.addressStreet1))
      this.setState({message: 'Street address line 1 must be at least 5 characters long and contain letters'})
    else if (this.state.addressCity === '')
      this.setState({message: 'Address city required'})
    else if (!validateCountry(this.state.addressCountryCode))
      this.setState({message: 'Please select a valid country'})
    // else if (!validateRegion(this.state.addressCountryCode, this.state.addressStateCode))
    //   this.setState({message: 'Please select a valid state/region'})
    else if (!this.state.addressPostalCodeValid)
      this.setState({message: 'Valid postal code required'})
    else if (!this.state.addressPhoneValid)
      this.setState({message: 'Valid phone number required for shipping'})
    else if (!!this.state.addressMessageSpecial
      || filterStr(this.state.addressName) !== this.state.addressName
      || filterStr(this.state.addressStreet1) !== this.state.addressStreet1
      || filterStr(this.state.addressStreet2) !== this.state.addressStreet2
      || filterStr(this.state.addressCity) !== this.state.addressCity
    )
      this.setState({message: 'Special characters are not supported in addresses'})
    else {
      this.setState({loading: true})

      const params = new URLSearchParams(this.props.location.search)
      const pkgId = params.get('pkg')

      let postData = {
        name: this.state.addressName,
        street1: this.state.addressStreet1,
        street2: this.state.addressStreet2,
        city: this.state.addressCity,
        stateCode: filterValidRegion(this.state.addressCountryCode, this.state.addressStateCode),
        countryCode: this.state.addressCountryCode,
        postalCode: this.state.addressPostalCode,
        phone: this.state.addressPhoneFormatted,
        notes: this.state.addressNotes,
        packageId: pkgId
      }
      axios.post('/customer/addresses/addAddress', postData)
        .then((response) => {
          this.setState({
            loading: false,
            addresses: response.data.addresses
          })
          this.closeAddressEditor()
        })
        .catch((error) => {
          this.setState({loading: false, message: error.response ? error.response.data.message : error.message})
        })
        .finally(() => {
          this.getAddresses()
        })
    }
  }

  editAddress = () => {
    if (this.state.addressName === '')
      this.setState({message: 'Recepient name required'})
    else if (this.state.addressStreet1 === '')
      this.setState({message: 'Street address line 1 required'})
    else if (this.state.addressName.replace(/[^a-zA-Z]/g, '').length < 5)
      this.setState({message: 'Name must be at least 5 letters long'})
    else if (this.state.addressStreet1.length < 5 || !/[a-zA-Z]/g.test(this.state.addressStreet1))
      this.setState({message: 'Street address line 1 must be at least 5 characters long and contain letters'})
    else if (this.state.addressCity === '')
      this.setState({message: 'Destination city required'})
    else if (!validateCountry(this.state.addressCountryCode))
      this.setState({message: 'Please select a valid country'})
    // else if (!validateRegion(this.state.addressCountryCode, this.state.addressStateCode))
    //   this.setState({message: 'Please select a valid state/region'})
    else if (!this.state.addressPostalCodeValid)
      this.setState({message: 'Valid postal code required'})
    else if (!this.state.addressPhoneValid)
      this.setState({message: 'Valid phone number required for shipping'})
    else if (!!this.state.addressMessageSpecial
      || filterStr(this.state.addressName) !== this.state.addressName
      || filterStr(this.state.addressStreet1) !== this.state.addressStreet1
      || filterStr(this.state.addressStreet2) !== this.state.addressStreet2
      || filterStr(this.state.addressCity) !== this.state.addressCity
    )
      this.setState({message: 'Please remove special characters from address'})
    else {
      this.setState({loading: true})

      const params = new URLSearchParams(this.props.location.search)
      const pkgId = params.get('pkg')

      let postData = {
        addressId: this.state.activeAddressId,
        name: this.state.addressName,
        street1: this.state.addressStreet1,
        street2: this.state.addressStreet2,
        city: this.state.addressCity,
        stateCode: filterValidRegion(this.state.addressCountryCode, this.state.addressStateCode),
        countryCode: this.state.addressCountryCode,
        postalCode: this.state.addressPostalCode,
        phone: this.state.addressPhoneFormatted,
        notes: this.state.addressNotes,
        packageId: pkgId
      }
      axios.post('/customer/addresses/updateAddress', postData)
        .then((response) => {
          this.setState({
            loading: false,
            addresses: response.data.addresses,
          })
          this.closeAddressEditor()
        })
        .catch((error) => {
          this.setState({loading: false, message: error.response ? error.response.data.message : error.message})
        })
        .finally(() => {
          this.setState({ shippingMethodState: 0 })
          this.getAddresses()
        })
    }
  }

  deleteAddress = (addressId) => {
    let postData = {
      addressId: addressId,
    }
    axios.post('/customer/addresses/deleteAddress', postData)
    .then((response) => {
      this.setState({
        addresses: response.data.addresses,
        selectedAddressId: this.state.selectedAddressId===postData.addressId ? 0 : this.state.selectedAddressId
      })
    })
    .finally(() => {
      this.setState({
        loading: false
      })
    })
  }

  closeAddressEditor = () => {
    this.setState({
      loading: false,
      message: '',
      addressEdit: false,
      addressAdd: false,

      activeAddressId: 0,
      activeAddress: {},
      addressName: '',
      addressStreet1: '',
      addressStreet2: '',
      addressCity: '',
      addressStateCode: '',
      addressCountryCode: '',
      addressPostalCode: '',
      addressPostalCodeValid: false,
      addressPhone: '',
      addressPhoneValid: false,
      addressPhoneFormatted: '',
      addressNotes: '',
      addressMessageSpecial: false,
    }, () => document.querySelector(".package-address-list")?.scrollIntoView({behavior: "smooth", block: "start"}))
  }

  backToAddress = () => {
    this.setState({
      page: 1,
      selectedAddressId: 0,
      selectedQuoteId: 0,
      shippingOptions: [],
      UPSShippingOptions: [],
      loading: false,
      message: ''
    })
  }

  selectAddress = (selectedAddressId) => {
    this.setState({
      loading: false,
      message: '',
      addressEdit: false,
      addressAdd: false,

      activeAddressId: 0,
      activeAddress: {},
      addressName: '',
      addressStreet1: '',
      addressStreet2: '',
      addressCity: '',
      addressStateCode: '',
      addressCountryCode: '',
      addressPostalCode: '',
      addressPostalCodeValid: false,
      addressPhone: '',
      addressPhoneValid: false,
      addressPhoneFormatted: '',
      addressNotes: '',

      selectedAddressId: selectedAddressId,
      shippingOptions: [],
      UPSShippingOptions: [],

      shippingNotes: '',
      shippingDeliveryNotes: '',
      shippingAddrState: 2,
      shippingMethodState: 0,
      selectedQuoteId: 0,
      ddp: ''
    })

    const selectedAddressData = this.state.addresses.filter(address => address.addressId === selectedAddressId)[0] || warehouseData(-selectedAddressId)
    selectedAddressData?.isDdpEnabled ? this.setState({ddp: ''}) : this.setState({ddp: -1, hasProvidedReleaseAuthority: false})
    this.setState({
      isDdpEnabled: selectedAddressData.isDdpEnabled,
    })

    this.setState({
      selectedAddressData: selectedAddressData,
      showEoriForm: ICS2_REQUIRED_COUNTRY_CODES.includes(selectedAddressData.countryCode),
      page: 2,
    // }, () => this.proceedToQuote(this.state.selectedAddressId))
    }, () => this.state.isDdpEnabled ? this.setState({shippingMethodState : 1}) : this.proceedToQuote(this.state.selectedAddressId))

    if (selectedAddressData.notes)
      this.setState({shippingDeliveryNotes: selectedAddressData.notes})

  }

  selectDdp = (value) => {
    this.setState({
      ddp: value,
    }, () => {
      this.proceedToQuote(this.state.selectedAddressId)
    })
  }

  setInsurance = (value) => {
    this.setState({
      shippingInsuranceSelected: value
    })
  }
  
  returnAddress = () => {

    this.setState({
      loading: false,
      message: 0,
      page: 31,
      activeAddressId: 0,
      selectedAddressId: 0,
      selectedQuoteId: 0,
      shippingOptions: [],
      UPSShippingOptions: [],

    })
  }
  
  returnSubmit = () => {
    if (this.state.addressName === '')
      this.setState({message: 'Company/Returns Dept'})
    else if (this.state.addressStreet1 === '')
      this.setState({message: 'Vendor Return address line 1 required'})
    else if (this.state.addressCity === '')
      this.setState({message: 'Vendor Return city required'})
    else if (!validateCountry(this.state.addressCountryCode))
      this.setState({message: 'Please select a valid country'})
    // else if (!validateRegion(this.state.addressCountryCode, this.state.addressStateCode))
    //   this.setState({message: 'Please select a valid state/region'})
    else if (!this.state.addressPostalCodeValid)
      this.setState({message: 'Please enter a valid postal code'})
    else if (!this.state.addressPhoneValid)
      this.setState({message: 'Vendor phone number required'})
    else {
      this.setState({loading: true})

      let addressData = {
        name: this.state.addressName,
        street1: this.state.addressStreet1,
        street2: this.state.addressStreet2,
        city: this.state.addressCity,
        stateCode: filterValidRegion(this.state.addressCountryCode, this.state.addressStateCode),
        countryCode: this.state.addressCountryCode,
        postalCode: this.state.addressPostalCode,
        phone: this.state.addressPhoneFormatted,
      }

      let postData = {
        packageId: this.state.package.packageId,
        ...addressData
      }
      axios.post('customer/ship/return', postData)
        .then((response) => {
          this.setState({
            loading: false,
            message: '',
            selectedQuoteId: response.data.data.quoteId,
            returnOption: true
          }, () => {
            this.proceedToCheckout()
          })
        })
        .catch((error) => {
          this.setState({loading: false, message: error.response ? error.response.data.message : error.message})
        })
    }
  }

  sanitizeDeclaredItemKeys = (declaredItem) => {

    const sanitizedDeclaredItem = {}

    Object.keys(declaredItem).forEach(key => {
      const name = key.replace('item', 'declared')

      if (key === 'itemId') {
        sanitizedDeclaredItem[key] = declaredItem[key]
        return;
      }

      sanitizedDeclaredItem[name] = declaredItem[key]
      sanitizedDeclaredItem['packageId'] = this.state.package.packageId
    })

    return sanitizedDeclaredItem
  }

  proceedToQuote = async (selectedAddressId) => {
    try {
      this.setState({
          loading: true,
          message: '',
          selectedQuoteId: 0,
          shippingAddrState: 2,
          page: 3,
          shippingOptions: [],
          shippingMethodState: 1,
          itemsDeclaredTotal: this.state.items.reduce((total, item) => {
            return total + item.itemValue
          }, 0)
        }
      )

      let postData = {}

      if (this.state.ddp === 1) {
        postData = {
          addressId: selectedAddressId,
          packageId: this.state.package.packageId,
          ddp: true
        }
      }

      if (this.state.ddp !== 1) {
        postData = {
          addressId: selectedAddressId,
          packageId: this.state.package.packageId,
          ddp: false
        }
      }

      if (postData.addressId < 1) {
        const response = await axios.post('/customer/ship/shipEstimate/pickup', postData)
        if (response.status === 200) {
          this.setState({
            loading: false,
            shippingOptions: response.data.shippingOptions.slice().sort((a, b) => {
              return (b.system - a.system) || (a.rate - b.rate)
            }),
            shippingSortPrice: true,
          })
        }

        return;
      }

      const response = await axios.post('/customer/ship/shipEstimate', postData)
      if (response.status === 200) {
        this.setState({
          loading: false,
          shippingOptions: response.data.shippingOptions.slice().sort((a, b) => {
            return (a.rate - b.rate)
          }),
          shippingSortPrice: true,
        })
      }

    } catch (e) {
      this.setState({
        loading: false,
        message: (e.response ? e.response.data.message : e.message) || 'Could not load shipping quotes. Please reload the page and try again.'
      })
    }
  }

  sortUPS = (shippingOptions) => {
    const UPSSorted = shippingOptions.filter(item => item.carrier === 'UPS')
    const nonUPSShippingOptions = shippingOptions.filter(item => item.carrier !== 'UPS')

    return {
      UPSSorted: UPSSorted,
      nonUPSShippingOptions: nonUPSShippingOptions
    }
  }

  switchShippingSort = () => {
    let resortedOptions = this.state.shippingSortPrice ?
      this.state.shippingOptions.slice().sort((a, b) => {
        return (b.rate - a.rate)
      })
      :
      this.state.shippingOptions.slice().sort((a, b) => {
        return (a.rate - b.rate)
      })

    this.setState({
      shippingSortPrice: !this.state.shippingSortPrice,
      shippingOptions: resortedOptions
    })
  }

  updatePackageReleaseAuthority = async () => {
    try {
      this.setState({loading: true})

      const requestObject = {
        packageId: this.state.package.packageId,
        hasProvidedReleaseAuthority: this.state.hasProvidedReleaseAuthority
      }

      await axios.put('/package/releaseAuthority', requestObject)

    } catch {
      this.setState({hasProvidedReleaseAuthority: true})
    } finally {
      this.setState({loading: false})
    }
  }

  proceedToCheckout = async () => {
    if (!this.state.selectedQuoteId)
      this.setState({message: 'Please select a shipping option'})
    else if (
      this.state.shippingOptions.length
      && (this.state.shippingOptions.filter(q => {
        return q.quoteId === this.state.selectedQuoteId
      })[0]?.system === 1 || this.state.UPSShippingOptions.filter(q => {
        return q.quoteId === this.state.selectedQuoteId
      })[0]?.system === 1)
      && !this.state.shippingDeliveryNotes

    )
      this.setState({message: 'Please enter mailbox/pickup details for third-party pickup location in the delivery notes'})
    else {
      try {
        await this.updatePackageReleaseAuthority()
        this.setState({
          page: 4,
          loading: true,
        })
        let postData = {
          packageId: this.state.package.packageId,
          shippingQuoteId: this.state.selectedQuoteId,
          insurance: this.state.shippingInsuranceSelected,
          requestGiftWrap: this.state.requestGiftWrap,
          requestInvoiceRemoval: this.state.requestInvoiceRemoval,
          requestPadding: this.state.requestPadding,
          shippingNotes: this.state.shippingNotes,
          deliveryNotes: this.state.shippingDeliveryNotes,
          eoriNumber: this.state.eoriNumber
        }
        const response = await axios.post('/customer/ship/review', postData)
      
        if (!response.data.success) {
          this.setState({
            page: 3,
            loading: false,
            message: 'Could not process.'
          })
        } else {
          const responseData = response.data.data
          this.setState({
            loading: false,
            message: '',
            invoiceId: responseData.invoiceId,
            checkoutInvoice: responseData.invoice,
            checkoutPackage: responseData.package,
            checkoutAddress: JSON.parse(responseData.address),
            checkoutQuote: responseData.quote,

            creditBalance: responseData.credits,
            creditApplyPending: responseData.invoice.pendingCredits,
            creditApplyInput: responseData.invoice.pendingCredits,
            creditApplyPendingBalance: responseData.credits - responseData.invoice.pendingCredits,

            paypalPaymentId: responseData.ppPaymentId,
            // paypalMessage: responseData.ppMessage,
            braintreeToken: responseData.btToken
          }, () => {document.querySelector(".ship-review-right").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})})
        }
      } catch (error) {
        this.setState({
          page: 1,
          loading: false,
          message: error.response ? error.response.data.message : error.message,
          selectedAddressId: 0,
          quotes: [],
      })
        handleError(error)
      }
    }
  }

  doTryCoupon = () => {
    let couponCode = this.state.couponCode.replace(/[^A-Za-z0-9-]/g, '').slice(0, 45).toUpperCase()
    if (!couponCode)
      this.setState({couponMessage: 'Please enter a valid coupon/promo code'})
    else {
      this.setState({
        couponLoading: true,
      })
      let postData = {
        invoiceId: this.state.invoiceId,
        couponCode: couponCode
      }
      axios.post('/customer/checkout/tryCoupon', postData)
        .then((response) => {
          if (!response.data.success)
            this.setState({couponLoading: false, couponMessage: response.data.message})
          else {
            this.setState({
              couponLoading: false,
              couponMessage: '',
              couponCode: couponCode,
              checkoutInvoice: response.data.invoice,
              paypalPaymentId: response.data.ppPaymentId,
            })
            if (response.data.invoice.pendingCredits && response.data.invoice.pendingCredits !== this.state.creditApplyPending) {
              this.setState({
                creditApplyPending: response.data.invoice.pendingCredits,
                creditApplyInput: response.data.invoice.pendingCredits,
                creditApplyPendingBalance: this.state.creditBalance - response.data.invoice.pendingCredits,
                couponMessage: 'We reduced the credit being used to match the balance after the coupon was applied.',
              })
            }
          }
        })
        .catch((error) => {
          this.setState({
            couponLoading: false,
            couponMessage: error.response ? error.response.data.message : error.message
          })
        })
    }
  }
  doTryCredit = () => {
    if (!this.state.creditApplyInput)
      this.setState({creditMessage: 'Please enter an amount'})
    else if (!isDecimal(('' + this.state.creditApplyInput), {decimal_digits: '0,2'}))
      this.setState({creditMessage: 'Please enter a valid amount'})
    else if (this.state.creditApplyInput * 1 < 0)
      this.setState({creditMessage: 'Please enter a valid amount'})
    else if (this.state.creditApplyInput * 1 > this.state.creditBalance)
      this.setState({creditMessage: 'Insuffucient credit balance'})
    else {
      this.setState({
        creditLoading: true,
      })
      let postData = {
        invoiceId: this.state.invoiceId,
        useCredit: this.state.creditApplyInput
      }
      axios.post('/customer/checkout/tryCredit', postData)
        .then((response) => {
          if (!response.data.success)
            this.setState({creditLoading: false, creditMessage: response.data.message})
          else {
            this.setState({
              creditLoading: false,
              creditMessage: '',
              checkoutInvoice: response.data.invoice,

              creditBalance: response.data.creditBalance,
              creditApplyPending: response.data.invoice.pendingCredits,
              creditApplyInput: '' + response.data.invoice.pendingCredits,
              creditApplyPendingBalance: response.data.creditBalance - response.data.invoice.pendingCredits,
              paypalPaymentId: response.data.ppPaymentId
            })
          }
        })
        .catch((error) => {
          this.setState({
            creditLoading: false,
            creditMessage: error.response ? error.response.data.message : error.message
          })
        })
    }
  }

  doSubmitNoPayment = () => {
    this.setState({
      paymentScreen: true,
      paymentLoading: true,
      paymentMessage: ''
    })
    let postData = {
      invoiceId: this.state.invoiceId,
      packageId: this.state.checkoutPackage.packageId,
    }
    axios.post('/customer/checkout/noPaymentCheckout', postData)
      .then((response) => {
        if (!response.data.success)
          this.setState({
            paymentMessage: response.data.message
          })
        else {
          this.setState({
            paymentLoading: false,
            paymentScreen: false,
            paymentMessage: '',
            page: 5
          })
          this.props.history.replace('/done/packageShipping')
        }
      })
      .catch((error) => {
        this.setState({paymentMessage: error.response ? error.response.data.message : error.message})
      })
  }

  selectBillingAddress = (addressId) => {
    if (addressId > 0) {
      let selectedAddress = this.state.addresses.filter(a => {
        return +a.addressId === +addressId
      })[0]

      this.setState({
        billingAddressId: addressId,
        billingAddressLine1: selectedAddress.street1.substr(0, 44),
        billingAddressLine2: selectedAddress.street2.substr(0, 44) || '',
        billingAddressCity: selectedAddress.city.substr(0, 44),
        billingAddressStateCode: selectedAddress.stateCode,
        billingAddressCountryCode: selectedAddress.countryCode,
        billingAddressPostalCode: selectedAddress.postalCode.substr(0, 15),
        billingAddressMessage: ''
      })
    }
    this.setState({
      billingAddressId: addressId,
      billingAddressMessage: ''
    })
    // this.showLauncher(false)
  }
  proceedFromBilling = () => {
    if (!cleanBillingAddress(this.state.billingAddressLine1) || !cleanBillingAddress(this.state.billingAddressCity) || !cleanBillingAddress(this.state.billingAddressPostalCode) || !this.state.billingAddressCountryCode)
      this.setState({billingAddressMessage: 'Address is incomplete'})
    else if (this.state.paymentFlow === 2)
      this.loadRxpFrame()
    else if (this.state.paymentFlow === 3)
      this.loadBraintreeDropin()
  }

  startRxpScreen = (skipToPayment) => {
    if (skipToPayment)
      this.loadRxpFrame()
    else {
      this.setState({
        paymentScreen: 1,
        paymentFlow: 2,
        billingAddressMessage: ''
      })
      this.selectBillingAddress(this.state.selectedAddressId)
    }
  }
  loadRxpFrame = () => {
    this.setState({
      paymentScreen: 20,
      paymentLoading: true,
      paymentMessage: ''
    })

    let postData = {
      invoiceId: this.state.invoiceId,
      packageId: this.state.package.packageId,
      forceNew: this.state.rxpNewTrans,
      billingAddress: {
        line1: cleanBillingAddress(this.state.billingAddressLine1),
        line2: cleanBillingAddress(this.state.billingAddressLine2) || '',
        city: cleanBillingAddress(this.state.billingAddressCity),
        stateCode: this.state.billingAddressStateCode,
        countryCode: this.state.billingAddressCountryCode,
        postalCode: cleanBillingAddress(this.state.billingAddressPostalCode)
      },
      shippingAddress: {
        line1: this.state.checkoutAddress.street1,
        line2: this.state.checkoutAddress.street2 || '',
        city: this.state.checkoutAddress.city,
        stateCode: this.state.checkoutAddress.stateCode,
        countryCode: this.state.checkoutAddress.countryCode,
        postalCode: this.state.checkoutAddress.postalCode,
      }
    }
    axios.post('/customer/checkout/startRxpTransaction', postData)
      .then((response) => {
        this.setState({
          paymentLoading: false,
          paymentMessage: '',
          rxpUrl: response.data.rxpUrl,
          rxpNewTrans: false
        })
      })
      .catch((error) => {
        this.setState({
          paymentScreen: 1,
          paymentLoading: false,
          paymentMessage: error.response ? error.response.data.message : error.message
        })
      })
  }
  parseRxpResponse = (response) => {
    try {
      let rawResponse = JSON.parse(response)
      let paymentResult = window.atob(rawResponse.RESULT)
      let paymentMessage = window.atob(rawResponse.MESSAGE)
      return {
        paymentResult: paymentResult,
        paymentMessage: paymentMessage
      }
    } catch (e) {
      return {
        paymentResult: '200',
        paymentMessage: response
      }
    }
  }
  handleRxpResponse = (response) => {
    let result = this.parseRxpResponse(response)

    if (result.paymentResult === '00') {
      this.paymentSuccess()
    } else if (result.paymentResult === '200') {
      // Refresh iframe and display error message under iframe
      this.setState({
        rxpMessage: 'Globalpayments error. Please try again.',
        paymentLoading: true,
        rxpNewTrans: true
      })
      this.startRxpScreen(true)
    } else if (+result.paymentResult > 100) {
      // Get new transaction, refresh iframe and display declined message under iframe
      this.setState({
        rxpMessage: 'Your card was declined. Please try a different one. (Code ' + result.paymentResult + ')',
        paymentLoading: true,
        rxpNewTrans: true
      })
      this.startRxpScreen(true)
    }
  }

  startBraintreeScreen = (skipToPayment) => {
    if (skipToPayment)
      this.loadBraintreeDropin()
    else {
      this.setState({
        paymentScreen: 1,
        paymentFlow: 3,
        billingAddressMessage: ''
      })
      this.selectBillingAddress(this.state.selectedAddressId)
    }
  }

  loadBraintreeDropin = () => {

    this.setState({
      paymentScreen: 30,
      paymentLoading: false,
      paymentMessage: '',
      braintreeParams: {
        clientToken: this.state.braintreeToken,
        amount: this.state.checkoutInvoice.totalInvoice === this.state.checkoutInvoice.balance ? this.state.checkoutInvoice.totalInvoice : this.state.checkoutInvoice.balance,
        billingAddress: {
          streetAddress: this.state.billingAddressLine1,
          extendedAddress: this.state.billingAddressLine2,
          locality: this.state.billingAddressCity,
          region: this.state.billingAddressStateCode,
          postalCode: this.state.billingAddressPostalCode,
          countryCodeAlpha2: this.state.billingAddressCountryCode,
        },
        shippingAddress: {
          recipientName: this.state.selectedAddressData.name,
          line1: this.state.selectedAddressData.street1,
          line2: this.state.selectedAddressData.street2,
          city: this.state.selectedAddressData.city,
          state: this.state.selectedAddressData.stateCode,
          postalCode: this.state.selectedAddressData.postalCode,
          countryCode: this.state.selectedAddressData.countryCode,
        }
      }
    })
  }
  braintreeError = (err) => {
    this.setState({
      paymentLoading: true,
      paymentMessage: 'Sorry, this payment method was declined. Please try another.'
    })
  }
  braintreeSubmit = (transaction, save) => {

    this.setState({
      paymentScreen: true,
      paymentLoading: true,
      paymentMessage: ''
    })

    const postData = {
      transaction: transaction, // Not used by server, but recorded in datadog for debugging and future use
      paymentStore: !!save,
      paymentNonce: transaction.nonce,
      deviceData: transaction.deviceData,
      invoiceId: this.state.invoiceId,
      packageId: this.state.package.packageId,
      billingAddress: {
        street1: this.state.billingAddressLine1,
        street2: this.state.billingAddressLine2,
        city: this.state.billingAddressCity,
        stateCode: this.state.billingAddressStateCode,
        countryCode: this.state.billingAddressCountryCode,
        postalCode: this.state.billingAddressPostalCode,
      }
    }

    axios.post('/customer/checkout/processBraintree', postData)
      .then(() => {
        const tagManagerArgs = {
          dataLayer: {
            event: 'purchase',
            transaction_id:	this.state.invoiceId, 
            value:	this.state.checkoutInvoice.balance,
            items: [
              {
                item_id: this.state.package.packageId,
                item_name: `Shipment for ${this.state.package.packageId}`,
                affiliation: `ship.reship.com`,
                price: this.state.checkoutInvoice.balance,
                quantity: 1
              }
            ],
            currency:	'USD'
          },
          dataLayerName: 'dataLayer'
        }
  
        TagManager.dataLayer(tagManagerArgs)
  
        this.paymentSuccess()
      })
      .catch((error) => {
        this.setState({
          paymentMessage: error.response ? error.response.data.message : error.message
        })
      })
  }

  paymentSuccess = () => {
    // Success! Close form and go to thank you page.
    this.paymentClose()

    if (this.state.checkoutQuote.system === 99)
      this.props.history.replace('/done/packagePickup')
    else
      this.props.history.replace('/done/packageShipping')
  }
  
  paymentClose = () => {
    this.setState({
      paymentLoading: false,
      paymentScreen: false,
      paymentMessage: '',
      rxpMessage: '',
      braintreeParams: {}
    })
  }

  handleSelectShippingQuote = async (quote) => {
    this.setState({
      selectedQuote: quote,
      selectedQuoteId: quote.quoteId,
    })

    await this.getInsuranceCost(quote);
  }
  
  getInsuranceCost = async (quote) => {
    try {
      this.setState({
        selectedQuoteInsuranceCost: null,
        insuranceQuoteLoading: true,
      })
  
      const { quoteId } = quote
  
      const response = await agent.pkg.getInsuranceCost({ quoteId })
  
      this.setState({
        shippingInsuranceSelected: true, 
        selectedQuoteInsuranceCost: response.data.data.cost,
      })

    } catch (e) {
      this.setState({
        shippingInsuranceSelected: false,
        selectedQuoteInsuranceCost: null
      })
    } finally {
      this.setState({
        insuranceQuoteLoading: false
      })
    }
  }
  

  updatePackageEori = async () => {
    try {
      this.setState({loading: true})

      const requestObject = {
        packageId: this.state.package.packageId,
        eori: this.state.eoriNumber
      }

      await axios.put('/package/eori', requestObject)
      
    } catch {
      this.setState({eoriNumber: ''})
    } finally { 
      this.setState({loading: false})
    }
  }

  savedDeclaredValue = (value) => {
    this.setState({
      totalDeclarationValue: value,
      modalScreen:false
    })
  }

  closeDeclarationForm = () => {
    this.setState({
      editDeclarationForm: false,
      shippingAddrState: 1,
      modalScreen: false
    })
  }

  saveCustoms = (value) => {
    this.setState({
      customsType: value
    })
  }

  disableCheckoutButton = () => {
    const isToCountryCAAndDdp = this.state.selectedAddressData.countryCode === 'CA' && this.state.ddp === 1
    const isCommonCriteriaMet = !this.state.loading && !!this.state.selectedQuoteId && !!this.state.shippingOptions.length && this.state.package.disposition === 0 && this.state.package.locked === 0

    let isIdCollectionCriteriaMet
    if (this.state.selectedAddressData.consigneeDataCollection && this.state.ddp === 1) {
      isIdCollectionCriteriaMet = !!this.state.selectedAddressData.consigneeId
    } else {
      isIdCollectionCriteriaMet = true
    }

    return isCommonCriteriaMet && (!isToCountryCAAndDdp || this.state.hasProvidedReleaseAuthority) && isIdCollectionCriteriaMet
  }

  proceedToDdpOption = async () => {
    this.setState({
      ddp: '',
      showReleaseAuthModal: false,
      hasProvidedReleaseAuthority: true
    })
    await this.selectAddress(this.state.selectedAddressId)
  }

  packageStatus = () => {
    if (GREEN_STATUS.includes(this.state.package.status))
      return 'green'

    if (YELLOW_STATUS.includes(this.state.package.status) || (this.state.package.reqPhotographs === 1 && this.state.package.reqPhotographsComplete === 0))
      return 'yellow'

    if (RED_STATUS.includes(this.state.package.status))
      return 'red'
    else return 'yellow'
  }

  renderShippingOption = (option) => (
    <div className={`ship-quote ${this.state.selectedQuoteId === option.quoteId ? 'ship-quote-selected' : ''} ${option.system === 0 ? 'ship-quote-ownlabel' : ''}`}
         key={option.quoteId} onClick={async () => { await this.handleSelectShippingQuote(option)}} style={this.state.selectedQuoteId === option.quoteId ? {border: '1px solid #84329B'} : {}}>
      <div className="ship-quote-select">
        {this.state.selectedQuoteId === option.quoteId ?
          <div className="radio radio-checked"></div> : <div className="radio"></div>
        }
      </div>
      <div className="ship-quote-info">
        <div className="ship-quote-title">{option.serviceName}</div>
        {(option.deliveryDaysMin && option.deliveryDaysMax) ?
          (option.deliveryDaysMin === option.deliveryDaysMax ?
              <div className="ship-quote-time">Estimate {option.deliveryDaysMax} Business Days</div>
              :
              <div className="ship-quote-time">Estimate {option.deliveryDaysMin}-{option.deliveryDaysMax} Business Days</div>
          )
          :
          (option.deliveryDays ?
              <div className="ship-quote-time">Estimate {option.deliveryDays} Business Days</div>
              :
              <div className="ship-quote-time">{option.serviceDescription || 'Delivery time depends on destination and cannot be guaranteed'}</div>
          )
        }
        {option.hazmatFeeDescription && <div className="hazmat-fee, ship-quote-time">{option.hazmatFeeDescription}</div>}
        {option.carrier === 'InterlinkExpress' ?
          <div className="ship-quote-description"> This service is temporarily unavailable </div> : null}
        {(this.state.itemDeclarationRequired && [1, 4].includes(this.state.customsType)) && getBrokerageMessage(option.methodId, this.state.selectedAddressData.countryCode, this.state.itemsDeclaredTotal)}
      </div>
      <div className="ship-quote-price">{option.rateCustomer ?
        <Fragment>${option.rateCustomer.toFixed(2)}</Fragment> : 'Free'}</div>
    </div>

  )

  renderAddressOption = (a) => (
    <div className={"package-address-checkout" + (this.state.selectedAddressId ? ' ship-addresses-selected' : '')} key={a.addressId} style={this.state.selectedAddressId === a.addressId ? {borderColor: '#84329B'} : {}}>
      <div className="radio-select" onClick={this.state.loading? ()=>{} : () => {!this.isAddressInvalid(a) && this.selectAddress(a.addressId)}}>
        {!this.isAddressInvalid(a) && (this.state.selectedAddressId === a.addressId ?
          <div className="radio radio-checked"></div> : <div className="radio"></div>)}
      </div>
      <div className="package-address-content" onClick={this.state.loading? ()=>{} : () => {!this.isAddressInvalid(a) && this.selectAddress(a.addressId)}}>
        <div className="ship-address-line ship-address-line-name">{a.name.substr(0, addressCharLimits.name)}</div>
        <div className="ship-address-line">{a.street1.substr(0, addressCharLimits.streets)}</div>
        {!!a.street2 && <div className="ship-address-line">{a.street2.substr(0, addressCharLimits.streets)}</div>}
        {/*<div className="ship-address-line">{a.city.substr(0, addressCharLimits.city)}</div>*/}
        {(!!a.stateCode || displayPostalCode(a.postalCode)) &&
          <div className="ship-address-line">
            {a.city.substr(0, addressCharLimits.city)}{' '}
            {a.stateCode ? regionText(a.countryCode, a.stateCode) : ''}
            {(!!a.stateCode && displayPostalCode(a.postalCode)) ? ' ' : ''}
            {displayPostalCode(a.postalCode) ? a.postalCode.substr(0, addressCharLimits.postalCode) : ''}
          </div>
        }
        <div className="ship-address-line">{countryText(a.countryCode)}</div>
        {this.isAddressInvalid(a) ?
          <div className=" invalid-address-message ">Issue with address, please edit</div>
          :
          null
        }
      </div>
      <div className="edit-icon">
        <i onClick={() => this.openEditAddress(a.addressId)}>
          <svg className="feather">
            <use href="/assets/images/icons/feather-sprite.svg#edit"/>
          </svg>
        </i>
      </div>
    </div>
  )

  doSaveInfo = async () => {
    try {
      let postData = {
        packageId: this.state.package.packageId,
        customerTitle: this.state.packageTitle,
      }

      const response = await axios.post('/customer/packages/updateInfo', postData)
      if(response.status === 200) {
        this.setState({
          message: '',
          titleEdit: false,
          package: {
            ...this.state.package,
            customerTitle: this.state.packageTitle,
          }
        })
      }

    } catch (e) {
      this.setState({
        message: (e.response? e.response.data.message : e.message) || 'Request failed.'
      })
    } finally {
      this.setState({loading: false})
    }
  }

  doCancelSave = () => {
    this.setState({
      loading: false,
      titleEdit: false,
      packageTitle: this.state.package.customerTitle,
    })
  }

  updateConsigneeID = async (value) => {
    try {
      this.setState({loading: true, message: ''})

      const requestObject = {
        ...this.state.selectedAddressData,
        consigneeId: value.consigneeId,
        consigneeBirthDate: value.consigneeBirthDate,
        consigneeIdExpiryDate: value.consigneeIdExpiryDate
      }

      await axios.post('/customer/addresses/updateAddress', requestObject)

      this.setState({
        selectedAddressData: requestObject
      })

    } catch (error) {
      this.setState({loading: false, message: error.response ? error.response.data.message : error.message})
    } finally {
      this.setState({loading: false})
    }
  }

  render() {

    const AddInsuranceToggle = this.state.insuranceQuoteLoading ? (
      <Skeleton />
    ) : (
      <>
        {(
          !!this.state.selectedQuoteId &&
          (
            !!this.state.shippingOptions.filter(option => option.quoteId === this.state.selectedQuoteId).length ||
            !!this.state.UPSShippingOptions.filter(option => option.quoteId === this.state.selectedQuoteId).length
          )
        ) && (
          <div
            className="ship-option"
            style={{ alignItems: 'center' }}
          >
            <div className="select-ddp mb-4" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <div className="select-ddp-info">
                <div className="select-ddp-title">Add Insurance?</div>
                <div className="ship-quote-description">
                  Insure the declared value of your package against damage and loss (up to $5000.00 USD).
                  <br />
                </div>
                <div className="ship-quote-link">
                  <a href="https://u-pic.com/reship-coverage" target="_blank" onClick={(e) => e.stopPropagation()}>
                    Click here to view insurance coverage terms
                  </a>
                </div>
              </div>
              <div className="btn-toggle">
                <button
                  className={`ddpEnable btn ${this.state.shippingInsuranceSelected ? 'btn-selected' : ''}`}
                  disabled={!this.state.selectedQuoteInsuranceCost || this.state.loading}
                  onClick={() => this.setInsurance(true)}
                >
                  Yes
                </button>
                <button
                  className={`ddpDisable btn ${!this.state.shippingInsuranceSelected ? 'btn-selected' : ''}`}
                  disabled={!this.state.selectedQuoteInsuranceCost || this.state.loading}
                  onClick={() => this.setInsurance(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    )
    
    if (this.state.initialLoading < 2)
      return (
        <div className="body">
          <CustomerSidebar/>
          <CustomerLoading/>
        </div>
      )
    else if (this.state.initialLoading > 2)
      return (
        <div className="body">
          <CustomerSidebar/>
          <GlobalError/>
        </div>
      )

    return (
      <div className={"body" + ((this.state.modalScreen || this.state.paymentScreen) ? ' modal-active' : '')} id="ship">
        <CustomerSidebar/>
        <div className="package-content">
          <div className="page-title flex-column align-items-start">
            {this.state.page < 4 &&
              <div className="package-header">
                <PhotoModal packageInfo={this.state.package}/>
                <div>
                  <PageHeader title={this.state.package.type===1 ? `Consolidation #${this.state.package.packageId.toFixed().padStart(6,'0')}` : `Package #${this.state.package.packageId.toFixed().padStart(6,'0')}`}/>
                  {this.state.titleEdit ?
                    <div className="package-title-edit">
                      <input className="package-title-input" type="text" maxLength="45" value={this.state.packageTitle} onChange={(e)=>{this.setState({packageTitle: e.target.value})}} />
                      <div className="package-title-icon" onClick={this.doSaveInfo}><i><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#check"/></svg></i></div>
                      <div className="package-title-icon" onClick={this.doCancelSave}><i><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#x"/></svg></i></div>
                    </div>
                    :
                    (this.state.package.customerTitle ?
                        <div className="package-customer-title pointer" onClick={()=>{this.setState({titleEdit: true})}}>
                          <div className="package-customer-title-text">{this.state.package.customerTitle}</div>
                          <div className="edit-icon">
                            <i>
                              <svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#edit"/></svg>
                            </i>
                          </div>
                        </div>
                        :
                        <div className="package-title-subtitle pointer" onClick={()=>{this.setState({titleEdit: true})}}>+ Give package a nickname/reference</div>
                    )
                  }

                </div>
                {this.state.package.status?.toLowerCase() === 'pending consolidation' && <CancelConsolidationModal packageInfo={this.state.package} history={this.props.history}/>}
                {!!this.state.items.length && <RequestPhotoModal packageInfo={this.state.package} fees={this.state.fees}/>}
                {!this.state.package.consolidation && !this.state.items.length && this.state.package.allowDelete && <CancelPrereceiveModal packageId={this.state.package.packageId} history={this.props.history}/>}
              </div>
            }
            {this.state.page === 31 && <PageHeader title={<div>Return Package</div>}/>}
            {(this.state.page > 3 && this.state.page !== 31 && !this.state.returnOption) && <PageHeader title={<div> Review Shipment </div>} onBack={this.goBack}/>}
            {(this.state.page > 3 && this.state.page !== 31 && this.state.returnOption) && <PageHeader title={<div> Review Shipment </div>} onBack={() => {this.setState({page: 31})}}/>}
          </div>

          <hr className="page-divider"></hr>
          <div className="package-container">
            <div className="package-side" style={this.state.page === 4 ? {display: "none"} : {}}>
              <div className="package-side-content">
                {(this.state.page < 4) &&
                  <div className="package-side-sticky">
                    <div className="package-info">
                      <div className="package-side-header">
                        {(this.state.package.consolidation) ?
                          <h4>Consolidation Details</h4> :
                          <h4>Package Details</h4>
                        }
                        <div className="package-status-container">
                          <div className={`package-status ${this.packageStatus()}`}>
                            {(this.state.package.reqPhotographs === 1 && this.state.package.reqPhotographsComplete === 0)? 'Awaiting additional photographs' : this.state.package.status}
                          </div>
                        </div>
                      </div>
                        <div className={(this.state.package.consolidation) ? 'package-card-consolidation' : 'package-card'}>
                          <div className="package-details">
                            <div className="package-info-item package-details-item package-info-toggle" onClick={()=> {this.setState({weightInLb: !this.state.weightInLb})}}>
                              <i><svg className="feather feather-light feather-small"><use href="/assets/images/icons/feather-sprite.svg#weight"/></svg></i> 
                              <div className="package-info-content p-select">
                                <div className="package-info-content-title">
                                  <span>Weight</span>
                                  {this.state.package.physical.weight && <div className="btn-toggle-small">
                                    <button className={`btn ${this.state.weightInLb ? `btn-selected` : ``}`}>lb</button><button className={`btn ${this.state.weightInLb ? `` : `btn-selected`}`}>kg</button>
                                  </div>}
                                </div>

                                {this.state.package.physical.weight?
                                  <div className="package-info-status">
                                    {this.state.weightInLb && <div>{weightImperial(this.state.package.physical.weight)} lb</div>}
                                    {!this.state.weightInLb && <div>{this.state.package.physical.weight} kg</div>}
                                  </div>
                                  :
                                  <div className="package-info-status">TBD</div>
                                }
                              </div>
                            </div>
                            <div className="package-info-item package-details-item package-info-toggle" onClick={() => {this.setState({dimensionInInch: !this.state.dimensionInInch})}}>
                              <i><svg className="feather feather-light feather-small"><use href="/assets/images/icons/feather-sprite.svg#box"/></svg></i> 
                              <div className="package-info-content p-select" >
                                <div className="package-info-content-title">
                                  <span>Dimensions</span>
                                  {this.state.package.physical.length && <div className="btn-toggle-small">
                                    <button className={`btn ${this.state.dimensionInInch ? `btn-selected` : ``}`}>in</button><button className={`btn ${this.state.dimensionInInch ? `` : `btn-selected`}`}>cm</button>
                                  </div>}
                                </div>
                                {this.state.package.physical.length?
                                  <div>
                                    {this.state.dimensionInInch && <div className="package-info-status" >{dimensionsImperial(this.state.package.physical.length)} x {dimensionsImperial(this.state.package.physical.width)} x {dimensionsImperial(this.state.package.physical.height)} in</div>}
                                    {!this.state.dimensionInInch && <div className="package-info-status" >{this.state.package.physical.length} x {this.state.package.physical.width} x {this.state.package.physical.height} cm</div>}
                                  </div>
                                  :
                                  <div className="package-info-status">TBD</div>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="package-details">
                            <div className="package-info-item package-details-item">
                              <i><svg className="feather feather-light feather-small"><use href="/assets/images/icons/feather-sprite.svg#map-pin"/></svg></i> 
                              <div className="package-info-content">
                                <span>Warehouse</span>
                                <div className="package-info-status">
                                  {warehouseData(this.state.package.warehouse).name}
                                </div>
                              </div>
                            </div>
                            <div className="package-info-item package-details-item">
                              <i><svg className="feather feather-light feather-small"><use href="/assets/images/icons/feather-sprite.svg#calendar"/></svg></i>
                              {this.state.package.consolidation ?
                                <div className="package-info-content">
                                  <span>Requested On</span>
                                  <div className="package-info-status">{displayDate(convertToClientTimestamp(this.state.package?.created), "d MMM yyyy")}</div>
                                </div>
                                :
                                <div className="package-info-content">
                                <span>Received On</span>
                                <div className="package-info-status">{displayDate(convertToClientTimestamp(this.state.package.incoming?.received), "d MMM yyyy")}</div>
                                </div>
                              }
                            </div>
                            {this.state.package.hazmatName && <div className="package-info-item package-details-item">
                              <i><svg className="feather feather-light feather-small"><use href="/assets/images/icons/feather-sprite.svg#alert-octagon"/></svg></i>
                              <div className="package-info-content">
                                <span>Hazmat Description</span>
                                <div className="package-info-status">
                                  {this.state.package.hazmatName}
                                </div>
                              </div>
                            </div>}
                          </div>
                          {!this.state.package.consolidation &&
                          <div className="package-details">
                            <div className="package-info-item package-details-item">
                              <i><svg className="feather feather-light feather-small"><use href="/assets/images/icons/feather-sprite.svg#shopping-bag"/></svg></i>
                              <div className="package-info-content">
                                <span>Store/Vendor</span>
                                <div className="package-info-status">{this.state.package.incoming?.vendor}</div>
                              </div>
                            </div>
                            <div className="package-info-item package-details-item">
                              <i><svg className="feather feather-light feather-small"><use href="/assets/images/icons/feather-sprite.svg#truck"/></svg></i>
                              <div className="package-info-content">
                                <span>Carrier</span>
                                <div className="package-info-status">{carrierLabel(this.state.package.incoming?.carrier)}</div>
                              </div>
                            </div>
                          </div>}
                          {(this.state.package.consolidation) &&
                          <div className="package-details package-consolidation">
                            <div className="package-info-item package-details-item">
                              <i>
                                <svg className="feather feather-light feather-small">
                                  <use href="/assets/images/icons/feather-sprite.svg#package"/>
                                </svg>
                              </i>
                              <div className="package-info-content">
                                <span>Packages in Consolidation</span>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>ID</th>
                                      <th>Vendor</th>
                                      <th>Received</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!!this.state.package.subpackages && this.state.package?.subpackages.map((pkg, index) => {
                                      return (
                                        <tr key={`subPackage-${index}-${pkg.packageId}`}>
                                          <td>
                                            #{pkg.packageId.toFixed().padStart(6,'0')}
                                          </td>
                                          <td key={`subPackage-${index}-${pkg.vendor}`}>
                                            {pkg.vendor}
                                          </td>
                                          <td key={`subPackage-${index}`}>
                                            {displayDate(convertToClientTimestamp(pkg.received), "d MMM yyyy")}
                                          </td>
                                        </tr>
                                        )
                                      })
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>}
                        </div>
                        {!this.state.package.consolidation && <div className="package-info-item tracking-number">
                          <div className="package-info-status">
                            {this.props.store.allowPreReceiving && <TimelineModal timelineData={this.state.timeline}/>}
                            {!this.props.store.allowPreReceiving && (this.state.package.incoming?.tracking ? <TrackingLink trackingLink={this.state.package.incoming.trackingLink} tracking={this.state.package.incoming.tracking}/> : 'Not yet available.')}
                          </div>
                        </div>}
                      </div>
                  </div>}
              </div>
            </div>
            <div className="package-main">

              {(!this.state.package.shipped && !!this.state.package.restrictDomesticOnly && this.state.package.restricted !== 27) &&
                <div className="notice notice-warn">
                  {/* <div className="notice-icon">⚠</div> */}
                  <i><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#alert-triangle"/></svg></i> 
                  <div className="notice-content">Due to export restrictions, this package can only be shipped within the same country. Your shipping addresses have been filtered accordingly.</div>
                </div>
              }
              {(!this.state.package.shipped && !!this.state.package.restrictGroundOnly && this.state.package.restricted !== 26) &&
                <div className="notice notice-warn">
                  {/* <div className="notice-icon">⚠</div> */}
                  <i><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#alert-triangle"/></svg></i> 
                  <div className="notice-content">Due to carrier restrictions, this package can only be shipped via surface delivery methods.</div>
                </div>
              }
              {(!this.state.package.shipped && !!restrictionStatus(this.state.package.restricted)) &&
                <div className="notice notice-warn">
                  {/* <div className="notice-icon">⚠</div> */}
                  <i><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#alert-triangle"/></svg></i> 
                  <div className="notice-content">{restrictionStatus(this.state.package.restricted)}</div>
                </div>
              }
              {(!this.state.package.shipped && !!this.state.package.restrictPickupOnly) &&
                <div className="notice notice-warn">
                  {/* <div className="notice-icon">⚠</div> */}
                  <i><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#alert-triangle"/></svg></i>
                  <div className="notice-content">Due to export restrictions, this package can only be picked up from Reship warehouse. Your shipping addresses have been filtered accordingly.</div>
                </div>
              }

              {(this.state.page < 4) &&
                <div className={`package-card ${(!this.state.items.length ? "package-default" : (this.state.editDeclarationForm ? "package-current" : "package-complete" ))}`}>
                  <div className="package-card-title ">
                    <Tooltip placement="right" overlayStyle={{maxWidth: "400px"}} title={<div><p style={{color: "#FFFFFF", fontSize: "12px"}}>Reship will submit your customs declaration in {warehouseData(this.state.package.warehouse).currency}.</p>
                               <p style={{color: "#FFFFFF", fontSize: "12px"}}>Simply enter the value of each item in your package in the currency in which you paid, and our currency conversion tool will convert the value of your items to {warehouseData(this.state.package.warehouse).currency}.</p></div>}>
                     <div className="package-card-title-content">
                        <div className="package-card-step">
                          {(this.state.editDeclarationForm || !this.state.items.length) ?
                          '1' : 
                          <i><svg className="feather feather-white"><use href="/assets/images/icons/feather-sprite.svg#check"/></svg></i>}
                          </div>
                        <h2>Declaration Form</h2>
                      </div>
                    </Tooltip>
                    <div className="package-card-title-content">
                      <div className="package-card-value">
                        <div className="package-card-value-title">Total Value</div>
                        <div className="package-card-value-total">${this.state.totalDeclarationValue} ({warehouseData(this.state.package?.warehouse).currency})
                        </div>
                      </div>
                      <div>
                        {!this.state.editDeclarationForm ?
                          <div>
                            <button className="btn btn-mobile declaration-edit-btn" disabled={!this.state.items.length} onClick={() => {this.setState({editDeclarationForm: true, modalScreen: true, shippingAddrState: (this.state.selectedAddressId ? 2 : 0)})}}>
                              <i>
                                <svg className="feather">
                                  <use href="/assets/images/icons/feather-sprite.svg#edit"/>
                                </svg>
                              </i>
                              <span className="declaration-edit-btn">
                                Edit Form
                              </span>
                            </button>
                          </div>
                          :
                          <button className="accordion-icon" >
                            <i>
                              <svg className="feather">
                                <use href="/assets/images/icons/feather-sprite.svg#chevron-up"/>
                              </svg>
                            </i>
                          </button>
                          }
                      </div>
                    </div>
                  </div>
                  <div className="declared-form">
                    {this.state.editDeclarationForm &&
                      (this.state.windowWidth > 768 ?
                        <ItemsDeclarationForm pkg={this.state.package} type={'checkout'} pkgCustomsType={this.state.customsType} saveAction={() => {}} setEdit={() => {}} saveValue={this.savedDeclaredValue} closeAction={this.closeDeclarationForm} saveCustoms={this.saveCustoms} onFinish={this.getAddresses}/>
                        :
                        <MobileDeclarationForm pkg={this.state.package} pkgCustomsType={this.state.customsType} saveValue={this.savedDeclaredValue} openForm={this.state.editDeclarationForm} closeAction={this.closeDeclarationForm} saveCustoms={this.saveCustoms}/>
                      )}
                  </div>
                </div>
              }
              {(this.state.page < 4) &&
                <div className={`package-card ${(this.state.shippingAddrState === 0 ? "package-default" : (this.state.shippingAddrState === 1 ? "package-current" : "package-complete" ))}`}>
                  <div className=" package-card-title">
                    <div className="package-card-title-content">
                      <div className="package-card-step">2</div>
                      <h2>Shipping Address</h2>
                    </div>
                    <div className="checkout-title-content">
                      {this.state.shippingAddrState !== 1 && !!this.state.selectedAddressId &&
                        <div className="checkout-title-content">
                          <div className="selected-info-one-line">
                            {this.state.selectedAddressData.name},&nbsp;
                            {this.state.selectedAddressData.street1},&nbsp;
                            {!!this.state.selectedAddressData.street2 && `${this.state.selectedAddressData.street2}, `}
                            {this.state.selectedAddressData.city},&nbsp;
                            {countryText(this.state.selectedAddressData.countryCode)}
                          </div>
                        </div>
                      }
                      <div className="toggle-expand">
                        {this.state.shippingAddrState === 1 ?
                          <div className="shipping-address-newAddr-btn">
                            <div className="btn btn-mobile btn-border" onClick={() => {this.props.clearAutocompleteValues(); this.openAddAddress()}}><i>+ Address</i><span>+ New Address</span></div>
                            <div className="accordion-icon" onClick={() => {this.setState({shippingAddrState: (this.state.selectedAddressId ? 2 : 0)})}}>
                              <svg className="feather">
                                <use href="/assets/images/icons/feather-sprite.svg#chevron-up"/>
                              </svg>
                            </div>
                          </div>
                          :
                          <button className="accordion-icon" disabled={!this.allItemsDeclared(this.state.items) || this.state.package.status?.toLowerCase() === 'pending consolidation' || this.state.loading} onClick={() => {this.setState({shippingAddrState: 1})}}>
                            <i >
                              <svg className="feather">
                                <use href="/assets/images/icons/feather-sprite.svg#chevron-down"/>
                              </svg>
                            </i>
                          </button>
                        }
                      </div>
                    </div>
                  </div>

                  {this.state.shippingAddrState === 1 &&
                    <div className="package-address-list">
                      {!this.state.package.restrictPickupOnly && this.state.addresses.map(a => {
                        return this.renderAddressOption(a)
                      })}
                      {this.state.package.warehouse === 1 &&
                        <div className="package-address-checkout" onClick={this.state.loading? ()=>{} : () => {this.selectAddress(-1)}} style={this.state.selectedAddressId === -1 ? {borderColor: '#84329B'} : {}}>
                          <div className="radio-select">
                            {this.state.selectedAddressId === -1 ?
                              <div className="radio radio-checked"></div> : <div className="radio"></div>}
                          </div>
                          <div className="package-address-content">
                            <div className="ship-address-line ship-address-line-name">Pickup</div>
                            <div className="ship-address-line">Pick up in Portland, US</div>
                          </div>
                        </div>
                      }
                      {this.state.package.warehouse === 2 &&
                        <div className="package-address-checkout" onClick={this.state.loading? ()=>{} : () => {this.selectAddress(-2)}} style={this.state.selectedAddressId === -2 ? {borderColor: '#84329B'} : {}}>
                          <div className="radio-select">
                            {this.state.selectedAddressId === -2 ?
                              <div className="radio radio-checked"></div> : <div className="radio"></div>}
                          </div>
                          <div className="package-address-content">
                            <div className="ship-address-line ship-address-line-name">Pick up in Blakelands, UK</div>
                            <div className="ship-address-line">{warehouseData(2).street1}</div>
                            <div className="ship-address-line">{warehouseData(2).city}, {warehouseData(2).stateCode} {warehouseData(2).postalCode}</div>
                            <div className="ship-address-line">{warehouseData(2).countryLong}</div>
                          </div>
                        </div>
                      }
                      {(this.state.package.warehouse === 3 || this.state.package.warehouse === 99) &&
                        <div className="package-address-checkout" onClick={this.state.loading? ()=>{} : () => {this.selectAddress(-3)}}
                             style={this.state.selectedAddressId === -3 ? {borderColor: '#84329B'} : {}}>
                          <div className="radio-select">
                            {this.state.selectedAddressId === -3 ?
                              <div className="radio radio-checked"></div> : <div className="radio"></div>}
                          </div>
                          <div className="package-address-content">
                            <div className="ship-address-line ship-address-line-name">Pick up in Surrey, CA</div>
                            <div className="ship-address-line">{warehouseData(3).street1}</div>
                            <div className="ship-address-line">{warehouseData(3).city}, {warehouseData(3).stateCode} {warehouseData(3).postalCode}</div>
                            <div className="ship-address-line">{warehouseData(3).countryLong}</div>
                          </div>
                        </div>
                      }
                    </div>
                  }

                  {(this.state.addressAdd || this.state.addressEdit) &&
                    <div className="address-form">
                      <div className="address-form-title">
                        <h3>{this.state.addressEdit ? 'Edit Address' : 'Add Shipping Address'}</h3>
                      </div>
                      <div className="address-form-group">
                        <div className="address-form-label">Full Name</div>
                        <input className="address-form-input" value={this.state.addressName} placeholder="eg. John Smith" onChange={(e) => {this.updateAddressField(e, 'name')}} maxLength={addressCharLimits.name}/>
                        {this.state.addressMessageSpecial === 'name' && <div className="error-text-special">{specialCharacterMessage}</div>}
                      </div>
                      <div className="address-form-group">
                        <div className="address-form-label">Street Address</div>
                        <input 
                          className="address-form-input" 
                          placeholder="Street address" 
                          value={this.props.value.street1}
                          onChange={(e) => {this.props.handleInput(e); this.setState({ addressStreet1: e.target.value })}} 
                          maxLength={addressCharLimits.streets}
                          onBlur={() => this.props.setShowSuggestions(false)}
                          onFocus={() => this.props.setShowSuggestions(true)}
                          disabled={!this.props.ready}
                        />
                        {this.props.renderedSuggestions}

                        <div className="address-form-label">Street Address 2</div>
                        <input className="address-form-input" placeholder="Unit, buzzcode, etc." value={this.state.addressStreet2} onChange={(e) => {this.updateAddressField(e, 'street2')}} maxLength={addressCharLimits.streets}/>
                        {this.state.addressMessageSpecial === 'street' && <div className="error-text-special">{specialCharacterMessage}</div>}
                      </div>
                      <div className="address-form-group">
                        <div className="address-form-label">City</div>
                        <input className="address-form-input" placeholder="City" value={this.state.addressCity} onChange={(e) => {this.updateAddressField(e, 'city')}} maxLength={addressCharLimits.city}/>
                        {this.state.addressMessageSpecial === 'city' && <div className="error-text-special">{specialCharacterMessage}</div>}
                      </div>
                      <div className="address-form-group">
                        <div className="address-form-label">Country</div>
                        <DropdownCountry className="address-form-select" value={this.state.addressCountryCode} onChange={(val) => {if (val !== this.state.addressCountryCode) this.setState({addressCountryCode: val, addressStateCode: ''})}}/>
                      </div>
                      <div className="address-form-group">
                        <DropdownRegion className="address-form-select" country={this.state.addressCountryCode} value={this.state.addressStateCode} onChange={(val) => this.setState({addressStateCode: val})} showLabel={true}/>
                      </div>
                      <InputPostalCode value={this.state.addressPostalCode} doUpdate={(v) => {this.setState({addressPostalCode: v.value, addressPostalCodeValid: v.valid})}} countryCode={this.state.addressCountryCode} showLabel={true}/>
                      <InputPhoneNumber value={this.state.addressPhone} doUpdate={(v) => {this.setState({addressPhone: v.value, addressPhoneValid: v.valid, addressPhoneFormatted: v.formatted})}} countryCode={this.state.addressCountryCode}/>
                      <div className="address-form-group">
                        <div className="address-form-label">Delivery Notes/Instructions</div>
                        <textarea className="address-form-notes" onChange={(e) => this.setState({addressNotes: filterNotes(e.target.value)})} value={this.state.addressNotes} maxLength={addressCharLimits.notes}/>
                        <div className="sub">Delivery notes will be printed and added to package for courier/recipient where possible. Optional. Max 500 characters.
                        </div>
                      </div>
                      <div className="button-row address-edit-btn">
                        <button className="btn" onClick={this.closeAddressEditor}><i>
                          <svg className="feather">
                            <use href="/assets/images/icons/feather-sprite.svg#x"/>
                          </svg>
                        </i> Cancel</button>
                        {this.state.addressAdd && <div className="btn btn-mobile btn-border" onClick={this.addAddress}>
                          <i><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#check"/></svg>&nbsp;Save</i>
                          <span><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#check"/></svg>&nbsp;Save Address</span></div>}
                        {this.state.addressEdit && <div className="btn btn-mobile btn-border" onClick={this.editAddress}>
                          <i><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#check"/></svg>&nbsp;Save</i>
                          <span><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#check"/></svg>&nbsp;Save Address</span></div>}
                      </div>
                      {!!this.state.message && <div className="error-text">{this.state.message}</div>}
                    </div>
                  }
                  {(!(this.state.addressAdd || this.state.addressEdit) && this.state.shippingAddrState === 1) &&
                    <div className="package-return"><span onClick={this.returnAddress}>I have a vendor return shipping label</span></div>
                  }
                </div>
              }
              {this.state.page < 4 &&
                <>
                  <div className={`package-card ${(this.state.shippingMethodState === 0 ? "package-default" : (this.state.shippingMethodState === 1 ? "package-current" : "package-complete" ))}`}>
                    <div className="package-card-title">
                      <div className="package-card-title-content">
                        <div className="package-card-step">{(this.state.shippingMethodState === 2 ? <i><svg className="feather feather-white"><use href="/assets/images/icons/feather-sprite.svg#check"/></svg></i>  : '3')}</div>
                        <h2>{this.state.shippingMethodState === 1 ? this.state.selectedAddressId > 0 ? 'Shipping Method' : 'Pickup Options' : 'Shipping Method'}</h2>
                      </div>
                      <div className="toggle-expand">
                        {this.state.shippingMethodState === 1 ?
                          <div className="flex">
                            {(this.state.shippingOptions.length > 2 || this.state.UPSShippingOptions.length > 2) &&
                              <div className="ship-quotes-sort-new" onClick={this.switchShippingSort} style={{fontSize: '14px'}}> {this.state.shippingSortPrice ? 'Price Low to High' : 'Price High to Low'}</div>}
                            <button className="accordion-icon" disabled={!this.state.selectedQuoteId} onClick={() => {this.setState({shippingMethodState: (this.state.selectedQuoteId ? 2 : 0)})}}>
                              <i className="accordion-icon">
                                <svg className="feather">
                                  <use href="/assets/images/icons/feather-sprite.svg#chevron-up"/>
                                </svg>
                              </i>
                            </button>
                          </div>
                          :
                          <div className="checkout-title-content">
                            {!!this.state.selectedQuoteId &&
                              <div className="checkout-title-content">
                                <div className="selected-info-one-line">
                                  ${this.state.selectedQuote.rateCustomer},&nbsp;{this.state.selectedQuote.serviceName}&nbsp;
                                </div>
                              </div>}
                            <button className="accordion-icon" disabled={!this.state.selectedQuoteId} onClick={() => {this.setState({shippingMethodState: 1})}}>
                              <i className="accordion-icon">
                                <svg className="feather">
                                  <use href="/assets/images/icons/feather-sprite.svg#chevron-down"/>
                                </svg>
                              </i>
                            </button>
                          </div>
                        }
                      </div>
                    </div>

                    {this.state.showEoriForm && this.state.shippingMethodState === 1 &&
                      <div className='eori-card-checkout '>
                        <div className='eori-card-title'>Receiver's EORI Number</div>
                        <EoriForm eoriNumber={this.state.eoriNumber} onChange={(value) => this.setState({eoriNumber: value})} onSubmit={this.updatePackageEori}/>
                      </div>
                    }

                    {(this.state.selectedAddressId > 0 && this.state.shippingMethodState === 1) &&
                      <div className="select-ddp mb-4">
                        <div className="select-ddp-info">
                          <div className="select-ddp-title">Let Reship Handle Customs Clearance?</div>
                          <div className="select-ddp-time">
                            By selecting "Yes", Reship will calculate the import duties and taxes for your shipment. These charges will be included in your total at checkout. Once checkout is completed, Reship will take responsibility for the payment of these duties and taxes to the relevant authorities and ensure your package clears customs on arrival. You will not incur any additional charges in order to receive your package.
                          </div>
                          <div className="select-ddp-time">
                            <br/>
                            By selecting "No", you are solely responsible for paying any duties and taxes to the carrier prior to, or on, delivery of your package.                          
                          </div>
                          </div>
                        <div className="btn-toggle">
                          <button className={`ddpEnable btn ${this.state.ddp === 1 ? 'btn-selected' : ''}`} disabled={!this.state.isDdpEnabled || this.state.loading}  onClick={() => {this.selectDdp(1); this.setState({hasProvidedReleaseAuthority: true})}}>Yes</button>
                          <button className={`ddpDisable btn ${this.state.ddp === -1 ? 'btn-selected' : ''} `} disabled={!this.state.isDdpEnabled || this.state.loading} onClick={() => {this.selectDdp(-1); this.setState({hasProvidedReleaseAuthority: false})}}>No</button>
                        </div>
                      </div>
                    }

                    {this.state.selectedAddressData.countryCode === 'CA' && this.state.ddp === 1 &&
                      <div className="ship-option-auth">
                        <div className="ship-option-info">
                          <div className="release-auth-title">Release Authority</div>
                          <div className="release-auth-body">
                            <div className="release-auth-description">I hereby constitute and appoint Border Buddy Technologies Inc. o/a BorderBuddy Customs Brokers, of 19162 22 Ave Suite 105, Surrey, BC V3S 3S6 my true, authorized and lawful
                              attorney and agent to transact business on my behalf in all matters relating to: (1) Customs that may be transacted by a customs broker licensed under the Customs Act; (2) Excise
                              and any tax or levies under the Excise Tax Act;
                              (3) Shipping and storage related to any contract; and Including all matters relating to the account for and payment and refund of customs and/or excise duties, excise tax, sales tax
                              and goods and services tax in respect of imported goods released or to be released under such legislation, at the customs offices located in ANY AND ALL PORTS IN CANADA.</div>
                            <br/>
                            <div className="release-auth-description">I acknowledge that in authorizing this release authority, I also agree to all terms and conditions at <a href="https://www.reship.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">reship.com/terms-and-conditions</a>
                            </div>
                          </div>
                        </div>
                        <div className="btn-toggle">
                          <button className={`releaseAuth btn ${this.state.hasProvidedReleaseAuthority ? 'btn-selected' : ''}`} onClick={() => this.setState({hasProvidedReleaseAuthority: true, showReleaseAuthModal: false})}>Accept</button>
                          <button className={`releaseDecline btn ${!this.state.hasProvidedReleaseAuthority ? 'btn-selected' : ''} `} onClick={() => this.setState({hasProvidedReleaseAuthority: false, showReleaseAuthModal: true})}>Decline</button>
                        </div>
                      </div>
                    }

                    {this.state.loading ?
                      <div className="ship-quotes-loading mt-5">
                        <LoadingSpinner icon={'local_shipping'}/>
                      </div>
                      :
                      <div className="ship-quotes-card mt-3">
                        {this.state.shippingMethodState === 1 && <Fragment>
                          {((this.state.shippingOptions.length || this.state.UPSShippingOptions.length) ?
                              <Fragment>
                                <div className="ship-quotes">
                                  {(this.state.itemDeclarationRequired && this.state.package.warehouse === 2) &&
                                    <div className='notice notice-critical'>
                                      <div className="notice-icon">📣</div>
                                      <div className="notice-content">Due to the pandemic and Brexit, some shipments may
                                        experience significant delays beyond normal estimates.<br/>Please visit carrier
                                        websites for the most up-to-date statuses of your preferred shipping services
                                        and destinations.
                                      </div>
                                    </div>
                                  }
                                  {this.state.UPSShippingOptions.map(option => {
                                    return (
                                      <div className={`ship-quote ${this.state.selectedQuoteId === option.quoteId ? 'ship-quote-selected' : ''} ${option.system === 0 ? 'ship-quote-ownlabel' : ''}`}
                                        key={option.quoteId} onClick={async () => { await this.handleSelectShippingQuote(option)}} style={this.state.selectedQuoteId === option.quoteId ? {border: '1px solid #84329B'} : {}}>
                                        <div className="ship-quote-select">
                                          {this.state.selectedQuoteId === option.quoteId ?
                                          <div className="radio radio-checked"></div> : <div className="radio"></div>}
                                        </div>
                                        <div className="ship-quote-info">
                                          <div className="ship-quote-title">{option.serviceName}</div>
                                          {(option.deliveryDaysMin && option.deliveryDaysMax && option.deliveryDaysMin !== option.deliveryDaysMax) ?
                                            <div
                                              className="ship-quote-time">Estimate {option.deliveryDaysMin}-{option.deliveryDaysMax} Business
                                              Days</div>
                                            :
                                            (option.deliveryDays ?
                                                <div className="ship-quote-time">Estimate {option.deliveryDays} Business
                                                  Days</div>
                                                :
                                                <div
                                                  className="ship-quote-time">{option.serviceDescription || 'Delivery time depends on destination and cannot be guaranteed'}</div>
                                            )
                                          }
                                          {option.carrier === 'InterlinkExpress' ?
                                            <div className="ship-quote-description"> This service is temporarily
                                              unavailable </div> : null}
                                          {(this.state.itemDeclarationRequired && [1, 4].includes(this.state.customsType)) && getBrokerageMessage(option.methodId, this.state.selectedAddressData.countryCode, this.state.itemsDeclaredTotal)}
                                        </div>
                                        <div className="ship-quote-price">{option.rateCustomer ?
                                          <Fragment>${option.rateCustomer.toFixed(2)}</Fragment> : 'Free'}
                                        </div>
                                      </div>
                                    )
                                  })}
                                  {this.state.shippingOptions.map(option => {
                                    return (
                                      <Fragment>
                                        {option.serviceName === 'Reship Select' ?
                                          <Tooltip placement="top" overlayStyle={{maxWidth: "400px"}} title={<div><p style={{color: "#FFFFFF", fontSize: "12px"}}>Reship Select is our economy shipping option, where we offer customers our cheapest rates.</p>
                                            <p style={{color: "#FFFFFF", fontSize: "12px"}}>Packages are shipped weekly from our warehouse. While this means reduced prices due to higher volume, it also results in longer delivery times, adding between 1-7 business days. Please note that online package tracking is not available with this option at present and delivery times are not guaranteed.</p></div>}>
                                            {this.renderShippingOption(option)}
                                          </Tooltip>
                                          :
                                          this.renderShippingOption(option)
                                        }
                                      </Fragment>
                                      )
                                  })}
                                </div>
                                {(this.state.itemDeclarationRequired && !['AU', 'NZ'].includes(this.state.selectedAddressData.countryCode)) &&
                                  <div className="ship-quotes-footnote">International shipments may be charged applicable customs duties & taxes at delivery</div>}
                                <div className="ship-quotes-footnote">* All amounts in USD</div>
                                {(!!this.state.selectedQuoteId
                                  && (this.state.shippingOptions.filter(q => {return q.quoteId === this.state.selectedQuoteId})[0]?.system === 1 ||
                                    this.state.UPSShippingOptions.filter(q => {return q.quoteId === this.state.selectedQuoteId})[0]?.system === 1))
                                  ?
                                  <div className="notice notice-info">
                                    <div className="notice-content">Please enter a <strong>name</strong> and/or <strong>mailbox number</strong> for the border pickup location in the shipping notes below</div>
                                  </div> : null
                                }
                                {(!!this.state.selectedQuoteId
                                  && (this.state.shippingOptions.filter(q => {return q.quoteId === this.state.selectedQuoteId})[0]?.system === 99 ||
                                    this.state.UPSShippingOptions.filter(q => {return q.quoteId === this.state.selectedQuoteId})[0]?.system === 99))
                                  ?
                                  <div className="notice notice-info">
                                    <div className="notice-content">
                                      <strong>Pickup Instructions</strong>
                                      <div className="notice-content-line">A confirmation email will be sent to you
                                        after checkout, please bring it and a piece of government-issued ID to pick up
                                        the package. <br></br>The name on the ID must match the package addressee, but
                                        if a different person will be picking up the package, please specify their
                                        name(s) in the shipping notes below. <br></br>Please note that you can't ship a
                                        package when it's being held for pickup. <br></br>Packages unclaimed for 30 days
                                        may be subject to disposal.
                                      </div>
                                    </div>
                                  </div> : null
                                }

                                {(!!this.state.selectedQuoteId
                                  &&
                                  !!this.state.shippingOptions.filter(option => {
                                    return option.quoteId === this.state.selectedQuoteId && !!this.state.shippingOptions.filter(option => {
                                      return option.quoteId === this.state.selectedQuoteId})[0]}).length)
                                  && (this.state.selectedAddressData.consigneeDataCollection && this.state.ddp === 1)
                                  ?
                                  <div className="notice notice-info">
                                    <div className="notice-content">
                                      <strong>Consignee Information Required</strong>
                                      <div className="notice-content-description">ZATCA, the The Zakat, Tax and Customs Authority of the Kingdon of Saudi Arabia requires importers to present a valid Residence Permit (Iqama) or Saudi National ID (Bataqa) for all personal imports valued over 1000 SAR.
                                        <br/>
                                        <br/>Please provide the following information to streamline clearance of your package upon arrival in the Kingdom of Saudi Arabia.</div>
                                      <ConsigneeIDForm addressValue={this.state.selectedAddressData} onSubmit={this.updateConsigneeID}/>
                                    </div>
                                  </div> : null
                                }

                                <div className="ship-options">

                                  {AddInsuranceToggle}

                                  {this.state.selectedAddressId > 0 && !!this.state.selectedQuoteId &&
                                    <div className="ship-notes-content pad-t-20">
                                      <div className="ship-option-title">Delivery Notes</div>
                                      <textarea className="form-control address-form-notes" onChange={(e) => this.setState({shippingDeliveryNotes: e.target.value})} value={this.state.shippingDeliveryNotes} maxLength={addressCharLimits.notes}/>
                                      <div className="address-form-label-sub">
                                        Delivery notes will be printed and added to the package with the shipping label for the
                                        courier/recipient where possible. Please put any additional delivery details here. Max 500
                                        characters.
                                      </div>
                                    </div>
                                  }

                                  {!this.state.package.consolidation &&
                                    <Fragment>
                                      {/* {(this.state.fees.giftWrap!==null && this.state.package.physical.length < 60 && this.state.package.physical.width < 60 && this.state.package.physical.height < 60) && <div className="ship-option" onClick={()=>{this.setState({requestGiftWrap: !this.state.requestGiftWrap})}}>
                                <div className="ship-option-checkbox">{this.state.requestGiftWrap ? <i className="far fa-check-square" /> : <i className="far fa-square" />}</div>
                                <div className="ship-option-title">Gift wrap <span className="badge badge-primary">$ {this.state.fees.giftWrap===0 ? 'Free' : this.state.fees.giftWrap.toFixed(2)}</span></div>
                              </div>}
                              {(this.state.fees.invoiceRemoval!==null) && <div className="ship-option" onClick={()=>{this.setState({requestInvoiceRemoval: !this.state.requestInvoiceRemoval})}}>
                                <div className="ship-option-checkbox">{this.state.requestInvoiceRemoval ? <i className="far fa-check-square" /> : <i className="far fa-square" />}</div>
                                <div className="ship-option-title">Invoice Removal <span className="badge badge-primary">$ {this.state.fees.invoiceRemoval===0 ? 'Free' : this.state.fees.invoiceRemoval.toFixed(2)}</span></div>
                              </div>} */}
                                      {/* Removed temporarily due to difficulty of reassessing shipping cost when having to pad shipment
                                (this.state.fees.padding!==null) && <div className="ship-option" onClick={()=>{this.setState({requestPadding: !this.state.requestPadding})}}>
                                <div className="ship-option-checkbox">{this.state.requestPadding ? <i className="far fa-check-square" /> : <i className="far fa-square" />}</div>
                                <div className="ship-option-title">Additional Padding (for fragile items) <span className="badge badge-primary">$ {this.state.fees.padding===0 ? 'Free' : this.state.fees.padding.toFixed(2)}</span></div>
                              </div>*/}
                                    </Fragment>
                                  }
                                </div>
                                {!!this.state.message && <div className="error-text">{this.state.message}</div>}
                                <div className='flex justify-content-end mt-4'>
                                  <button className={`btn btn-primary`}
                                          disabled={!this.disableCheckoutButton()}
                                          onClick={this.proceedToCheckout}><Fragment>Checkout</Fragment>
                                  </button>
                                </div>


                              </Fragment>
                              :
                              <>
                              {!!this.state.ddp && <div className="ship-quotes-none">
                                  Whoops! It looks like we’re having trouble finding an appropriate shipping method for your address. There are several reasons why this might be happening, including: <br/><br/>
                                  <ul>
                                    <li>• Our shipping partners may not service your location.</li>
                                    <li>• Your address may be on the government blacklists that we cross-reference before shipping.</li>
                                    <li>• Your package may require special handling due to size, weight, or contents.</li>
                                  </ul>
                                  Please contact our support team via the chat tool on our website so that we can make special arrangements for shipping your package.
                                </div>}
                              </>

                          )}
                        </Fragment>}
                      </div>}
                  </div>

                  {/*<div className="package-card">*/}
                  {/*  <div className='package-card-title'>*/}
                  {/*    <div className="package-card-title-content ">*/}
                  {/*      <div className="package-card-step">4</div>*/}
                  {/*      <h2>Shipping Notes</h2>*/}
                  {/*    </div>*/}
                  {/*    <div className="toggle-expand">*/}
                  {/*      {this.state.shipNotesState ?*/}
                  {/*        <button className="accordion-icon" onClick={() => {this.setState({shipNotesState: false})}}>*/}
                  {/*          <i className="accordion-icon">*/}
                  {/*            <svg className="feather">*/}
                  {/*              <use href="/assets/images/icons/feather-sprite.svg#chevron-up"/>*/}
                  {/*            </svg>*/}
                  {/*          </i>*/}
                  {/*        </button>*/}
                  {/*        :*/}
                  {/*        <button className="accordion-icon" disabled={!this.state.selectedQuoteId} onClick={() => {this.setState({shipNotesState: true})}}>*/}
                  {/*          <i>*/}
                  {/*            <svg className="feather">*/}
                  {/*              <use href="/assets/images/icons/feather-sprite.svg#chevron-down"/>*/}
                  {/*            </svg>*/}
                  {/*          </i>*/}
                  {/*        </button>*/}
                  {/*      }*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*  {((!this.state.loading && !!this.state.selectedQuoteId) && !!this.state.shippingOptions.length) &&*/}
                  {/*    <div className={`ship-notes-card ${this.state.shipNotesState ? `clicked` : ``}`}>*/}
                  {/*      <div className="ship-notes-content">*/}
                  {/*        /!* <div className="ship-notes-title">Handling Notes</div> *!/*/}
                  {/*        <textarea className="form-control address-form-notes" onChange={(e) => this.setState({shippingNotes: e.target.value})} value={this.state.shippingNotes} maxLength={addressCharLimits.notes}/>*/}
                  {/*        <div className="sub">*/}
                  {/*          Handling comments written here will be visible to our staff but not added to the package or*/}
                  {/*          passed to the courier. Max 500 characters.<br/>If you require special handling, please*/}
                  {/*          contact customer service <strong>before</strong> checking out. A nominal fee may be charged*/}
                  {/*          based on the cost and complexity of the request. <br/>Please note that unless arranged or*/}
                  {/*          paid for, not all requests may be able to be fulfilled, and requests may delay the shipment*/}
                  {/*          of your package.*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*      {this.state.selectedAddressId > 0 &&*/}
                  {/*        <div className="ship-notes-content pad-t-20">*/}
                  {/*          <div className="ship-option-title">Delivery Notes</div>*/}
                  {/*          <textarea className="form-control address-form-notes" onChange={(e) => this.setState({shippingDeliveryNotes: e.target.value})} value={this.state.shippingDeliveryNotes} maxLength={addressCharLimits.notes}/>*/}
                  {/*          <div className="address-form-label-sub">*/}
                  {/*            Delivery notes will be printed and added to the package with the shipping label for the*/}
                  {/*            courier/recipient where possible. Please put any additional delivery details here. Max 500*/}
                  {/*            characters.*/}
                  {/*          </div>*/}
                  {/*        </div>*/}
                  {/*      }*/}

                  {/*    </div>*/}
                  {/*  }*/}
                  {/*</div>*/}

                  {/*<div className="package-card">*/}
                  {/*  <div className="package-card-title">*/}
                  {/*    <div>*/}
                  {/*      <div className="package-card-title-content">*/}
                  {/*        <div className="package-card-step">5</div>*/}
                  {/*        <h2>Confirm</h2>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*    <button className={`btn btn-green`}*/}
                  {/*            disabled={!(!this.state.loading && !!this.state.selectedQuoteId && !!this.state.shippingOptions.length && this.state.totalDeclarationValue > 0 && (this.state.package.disposition===0 && !this.state.package.locked))}*/}
                  {/*            onClick={this.proceedToCheckout}><Fragment>Checkout</Fragment>*/}
                  {/*    </button>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </>
              }

              {this.state.page === 31 &&
                <div className="ship-card ship-card-address">
                  <div className="notice notice-info">
                    <div className="notice-content">If you have a vendor-provided returns label for returning a package to a vendor:
                      <br/>1. Enter the label address below
                      <br/>2. Complete the checkout process
                      <br/>3. Email our customer service team the returns shipping label
                      <br/>
                      <br/>The address or vendor on the shipping label must match the original sender.
                    </div>
                  </div>
                  <div className="address-form card card-white">
                    <div className="address-form-title"><h3>Return label Address</h3></div>
                    <div className="address-form-group">
                      <div className="address-form-label">Vendor / Return Addressee</div>
                      <input className="address-form-input" value={this.state.addressName} onChange={(e) => {this.setState({addressName: e.target.value})}}/>
                    </div>
                    <div className="address-form-group">
                      <div className="address-form-label">Return Address</div>
                      <input
                        className="address-form-input"
                        placeholder="Street address"
                        value={this.props.value.street1}
                        onChange={(e) => {this.props.handleInput(e); this.setState({ addressStreet1: e.target.value })}}
                        maxLength={addressCharLimits.streets}
                        onBlur={() => this.props.setShowSuggestions(false)}
                        onFocus={() => this.props.setShowSuggestions(true)}
                        disabled={!this.props.ready}
                      />
                      {this.props.renderedSuggestions}
                      <div className="address-form-label">Return Address 2</div>
                      <input className="address-form-input" placeholder="Unit, buzzcode, etc." value={this.state.addressStreet2} onChange={(e) => {this.setState({addressStreet2: e.target.value})}} maxLength={44}/>
                    </div>
                    <div className="address-form-group">
                      <div className="address-form-label">Return City</div>
                      <input className="address-form-input" placeholder="City" value={this.state.addressCity} onChange={(e) => {this.setState({addressCity: e.target.value})}} maxLength={40}/>
                    </div>
                    <div className="address-form-group">
                      <div className="address-form-label">Return Country</div>
                      <DropdownCountry className="address-form-select" value={this.state.addressCountryCode} onChange={(val) => {if (val !== this.state.addressCountryCode) this.setState({addressCountryCode: val, addressStateCode: ''})}}/>
                    </div>
                    <div className="address-form-group">
                      <DropdownRegion className="address-form-select" country={this.state.addressCountryCode} value={this.state.addressStateCode} onChange={(val) => this.setState({addressStateCode: val})} showLabel={true}/>
                    </div>
                    <InputPostalCode value={this.state.addressPostalCode} doUpdate={(v) => {this.setState({addressPostalCode: v.value, addressPostalCodeValid: v.valid})}} countryCode={this.state.addressCountryCode} showLabel={true}/>
                    <InputPhoneNumber value={this.state.addressPhone} doUpdate={(v) => {this.setState({addressPhone: v.value, addressPhoneValid: v.valid, addressPhoneFormatted: v.formatted})}} countryCode={this.state.addressCountryCode}/>
                    <div className="button-row">
                      <button className="btn btn-outline-primary" onClick={this.backToAddress}><i>
                        <svg className="feather">
                          <use href="/assets/images/icons/feather-sprite.svg#x"/>
                        </svg>
                      </i> Cancel
                      </button>
                      <div className="btn btn-primary" onClick={this.returnSubmit}><i>
                        <svg className="feather">
                          <use href="/assets/images/icons/feather-sprite.svg#check"/>
                        </svg>
                      </i> Next
                      </div>
                    </div>
                    {!!this.state.message && <div className="error-text">{this.state.message}</div>}
                  </div>
                </div>
              }


              {this.state.page === 4 &&
                (this.state.loading ?
                    <div className="ship-review-loading">
                      <LoadingSpinner icon={'local_shipping'}/>
                    </div>
                    :
                    <div className="ship-review">
                      <div className="ship-review-left">
                        <div className="srl-top">
                          <div className="srl-address">
                            <div className="srl-title">
                              {(this.state.checkoutQuote.system > 1 && this.state.checkoutQuote.system < 99) && 'Shipping address'}
                              {this.state.checkoutQuote.system === 1 && 'Delivery address'}
                              {this.state.checkoutQuote.system === 99 && 'Pickup address'}
                            </div>
                            {this.state.checkoutQuote.system !== 99 &&
                              <div className="srl-address-line">{this.state.checkoutAddress.name}</div>}
                            <div className="srl-address-line">{this.state.checkoutAddress.street1}</div>
                            {!!this.state.checkoutAddress.street2 &&
                              <div className="srl-address-line">{this.state.checkoutAddress.street2}</div>}
                            <div className="srl-address-line">{this.state.checkoutAddress.city}</div>
                            <div
                              className="srl-address-line">{regionText(this.state.checkoutAddress.countryCode, this.state.checkoutAddress.stateCode)} {this.state.checkoutAddress.postalCode}</div>
                            <div
                              className="srl-address-line">{countryText(this.state.checkoutAddress.countryCode)}</div>
                            <div className="srl-address-line">Phone: {this.state.checkoutAddress.phone}</div>
                          </div>
                          <div className="srl-package-method">
                            <div className="srl-package-method-title">Shipping Method</div>
                            <div className="srl-package-method-text">{this.state.checkoutQuote.serviceName}</div>
                            {(this.state.checkoutQuote.deliveryDaysMax || this.state.checkoutQuote.deliveryDays || this.state.checkoutQuote.deliveryDaysMin) &&
                              <div className="srl-package-method-text">Delivery estimate: {displayDate(addDays(new Date(), this.state.checkoutQuote.deliveryDays), 'd MMM')} - {displayDate(addDays(new Date(), (1 + this.state.checkoutQuote.deliveryDaysMax)), ' d MMM')}</div>}
                            {this.state.checkoutQuote.insured && <div className="srl-package-method-text">Insured value: {this.state.checkoutInvoice.declaredTotal ? 'USD ' + this.state.checkoutInvoice.declaredTotal.toFixed(2) : 'Contents not declared; insured for up to $100'}</div>}
                            {this.state.shippingNotes && <div className="srl-package-method-text">Shipping notes: {this.state.shippingNotes.split('\n').map((item) => {return (<Fragment><br/>{item}</Fragment>)})}</div>}
                          </div>
                        </div>
                        <div className="srl-package card card-white">
                          <div className="srl-package-warehouse">
                            <div className="packages-package-flag"><img src={`/assets/images/${warehouseData(this.state.checkoutPackage.warehouse).flag}`} alt="flag"/> {warehouseData(this.state.checkoutPackage.warehouse).name}</div>
                            {/* <div className="srl-package-warehouse-text">{warehouseData(this.state.checkoutPackage.warehouse).prefix}{this.props.store.customer.customerId.toString().padStart(5,'0')}</div> */}
                          </div>
                          <div className="srl-package-title">
                            <div className="srl-packge-icon packages-package-icon">{this.state.checkoutPackage.subPackages.length ?
                              <i className="fas fa-boxes"/> : <i className="fas fa-box"/>}</div>
                            <div className="srl-package-id">{this.state.checkoutPackage.subPackages.length ? 'Consolidation' : 'Package'} #{this.state.checkoutPackage.packageId.toString().padStart(6, '0')}</div>
                          </div>
                          {this.state.checkoutPackage.subPackages.length ?
                            <div className="srl-subpackages">
                              <div className="srl-subpackages-title">Packages in Consolidation</div>
                              {this.state.checkoutPackage.subPackages.map(sp => {

                                return (
                                  <div className="srl-subpackage" key={sp.packageId}>
                                    <i className="fas fa-box color-grey"/>&nbsp; Package {sp.packageId} from {sp.vendor}, received on {displayDate(convertToClientTimestamp(sp.received), 'd MMM yyyy')} via {sp.carrier}
                                  </div>
                                )
                              })}
                            </div>
                            :
                            <div className="srl-package-info">From {this.state.checkoutPackage.vendor}, received on {displayDate(convertToClientTimestamp(this.state.checkoutPackage.received), 'd MMM yyyy')} via {this.state.checkoutPackage.carrier}</div>
                          }
                        </div>
                      </div>
                      <div className="ship-review-right">
                        <div className="card card-white">
                          <div className="srr-invoice">
                            <div className="srr-invoice-title">Invoice Summary</div>
                            <div className="srr-invoice-grid">
                              <div className="srr-invoice-line">
                                <div className="srr-invoice-line-label">Receiving fees{(this.state.checkoutPackage.subPackages && this.state.checkoutPackage.subPackages.length) ? ' (' + this.state.checkoutPackage.subPackages.length + ' packages)' : ''}</div>
                                <div className="srr-invoice-line-value">{this.state.checkoutInvoice.services.receivingTotal ? '$ ' + this.state.checkoutInvoice.services.receivingTotal.toFixed(2) : 'Free'}</div>
                              </div>
                              {!!this.state.checkoutInvoice.services.repackTotal &&
                                <div className="srr-invoice-line">
                                  <div className="srr-invoice-line-label">Consolidation/Repack Fees</div>
                                  <div className="srr-invoice-line-value">$ {this.state.checkoutInvoice.services.repackTotal.toFixed(2)}</div>
                                </div>
                              }
                              {this.state.checkoutInvoice.services.requestLineItems.map(line => {
                                return (
                                  <div className="srr-invoice-line" key={line.lineItemId}>
                                    <div className="srr-invoice-line-label">{line.itemName} {!!line.packageId &&
                                      <Fragment>(#{line.packageId.toString().padStart(6, '0')})</Fragment>}</div>
                                    <div className="srr-invoice-line-value">{(!line.waived && line.amount) ? '$ ' + line.amount.toFixed(2) : 'Free'}</div>
                                  </div>
                                )
                              })}
                              {this.state.checkoutInvoice.services.storageLineItems.filter(line => !line.waived && line.amount > 0).map(line => {
                                return (
                                  <div className="srr-invoice-line" key={line.lineItemId}>
                                    <div className="srr-invoice-line-label">Storage Fee {!!line.packageId &&
                                      <Fragment>(#{line.packageId.toString().padStart(6, '0')})</Fragment>}</div>
                                    <div className="srr-invoice-line-value">$ {line.amount.toFixed(2)}</div>
                                  </div>
                                )
                              })}
                              {this.state.checkoutInvoice.services.shippingLineItems.map(line => {
                                return (
                                  <div className="srr-invoice-line" key={line.lineItemId}>
                                    {getShipLineLabel(line.itemName)}
                                    <div className="srr-invoice-line-value">{(!line.waived && line.amount) ? '$ ' + line.amount.toFixed(2) : 'Free'}</div>
                                  </div>
                                )
                              })}
                              {this.state.checkoutInvoice.services.setupLineItems.map(line => {
                                return (
                                  <div className="srr-invoice-line" key={line.lineItemId}>
                                    <div className="srr-invoice-line-label">{line.itemName}</div>
                                    <div className="srr-invoice-line-value">$ {line.amount.toFixed(2)}</div>
                                  </div>
                                )
                              })}
                              {!!this.state.checkoutInvoice.coupon &&
                                <Fragment>
                                  <div className="srr-invoice-line-hr">&nbsp;</div>
                                  <div className="srr-invoice-line">
                                    <div className="srr-invoice-line-label">Subtotal</div>
                                    <div className="srr-invoice-line-value">$ {this.state.checkoutInvoice.subtotal.toFixed(2)}</div>
                                  </div>
                                  <div className="srr-invoice-line">
                                    <div className="srr-invoice-line-label">{this.state.checkoutInvoice.couponLineItem.itemName}</div>
                                    <div className="srr-invoice-line-value">$ -{this.state.checkoutInvoice.coupon.toFixed(2)}</div>
                                  </div>
                                </Fragment>
                              }
                              {!!this.state.checkoutInvoice.taxesTotal &&
                                <Fragment>
                                  <div className="srr-invoice-line-hr">&nbsp;</div>
                                  <div className="srr-invoice-line">
                                    <div className="srr-invoice-line-label">Total before tax</div>
                                    <div className="srr-invoice-line-value">$ {this.state.checkoutInvoice.subtotalWithCoupon.toFixed(2)}</div>
                                  </div>
                                  {this.state.checkoutInvoice.taxes.map(tax => {
                                    return (
                                      <div className="srr-invoice-line" key={tax.lineItemId}>
                                        <div className="srr-invoice-line-label">{tax.itemName}</div>
                                        <div className="srr-invoice-line-value">$ {tax.amount.toFixed(2)}</div>
                                      </div>
                                    )
                                  })}
                                </Fragment>
                              }
                              {
                                this.state.checkoutInvoice.ddpLineItems.map(item => {
                                  return (
                                    <div className="srr-invoice-line" key={item.lineItemId}>
                                      <div className="srr-invoice-line-label">{item.itemName}</div>
                                      <div className="srr-invoice-line-value">$ {item.amount.toFixed(2)}</div>
                                    </div>
                                  )
                                })
                              }
                              {!!this.state.checkoutInvoice.credits.length &&
                                <Fragment>
                                  <div className="srr-invoice-line-hr">&nbsp;</div>
                                  <div className="srr-invoice-line">
                                    <div className="srr-invoice-line-label">Total</div>
                                    <div
                                      className="srr-invoice-line-value">$ {this.state.checkoutInvoice.totalInvoice.toFixed(2)}</div>
                                  </div>
                                  {this.state.checkoutInvoice.credits.map((credit, idx) => {
                                    return (
                                      <div className="srr-invoice-line" key={'credit-' + idx}>
                                        <div className="srr-invoice-line-label">{credit.itemName}</div>
                                        <div className="srr-invoice-line-value">$ -{credit.amount.toFixed(2)}</div>
                                      </div>
                                    )
                                  })}
                                </Fragment>
                              }
                            </div>

                          </div>
                          {this.state.creditBalance >= 0 &&
                            <Fragment>
                              <div className="srr-invoice-line-hr">&nbsp;</div>
                              <div className="srr-coupon">
                                <div className="srr-coupon-label">Coupon/Promo Code</div>
                                <div className="srr-coupon-input">
                                  <input className="srr-coupon-input-actual form-control" type="text" value={this.state.couponCode} onChange={(e) => this.setState({couponCode: e.target.value, couponMessage: ''})}/>
                                  {!!this.state.couponLoading ?
                                    <div className="btn btn-sm btn-primary srr-coupon-button"><ButtonLoading/></div> :
                                    <div className="btn btn-primary btn-sm srr-coupon-button" onClick={this.doTryCoupon}>Apply</div>}
                                </div>
                                {!!this.state.couponMessage &&
                                  <div className="srr-coupon-message">{this.state.couponMessage}</div>}
                              </div>
                            </Fragment>
                          }
                          {!!this.state.creditBalance &&
                            <Fragment>
                              <div className="srr-invoice-line-hr">&nbsp;</div>
                              <div className="srl-credit">
                                <div className="srl-credit-label">Use Reship Credits</div>
                                <div className="srl-credit-action">
                                  <input className="srl-credit-input form-control" disabled={this.state.creditBalance < 0} type="text" value={this.state.creditApplyInput} onChange={(e) => this.setState({creditApplyInput: e.target.value, creditMessage: ''})}/>
                                  {this.state.creditBalance >= 0 && (
                                    this.state.creditLoading ?
                                      <div className="btn btn-primary btn-sm srl-credit-button"><ButtonLoading/></div>
                                      :
                                      <div className="btn btn-primary btn-sm srl-credit-button" onClick={this.doTryCredit}>{this.state.creditApplyPending ? 'Update' : 'Apply'}</div>
                                  )
                                  }
                                </div>
                                {!!this.state.creditMessage &&
                                  <div className="srl-credit-message">{this.state.creditMessage}</div>}
                                {!!this.state.creditApplyPending ?
                                  <div className="srl-credit-info">Using ${this.state.creditApplyPending.toFixed(2)}, remaining balance: ${this.state.creditApplyPendingBalance.toFixed(2)}</div>
                                  :
                                  <div className="srl-credit-info">Current balance: ${this.state.creditBalance.toFixed(2)}</div>
                                }
                              </div>
                            </Fragment>
                          }
                          <div className="srr-invoice-line-hr">&nbsp;</div>
                          <div className="srr-invoice-line srr-invoice-total">
                            <div className="srr-invoice-line-label font-weight-600">{this.state.checkoutInvoice.totalInvoice === this.state.checkoutInvoice.balance ? 'Total' : 'Balance'}</div>
                            <div className="srr-invoice-line-value font-weight-600">$ {this.state.checkoutInvoice.balance.toFixed(2)}</div>
                          </div>
                          <div className="srr-currency">USD</div>
                          <div className="srr-invoice-line-hr">&nbsp;</div>
                          {this.state.creditBalance < 0 ?
                            <div className="error-text small">Sorry, your account has a hold on it due to an outstanding
                              negative balance.<br/><br/>Please contact <a href="mailto:accounts@reship.com" target="blank">accounts@reship.com</a> to resolve this issue, thank you.</div>
                            :
                            (!this.state.paymentScreen &&
                              <div className="srr-buttons">
                                {this.state.checkoutInvoice.balance === 0 ?
                                  <button className="btn btn-primary btn-green srr-button" onClick={this.doSubmitNoPayment}>Ship Package</button>
                                  :
                                  <Fragment>
                                    {!!this.state.braintreeToken &&
                                      <div className="payment-button btn btn-primary" onClick={() => {this.startBraintreeScreen()}}>
                                        <div className="payment-button-icon">
                                          <img src="/assets/images/payment-ico-pp.svg" className="payment-ico" alt="Paypal accepted" height="32" width="20"/>
                                          <img src="/assets/images/payment-ico-visa.svg" className="payment-card-ico" alt="Visa accepted" height="32" width="20"/>
                                          <img src="/assets/images/payment-ico-mastercard.svg" className="payment-card-ico" alt="Mastercard accepted" height="32" width="20"/>
                                          <img src="/assets/images/payment-ico-amex.svg" className="payment-card-ico" alt="American Express accepted" height="32" width="20"/>
                                        </div>
                                        <div className="payment-button-text">Checkout</div>
                                      </div>
                                    }
                                  </Fragment>
                                }
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                )
              }

              {!!this.state.paymentScreen &&
                <div className="global-overlay">
                  <div className="global-overlay-tapcapture global-overlay-tapcapture-grey">&nbsp;</div>
                  {this.state.paymentLoading ?
                    <div className="global-overlay-modal">
                      {this.state.paymentMessage ?
                        <div className="modal-loading">
                          <div className="loading-error">
                            <div className="loading-error-icon"><i className="material-icons">error_outline</i></div>
                          </div>
                        </div>
                        :
                        <div className="modal-loading">
                          <LoadingSpinner icon={'lock_outline'}/>
                        </div>
                      }
                      {!!this.state.paymentMessage &&
                        <div className="payment-message">{this.state.paymentMessage}</div>}
                      {(!!this.state.paymentMessage && this.state.paymentScreen === 30) &&
                        <div className="modal-address-button-row">
                          <div className="btn btn-primary" onClick={this.loadBraintreeDropin}>Return</div>
                        </div>
                      }
                      <button className="btn btn-sm btn-outline-primary btn-block" onClick={this.paymentClose}>Cancel</button>
                    </div>
                    :
                    <Fragment>
                      {this.state.paymentScreen === 1 &&
                        <div className="global-overlay-modal modal-address">
                          <div className="payment-cancel" onClick={this.paymentClose}><i className="material-icons">close</i></div>
                          <div className="modal-address-form-title">Enter Billing Address</div>
                          <div className="modal-address-form">
                            <input className="modal-address-form-input form-control" placeholder="Street address" value={this.state.billingAddressLine1} onChange={(e) => {this.setState({billingAddressLine1: e.target.value, billingAddressId: 0})}} maxLength={addressCharLimits.streets}/>
                            <input className="modal-address-form-input form-control" value={this.state.billingAddressLine2} onChange={(e) => {this.setState({billingAddressLine2: e.target.value, billingAddressId: 0})}} maxLength={addressCharLimits.streets}/>
                            <input className="modal-address-form-input form-control" placeholder="City" value={this.state.billingAddressCity} onChange={(e) => {this.setState({billingAddressCity: e.target.value, billingAddressId: 0})}} maxLength={40}/>
                            <input className="modal-address-form-input form-control" placeholder="Postal/zip code" value={this.state.billingAddressPostalCode} onChange={(e) => {this.setState({billingAddressPostalCode: e.target.value, billingAddressId: 0})}} maxLength={15}/>
                            <div className="modal-address-form-label">Country</div>
                            <DropdownCountry className="address-form-select form-control" value={this.state.billingAddressCountryCode} onChange={(val) => {if (val !== this.state.billingAddressCountryCode) this.setState({billingAddressCountryCode: val, billingAddressStateCode: '', billingAddressId: 0})}}/>
                            <DropdownRegion className="address-form-select form-control" country={this.state.billingAddressCountryCode} value={this.state.billingAddressStateCode} onChange={(val) => this.setState({billingAddressStateCode: val, billingAddressId: 0})} showLabel={true}/>
                          </div>
                          <p className="txt-center mar-b-5">OR</p>
                          <div className="modal-address-select">
                            <div className="modal-address-form-title">Select from a saved address</div>
                            <select className="modal-address-select-actual form-control" value={this.state.billingAddressId} onChange={(event) => {this.selectBillingAddress(event.target.value)}}>
                              <option className="modal-address-select-option" value={0}>Select...</option>
                              {this.state.addresses.map(a => {
                                return (<option className="modal-address-select-option" value={a.addressId} key={a.addressId}>{a.street1}{a.street2 && ', ' + a.street2}, {a.city}, {a.stateCode}, {a.postalCode}, {a.countryCode}</option>)
                              })}
                            </select>
                          </div>
                          {this.state.paymentMessage &&
                            <div className="payment-message">{this.state.paymentMessage}</div>}
                          <div className="modal-address-button-row">
                            <div className="btn btn-primary" onClick={this.proceedFromBilling}>Next</div>
                          </div>
                        </div>
                      }
                      {this.state.paymentScreen === 20 &&
                        <div className="global-overlay-modal">
                          <div className="payment-content">
                            <div className="payment-cancel" onClick={this.paymentClose}><i className="material-icons">close</i></div>
                            <HppIframe rxpUrl={this.state.rxpUrl} onClose={this.paymentClose} onResult={this.handleRxpResponse}/>
                            {!!this.state.rxpMessage && <div className="payment-message">{this.state.rxpMessage}</div>}
                          </div>
                        </div>
                      }
                      {this.state.paymentScreen === 30 &&
                        <div className="global-overlay-modal modal-payment">
                          <div className="payment-content">
                            <BraintreeDropIn params={this.state.braintreeParams} handleSubmit={this.braintreeSubmit.bind(this)} handleError={this.braintreeError.bind(this)}/>
                            <button className="btn btn-sm btn-outline-primary btn-block" onClick={this.paymentClose}>Cancel</button>
                          </div>
                        </div>
                      }
                    </Fragment>
                  }
                </div>
              }

              {this.state.showReleaseAuthModal && (
                <div className="global-overlay">
                  <div className="global-overlay-tapcapture global-overlay-tapcapture-grey">&nbsp;</div>
                  <div className="global-overlay-modal modal-address">
                    <div className="payment-cancel" onClick={() => {this.setState({showReleaseAuthModal: false, hasProvidedReleaseAuthority: true})}}><i className="material-icons">close</i></div>
                    <div className="modal-address-form-title">Decline Release Authority</div>
                    <p className="txt-center mar-b-5">Reship is unable to process Delivered Duty Paid (DDP) shipments via our <span style={{fontStyle: 'italic'}}>
                        Reship Select True North</span> service without obtaining a Power of Attorney release authorization. </p>
                    <div className="modal-address-button-row">
                      <div className="btn btn-primary" onClick={this.proceedToDdpOption}>Next</div>
                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>

        </div>
        <GlobalFooter/>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withAddressAutocomplete(Checkout)))