import React, {useEffect, useState} from 'react'
import {customsTypes, RESHIP_CONVERTABLE_CURRENCIES, warehouseData} from "../common/constants";
import axios from "axios";
import {handleError, round} from "../common/utils";
import {message, PageHeader, Select, Spin} from "antd";
import {remove} from "lodash";
import {useSelector} from "react-redux";
import {it} from "date-fns/locale";

const MobileDeclarationForm = ({pkg, saveValue, openForm, closeAction, saveCustoms, pkgCustomsType}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [declaredItems, setDeclaredItems] = useState([])
  const [customsType, setCustomsType] = useState(pkgCustomsType ? pkgCustomsType : 1)
  const [forexRates, setForexRates] = useState([])
  const [actionItem, setActionItem] = useState(null)
  const [actionType, setActionType] = useState(null)

  const itemDeclarationRules = useSelector(state => state.itemDeclarationRules)

  const DEFAULT_NEW_ITEM = {
    itemId: 'new',
    declaredName: '',
    declaredDescription: '',
    declaredValue: '',
    declaredQuantity: '',
    declaredOrigin: warehouseData(pkg.warehouse).countryCode,
    declaredFromCurrency: warehouseData(pkg.warehouse).currency,
    declaredToCurrency: warehouseData(pkg.warehouse).currency,
    declaredFxRate: 1,
    declaredFxValue: 0,
  }

  const IS_DELETE_BUTTON_DISABLED = [8].includes(pkg.disposition)

  useEffect(() => {
  }, [actionItem, declaredItems])

  useEffect(() => {
    getCustomsDeclarationData()
  }, [])

  const getCustomsDeclarationData = async () => {
    try {
      setIsLoading(true)
      const items = await getItemsData()
      const forexRates = await getForexData()

      const sanitizedItems = await insertInitialForexRates(items, forexRates)

      if (!sanitizedItems.length) {
        sanitizedItems.push(DEFAULT_NEW_ITEM)
      }

      setDeclaredItems(sanitizedItems)
      setForexRates(forexRates)
      setIsLoading(false)

    } catch (e) {
      handleError(e)
      setIsLoading(false)
    }
  }

  const insertInitialForexRates = (items, forexRates) => {
    if (items && !items.length) {
      return []
    }

    if (forexRates && !forexRates.length) {
      return []
    }

    const declaredItemsClone = [...items]

    const sanitizedItems = declaredItemsClone.map(item => {

      const selectedForexRate = getForexRate(forexRates, item.declaredToCurrency, item.declaredFromCurrency)

      const rate = handleFXConversion(selectedForexRate)
      const fxValue = round((item.declaredValue * rate))
      return {
        ...item,
        declaredFromCurrency: item.declaredFromCurrency ? item.declaredFromCurrency : warehouseData(pkg.warehouse).currency,
        declaredToCurrency: warehouseData(pkg.warehouse).currency,
        declaredFxRate: rate,
        declaredFxValue: fxValue
      }
    })
    return sanitizedItems
  }

  const getItemsData = async () => {
    const response = await axios.get(`/package/items/${pkg.packageId}?disposition=${pkg.disposition}&type=${pkg.type}`)
    const items = response.data.data

    return items
  }

  const getForexData = async () => {
    const response = await axios.get(`/forex/rates/${warehouseData(pkg.warehouse).currency}`)
    const forexRates = response.data.data

    return forexRates
  }

  const getForexRate = (forexRates, toCurrency, fromCurrency) => {
    const selectedForexRate = forexRates.find(item => {
      const fxFromCurrency = fromCurrency ? fromCurrency : warehouseData(pkg.warehouse).currency
      const fxToCurrency = toCurrency ? toCurrency : warehouseData(pkg.warehouse).currency

      return item.fromCurrency === fxToCurrency && item.toCurrency === fxFromCurrency
    })
    return selectedForexRate
  }

  const handleFXConversion = (selectedForexRate) => {
    const rate = 1 / selectedForexRate.rate * 1
    return round(rate)
  }

  const updateCustomsType = async (value) => {

    if (pkg.locked === 1 || pkg.locked) {
      return
    }

    try {
      setCustomsType(value)
      await axios.post(`/package/customsType/${pkg.packageId}`, {outgoingCustomsType: value})
      saveCustoms(value)

    } catch (e) {
      handleError(e)
    }
  }

  const CONVERTED_TOTAL = round(declaredItems.reduce((acc, val) => acc += val.declaredFxValue * 1, 0))

  const onAddItem = () => {
    setPage(2)
    setActionItem(DEFAULT_NEW_ITEM)
    setActionType('Add')
  }

  const onEditItem = (item) => {
    setPage(2)
    setActionItem(item)
    setActionType('Edit')
  }

  const handleStringInputChange = (e) => {
    try {
      const declaredItemClone = actionItem
      const name = e.target.name
      const value = e.target.value.replace(/[^A-Za-z0-9 _]/, '').slice(0, 45)
      declaredItemClone[name] = value
      setActionItem(declaredItemClone)

    } catch (e) {
      console.log(e)
    }
  }

  const handleNumberInputChange = (e) => {

    try {
      const declaredItemClone = actionItem
      const name = e.target.name
      let value = e.target.value * 1

      if (isNaN(value)) {
        value = 0
      }

      declaredItemClone[name] = value
      const fxValue = round(declaredItemClone.declaredValue * declaredItemClone.declaredFxRate)
      declaredItemClone.declaredFxValue = fxValue

      setActionItem(declaredItemClone)

    } catch (e) {
      console.log(e)
    }
  }

  const handleDropdownInput = (e) => {
    try {
      const declaredItemClone = actionItem
      declaredItemClone.declaredFromCurrency = e

      const toCurrency = declaredItemClone.declaredToCurrency
      const fromCurrency = declaredItemClone.declaredFromCurrency

      const selectedForexRate = getForexRate(forexRates, toCurrency, fromCurrency)
      const rate = handleFXConversion(selectedForexRate)
      const fxValue = round((declaredItemClone.declaredValue * rate))
      declaredItemClone.declaredFxRate = rate
      declaredItemClone.declaredFxValue = fxValue
      setActionItem(declaredItemClone)

    } catch (e) {
      console.log(e)
    }
  }

  const addDeclaredItem = async () => {
    try {
      setIsLoading(true)
      const addedItem = actionItem

      delete addedItem.itemId

      const requestObject = {
        packageId: pkg.packageId,
        item: addedItem,
        customsType
      }

      await axios.post('/package/item/add', requestObject)
      await getCustomsDeclarationData()
      setIsLoading(false)
      setPage(1)

    } catch (e) {
      handleError(e)
      setIsLoading(false)
    }
  }

  const updateDeclaredItem = async () => {
    try {
      if (!!actionItem) {
        const updateItemIndex = declaredItems.findIndex(item => item.itemId === actionItem.itemId)
        declaredItems[updateItemIndex] = actionItem
        setDeclaredItems(declaredItems)
      }

      const requestObject = {
        packageId: pkg.packageId,
        items: declaredItems,
        customsType
      }
      
      await axios.put('/package/item/update', requestObject)
      setIsLoading(false)
      await getCustomsDeclarationData()
      setPage(1)

    } catch (e) {
      handleError(e)
      setIsLoading(false)
    }
  }

  const saveDeclaredItem = () => {
    try {
      setIsLoading(true)

      if (!declaredItems.length) {
        setIsLoading(false)
        return;
      }

      if (!customsType) {
        message.error('Please declare and save the type of items in your package.')
        return;
      }

      updateDeclaredItem()

      saveValue(CONVERTED_TOTAL)
      setIsLoading(false)
      closeAction()

    } catch (e) {
      console.log(e)
    }
  }

  const goBackAction = () => {
    saveValue(CONVERTED_TOTAL)
    closeAction()
  }

  const goPrevPage = async () => {
    const items = await getItemsData()
    setDeclaredItems(items)
    setPage(1)
  }

  const deleteDeclaredItem = async () => {
    try {
      if (IS_DELETE_BUTTON_DISABLED) {
        return;
      }

      if (declaredItems.length === 0) {
        return;
      }

      if (pkg.locked === 1 || pkg.locked) {
        return
      }

      setIsLoading(true)

      const [removedItem] = remove(declaredItems, (item) => {
        return item.itemId === actionItem.itemId
      })

      const requestObject = {
        packageId: pkg.packageId,
        itemId: removedItem.itemId
      }

      await axios.post('/package/item/delete', requestObject)
      getCustomsDeclarationData()
      setIsLoading(false)
      setPage(1)

    } catch (e) {
      handleError(e)
      setIsLoading(false)
    }
  }

  return (
    <>
      {openForm &&
        <div className="global-overlay">
          <div className="global-overlay-tapcapture global-overlay-tapcapture-grey"
               onClick={closeAction}>&nbsp;</div>
          {page === 1 &&
            <div className="declaration-item-list global-overlay-modal">
              <div className="declaration-item-list-top">
                <div className="declaration-item-page-header" onClick={goBackAction}>
                  <div class="btn btn-clear">
                    <i><svg class="feather"><use href="/assets/images/icons/feather-sprite.svg#arrow-left"/></svg></i> 
                    Go back
                  </div>
                </div>
                {/*<PageHeader title="Go back" onBack={closeAction} style={{paddingLeft: 0}}/>*/}
                <div className="declaration-item-list-title">
                  <h2>Contents</h2>
                  {itemDeclarationRules.enableAddItem && <div className="btn-add" style={{color: '#3582c4'}} onClick={onAddItem}>
                    <i><svg class="feather"><use href="/assets/images/icons/feather-sprite.svg#plus-circle"/></svg></i> Add Item
                  </div>}
                </div>
                <div className="pad-b-10">Please complete the declaration information for customs purpose, ensuring the accuracy before checkout.</div>
                <Spin spinning={isLoading}>
                  <div className="declaration-item-list-content">
                    {declaredItems.length > 0 && declaredItems[declaredItems.length-1].itemId !== 'new' && declaredItems.map((item, index) => {
                      return <div key={index} className="declaration-item-card" onClick={() => {onEditItem(item)}}>
                        <div className="declaration-item-card-left">{item.declaredName}</div>
                        <div className="declaration-item-card-right">
                          <div className="declaration-item-card-price">${item.declaredFxValue}</div>
                          <svg class="feather feather-light"><use href="/assets/images/icons/feather-sprite.svg#chevron-right"/></svg>
                        </div>
                      </div>
                    })}
                  </div>
                </Spin>
              </div>
              <div className="declaration-item-list-bottom">
                <div className="declaration-item-customs">
                  <h4>Shipment Type</h4>
                  <div className="btn-toggle declaration-item-customs-type">
                    {customsTypes.map(type => {
                      return (
                        <div
                          className={"btn declaration-item-type-option" + (customsType === type.value ? ' selectedType' : '')}
                          key={'customs-' + type.value} onClick={() => updateCustomsType(type.value)}>
                          {type.label}
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="declaration-item-value">
                  <div>Total Value</div>
                  <div className="declaration-item-value-total-price">${CONVERTED_TOTAL} {warehouseData(pkg.warehouse).currency}</div>
                </div>
                <div className="btn btn-fill" onClick={() => saveDeclaredItem(declaredItems)}>I've confirmed the information, Save</div>
              </div>
            </div>
          }

          {page === 2 &&
            <div className="declaration-item-list global-overlay-modal">
              <div className="declaration-item-upper">
                <div className="declaration-item-page-header" onClick={goPrevPage}>
                  <div class="btn btn-clear">
                    <i><svg class="feather"><use href="/assets/images/icons/feather-sprite.svg#arrow-left"/></svg></i> 
                    Go back
                  </div>
                </div>
                <div className="declaration-item-list-title">
                  {actionType === 'Edit' ? <h2>Edit Item</h2> : <h2>Add Item</h2>}
                </div>
                <Spin spinning={isLoading}>
                  <div className="declaration-item-edit-content">
                    {/* <div className="address-form-label">Item Name</div>
                    <input className="address-form-input" type="text" disabled={pkg.locked === 1 || pkg.locked || !itemDeclarationRules?.enableEditName}
                           defaultValue={actionItem.declaredName} name='declaredName'
                           onChange={handleStringInputChange}/> */}
                    <div className="address-form-label">Item Description</div>
                    <input className="address-form-input" type="text" disabled={pkg.locked === 1 || pkg.locked || !itemDeclarationRules?.enableEditDescription}
                           defaultValue={actionItem.declaredDescription} name='declaredDescription'
                           onChange={handleStringInputChange}/>
                    <div className="declaration-item-row ">
                      <div className="pad-r-10 flexItem">
                        <div className="address-form-label">Qty</div>
                        <input className="address-form-input" type="text"
                               disabled={pkg.locked === 1 || pkg.locked || !itemDeclarationRules?.enableEditQty} defaultValue={actionItem.declaredQuantity}
                               name='declaredQuantity' onChange={handleNumberInputChange}/>
                      </div>
                      <div className="pad-l-10 flexItem">
                        <div className="address-form-label">Declared Value</div>
                        <input className="address-form-input" type="text"
                               disabled={pkg.locked === 1 || pkg.locked || !itemDeclarationRules?.enableEditValue} defaultValue={actionItem.declaredValue}
                               name='declaredValue' onChange={handleNumberInputChange}/>
                      </div>
                    </div>
                    <div className="address-form-label">Currency</div>
                    <Select className="address-form-select"
                      defaultValue={actionItem.declaredFromCurrency ? actionItem.declaredFromCurrency : warehouseData(pkg.warehouse).currency}
                      onChange={handleDropdownInput}
                      name='declaredFromCurrency'
                      disabled={pkg.locked === 1 || pkg.locked || !itemDeclarationRules?.enableEditCurrency}
                      options=
                        {RESHIP_CONVERTABLE_CURRENCIES.map((item, i) => ({value: item, label: item}))}
                    />
                  </div>
                </Spin>
              </div>
              <div className="declaration-item-edit-btn">
                <div className="btn btn-fill"
                     onClick={actionType === 'Add' ? addDeclaredItem : updateDeclaredItem}>Save Item
                </div>
                {actionType === 'Edit' && itemDeclarationRules?.enableDeleteItem &&
                  <div className="btn btn-clear mt-3" onClick={deleteDeclaredItem}>
                    <i><svg class="feather"><use href="/assets/images/icons/feather-sprite.svg#trash-2"/></svg></i> 
                    Delete Item
                  </div>}
              </div>
            </div>}

        </div>}


    </>
  )
}

export default MobileDeclarationForm