import React, {useEffect, useState} from "react";
import {Input, Select} from "antd";
import {addressCharLimits, filterPhone} from "../common/handleAddress";
import {countriesInfo} from "../common/countriesInfo";
import {parsePhoneNumberFromString} from "libphonenumber-js/max";

export const InputPhoneNumber = ({value, doUpdate, countryCode, removeLabel}) => {
  const [inputValue, setInputValue] = useState(value ? parsePhoneNumberFromString(value, countryCode)?.nationalNumber : value)
  const [dialCode, setDialCode] = useState('')
  const [inputValueIsValid, setInputValueIsValid] = useState(false)

  useEffect(() => {
    processNumber(value, countryCode)
  }, [])

  useEffect(() => {
    if (countryCode) {
      getDialCode(countryCode)
    }
  }, [countryCode])

  const getDialCode = (countryCode) => {
    const countryInfo = countriesInfo.find(c => c.code === countryCode)
    setDialCode(`${countryCode} ${countryInfo.dial_code}`)
  }

  const processNumber = (inputRaw, countryCode) => {

    let input = filterPhone(inputRaw)
    let pn = parsePhoneNumberFromString(input, countryCode)
    setInputValue(pn?.nationalNumber ? pn.nationalNumber : input)

    // No countryCode check... to allow anyone to any country while providing a third-party number.
    // However, if any carrier validates phone number destination format, we will need to implement this check here and in the validatePhone function.
    // This module will always append the +xxx countrycode if not included. However, legacy numbers without the +xxx segment but are still considered valid will have to be internationalized in the backend.

    if(pn && pn.isValid() && pn.number) {
      setInputValueIsValid(true)

      doUpdate({
        value: pn.number,
        valid: true,
        formatted: pn.format('E.164')
      })
    }
    else {
      setInputValueIsValid(false)
      doUpdate({
        value: pn?.number,
        valid: false,
        formatted: ''
      })
    }
  }

  const inputOnChange = (e) => {
    processNumber(e.target.value, countryCode)
  }

  const selectBefore = (
    <Select value={dialCode} style={{width: 120}}>
      {countriesInfo.map(country => {
        return (
          <Select.Option>{country.code} {country.dial_code}</Select.Option>
        )
      })}
    </Select>
  )

  return (
    <div className="address-form-group">
      {!removeLabel && <div className="address-form-label">Phone Number</div>}
      <Input
        className="address-input-phonenumber"
        addonBefore={selectBefore}
        name="phone"
        defaultValue={inputValue}
        onChange={inputOnChange}
        maxLength={addressCharLimits.phone}/>
      {(!inputValueIsValid && !!inputValue) && <div className="address-form-validation">Please enter a valid phone number starting with country & area code</div>}
    </div>
  )


}