import React, { useEffect } from 'react';
import { notification } from 'antd';

const openNotification = (title, message, onClick) => {
  notification.open({
    message: title,
    description: message,
    onClick,
  });
};

function Notification({ ...props }) {
  const { search } = props.location;
  const notification = new URLSearchParams(search).get('notification');
  const type = new URLSearchParams(search).get('type');
  const status = new URLSearchParams(search).get('status');

  const getNotificationInfo = (type, status) => getTitleAndMessage(type, status);

  const getTitleAndMessage = (type, status) => {
    if (type === 'pre_credit_purchase') {
      return handlePreCreditPurchase(status);
    }

    if (type === 'post_credit_purchase') {
      return handlePostCreditPurchase(status);
    }

    if (type === 'general') {
      return handleGeneral(status);
    }
  };

  const handlePreCreditPurchase = (status) => {
    if (status === 'success') {
      const title = 'Credit Purchase Email Sent.';
      const message = 'An email has been sent to complete your credit purchase.';
      return { title, message };
    }

    if (status === 'fail') {
      const title = 'Credit Purchase Request Failed.';
      const message = 'Failed to process your credit purchase request. Please resubmit a new purchase request.';
      return { title, message };
    }

    if (status === 'cancel') {
      const title = 'Canceled Credit Purchase Request.';
      const message = 'Please resubmit a credit purchase request to add credits to your account.';
      return { title, message };
    }
  };

  const handlePostCreditPurchase = (status) => {
    if (status === 'success') {
      const title = 'Credit Purchase Successful.';
      const message = 'Your credits are now active and can be used immeditately.';
      return { title, message };
    }

    if (status === 'fail') {
      const title = 'Credit Purchase Failed.';
      const message = 'Failed to process your credit purchase request. Please resubmit a new purchase request.';
      return { title, message };
    }

    if (status === 'cancel') {
      const title = 'Canceled Credit Purchase.';
      const message = 'Please resubmit a credit purchase request to add credits to your account.';
      return { title, message };
    }
  };

  const handleGeneral = (status) => {
    if (status === 'success') {
      const title = 'Request Successful.';
      const message = 'Your request has been successful.';
      return { title, message };
    }

    if (status === 'fail') {
      const title = 'Request Failed.';
      const message = 'Your request has failed. Please try again later.';
      return { title, message };
    }

    if (status === 'cancel') {
      const title = 'Request Cancelled.';
      const message = 'Your request has been cancelled.';
      return { title, message };
    }
  };

  useEffect(() => {
    if (notification === 'true') {
      const { title, message } = getNotificationInfo(type, status);
      openNotification(title, message, props.onClick);
    }
  }, [notification, type, status]);

  return null;
}

export default Notification;
