import React, { useState } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";

function RequestPhotoModal({ packageInfo, fees }) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [photoNotes, setPhotoNotes] = useState(null);
  const [photoModal, setPhotoModal] = useState(1);
  const [message, setMessage] = useState(null);

  const submitPhotoRequest = async () => {
    try {
      setLoading(true);
      const postData = {
        packageId: packageInfo.packageId,
        photoNotes,
      };

      const response = await axios.post('/customer/packages/reqPhotos', postData);
      if (!response.data.success) {
        setLoading(false);
        setMessage('Request failed.');
      } else {
        setPhotoModal(2);
        setLoading(false);
        setMessage('');
      }
    } catch (e) {
      setLoading(false);
      e.response ? setMessage(e.response.message) : setMessage(e.message);
    }
  };

  return (
    <>
      {!!packageInfo.reqPhotographs
        && (
        <div className="photos-requested-mobile">
          <i><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#camera" /></svg></i>
          {' '}
          {packageInfo.reqPhotographsComplete ? 'Photo Request Complete' : 'Photographs Requested'}
        </div>
        )}
      {(!packageInfo.reqPhotographs && packageInfo.disposition === 0 && !packageInfo.locked)
        && (
        <div className="btn" onClick={() => setShowModal(true)}>
          <i><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#camera" /></svg></i>
          {' '}
          Request Additional Photographs
          {' '}
        </div>
        )}

      {showModal
        && (
        <div className="global-overlay">
          <div className="global-overlay-tapcapture global-overlay-tapcapture-grey" onClick={() => setShowModal(false)}>&nbsp;</div>
          {photoModal === 1
            && (
            <div className="global-overlay-modal package-request-form">
              <div className="flex justify-content-between">
                <div className="package-request-title">Need more photographs?</div>
                <i className="icon" onClick={() => setShowModal(false)}><svg className="feather feather-black"><use href="/assets/images/icons/feather-sprite.svg#x" /></svg></i>
              </div>
              <p className="text-small">
                Reship is happy to offer package photography services for a nominal fee of $
                {fees.photo.toFixed(2)}
                {' '}
                / 3 photos.
              </p>
              <textarea placeholder="Comments, requests, etc." className="form-control wide" id="package-request-input" value={photoNotes} onChange={(e) => { setPhotoNotes(e.target.value); }} />
              {message && <div className="error-text">{message}</div>}
              <button className="btn w-100" onClick={submitPhotoRequest} disabled={loading}>Submit Request</button>
            </div>
            )}
          {photoModal === 2
            && (
            <div className="global-overlay-modal package-request-form">
              <div className="package-request-title">Request received!</div>
              <div className="package-request-text"><p>We'll get right on it and email you when it's done!</p></div>
              <button className="btn btn-primary" onClick={() => setShowModal(false)}>
                <Link to={'/packages'} style={{ textDecoration: 'none', color: 'white'}}>
                  <i className="material-icons">done</i>
                  {' '}
                  Done
                </Link>
              </button>
            </div>
            )}
        </div>
        )}
    </>
  );
}

export default RequestPhotoModal;
