const isDecimal = (value, options = { decimal_digits: '0,2' }) => {
  const decimalDigits = options.decimal_digits.split(',');
  const [minDigits, maxDigits] = decimalDigits.map(Number);

  // Create a regular expression for the decimal validation
  const regex = new RegExp(`^\\d+(\\.\\d{${minDigits},${maxDigits}})?$`);
  
  // Convert the value to a string and test it against the regular expression
  return regex.test(String(value))
}

export {
  isDecimal
}