import React, {Component, Fragment}   from 'react'
import {withRouter}                   from 'react-router-dom'
import {connect}                      from 'react-redux'
import {CjConversionCode, debug} from '../common/constants'
import GlobalFooter                   from '../components/GlobalFooter'
import GlobalError                    from '../components/GlobalError'
import GlobalNotices                  from '../components/GlobalNotices'
import CustomerSidebar                from '../components/CustomerSidebar'
import CustomerLoading                from '../components/CustomerLoading'
import CustomerAddresses              from '../components/CustomerAddresses'
import TagManager                     from 'react-gtm-module'
import axios from "axios";
import {handleError} from "../common/utils";

const mapStateToProps = (globalStore) => {return {store: globalStore}}
const mapDispatchToProps = (dispatch) => {return {customerRegistered: ()=>{dispatch({type: 'CUSTOMER_REGISTERED'})}}}

class Outcomes extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      initialLoading: 0,
      initialLoadingError: false,
      
      outcome: '',
      plan: '',
    }
  }
  
  componentDidMount() {

    try {
      if(window.location.pathname === '/done/registered' && (!Object.keys(this.props.store?.transactionRecord).length > 0 || typeof this.props.store?.transactionRecord === 'undefined' )) {
        this.handleCjRegistration()
      }
    } catch (e) {
      handleError(e)
    }

    if(window.location.pathname === '/done/registered') {
      this.handleRegistration()
    }
    
    if(this.props.store.isLoggedIn && this.state.initialLoading===0)
      this.getOutcome(this.props)
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.store.isLoggedIn===true && this.state.initialLoading===0)
      this.getOutcome(nextProps)
  }

  handleRegistration = () => {
    //Update redux store state 'isFromRegister' back to default 
    this.props.customerRegistered()

    const utmParams = {
      utm_id: localStorage.getItem('utm_id') || '',
      utm_source: localStorage.getItem('utm_source') || '',
      utm_medium: localStorage.getItem('utm_medium') || '',
      utm_campaign: localStorage.getItem('utm_campaign') || '',
    }

    //Push registration event to the dataLayer
    const tagManagerArgs = {
      dataLayer: {
        event: 'new_registration',
        pagePath: this.props.location.pathname,
        pageTitle: 'registration success',
        utm_id: utmParams.utm_id,
        utm_source: utmParams.utm_source,
        utm_medium: utmParams.utm_medium,
        utm_campaign: utmParams.utm_campaign
      },
      dataLayerName: 'dataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  handleCjRegistration = async () => {
    //Update redux store state 'isFromRegister' back to default
    this.props.customerRegistered()

    const response = await axios.get('/customer')
    const data = response.data.data

    //Push registration event to the dataLayer
    const tagManagerArgs = {
      dataLayer: {
        event: 'cj_registration',
        pagePath: this.props.location.pathname,
        pageTitle: 'registration success',
        pageType: 'conversionConfirmation',
        actionTrackerId: CjConversionCode.NEW_SIGNUP,
        cId: data.customerId,
        invoiceTotal: 0

      },
      dataLayerName: 'dataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  getOutcome = (props) => {
    this.setState({
      initialLoading: 1
    })
    
    if(props.match.params.type){
      let outcome = props.match.params.type
      this.setState({
        outcome: outcome,
        initialLoading: 2
      })
      
      if(outcome.includes('newSubscription')){
        this.setState({
          outcome: 'newSubscription',
        })
      }
    }
  }

  renderDynAdmicTrackingPixel = () => {
    return window.location.pathname === '/done/registered' ? <img src="https://t.eu1.dyntrk.com/pt.php?dynk=MTAyMTQ102148&dynt=31&dyncp=107172&dynf=img&gdpr=${1}&gdpr_consent=${GDPR_CONSENT_434}"/> : null
  }
  
  render() {
    if(this.state.initialLoading < 2)
      return (
        <div className="body body-customer" id="customer-home">
          <CustomerSidebar />
          <CustomerLoading />
        </div>
      )
    else if (this.state.initialLoadingError)
      return (
        <div className="body body-customer" id="my-apr">
          <CustomerSidebar />
          <GlobalError error={{message: this.state.message}} />
        </div>
      )

    return (
      <div className="body body-customer" id="my-aprs">
        <CustomerSidebar />
        <div className="content-area">
          <GlobalNotices { ...this.props }/>

          {this.state.outcome==='registered' &&
            <Fragment>
              {this.renderDynAdmicTrackingPixel()}
              <div className="outcome-registered blank-order text-center">
                <div className="bl-card-image">
                  <img src="/assets/images/virtual-image.svg" alt="" />
                  <div className="green-circle"><i className="material-icons">favorite</i></div>
                </div>
                <h5>Welcome to Reship!</h5>
                <p>Here are your new mailbox addresses!<br />When a package for you arrives, you'll get an email notification, and then we can hold it for pickup or ship it onward for you!</p>
                <button className="btn btn-primary align-self-ctr" onClick={()=>{this.props.history.push('/dashboard')}}>Go to Dashboard</button>
              </div>
              <br />
              <CustomerAddresses/>
            </Fragment>
          }
          {this.state.outcome==='newSubscription' &&
            <div className="blank-order text-center">
              <div className="bl-card-image">
                <img src="/assets/images/virtual-image.svg" alt="" />
                <div className="green-circle"><i className="material-icons">done</i></div>
              </div>
              <h5>Welcome to Reship {this.state.plan}!</h5>
              <p>Enjoy your new member-only rates, happy shopping!</p>
              <button className="btn btn-primary align-self-ctr" onClick={()=>{this.props.history.push('/my-plan')}}>Return to Membership</button>
            </div>
          }
          {this.state.outcome==='newSubscriptionProcessing' &&
            <div className="blank-order text-center">
              <div className="bl-card-image">
                <img src="/assets/images/virtual-image.svg" alt="" />
                <div className="green-circle"><i className="material-icons">done</i></div>
              </div>
              <h5>Welcome to Reship {this.state.plan}!</h5>
              <p>We're currently processing your new membership subscription and will email you when your new rates have taken effect!</p>
              <button className="btn btn-primary align-self-ctr" onClick={()=>{this.props.history.push('/my-plan')}}>Return to Membership</button>
            </div>
          }
          {this.state.outcome==='packageConsolidation' &&
            <div className="blank-order text-center">
              <div className="bl-card-image">
                <img src="/assets/images/virtual-image.svg" alt="" />
                <div className="green-circle"><i className="material-icons">done</i></div>
              </div>
              <h5>Consolidation request submitted!</h5>
              <p>An email will be sent when your packages have been consolidated</p>
              <button className="btn btn-primary align-self-ctr" onClick={()=>{this.props.history.push('/packages')}}>Return to Packages</button>
            </div>
          }
          {this.state.outcome==='packageShipping' &&
            <div className="blank-order text-center">
              <div className="bl-card-image">
                <img src="/assets/images/virtual-image.svg" alt="" />
                <div className="green-circle"><i className="material-icons">done</i></div>
              </div>
              <h5>Your package is on its way!</h5>
              <p>An email will be sent to you with a tracking number (if available) when your package has been received by the shipping company.</p>
              <button className="btn btn-primary align-self-ctr" onClick={()=>{this.props.history.push('/packages')}}>Return to Packages</button>
            </div>
          }
          {this.state.outcome==='packagePickup' &&
            <div className="blank-order text-center">
              <div className="bl-card-image">
                <img src="/assets/images/virtual-image.svg" alt="" />
                <div className="green-circle"><i className="material-icons">unarchive</i></div>
              </div>
              <h5>We'll email when your package is ready for pickup!</h5>
              <p>Please have a copy of the email printed out or on your phone when you come to pick up your package, so we can verify it's you!</p>
              <button className="btn btn-primary align-self-ctr" onClick={()=>{this.props.history.push('/packages')}}>Return to Packages</button>
            </div>
          }
          {this.state.outcome==='aprCreated' &&
            <div className="blank-order text-center">
              <div className="bl-card-image">
                <img src="/assets/images/virtual-image.svg" alt="" />
                <div className="green-circle"><i className="material-icons">shopping_cart</i></div>
              </div>
              <h5>Thank you for your Assisted Purchase Request!</h5>
              <p>One of our staff members is creating a quote for the order. You'll receive an email when it's ready!</p>
              <button className="btn btn-primary align-self-ctr" onClick={()=>{this.props.history.push('/my-aprs')}}>Return to Assisted Purchases</button>
            </div>
          }
          {this.state.outcome==='aprPaid' &&
            <div className="blank-order text-center">
              <div className="bl-card-image">
                <img src="/assets/images/virtual-image.svg" alt="" />
                <div className="green-circle"><i className="material-icons">done</i></div>
              </div>
              <h5>Thank you for your Assisted Purchase Order!</h5>
              <p>We're placing the order and will send you an email when it arrives at our warehouse.</p>
              <button className="btn btn-primary align-self-ctr" onClick={()=>{this.props.history.push('/my-aprs')}}>Return to Assisted Purchases</button>
            </div>
          }
        </div>
        <GlobalFooter />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Outcomes))
