import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { constants } from '../common/constants';

class GlobalFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: (new Date()).getFullYear(),
    };
  }

  render() {
    return (
      <footer id="footer">
        <img src="https://t.us1.dyntrk.com/pt.php?dynk=MTAyMTQ102148&dynt=28&dyncp=107172&dynf=img&gdpr=1&gdpr_consent=${GDPR_CONSENT_434}"/>
        <div id="footer-main">
          <h3 id="footer-title">
            &copy;
            {this.state.year}
            {' '}
            ReShip.com. All rights reserved.
          </h3>
          <div id="footer-navigation">
            <a className="footer-navigation-link" href={`${constants.appHome}/about.html`}>About</a>
            <a className="footer-navigation-link" href={`${constants.appHome}/terms-conditions.html`}>Terms &amp; Conditions</a>
            <a className="footer-navigation-link" href="https://intercom.help/reship/en/articles/5610437-prohibited-and-restricted-items">Prohibited Items</a>
            <a className="footer-navigation-link" href={`${constants.appHome}/privacy-policy.html`}>Privacy</a>
            <a className="footer-navigation-link" href={`${constants.appHome}/contact.html`}>Contact</a>
            <a className="footer-navigation-link" href="http://www.twitter.com/reshipdotcom"><i className="fab fa-twitter" /></a>
            <a className="footer-navigation-link" href="http://www.facebook.com/pages/ReShipcom/156822107696982"><i className="fab fa-facebook" /></a>
          </div>
        </div>
      </footer>
    );
  }
}

export default withRouter(GlobalFooter);
