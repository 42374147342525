import React, {Component, Fragment}   from 'react'
import {Link, withRouter}             from 'react-router-dom'
import {connect}                      from 'react-redux'
import axios                          from 'axios/index'
import GlobalFooter                   from '../components/GlobalFooter'
import GlobalError                    from '../components/GlobalError'
import CustomerSidebar                from '../components/CustomerSidebar'
import CustomerLoading                from '../components/CustomerLoading'
import {warehouseData, debug}         from '../common/constants'
import { displayDate, convertToClientTimestamp } from '../common/utils'

const mapStateToProps = (globalStore) => {return {store: globalStore}}

class Invoice extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      initialLoading: 0,
      initialLoadingError: '',
      
      loading: false,
      message: '',
      
      invoice: null,
      tally: null,
      package: null,
      shipment: null,
      apr: null,
      subscription: null,
    }
  }
  
  componentDidMount() {
    if(this.props.store.isLoggedIn && this.state.initialLoading===0)
      this.getBillingData()
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.store.isLoggedIn && this.state.initialLoading===0)
      this.getBillingData()
  }
  
  getBillingData = () => {
    this.setState({initialLoading: 1})

    axios.get('/customer/billing/getInvoice/'+this.props.match.params.id)
    .then((response) => {
      if(response.data.package){
        this.setState({
          ...response.data.package,
          canvasHeight: Math.max(200, response.data.package.subPackages.length*150)
        })
      }

      this.setState({
        initialLoading: 2,
        initialLoadingError: '',
        
        invoice: response.data.invoice,
        tally: response.data.invoice.tally,
        transactions: response.data.transactions,
        apr: response.data.apr || null,
        subscription: response.data.subscription || null,
      })
    })
    .catch((error) => {
      this.setState({
        initialLoading: 3,
        initialLoadingError: error.response ? error.response.data.message : error.message
      })
    })
  }

  render() {
     if(this.state.initialLoading < 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <CustomerLoading />
        </div>
      )
    else if (this.state.initialLoading > 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <GlobalError />
        </div>
      )
      
    return (
      <div className="body body-customer" id="invoices">
        <CustomerSidebar />
        <div className="content-area">
          <Link className="page-title d-flex align-items-center mb-3" to={'/my-history'}>
            <div className="page-title-back mr-2" ><i className="material-icons primary-color">arrow_back</i></div>
            <h3>My History</h3>
          </Link>
          <div className="invoice-wrapper">
            <div className="invoice-top">
              <div className="invoice-top-l">
                <div className="invoice-top-logo"><img src="/assets/images/Reship_Logo_Pur.png" alt="logo" /></div>
              </div>
              <div className="invoice-top-r">
                <div className="invoice-top-title">Invoice #{this.state.invoice.invoiceId}</div>
                <div className="invoice-top-date">{displayDate(convertToClientTimestamp(this.state.invoice.invoiceDate), 'd MMM yyyy')}</div>
                <div className="invoice-top-address">Reship.com<br />13820 NE Airport Way<br />Portland, OR<br />USA 97251</div>
              </div>
            </div>
            {this.state.invoice.invoiceType===3 &&
              <div className="invoice-apr">
                <div className="invoice-apr-title">Assisted Purchase #{this.state.apr.aprId.toString().padStart(6, '0')}</div>
                <div className="invoice-apr-subtitle">Merchant: {this.state.apr.storeName} ({warehouseData(this.state.apr.warehouse).countryLong})</div>
                <div className="ii-lines">
                  <div className="ii-line ii-line-head">
                    <div className="ii-line-label">Assisted Purchase Order</div>
                  </div>
                  {this.state.apr.aprItems.map(line=>{
                    return (
                      <div className="ii-line">
                        <div className="ii-line-label">
                          <div className="ii-apritem">{line.quoteQuantity} x {line.productName}</div>
                          {!!line.productDescription && <div className="ii-apritem-desc">Description: {line.productDescription}</div>}
                          {!!line.quoteComment && <div className="ii-apritem-desc">Staff note: {line.quoteComment}</div>}
                        </div>
                        <div className="ii-line-value">{line.quotePrice ? '$ '+line.quotePrice.toFixed(2) : 'Free'} {this.state.apr.orderCostCurrency}</div>
                      </div>
                    )
                  })}
                  {!!this.state.apr.orderCostLocalTaxes &&
                    <div className="ii-line">
                      <div className="ii-line-label">Merchant taxes</div>
                      <div className="ii-line-value">$ {this.state.apr.orderCostLocalTaxes.toFixed(2)} {this.state.apr.orderCostCurrency}</div>
                    </div>
                  }
                  <div className="ii-line">
                    <div className="ii-line-label">Merchant shipping</div>
                    <div className="ii-line-value">{this.state.apr.orderCostLocalShipping ? '$ '+this.state.apr.orderCostLocalShipping.toFixed(2)+' '+this.state.apr.orderCostCurrency : 'Free shipping'}</div>
                  </div>
                  <div className="ii-line ii-line-total">
                    <div className="ii-line-label">Purchase total
                      {(this.state.apr.orderCostCurrency && this.state.apr.orderCostCurrency!=='USD') && <div className="ii-apritem-desc">{this.state.apr.orderCostLocal.toFixed(2)} {this.state.apr.orderCostCurrency} x {this.state.apr.orderCostFx.toFixed(4)} fx rate</div>}
                    </div>
                    <div className="ii-line-value">$ {this.state.apr.orderCost.toFixed(2)} USD</div>
                  </div>
                </div>
              </div>
            }
            <div className="invoice-invoice">
              <div className="ii-lines">
                <div className="ii-line ii-line-head">
                  <div className="ii-line-label">Invoice Item</div>
                  <div className="ii-line-value">Amount</div>
                </div>
                {this.state.tally.services.aprLineItems.map(line=>{
                  return (
                    <div className="ii-line" key={'l'+line.lineItemId}>
                      <div className="ii-line-label">{line.itemName}</div>
                      <div className="ii-line-value">$ {line.amount.toFixed(2)}</div>
                    </div>
                  )
                })}
                {this.state.tally.services.setupLineItems.filter(item => !item.waived).map(line=>{
                  return (
                    <div className="ii-line" key={'l'+line.lineItemId}>
                      <div className="ii-line-label">{line.itemName}</div>
                      <div className="ii-line-value">$ {line.amount.toFixed(2)}</div>
                    </div>
                  )
                })}
                {this.state.tally.services.receivingLineItems.filter(item => !item.waived).map(line=>{
                  return (
                    <div className="ii-line"  key={'l'+line.lineItemId}>
                      <div className="ii-line-label">Receiving Fee {line.packageId ? '(#'+line.packageId.toString().padStart(6, '0')+')' : ''}</div>
                      <div className="ii-line-value">{line.amount ? '$ '+line.amount.toFixed(2) : 'Free'}</div>
                    </div>
                  )
                })}
                {!!this.state.tally.services.repackTotal &&
                  <div className="ii-line">
                    <div className="ii-line-label">Consolidation/Repack Fees</div>
                    <div className="ii-line-value">$ {this.state.tally.services.repackTotal.toFixed(2)}</div>
                  </div>
                }
                {this.state.tally.services.requestLineItems.filter(item => !item.waived).map(line=>{
                  return (
                    <div className="ii-line" key={'l'+line.lineItemId}>
                      <div className="ii-line-label">{line.itemName} {line.packageId ? '(#'+line.packageId.toString().padStart(6, '0')+')' : ''}</div>
                      <div className="ii-line-value">{line.amount ? '$ '+line.amount.toFixed(2) : 'Free'}</div>
                    </div>
                  )
                })}
                {this.state.tally.services.shippingLineItems.filter(item => !item.waived).map(line=>{
                  return (
                    <div className="ii-line" key={'l'+line.lineItemId}>
                      <div className="ii-line-label">{line.itemName}</div>
                      <div className="ii-line-value">{line.amount ? '$ '+line.amount.toFixed(2) : 'Free'}</div>
                    </div>
                  )
                })}
                {this.state.tally.services.subscriptionLineItems.map(line=>{
                  return (
                    <div className="ii-line" key={'l'+line.lineItemId}>
                      <div className="ii-line-label">{line.itemName}{!!line.planName && ': '+line.planName}</div>
                      <div className="ii-line-value">{line.amount ? '$ '+line.amount.toFixed(2) : 'Free'}</div>
                    </div>
                  )
                })}
                {this.state.tally.services.storageLineItems.filter(item => !item.waived).map(line=>{
                  return (
                    <div className="ii-line"  key={'l'+line.lineItemId}>
                      <div className="ii-line-label">{line.itemName}</div>
                      <div className="ii-line-value">{line.amount ? '$ '+line.amount.toFixed(2) : 'Free'}</div>
                    </div>
                  )
                })}
                {!!this.state.tally.coupon &&
                  <Fragment>
                    <div className="ii-line">
                      <div className="ii-line-label">{this.state.tally.couponLineItem.itemName}</div>
                      <div className="ii-line-value">-$ {this.state.tally.coupon.toFixed(2)}</div>
                    </div>
                  </Fragment>
                }
                {!!this.state.tally.taxesTotal &&
                  <Fragment>
                    <div className="ii-line-hr" />
                    <div className="ii-line">
                      <div className="ii-line-label">Total before tax</div>
                      <div className="ii-line-value">$ {this.state.tally.subtotalWithCoupon.toFixed(2)}</div>
                    </div>
                    {this.state.tally.taxes.map(tax=>{
                      return (
                        <div className="ii-line" key={'l'+tax.lineItemId}>
                          <div className="ii-line-label">{tax.itemName}</div>
                          <div className="ii-line-value">$ {tax.amount.toFixed(2)}</div>
                        </div>
                      )
                    })}
                  </Fragment>}
                {this.state.tally.ddpLineItems.map(item => {
                  return (
                    <div className="ii-line" key={'l'+item.lineItemId}>
                      <div className="ii-line-label">{item.itemName}</div>
                      <div className="ii-line-value">$ {item.amount.toFixed(2)}</div>
                    </div>
                  )
                })}
                <div className="ii-line ii-line-total">
                  <div className="ii-line-label">Invoice Total</div>
                  <div className="ii-line-value">$ {this.state.tally.totalInvoice.toFixed(2)}</div>
                </div>
              </div>
              {!!this.state.tally.totalInvoice &&
                <div className="ii-transactions">
                  <div className="ii-trans">
                    <div className="ii-line ii-line-head">
                      <div className="ii-line-label">Payments</div>
                      <div className="ii-line-value">Amount</div>
                    </div>
                    {this.state.tally.credits.map(credit=>{
                      return (
                        <div className="ii-line" key={'l'+credit.lineItemId}>
                          <div className="ii-line-label">{credit.itemName}</div>
                          <div className="ii-line-value">$ {credit.amount.toFixed(2)}</div>
                        </div>
                      )
                    })}
                    {this.state.transactions.map(t=>{
                      if(t.transactionType < 10)
                        return (
                          <div>
                            <div className="ii-line" key={'t'+t.transactionId}>
                              <div className="ii-line-label">{t.transactionType===1 ? 'Paypal payment' : 'Credit card payment'} {!!t.transactionReference && <div className="ii-trans-detail">Ref: {t.transactionReference}</div>}</div>
                              <div className="ii-line-value">$ {t.transactionAmount.toFixed(2)}</div>
                            </div>
                            {
                              t.transactionType === 9 && (
                                <div className="ii-line" key={'t'+t.transactionId}>
                                  <div className="ii-line-label">Voided credit card payment <div className="ii-trans-detail">Ref: {t.transactionReference}</div></div>
                                  <div className="ii-line-value">$ -{t.transactionAmount.toFixed(2)}</div>
                                </div>
                              )
                            }
                          </div>
                          
                        )
                      else
                        return (
                          <div className="ii-line" key={'t'+t.transactionId}>
                            <div className="ii-line-label">{t.transactionType===13 ? 'Refund to Reship Credit' : 'Refund'}</div>
                            <div className="ii-line-value">$ -{t.transactionAmount.toFixed(2)}</div>
                          </div>
                        )
                    })}
                    <div className="ii-line-hr" />
                    {!!this.state.tally.balance &&
                      <div className="ii-line ii-balance">
                        <div className="ii-line-label">Balance</div>
                        <div className="ii-line-value">$ {this.state.tally.balance.toFixed(2)}</div>
                      </div>
                    }
                  </div>
                </div>
              }
              <div className="ii-currency">All amounts in USD</div>
            </div>
          </div>
        </div>
        <GlobalFooter />
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Invoice))
