import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios/index';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { warehouses } from '../common/constants';
import { validateRegion } from '../common/handleAddress';
import ButtonLoading from './ButtonLoading';
import DropdownCountry from './DropdownCountry';
import DropdownRegion from './DropdownRegion';
import RecaptchaV2 from './RecaptchaV2/recaptchaV2';


const plans = [
  {
    name: 'Free',
    value: 212
  },
  {
    name: 'Gold',
    value: 213
  },
  {
    name: 'Platinum',
    value: 215
  },
]

const mapStateToProps = (globalStore) => {
  return { store: globalStore };
};

const PublicCalculator = (props) => {
  const [plan, setPlan] = useState(212)
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [reinitAttempted, setReinitAttempted] = useState(false);
  const [weightMetric, setWeightMetric] = useState(0);
  const [dimensionsMetric, setDimensionsMetric] = useState(0);
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [warehouse, setWarehouse] = useState(0);
  const [city, setCity] = useState('');
  const [stateCode, setStateCode] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [isRecaptcha2Required, setIsRecaptcha2Required] = useState(false);
  const [recaptchaV2Response, setRecaptchaV2Response] = useState('');
  const [shippingOptions, setShippingOptions] = useState([]);

  useEffect(() => {
    setIsRecaptcha2Required(false);
  }, [countryCode]);

  const isOversized = () => {
    let dimensions = [length * 1, width * 1, height * 1];
    let longest = Math.max(...dimensions);
    dimensions.splice(dimensions.indexOf(longest), 1);
    let total = longest + (dimensions[0] + dimensions[1]) * 2;
    return dimensionsMetric ? total > 330 : total > 130;
  };

  const doRequestQuotes = () => {
    if (!warehouse) {
      setMessage('Please select a Reship location');
    } else if (!city || !countryCode || !postalCode) {
      setMessage('Please fill in the address');
    } else if (!validateRegion(countryCode, stateCode)) {
      setMessage('Please select a valid state/region');
    } else if (!length || !width || !height) {
      setMessage('Please fill in the dimensions');
    } else if (
      dimensionsMetric
        ? length > 300 || width > 300 || height > 300
        : length > 118 || width > 118 || height > 118
    ) {
      setMessage(
        'Sorry, maximum length, width, or height is 300 cm / 118 inches. Even so, many carriers may not support it.'
      );
    } else if (!weight) {
      setMessage('Please fill in a weight');
    } else if (weightMetric ? weight > 250 : weight > 550) {
      setMessage(
        'Sorry, maximum weight is 250 kg / 550 lb. Even so, many carriers may not support it.'
      );
    } else if (isNaN(length) || isNaN(width) || isNaN(height) || isNaN(weight)) {
      setMessage('Please enter only numbers for package details');
    } else {
      props.googleReCaptchaProps
        .executeRecaptcha('estimate')
        .then(doSubmitRequest)
        .catch(() => {
          setLoading(false);
          setMessage('Recaptcha Error :(');
        });
    }
  };

  const doSubmitRequest = (recaptchaV3Response) => {
    setLoading(true);
    setSubmitted(false);
    setMessage('');
    setShippingOptions([]);

    const postData = {
      warehouse,
      length: dimensionsMetric ? +(+length).toFixed(1) : +((length * 2.54).toFixed(1)),
      width: dimensionsMetric ? +(+width).toFixed(1) : +((width * 2.54).toFixed(1)),
      height: dimensionsMetric ? +(+height).toFixed(1) : +((height * 2.54).toFixed(1)),
      weight: weightMetric ? +(+weight).toFixed(2) : +((weight * 0.453592).toFixed(2)),
      address: {
        city,
        stateCode,
        countryCode,
        postalCode,
      },
      plan,
      recaptchaV3Response,
      recaptchaV2Response,
    };

    axios
      .post('/web/shipEstimate', postData)
      .then((response) => {
        setLoading(false);
        setMessage('');
        setSubmitted(true);
        setReinitAttempted(false);
        setShippingOptions(response.data.shippingOptions.slice().sort((a, b) => a.rate - b.rate));
      })
      .catch((error) => {
        setIsRecaptcha2Required(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onVerifyRecaptchaV2 = (response) => {
    setRecaptchaV2Response(response);
  };

  return (
    <div className="calc-wrapper2">
      <div className="calc-l">
        <div className="calc-label">Membership Plan</div>
        <table style={{marginTop: 10}}>
          <tbody>
          <tr>
            <td className="btn-toggle">
              {plans.map(type => {
                return (
                  <div className={"btn " + (plan === type.value ? ' btn-selected' : '')}
                      key={'customs-' + type.name} onClick={() => setPlan(type.value)}>{type.name}
                  </div>
                )
              })}
            </td>
          </tr>
          </tbody>
        </table>
        <div className="calc-subnote"> Save up to 20% more on shipping costs when on a Gold or Platinum Plan</div>
        <div className="calc-label">Package Details</div>
        <div className="calc-dimension2">
          <div className="calc-dimension">
            <input
              className="calc-input"
              placeholder="Length"
              value={length}
              onChange={(e) => setLength(e.target.value)}
            />
          </div>
          <div className="calc-dimension">
            <input
              className="calc-input"
              placeholder="Width"
              value={width}
              onChange={(e) => setWidth(e.target.value)}
            />
          </div>
          <div className="calc-dimension">
            <input
              className="calc-input"
              placeholder="Height"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
            />
          </div>
          <select
            className="calc-dropdown"
            value={dimensionsMetric}
            onChange={(e) => setDimensionsMetric(+e.target.value)}
          >
            <option value={0}>IN</option>
            <option value={1}>CM</option>
          </select>
        </div>
        {length && width && height && (
          <div className="calc-subnote">
            * Volumetric weight estimate:&nbsp;
            {weightMetric ? (
              dimensionsMetric ? (
                `${(length * width * height / 5000).toFixed(2)} kg`
              ) : (
                `${(length * 2.54 * width * 2.54 * height * 2.54 / 5000).toFixed(2)} kg`
              )
            ) : (
              dimensionsMetric ? (
                `${(length * 0.3937 * width * 0.3937 * height * 0.3937 / 139).toFixed(1)} lb`
              ) : (
                `${(length * width * height / 139).toFixed(1)} lb`
              )
            )}
            {isOversized() ? <span><br />**Oversized - some carriers may not accept, or charge significantly more</span> : ''}
          </div>
        )}
        <div className="calc-weight">
          <input
            className="calc-input"
            placeholder="Weight"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
          <select
            className="calc-dropdown"
            value={weightMetric}
            onChange={(e) => setWeightMetric(+e.target.value)}
          >
            <option value={0}>LB</option>
            <option value={1}>KG</option>
          </select>
        </div>
        <div className="calc-label">Reshipped to...</div>
        <DropdownCountry
          className="form-control"
          autoComplete="country"
          name="country"
          value={countryCode}
          onChange={(val) => {
            if (val !== countryCode) {
              setCountryCode(val);
              setStateCode('');
            }
          }}
        />
        <DropdownRegion
          className="form-control"
          autoComplete="address-level1"
          name="region"
          country={countryCode}
          value={stateCode}
          onChange={(val) => setStateCode(val)}
        />
        <input
          className="form-control"
          placeholder="City"
          autoComplete="address-level2"
          name="city"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <input
          className="form-control"
          placeholder="Postal code"
          autoComplete="postal-code"
          name="postal"
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
        />
        <div className="calc-subnote">
          * Carriers use the larger of actual and volumetric weights for their rates.<br />
          ** Many carriers also round up to the next lb or 0.5 kg.
        </div>
        <div className="calc-subnote">
          * The rates displayed are estimates only, and are subject to change without prior notice.
        </div>
        <div className="calc-label">From your mailbox in...</div>
        <div className="calc-warehouses">
          <div className="apr-new-store-flags">
            {warehouses.map(w => (
              <div
                className={`apr-new-store-flag ${w.id === warehouse ? 'apr-new-store-flag-active' : ''}`}
                onClick={() => setWarehouse(w.id)}
                key={w.id}
              >
                <img className="apr-new-store-flag-img" src={`/assets/images/${w.flag}`} alt={w.name} />
                <div className="apr-new-store-name">{w.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="calc-mid">
        {!!message && <div className="error-text">{message}</div>}
        {!!isRecaptcha2Required && (
          <div className="form-group">
            <RecaptchaV2 onVerify={onVerifyRecaptchaV2} />
          </div>
        )}
        <div className="calc-buttons">
          <div
            className="btn btn-primary"
            onClick={loading ? () => {} : doRequestQuotes}
          >
            {loading ? <ButtonLoading /> : 'Get Shipping Estimates'}
          </div>
        </div>
      </div>
      <div className="calc-mid">
        {submitted && !shippingOptions.length && (
          <div className="ship-quote-message">
            Sorry, there are no regular shipping options available for your package and destination.
            Please contact us for special shipment handling.
          </div>
        )}
        <div className="ship-quotes">
          {shippingOptions.map((option, index) => (
            <div className="ship-quote card card-package card-white" key={index}>
              <div className="ship-quote-info">
                <div className="ship-quote-title">{option.serviceName}</div>
                {option.deliveryDaysMin && option.deliveryDaysMax && option.deliveryDaysMin !== option.deliveryDaysMax ? (
                  <div className="ship-quote-time">Estimate {option.deliveryDaysMin}-{option.deliveryDaysMax} Business Days</div>
                ) : (
                  option.deliveryDays ? (
                    <div className="ship-quote-time">Estimate {option.deliveryDays} Business Days</div>
                  ) : (
                    <div className="ship-quote-time">{option.serviceDescription || 'Delivery time depends on destination and cannot be guaranteed'}</div>
                  )
                )}
              </div>
              <div className="ship-quote-price">{option.rateCustomer ? <Fragment>${option.rateCustomer}</Fragment> : 'Free'}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(withRouter(withGoogleReCaptcha(PublicCalculator)));
