import React from 'react';
import EoriForm from './EoriForm';
import EoriWarningMessage from './EoriWarningMessage';

function Index({ eoriNumber, onChange, onSubmit }) {
  return (
    <div>
      <EoriWarningMessage />
      <EoriForm value={eoriNumber} onChange={onChange} onSubmit={onSubmit} />
    </div>
  );
}

export default Index;
