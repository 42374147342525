import React, {Component, Fragment}   from 'react'
import {withRouter}                   from 'react-router-dom'
import {connect}                      from 'react-redux'
import agent                          from '../api/agent'
import GlobalFooter                   from '../components/GlobalFooter'
import GlobalError                    from '../components/GlobalError'
import GlobalNotices                  from '../components/GlobalNotices'
import CustomerSidebar                from '../components/CustomerSidebar'
import CustomerLoading                from '../components/CustomerLoading'
import {invoiceType, warehouseData, debug}   
                                      from '../common/constants'
import Notification                   from '../components/ui/Notification'
import { displayDate, convertToClientTimestamp } from '../common/utils'


const mapStateToProps = (globalStore) => {return {store: globalStore}}

class Billing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialLoading: 0,
      initialLoadingError: '',
      loading: false,
      message: '',
      
      year: (new Date()).getFullYear(),
      years: [(new Date()).getFullYear()],
      
      invoices: [],
      credit: 0
    }
  }
  
  componentDidMount() {
    if(this.props.store.isLoggedIn && this.state.initialLoading===0)
      this.getBillingData()
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.store.isLoggedIn && this.state.initialLoading===0)
      this.getBillingData()
  }

  getBillingData = () => {
    this.setState({initialLoading: 1})
    
    agent.billing.getBilling()
    .then((response) => {
      let invoices = response.data.invoices.sort((a,b)=>{return new Date(b.invoiceDate) - new Date(a.invoiceDate)})

      if(invoices.length){
        let years = [...new Set(invoices.map(inv => new Date(inv.invoiceDate.replace(" ", "T") + "Z").getFullYear()))]
        this.setState({
          initialLoading: 2,
          initialLoadingError: '',
          invoices: invoices,
          credit: response.data.creditBalance,
          years: years.length ? years : [(new Date()).getFullYear()],
          year: Math.max(...years),
        })
      }
      else {
        this.setState({
          initialLoading: 2,
          invoices: [],
          credit: response.data.creditBalance,
        })
      }
    })
    .catch((error) => {
      this.setState({
        initialLoading: 3,
        initialLoadingError: error.response ? error.response.data.message : error.message
      })
    })
  }
  
  render() {
    if(this.state.initialLoading < 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <CustomerLoading />
        </div>
      )
    else if (this.state.initialLoading > 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <GlobalError />
        </div>
      )
    
    return (
      <div className="body">
        <CustomerSidebar />
        <div className="content-area">
          <GlobalNotices { ...this.props }/>
          <div className="page-title"><h2>Billing History</h2></div>
          <div className="card">
            <div className="invoices-top">
              <div className="invoices-top-left">
                <select className="invoices-select form-control" value={this.state.year} onChange={(event)=>{this.setState({year: +event.target.value})}}>
                  {this.state.years.map(yr=>{
                    return (
                      <option value={yr} id={yr} key={'yr-'+yr}>{yr}</option>
                    )
                  })}
                </select>
              </div>
              <div className="invoices-top-right">
                <div className="invoices-credits">
                  <div className="invoices-credits-label">Reship Credits</div>
                  <div className="invoices-credits-value">${this.state.credit.toFixed(2)}</div>
                </div>
              </div>
            </div>
            <div className="invoices-list">
              <div className="invoices-list-head">
                <div className="invoices-row-invoice">Invoice</div>
                <div className="invoices-row-date">Date</div>
                <div className="invoices-row-type">&nbsp;</div>
                <div className="invoices-row-detail">Details</div>
                <div className="invoices-row-value">Amount</div>
              </div>
              {!this.state.invoices.length ?
                <div className="invoices-note">No previous purchases, shipments or subscriptions!</div>
                :
                <Fragment>
                  {this.state.invoices.filter(invoice=>{return invoice.invoiceYear===this.state.year}).map(invoice=>{
                    return (
                      <div className="invoices-row" key={invoice.invoiceId} onClick={()=>{this.props.history.push('/invoice/'+invoice.invoiceId)}}>
                        <div className="invoices-row-invoice">{invoice.invoiceId}</div>
                        <div className="invoices-row-date">{displayDate(convertToClientTimestamp(invoice.invoiceDate), 'd MMM yyyy')}</div>
                        <div className="invoices-row-type"><i className={`invoices-row-icon fas fa-${invoice.packageType ? 'boxes' : invoiceType(invoice.invoiceType).icon}`} /></div>
                        {invoice.invoiceType===1 &&
                          <div className="invoices-row-detail">
                            <div className="invoices-row-detail-main">Package #{invoice.packageId.toString().padStart(6, '0')}</div>
                            {invoice.shipmentSystem===99 ?
                              <div className="invoices-row-detail-sub">Pickup at {warehouseData(invoice.shipmentFrom).name}</div>
                              :
                              <div className="invoices-row-detail-sub">{warehouseData(invoice.shipmentFrom).countryLong}{!!invoice.shipmentTo && ' to '+invoice.shipmentTo}</div>
                            }
                          </div>
                        }
                        {invoice.invoiceType===2 &&
                          <div className="invoices-row-detail">
                            <div className="invoices-row-detail-main">Subscription: {invoice.planName}</div>
                          </div>
                        }
                        {invoice.invoiceType===3 &&
                          <div className="invoices-row-detail">
                            <div className="invoices-row-detail-main">APR #{invoice.aprId}</div>
                            <div className="invoices-row-detail-sub">{invoice.aprVendor} - {invoice.aprItems} item(s)</div>
                          </div>
                        }
                        {invoice.invoiceType===4 &&
                          <div className="invoices-row-detail">
                            <div className="invoices-row-detail-main">Credit Purchase</div>
                          </div>
                        }
                        <div className="invoices-row-total">
                          <div className="invoices-row-value">${(invoice.amtTotal*1).toFixed(2)}</div>
                          {invoice.amtBalance===0 && <div className="invoices-row-status invoices-row-status-paid">Paid</div>}
                        </div>
                      </div>
                    )
                  })}
                </Fragment>
              }
            </div>
          </div>

        </div>
        <GlobalFooter />
        <Notification {...this.props}/>
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Billing))
