import axios from 'axios';

const resBody = (response) => response;

const requests = {
  get: (url, config) => axios.get(url, config).then(resBody),
  post: (url, body, config) => axios.post(url, body, config).then(resBody),
  put: (url, body, config) => axios.put(url, body, config).then(resBody),
  del: (url, config) => axios.delete(url, config).then(resBody),
};

const billing = {
  getBilling: () => requests.get('/customer/billing/getBilling'),
};

const checkout = {
  startBraintree: (body) => requests.post('/customer/checkout/startBraintree', body),
};

const email = {
  customerReferralUsed: (body) => requests.post(`/email/referral`, body),
  customerWelcome: (body) => requests.post(`/email/welcome/${body.email}`, body),
  customerAccountDeleteRequest: () => requests.get(`/email/account/delete`),
  passwordReset: (body) => requests.post(`/email/customer/reset/${body.email}`, body)
}

const customer = {
  referralUsed: (body) => requests.post('/customer/referral/used', body)
}

const prereceiving = {
  startPreReceiving: (body) => requests.post('/v1/prereceive', body),
}

const pkg = {
  getPackage: ({packageId}) => requests.get(`/customer/packages/getPackage/${packageId}`),
  getInsuranceCost: ({quoteId}) => requests.get(`/customer/package/insurance/${quoteId}`),
  updatePackage: (body) => requests.post(`/customer/packages/updateInfo`, body)
}

const timeline = {
  addTimeline: (body) => requests.post('v1/timeline', body)
}

export default {
  billing,
  checkout,
  email,
  customer,
  prereceiving,
  pkg,
  timeline
};
