import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class GlobalHelmet extends Component {
  render() {
    const params = new URLSearchParams(this.props.location.search)
    const packageId = params.get('pkg')

    switch (this.props.match.path) {
      case '/':
      case '/dashboad':
        return (<Helmet><title>Dashboard | Reship</title></Helmet>);
      case '/my-account':
        return (<Helmet><title>Manage Account | Reship</title></Helmet>);
      case '/my-addresses':
        return (<Helmet><title>Manage Addresses | Reship</title></Helmet>);
      case '/packages':
        return (<Helmet><title>Packages | Reship</title></Helmet>);
      case '/shipments':
        return (<Helmet><title>Shipments | Reship</title></Helmet>);
      case '/my-aprs':
        return (<Helmet><title>Assisted Purchases | Reship</title></Helmet>);
      case '/my-plan':
        return (<Helmet><title>Manage Membership | Reship</title></Helmet>);
      case '/my-history':
        return (<Helmet><title>Billing History | Reship</title></Helmet>);
      case '/my-refers':
        return (<Helmet><title>Referrals | Reship</title></Helmet>);
      case '/package':
        return (
          <Helmet>
            <title>
              Package #
              {packageId}
              {' '}
              | Reship
            </title>
          </Helmet>
        );
      case '/shipment':
        return (
          <Helmet>
            <title>
              Ship Package #
              {packageId}
              {' '}
              | Reship
            </title>
          </Helmet>
        );
      default:
        return (<Helmet><title>Reship | Bringing the world to your door</title></Helmet>);
    }
  }
}

export default withRouter(GlobalHelmet);
