import React, {Fragment, Component}   from 'react'
import {connect}                      from 'react-redux'
import {withRouter}                   from 'react-router-dom'
import {withGoogleReCaptcha}          from 'react-google-recaptcha-v3'
import PropTypes                      from 'prop-types'
import Script                         from 'react-load-script'
import axios                          from 'axios/index'
import {constants, debug}             from '../common/constants'
import GlobalError                    from '../components/GlobalError'
import GlobalFooter                   from '../components/GlobalFooter'
import GlobalNotices                  from '../components/GlobalNotices'
import CustomerSidebar                from '../components/CustomerSidebar'
import CustomerLoading                from '../components/CustomerLoading'
import ButtonLoading                  from '../components/ButtonLoading'
import DropdownCountry                from '../components/DropdownCountry'
import DropdownRegion                 from '../components/DropdownRegion'
import '../assets/css/recurly.css'
import {convertToClientTimestamp, displayDate, handleError} from '../common/utils'
import Plans from '../components/Plans'
import RecaptchaV2 from '../components/RecaptchaV2/recaptchaV2'
import SwitchPlanModal from "../components/SwitchPlanModal";
import CancelSubscriptionModal from "../components/CancelSubscriptionModal";

const mapStateToProps = (globalStore) => {return {store: globalStore}}
const mapDispatchToProps = (dispatch) => {return {
  customerSubscription: (transactionRecord) => {dispatch({type: 'CUSTOMER_SUBSCRIPTION', payload: transactionRecord})}
}}

class PaymentForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    message: PropTypes.string
  }
  constructor(props) {
    super(props)
    this.state = {
      payMessage: this.props.message || '',
      payLoading: true,
      country: '',
      state: '',
      recaptchaV2Response: '',
    }
  }

  componentDidMount(){
    window.recurly.configure({
      publicKey: constants.recurlyPublicKey
    })
    this.setState({payLoading: false})
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.message){
      this.setState({payMessage: nextProps.message})
    }
  }

  doSubmit = () => {
    this.setState({
      payLoading: true,
      payMessage: '',
    })

    window.recurly.token(document.querySelector('#recurlyForm'), (err, token) => {
      if (err){
        this.setState({
          payLoading: false,
          payMessage: err.message ? err.message : 'Declined - please check your input'
        })
      }
      else{
        this.setState({
          payLoading: false,
          payMessage: ''
        })

        this.props.onSubmit({ tokenId: token.id, recaptchaV2Response: this.state.recaptchaV2Response })
      }
    })
  }

  onChange = () => {
    if(this.state.payMessage)
      this.setState({
        payMessage: ''
      })
  }

  onVerifyRecaptchaV2 = (response) => {
    this.setState({recaptchaV2Response: response})
  }

  render () {

    return (
      <div className="plan-payment-form">
        <form id="recurlyForm" /*className={this.state.payLoading ? 'display-none' : ''}*/>
          <div data-recurly="card" />
          <div className="plan-payment-spacer">&nbsp;</div>
          <input type="text" data-recurly="first_name" className="form-control plan-payment-input" placeholder="First Name" onChange={this.onChange} />
          <input type="text" data-recurly="last_name"  className="form-control plan-payment-input"  placeholder="Last Name" onChange={this.onChange} />
          <input type="text" data-recurly="address1"  className="form-control plan-payment-input"  placeholder="Street Address" onChange={this.onChange} />
          <input type="text" data-recurly="city" className="form-control plan-payment-input" placeholder="City" onChange={this.onChange} />
          <input type="text" data-recurly="postal_code"  className="form-control plan-payment-input" placeholder="Postal Code" onChange={this.onChange} />
          <DropdownCountry data-recurly="country" className="form-control plan-payment-input" autoComplete="country" onChange={(value)=>{if(this.state.country!==value) this.setState({country: value, state: ''}); this.onChange()}} value={this.state.country} />
          <DropdownRegion data-recurly="state" className="form-control plan-payment-input" autoComplete="address-level1" country={this.state.country} value={this.state.state} onChange={(val)=>{this.setState({state: val}); this.onChange()}} />
          <div className="plan-payment-text">Membership subscriptions renew automatically until cancellation by re-activating the free plan. When cancelled, the membership will end on the next renewal date. No refunds are provided for early cancellations.</div>
          <input type="hidden" name="recurly-token" data-recurly="token" />
          {!!this.state.payMessage && <div className="error-text">{this.state.payMessage}</div>}
          <div className="mt-3 mb-2">
            <div className="form-group">
              <RecaptchaV2 onVerify={this.onVerifyRecaptchaV2}/>
            </div>
            { <div className="btn btn-primary" style={{ pointerEvents: !!this.state.recaptchaV2Response ? 'auto' : 'none' }} onClick={this.state.payLoading ? ()=>{} : this.doSubmit}>{this.state.payLoading ? <ButtonLoading/> : 'Submit'}</div> }
            <div className="plan-payment-text text-center mt-1">By submitting, you agree to our <a target="_blank" rel="noopener noreferrer" href={constants.appHome+'/terms-conditions.html'}>terms & conditions</a></div>
          </div>
        </form>
      </div>
    )
  }
}

const SubscriptionInformation = ({ currentPlan, subscription }) => {
  return (
    <div className="plan-current">
      <div className="plan-current-info">
        <div className="plan-data-label">Current Plan</div>
        <div className="plan-data-value">{currentPlan.planName}</div>
      </div>
      <div className="plan-current-info">
        <div className="plan-data-label">Membership Fee</div>
        <div className="plan-data-value">{currentPlan.planRate ? currentPlan.planRate.toFixed(2)+'/'+(currentPlan.planInterval===2 ? 'yr' : 'mo') : 'Free'}</div>
      </div>
      <div className="plan-current-info">
        <div className="plan-data-label">{!!subscription.pendingPlanId ? 'Expires on' : 'Renews'}</div>
        <div className="plan-data-value">
          {['free', 'FREE', 'staff'].includes(currentPlan.planCode) ?
            'Forever'
            :
            displayDate(convertToClientTimestamp(subscription.subscriptionExpiry), 'dd MMMM yyyy')
          }
        </div>
      </div>
      {(!['free', 'FREE'].includes(currentPlan.planCode) && !!subscription.billingObject) &&
        <div className="plan-current-info">
          <div className="plan-data-label">Billing Info</div>
          <div className="plan-data-card">
            {subscription.billingObject.cardType==='Visa' && <img src="/assets/images/payment-ico-visa.svg" alt="Pay with VISA" height="32" width="20" />}
            {subscription.billingObject.cardType==='MasterCard' && <img src="/assets/images/payment-ico-mastercard.svg" alt="Pay with Master Card" height="32" width="20" />}
            {/* {subscription.billingObject.cardType==='AmericanExpress' && <img src="/assets/images/payment-ico-amex.svg" alt="Pay with American Express" height="32" width="20" />} */}
            ending in {subscription.billingObject.cardLastFour}, exp. {subscription.billingObject.cardExpiry}
          </div>
        </div>
      }
    </div>
  )
}

class Plan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialLoading: 0,
      initialLoadingError: '',
      paymentScriptLoaded: false,
      
      plans: [],
      visiblePlans: [],
      currentPlan: {},
      pendingPlan: {},
      subscription: {},
      selectedPlan: false,
      
      doPrompt: 0, // 1 - switch prompt, 2 - payment form, 3 - processing, 4 - thank you, 5 - cancel prompt, 6 - changes processed

      paymentIntent: false, // 1 - new subscription, 2 - change payment information
      paymentToken: '',
      paymentLoading: false,
      paymentResponse: '',
      paymentMessage: '',
      isAnnualView: true,
      isLoading: false
    }
  }
  
  componentDidMount() {
    // const customerId = this.props.store.customer.customerId
    this.getPlanData()
  }

  handlePlanView = (value) => {
    const { plans } = this.state
    const filteredPlans = this.filterPlans(value, plans)

    this.setState({
      isAnnualView: value,
      visiblePlans: filteredPlans,
    })
  }

  filterPlans = (isAnnualView, plans) => {
    let filteredPlans = 
      plans
      .filter(item => isAnnualView ? ([0, 2].includes(item.planInterval) || item.type ==='custom') : [0, 1].includes(item.planInterval) || item.type ==='custom')
      .sort((a,b) => a.planPriority-b.planPriority)   

    return filteredPlans
  }

  getPlanData = async () => {
    try {
      const response = await axios.get(`/customer/plan`)

      const currentPlan = response.data.data.plans.find(item => item.status === 'selected')
      const pendingPlan = response.data.data.plans.find(item => item.status === 'pending')
      const plans = response.data.data.plans
      const subscription = response.data.data.subscription    

      const IS_ANNUAL_VIEW = ['free', 'FREE'].includes(currentPlan.planCode) || currentPlan.planInterval === 2 || currentPlan.type === 'custom'

      const filteredPlans = this.filterPlans(IS_ANNUAL_VIEW, plans)

      this.setState({
        initialLoading: 2,
        initialLoadingError: '',
        plans: plans,
        visiblePlans: filteredPlans,
        currentPlan: currentPlan,
        pendingPlan: pendingPlan,
        subscription: subscription,
        isAnnualView: IS_ANNUAL_VIEW
      })
      
      return response.data.data

    } catch(e) {
      this.setState({
        initialLoading: 3
      })
    }
  }

  selectPlan = (planId) => {
    const selectedPlan = this.state.plans.find(item => item.planId === planId) 

    this.setState({
      selectedPlan: selectedPlan
    })

    const NEW_PLAN_CHECKOUT = ['free', 'FREE'].includes(this.state.currentPlan.planCode)
    const CANCEL_PLAN = ['free', 'FREE'].includes(selectedPlan.planCode)
    const DOWNGRADE_PLAN = selectedPlan.planPriority < this.state.currentPlan.planPriority 
    const UPGRADE_PLAN = selectedPlan.planPriority > this.state.currentPlan.planPriority
    const IS_SELECTED_PLAN_CURRENT_PLAN = selectedPlan.planId === this.state.currentPlan.planId

    if(IS_SELECTED_PLAN_CURRENT_PLAN) {
      this.setState({ doPrompt: 2, paymentIntent: 2 })
      return;
    }

    if(NEW_PLAN_CHECKOUT) {
      if(this.state.currentPlan.type === 'grandfathered' && selectedPlan.planCode === 'free') {
        this.setState({ doPrompt: 1 })
        return;
      }

      this.loadPaymentForm()      
      return; 
    }

    if(CANCEL_PLAN) {
      this.setState({ doPrompt: 1 })
      return; 
    }

    if(DOWNGRADE_PLAN) {
      this.setState({ doPrompt: 1 })
      return;
    } 

    if(UPGRADE_PLAN) {
      this.setState({ doPrompt: 1 })
      return;
    }
  }

  handleSubmitPaymentForm = async ({ tokenId, recaptchaV2Response }) => {
    this.setState({
      paymentFormToken: tokenId,
      paymentLoading: true
    })

    const recaptchaV3Response = await this.props.googleReCaptchaProps.executeRecaptcha('forgotPassword')

    const requestBody = {
      planId: this.state.selectedPlan.planId, 
      paymentToken: tokenId,
      recaptchaV2Response,
      recaptchaV3Response
    }

    const postURL = this.state.paymentIntent === 1 ? '/customer/plan/checkoutNew' : '/customer/plan/changePaymentMethod'
    const checkoutRes = await axios.post(postURL, requestBody)

    this.props.customerSubscription(checkoutRes.data.data)
    this.props.history.push('/done/newSubscription')
  }

  switchPlan = async () => {
    try {
      this.setState({isLoading: true})
      const DOWNGRADE_PLAN = this.state.selectedPlan.planPriority < this.state.currentPlan.planPriority
      const CANCEL_PLAN = ['free', 'FREE'].includes(this.state.selectedPlan.planCode) && DOWNGRADE_PLAN
      const UPGRADE_PLAN = this.state.selectedPlan.planPriority > this.state.currentPlan.planPriority

      const requestBody = {
        newPlanId: this.state.selectedPlan.planId
      }

      if(CANCEL_PLAN) {
        await axios.post('/customer/plan/cancel', requestBody)
      }
      else if (DOWNGRADE_PLAN) {
        await axios.post('/customer/plan/downgrade', requestBody)
      }
      else if (UPGRADE_PLAN) {
        await axios.post('/customer/plan/upgrade', requestBody)
      }

      await this.getPlanData()

      if(this.state.subscription.pendingPlanId === 1) {
        this.setState({
          doPrompt: 5
        })
        return;
      }

      if(this.state.subscription.currentPlanId !== requestBody.newPlanId) {
        this.props.history.push('/done/newSubscriptionProcessing')
        return;
      }

      this.props.history.push('/done/newSubscription'+this.state.subscription.currentPlanId)
      this.setState({isLoading: false})

    }catch (e) {
      handleError(e)
    } finally {
      this.setState({isLoading: false})
    }

  }
  
  cancelPrompt = () => {
    this.setState({
      paymentLoading: false,
      paymentMessage: '',
      
      doPrompt: false,
      selectedPlan: false,
    })
  }
  
  loadPaymentForm = () => {
    this.setState({
      loading: true,
      doPrompt: 2,
      paymentIntent: 1
    })
  }
  paymentScriptLoaded = () => {
    this.setState({
      paymentScriptLoaded: true
    })
  }
  
  render() {
    if(this.state.initialLoading < 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <CustomerLoading />
        </div>
      )

    else if (this.state.initialLoading > 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <GlobalError />
        </div>
      )

    return (
      <div className="body" id="customer-membership">
        {this.state.doPrompt ?
          <div className="global-overlay">
            <div className="global-overlay-tapcapture global-overlay-tapcapture-grey" onClick={this.cancelPrompt}>&nbsp;</div>
              {this.state.doPrompt===1 ? // Switch prompt
                <SwitchPlanModal
                  doPrompt={this.state.doPrompt}
                  currentPlan={this.state.currentPlan}
                  selectedPlan={this.state.selectedPlan}
                  subscription={this.state.subscription}
                  isLoading={this.state.isLoading}
                  cancelPrompt={this.cancelPrompt}
                  switchPlan={this.switchPlan}
                /> : null}
              {this.state.doPrompt===2 ? // Payment Form - for new or changing payment info
                <div className="global-overlay-modal">
                  <div className="plan-prompt">
                    <div className="plan-payment-top">
                      <div className="plan-payment-title">Billing Information</div>
                    </div>
                    <div className="plan-payment-info">
                      <div className="ppi-col">
                        <div className="ppi-label">Plan</div>
                        <div className="ppi-value">{this.state.selectedPlan.planName}</div>
                      </div>
                      <div className="ppi-col">
                        <div className="ppi-label">Begins on</div>
                        <div
                          className="ppi-value">{['free', 'FREE'].includes(this.state.currentPlan.planCode) ? displayDate(new Date(), 'dd MMMM yyyy') : displayDate(convertToClientTimestamp(this.state.currentPlan.endDate), 'dd MMMM yyyy')}</div>
                      </div>
                      <div className="ppi-col">
                        <div className="ppi-label">Total</div>
                        <div className="ppi-value">${this.state.selectedPlan.planRate ?
                          (this.state.selectedPlan.planRate.toFixed(2) + (this.state.selectedPlan.planInterval === 1 ? ' monthly' : ' annually'))
                          :
                          'FREE'
                        }</div>
                      </div>
                    </div>
                    <PaymentForm onSubmit={this.handleSubmitPaymentForm} message={this.state.paymentMessage}/>
                    <div className="plan-payment-cancel btn btn-light btn-primary mar-t-10" onClick={this.cancelPrompt}>Cancel</div>
                  </div>
                </div> : null}
              {this.state.doPrompt===5 ?
                <CancelSubscriptionModal
                  doPrompt={this.state.doPrompt}
                  subscription={this.state.subscription}
                  cancelPrompt={this.cancelPrompt}
                /> : null}
          </div> : null
        }
        <Script url="https://js.recurly.com/v4/recurly.js" onLoad={this.paymentScriptLoaded.bind(this)} />
        <CustomerSidebar />
        <div className="content-area">
          <div className="plans-wrapper" style={{ width: "100%" }}>
            <GlobalNotices { ...this.props }/>
            <div className="page-title"><h2>Membership Plans</h2></div>
            <SubscriptionInformation
              currentPlan={this.state.currentPlan}
              subscription={this.state.subscription}
            />
            <Plans
              plans={this.state.plans}
              subscription={this.state.subscription}
              selectPlan={this.selectPlan}
            />
          </div>
          <div className="plan-clarification">
            <span>* For your information, all our pricing is denominated in U.S. Dollars (USD).</span>
            <br/>
            <span>* Please be aware that for certain countries, additional Goods and Services Tax (GST) may be applied in accordance with governmental regulations.</span>
          </div>
        </div>
        <GlobalFooter />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withGoogleReCaptcha(Plan)))
