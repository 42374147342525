import React, {Component}   from 'react'
import {connect}            from 'react-redux'
import {Link, withRouter}   from 'react-router-dom'
import axios                from 'axios/index'
import {warehouses}  from '../common/constants'
import GlobalFooter         from '../components/GlobalFooter'
import GlobalError          from '../components/GlobalError'
import GlobalNotices        from '../components/GlobalNotices'
import CustomerSidebar      from '../components/CustomerSidebar'
import CustomerAddresses    from '../components/CustomerAddresses'
import CustomerLoading      from '../components/CustomerLoading'
import Notification from '../components/ui/Notification'
import TagManager from "react-gtm-module";

const mapStateToProps = (globalStore) => {return {store: globalStore}}
const mapDispatchToProps = (dispatch) => {return {
  logout: ()=>{dispatch({type: 'CUSTOMER_LOGOUT'})},
  updateUserConfig: (userConfig) => {dispatch({type: 'UPDATE_USER_CONFIG', payload: userConfig})}
}}

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      initialLoading: 0,
      initialLoadingError: '',

      customer: {
        customerId: '',
        name: '',
        email: '',
        phone: '',
        country: '',
        loyalty: '',
        plan: ''
      },
      packages: [],
      aprs: [],
      aprsPending: 0
    }
  }
  
  componentDidMount() {
    if(this.props.store.isFromRegister) {
      this.props.history.push('/done/registered')
      return; 
    }

    this.getCustomerData()
    this.handleGetUserConfigData()
    this.handleCjPageType()
  }

  handleCjPageType = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'cj_page_view',
        pagePath: this.props.location.pathname,
        pageType: 'accountCenter',
      },
      dataLayerName: 'dataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  getCustomerData = () => {
    this.setState({initialLoading: 1})
    
    axios.get('/customer/getCustomer')
    .then((response) => {
      this.setState({
        initialLoading: 2,
        initialLoadingError: '',
        customer: response.data.customer,
        packages: response.data.packages,
        aprs: response.data.aprs,
        aprsPending: response.data.aprs.filter(a=>{return a.status===2}).length
      })
    })
    .catch((error) => {
      this.setState({
        initialLoading: 3,
        initialLoadingError: error.response ? error.response.data.message : error.message
      })
    })
  }

  handleGetUserConfigData = async () => {
    const userConfigRes = await axios.get('/userConfig')

    this.props.updateUserConfig(userConfigRes.data.userConfig)
  }
  
  render() {    

    if(this.state.initialLoading < 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <CustomerLoading />
        </div>
      )
    else if (this.state.initialLoading > 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <GlobalError />
        </div>
      )
    
    return (
      <div className="body" id="customer-home">
        <CustomerSidebar />
        <div className="content-area">
          <GlobalNotices { ...this.props }/>
          <div className="page-title">
            <h2>Dashboard</h2>
          </div>
          <CustomerAddresses customer={this.state.customer}/>
          <div className="card card-white card-address--details">
            <h4 className="mb-4 pb-3 mt-2">Welcome back, {this.state.customer.name}!</h4>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <Link to="/packages" className="card card-outline mb-4 p-3 align-items-center flex-sm-row c-box-media dash-section-links">
                  <div className="mr-sm-3 mb-1 mb-sm-0 rounded-circle c-box-icon"><img src="assets/images/package.svg" alt="my package"/></div>
                  <div className="c-box-body">
                    <h5>My Packages</h5>
                    <p>You currently have <strong>{this.state.packages.length ? this.state.packages.length : 'no'}</strong> package{this.state.packages.length!==1 && 's'} in your Reship mailbox.</p>
                    <div className="dashboard-packages-badges">
                      {warehouses.map(warehouse=>{
                        let packagesInThisWarehouse = this.state.packages.filter(p=>{return p.warehouse===warehouse.id}).length
                        if(packagesInThisWarehouse){
                          return (
                            <div className="dashboard-packages-badge" key={'w'+warehouse.id}>
                              <div className="dashboard-packages-country-count"><span>{packagesInThisWarehouse}</span> at {warehouse.name}</div>
                              <div className="dashboard-packages-country-flag"><img src={`assets/images/${warehouse.flag}`} alt="flag" /></div>
                            </div>
                          )
                        }
                        else return false
                      })}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-sm-12">
                <Link to="shipments" className="card card-outline mb-4 p-3 align-items-center flex-sm-row c-box-media">
                  <div className="mr-sm-3 mb-1 mb-sm-0 rounded-circle c-box-icon"><img src="assets/images/shipments.svg" alt="My Shipments"/></div>
                  <div className="c-box-body">
                    <h5>My Shipments</h5>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <Link to="/my-plan" className="card card-outline mb-4 p-3 align-items-center flex-sm-row c-box-media">
                  <div className="mr-sm-3 mb-1 mb-sm-0 rounded-circle c-box-icon"><img src="assets/images/membership.svg" alt="Membership Plan"/></div>
                  <div className="c-box-body">
                    <h5>Membership Plan</h5>
                    {this.state.customer.plan!=='Free' && <p>{this.state.customer.plan}</p>}
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <Link to="/my-account" className="card card-outline mb-4 p-3 align-items-center flex-sm-row c-box-media">
                  <div className="mr-sm-3 mb-1 mb-sm-0 rounded-circle c-box-icon"><img src="assets/images/account.svg" alt="My Account"/></div>
                  <div className="c-box-body">
                    <h5>My Account</h5>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <Link to="/my-addresses" className="card card-outline mb-4 p-3 align-items-center flex-sm-row c-box-media">
                  <div className="mr-sm-3 mb-1 mb-sm-0 rounded-circle c-box-icon"><img src="assets/images/address.svg" alt="Addresses"/></div>
                  <div className="c-box-body">
                    <h5>Shipping Addresses</h5>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <Link to="/my-history" className="card card-outline mb-4 p-3 align-items-center flex-sm-row c-box-media">
                  <div className="mr-sm-3 mb-1 mb-sm-0 rounded-circle c-box-icon"><img src="assets/images/invoice.svg" alt="Invoices"/></div>
                  <div className="c-box-body">
                    <h5>Invoice History</h5>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <img style={{display:'none', width: 1, height: 1}} src="https://www.facebook.com/tr?id=1595109630550383&ev=PageView&noscript=1" alt="" />
        </div>
        <GlobalFooter />
        <Notification {...this.props}/>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard))
