import React, {Component, Fragment} from 'react'
import { PageHeader } from 'antd'
import {Link, withRouter}     from 'react-router-dom'
import {connect}              from 'react-redux'
import axios           from 'axios/index'
import {Helmet}        from 'react-helmet'
import {consolidationStatus, parseStatus, weightImperial, dimensionsImperial, warehouseData, restrictionStatus, carrierLabel}
                       from '../common/constants'
import {countryText, regionText}
                       from '../common/handleAddress'
import GlobalFooter    from '../components/GlobalFooter'
import GlobalError     from '../components/GlobalError'
import CustomerSidebar from '../components/CustomerSidebar'
import CustomerLoading from '../components/CustomerLoading'
import ButtonLoading   from '../components/ButtonLoading'
import TrackingLink    from '../components/TrackingLink'
import ItemsDeclarationForm from '../components/ItemsDeclarationForm'
import { convertToClientTimestamp, displayDate } from '../common/utils'
import PhotoModal from '../components/PhotoModal'
import RequestPhotoModal from "../components/RequestPhotoModal";
const mapStateToProps = (globalStore) => {return {store: globalStore}}
const mapDispatchToProps = (dispatch) => {return {logout: ()=>{dispatch({type: 'CUSTOMER_LOGOUT'})}}}

class Shipment extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      initialLoading: 0,
      initialLoadingError: '',
      loading: false,
      message: '',
      
      titleEdit: false,
      notesEdit: false,

      packageTitle: '',
      packageNotes: '',
      
      package: {},
      items: [],
      fees: {},
      
      itemDescription: '',
      itemQuantity: '',
      itemValue: '',
      
      photoView: false,
      photoModal: false,
      photoNotes: '',
      
      consolidationModal: false,

      dimensionType: 'in',
      declaredItems:[],
      editContent: false,
    }
  }
  
  componentDidMount() {
    if(this.props.store.isLoggedIn && this.state.initialLoading===0)
      this.getPackageData()
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.store.isLoggedIn && this.state.initialLoading===0)
      this.getPackageData()
  }
  
  getPackageData = () => {
    this.setState({initialLoading: 1})

    const params = new URLSearchParams(this.props.location.search)
    const packageId = params.get('pkg')
    
    axios.get('/customer/packages/getPackage/'+packageId)
    .then((response) => {      
      this.setState({
        initialLoading: 2,
        initialLoadingError: '',
        package: response.data.package,
        items: response.data.items,
        packageTitle: response.data.package.customerTitle || '',
        packageNotes: response.data.package.customerNotes || '',
        fees: response.data.fees,
        status: parseStatus(response.data.package),
        message: ''
      })
    })
    .catch((error) => {
      if(error.response && error.response.status===403){
        this.props.logout()
        this.props.history.push('/login')
      }
      else {
        this.setState({
          initialLoading: 3,
          initialLoadingError: error.response ? error.response.data : error
        })
      }
    })
  }
  
  doSaveInfo = () => {
    let postData = {
      packageId: this.state.package.packageId,
      customerTitle: this.state.packageTitle,
      customerNotes: this.state.packageNotes,
    }
    axios.post('/customer/packages/updateInfo', postData)
    .then((response) => {
      if(!response.data.success)
        this.setState({loading: false, message: 'Request failed.'})
      else {
        this.setState({
          loading: false,
          message: '',
          titleEdit: false,
          notesEdit: false,
          package: {
            ...this.state.package,
            customerTitle: this.state.packageTitle,
            customerNotes: this.state.packageNotes
          }
        })
      }
    })
    .finally(() => {
      this.setState({ loading: false })
    })
  }
  doCancelSave = () => {
    this.setState({
      loading: false,
      titleEdit: false,
      notesEdit: false,
      packageTitle: this.state.package.customerTitle,
      packageNotes: this.state.package.customerNotes,
    })
  }
  
  doPhotoView = (index) => {
    this.setState({photoView: index})
  }
  closePhotoView = () => {
    this.setState({photoView: false})
  }
  doRequestPhotosStart = () => {
    this.setState({
      photoModal: 1
    })
    /* Open photo modal, take notes on photo, submit notes. Backend should prepend 'Requested <date time>. Notes: '
      Photo modal - if notes exist...
        if not completed...
          We have received your request for additional photos and will obtain them within 1 business day. You will receive an email when the pictures are available to be viewed here.
          You can add/edit notes here (state should have been set spliced after first period to leave out prepend) We will try to accomodate your requests although last minute changes may be missed.
        if completed...
          We have taken the additional photos you requested. Should they not meet your satisfaction or you desire more, please contact us.
      If no notes...
        Should you require more photographs of the products in your package (e.g. specific angles, close-ups), we are happy to take and provide you with more pictures for a nominal fee (<fee>).
        Please include any specific picture-taking instructions below and we'll try our best to accomodate them.
    */
  }
  doSubmitRequestPhotos = async () => {
    try {
      this.setState({loading: true})
      const postData = {
        packageId: this.state.package.packageId,
        photoNotes: this.state.photoNotes
      }

      const response = await axios.post('/customer/packages/reqPhotos', postData)
      if (!response.data.success)
        this.setState({loading: false, message: 'Request failed.'})
      else {
        this.setState({
          loading: false,
          message: '',
          photoNotes: '',
          photoModal: 2,
          package: {
            ...this.state.package,
            locked: true,
            reqPhotographs: true,
            reqPhotographsComplete: false,
          }
        })
      }
    } catch (error) {
      this.setState({loading: false, message: error.response ? error.response.data.message : error.message})
    }
  }
  closePhotoModal = () => {
    this.setState({
      photoModal: false,
      loading: false,
      message: '',
      photoNotes: ''
    })
  }
  
  doCancelConsolidation = () => {
    this.setState({
      loading: true,
      message: ''
    })
    
    let postData = {
      packageId: this.state.package.packageId
    }
    axios.post('/customer/consolidation/cancel', postData)
    .then((response) => {
      this.setState({
        loading: false,
        consolidationModal: 2
      })
    })
    .catch((error) => {
      this.setState({
        loading: false,
        consolidationModal: 3,
        message: error.response ? error.response.data.message : error.message
      })
    })
  }
  closeConsolidationModal = () => {
    this.setState({
      consolidationModal: false,
      loading: false,
      message: ''
    })
  }
  exitConsolidation = () => {
    this.props.history.replace('/packages')
  }

  savedDeclaredItem = (items) => {
    this.setState({
      declaredItems: items
    })
  }

  setEdit = (item) => {
    this.setState({
      editContent: item
    })
  }


  render() {    
    if(this.state.initialLoading < 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <CustomerLoading />
        </div>
      )
    else if (this.state.initialLoading > 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <GlobalError error={this.state.initialLoadingError} />
        </div>
      )

      const params = new URLSearchParams(this.props.location.search)
      const pkgId = params.get('pkg')

    return (
      <div className="body" id="package">
        <CustomerSidebar />
        {!!this.state.package.customerTitle && <Helmet><title>Package #{pkgId} ({this.state.package.customerTitle}) | Reship</title></Helmet>}
        <div className="content-area">
          
          <Link className="page-title d-flex align-items-center mb-3" to={this.state.package.shipped ? '/shipments' : '/packages'}>
            <div className="page-title-back mr-2" ><i className="material-icons primary-color">arrow_back</i></div>
            <h3>{this.state.package.shipped ? 'My Shipments' : 'My Packages'}</h3>
          </Link>
          <div className="card row no-gutters align-items-stretch card-white flex flex-row">
            <div className="package-left">
              <div className="package-data-header">
                {this.state.titleEdit ?
                  <div className="package-title">
                    <PhotoModal packageInfo={this.state.package} />
                    <div className="package-title-subtitle">{this.state.package.type===1 ? 'Consolidation' : 'Package'} #{this.state.package.packageId.toString().padStart(6, '0')}</div>
                    <div className="package-title-edit">
                      <input className="package-title-input" type="text" maxLength="45" value={this.state.packageTitle} onChange={(e)=>{this.setState({packageTitle: e.target.value})}} />
                      <div className="package-title-icon" onClick={this.doSaveInfo}><i className="fas fa-check-circle" /></div>
                      <div className="package-title-icon" onClick={this.doCancelSave}><i className="fas fa-times-circle" /></div>
                    </div>
                  </div>
                  :
                  (this.state.package.customerTitle ?
                    <div className="package-title">
                      <PhotoModal packageInfo={this.state.package} />
                      <div className="package-title-subtitle">{this.state.package.type===1 ? 'Consolidation' : 'Package'} #{this.state.package.packageId.toString().padStart(6, '0')}</div>
                      <div className="package-title-title pointer">
                        {this.state.package.customerTitle}
                        <div className="package-title-edit-icon" onClick={()=>{this.setState({titleEdit: true})}}><i className="fas fa-pencil-alt" /></div>
                      </div>
                    </div>
                    :
                    <div className="package-title">
                      <PhotoModal packageInfo={this.state.package} />
                      <div className="package-title-title">
                        {this.state.package.type===1 ? 'Consolidation' : 'Package'} #{this.state.package.packageId.toString().padStart(6, '0')}
                      </div>
                      <div className="package-title-subtitle pointer" onClick={()=>{this.setState({titleEdit: true})}}>+ Give package a nickname/reference</div>
                    </div>
                  )
                }
              </div>
              <div className="package-process">
                <small>{this.state.package.status}</small>
                <div className="package-process-row form-row">
                  <div className="col"><div className={`package-step ${this.state.status.status > 0 ? (this.state.status.status > 9 ? (this.state.status.status > 39 ? 'step-green' : 'step-complete') : 'step-incomplete') : ''}`}>&nbsp;</div></div>
                  <div className="col"><div className={`package-step ${this.state.status.status > 10 ? (this.state.status.status > 19 ? (this.state.status.status > 39 ? 'step-green' : 'step-complete') : 'step-incomplete') : ''}`}>&nbsp;</div></div>
                  <div className="col"><div className={`package-step ${this.state.status.status > 20 ? (this.state.status.status > 29 ? (this.state.status.status > 39 ? 'step-green' : 'step-complete') : 'step-incomplete') : ''}`}>&nbsp;</div></div>
                </div>
                {(this.state.package.consolidation && this.state.package.consolidationStatus===1) && <small className="package-process-cancel" onClick={()=>{this.setState({consolidationModal: 1})}}><i className="material-icons">close</i> Cancel consolidation</small>}
              </div>
              <div className="row flex-wrap justify-content-start package-metadata">
                <div className="col-auto mt-2">
                  <b>Warehouse</b>
                  <p>{warehouseData(this.state.package.warehouse).name}</p>
                </div>
                <div className="col-auto mt-2">
                  <b>Weight</b>
                  {this.state.package.physical.weight ?
                    <p>{weightImperial(this.state.package.physical.weight)} lb / {this.state.package.physical.weight.toFixed(2)} kg</p>
                    :
                    <p>TBD</p>
                  }
                </div>
                <div className="col-auto mt-2">
                  <div className='flex justify-content-lg-start align-content-center'>
                    <b>Dimensions in</b>
                    <div className='flex'>
                      <button className={this.state.dimensionType === 'in' ? 'btn btn-primary' : 'btn btn-grey'} style={{width: '30px', height: '20px', margin: '0 3px'}} onClick={() => this.setState({dimensionType: 'in'})}>In</button>
                      <b>/</b>
                      <button className={this.state.dimensionType === 'cm' ? 'btn btn-primary' : 'btn btn-grey'} style={{width: '30px', height: '20px', margin: '0 3px'}} onClick={() => this.setState({dimensionType: 'cm'})}>Cm</button>
                    </div>

                  </div>

                  {this.state.package.physical.length ?
                    <>
                    {this.state.dimensionType === 'in' &&
                      <p>in: {dimensionsImperial(this.state.package.physical.length)} (L) x {dimensionsImperial(this.state.package.physical.width)} (W) x {dimensionsImperial(this.state.package.physical.height)} (H)</p>}
                    {this.state.dimensionType === 'cm' &&
                      <p>cm: {this.state.package.physical.length.toFixed(1)} (L) x {this.state.package.physical.width.toFixed(1)} (W) x {this.state.package.physical.height.toFixed(1)} (H)</p>}
                    </>
                    :
                    <p>TBD</p>
                  }
                </div>
              </div>
            </div>
            <div className="page-title flex-column align-items-start">
           
            {this.state.page === 31 && <PageHeader title={<div>Return Package</div>}/>}
            {(this.state.page > 3 && this.state.page !== 31 && !this.state.returnOption) && <PageHeader title={<div> Review Shipment </div>} onBack={this.goBack}/>}
            {(this.state.page > 3 && this.state.page !== 31 && this.state.returnOption) && <PageHeader title={<div> Review Shipment </div>} onBack={() => {this.setState({page: 31})}}/>}
          </div>
            <div className="package-right">
              {
                !!this.state.package?.manageInsuranceUrl 
                && 
                <button 
                  className="btn btn-sm mb-2 ml-sm-3 btn-primary" 
                  onClick={() => window.open(this.state.package.manageInsuranceUrl, '_blank')}
                  style={{ backgroundColor: '#36454F'}}
                >
                  <i className="material-icons">shield</i> Manage Insurance Claim
                </button>
              }
            </div>
          </div>
          {(!this.state.package.shipped && !!this.state.package.restrictDomesticOnly && this.state.package.restricted !== 27) &&
            <div className="notice notice-warn">
              <div className="notice-icon">⚠</div>
              <div className="notice-content">Due to export restrictions, this package can only be shipped within the same country.</div>
            </div>
          }
          {(!this.state.package.shipped && !!this.state.package.restrictGroundOnly && this.state.package.restricted !== 26) &&
            <div className="notice notice-warn">
              <div className="notice-icon">⚠</div>
              <div className="notice-content">Due to carrier restrictions, this package can only be shipped via surface delivery methods.</div>
            </div>
          }
          {(!this.state.package.shipped && !!restrictionStatus(this.state.package.restricted)) &&
            <div className="notice notice-warn">
              <div className="notice-icon">⚠</div>
              <div className="notice-content">{restrictionStatus(this.state.package.restricted)}</div>
            </div>
          }
          {this.state.package.consolidationStatus!==1 &&
            <ItemsDeclarationForm pkg={this.state.package} items ={this.state.items} getPackageData={this.getPackageData} saveAction={this.savedDeclaredItem} setEdit={this.setEdit} closeAction={()=>{}} saveCustoms={() => {}}/>
          }
          {this.state.package.consolidation ?
            <div className="card card-info--title card-white">
              <div className="package-declaration w-100">
                <h5>Packages in Consolidation</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Source Package</th>
                      <th scope="col">Vendor</th>
                      <th scope="col">Received On</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.package.subpackages.sort((a,b)=>{return a.packageId-b.packageId}).map(subpackage=>{
                      return (
                        <tr key={subpackage.packageId}>
                          <td>#{subpackage.packageId.toFixed().padStart(6,'0')}{!!subpackage.customerTitle && ' '+subpackage.customerTitle}</td>
                          <td>{subpackage.vendor}</td>
                          <td>{displayDate(convertToClientTimestamp(subpackage.received), 'dd MMMM yyyy')}</td>
                          <td>{consolidationStatus(subpackage.status).text}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            :
            <div className="card row no-gutters card-info--title card-white align-items-start">
              <div className="package-declaration w-100">
                <h5>Incoming Information</h5>
                <div className="row flex-wrap justify-content-start">
                  <div className="col-auto mt-2">
                    <b>Received On</b>
                    <p>{displayDate(convertToClientTimestamp(this.state.package.incoming.received), "d MMM yyyy")}</p>
                  </div>
                  <div className="col-auto mt-2">
                    <b>Store/Vendor</b>
                    <p>{this.state.package.incoming.vendor}</p>
                  </div>
                  <div className="col-auto mt-2">
                    <b>Carrier</b>
                    <p>{carrierLabel(this.state.package.incoming.carrier)}</p>
                  </div>
                  <div className="col-auto mt-2">
                    <b>Tracking #</b>
                    <p>{<TrackingLink trackingLink={this.state.package.incoming.trackingLink} tracking={this.state.package.incoming.tracking}/>}</p>
                    
                  </div>
                  {!!this.state.package.aprId &&
                    <div className="col-auto mt-2">
                      <b>APR Order #</b>
                      <p><Link to={"/my-apr/"+this.state.package.aprId}>{this.state.package.aprId.toString().padStart(6, '0')}</Link></p>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
          {this.state.package.shipped &&
            <div className="card card-info--title card-white">
              <div className="package-declaration w-100">
                <h5>Outgoing Information</h5>
                {/*!!this.state.package.shipment.apiTrackingId &&
                  <div className="cp-shipping-tracker">
                    Shipping tracker graphic from easypost
                  </div>
                */}
                <div className="row flex-wrap justify-content-start">
                  {this.state.package.shipment.shipped ?
                    <div className="col-auto mt-2">
                      <b>Shipped on</b>
                      <p>{displayDate(convertToClientTimestamp(this.state.package.shipment.shippedOn), 'dd MMMM yyyy')}</p>
                    </div>
                    :
                    <div className="col-auto mt-2">
                      <b>Shipment Submitted</b>
                      <div className="cp-data-value">{displayDate(convertToClientTimestamp(this.state.package.shipment.orderedOn), 'dd MMMM yyyy')}</div>
                    </div>
                  }
                  <div className="col-auto mt-2">
                    <b>Carrier</b>
                    <div className="cp-data-value">{carrierLabel(this.state.package.shipment.carrier)}</div>
                  </div>
                  <div className="col-auto mt-2">
                    <b>Service</b>
                    <div className="cp-data-value">{this.state.package.shipment.service}</div>
                  </div>
                  <div className="col-auto mt-2">
                    <b>Tracking #</b>
                    <div className="cp-data-value">{this.state.package.shipment.tracking ? <TrackingLink trackingLink={this.state.package.shipment.trackingLink} tracking={this.state.package.shipment.tracking}/> : 'Not yet available'}</div>
                  </div>
                  {this.state.package.shipment.destination &&
                    <div className="col-auto mt-2">
                      <b>Destination</b>
                      <div className="cp-address">
                        {this.state.package.shipment.destination.name}
                        <br/>{this.state.package.shipment.destination.street1}
                        {!!this.state.package.shipment.destination.street2 && <Fragment><br/>{this.state.package.shipment.destination.street2}</Fragment>}
                        <br/>{this.state.package.shipment.destination.city}
                        <br/>{regionText(this.state.package.shipment.destination.countryCode, this.state.package.shipment.destination.stateCode, true)} {this.state.package.shipment.destination.postalCode}
                        <br/>{countryText(this.state.package.shipment.destination.countryCode, true)}
                      </div>
                    </div>
                  }
                  {this.state.package.shipment.receipt &&
                    <div className="col-auto mt-2">
                      <b>Receipt</b>
                      <div className="cp-data-value"><Link to={`/invoice/${this.state.package.shipment.receipt}`}>Invoice #{this.state.package.shipment.receipt.toFixed().padStart(6,'0')}</Link></div>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
          {!!this.state.message && <div className="error-text">{this.state.message}</div>}

          <div className="card row no-gutters align-items-stretch card-white flex flex-row">
            <div className="row flex-wrap justify-content-start package-metadata">
              {this.state.notesEdit ?
                <div className="col-auto mt-2 width-100">
                  <div className="flex flex-wrap align-items-center justify-content-start">
                    <b>Notes</b>
                    <div className="package-notes-icon" onClick={this.doSaveInfo}><i className="fas fa-check-circle" /></div>
                    <div className="package-notes-icon" onClick={this.doCancelSave}><i className="fas fa-times-circle" /></div>
                  </div>
                  <textarea className="form-control wide" value={this.state.packageNotes} onChange={(e)=>{this.setState({packageNotes: e.target.value})}} />
                </div>
                :
                <div className="package-notes-wrapper width-100">
                  <div className="flex flex-wrap align-items-center justify-content-start">
                    <b>Notes</b>
                    <div className="package-notes-icon" onClick={()=>{this.state.package.locked ? this.setState({notesEdit: false}) : this.setState({notesEdit: true})}}><i className="fas fa-pencil-alt" /></div>
                  </div>
                  <p className="package-notes">{this.state.packageNotes ? this.state.packageNotes : 'None'}</p>
                </div>
              }
            </div>
          </div>
          {!!this.state.photoModal &&
            <div className="global-overlay">
              <div className="global-overlay-tapcapture global-overlay-tapcapture-grey" onClick={this.closePhotoModal}>&nbsp;</div>
              {this.state.photoModal===1 &&
                <div className="global-overlay-modal package-request-form">
                  <div className='flex justify-content-between'>
                    <div className="package-request-title">Need more photographs?</div>
                    <i className="package-request-title-icon material-icons" onClick={this.closePhotoModal}>close</i>
                  </div>

                  <div className="package-request-text">
                    <p>Reship is happy to offer package photography services for a nominal fee of ${this.state.fees.photo.toFixed(2)} / 3 photos.</p>
                    {/* <p>Our in-house photographer will take high-resolution close-up lightbox photographs of each item in your package from every angle that you'll be able to view and download here within 1 business day.</p>
                    <p>Specific picture-taking requests? Include them in the comments box below and we'll try our best to accomodate them!</p> */}
                  </div>
                  <textarea placeholder="Comments, requests, etc." className="form-control wide" id="package-request-input" value={this.state.photoNotes} onChange={(e)=>{this.setState({photoNotes: e.target.value})}} />
                  {!!this.state.message && <div className="error-text">{this.state.message}</div>}
                  <button className="btn w-100 btn-blue flex flex-row justify-content-center align-items-center mb-2" onClick={this.doSubmitRequestPhotos} disabled={this.state.loading}>Submit Request</button>
                </div>
              }
              {this.state.photoModal===2 &&
                <div className="global-overlay-modal package-request-form">
                  <div className="package-request-title">Request received!</div>
                  <div className="package-request-text"><p>We'll get right on it and email you when it's done!</p></div>
                  <button className="btn w-100 btn-primary flex flex-row justify-content-center align-items-center mb-2" onClick={this.closePhotoModal}><i className="material-icons">done</i></button>
                </div>
              }
            </div>
          }
          {!!this.state.consolidationModal &&
            <div className="global-overlay">
              <div className="global-overlay-tapcapture global-overlay-tapcapture-grey" onClick={this.state.consolidationModal===2 ? this.exitConsolidation : this.closeConsolidationModal}>&nbsp;</div>
              {this.state.consolidationModal===1 &&
                <div className="global-overlay-modal package-request-form">
                  <div className="package-request-title">Cancel Consolidation Request?</div>
                  <div className="package-request-text">
                    <p>This will cancel the package consolidation request and make the individual packages available for other consolidations or shipping. You will not be charged.</p>
                  </div>
                  <button className="btn w-100 btn-blue flex flex-row justify-content-center align-items-center mb-2 mt-3" onClick={this.state.loading ? ()=>{} : this.doCancelConsolidation}>{this.state.loading ? <ButtonLoading /> : 'Cancel Consolidation'}</button>
                  <button className="btn w-100 btn-light" onClick={this.closeConsolidationModal}>Close</button>
                </div>
              }
              {this.state.consolidationModal===2 &&
                <div className="global-overlay-modal package-request-form">
                  <div className="package-request-title">Consolidation Request Cancelled</div>
                  <button className="btn w-100 btn-green flex flex-row justify-content-center align-items-center mb-2" onClick={this.exitConsolidation}><i className="material-icons">done</i></button>
                </div>
              }
              {this.state.consolidationModal===3 &&
                <div className="global-overlay-modal package-request-form">
                  <div className="package-request-text"><p>Sorry, we could not cancel your consolidation. {this.state.message}</p></div>
                  <button className="btn w-100 btn-light" onClick={this.closeConsolidationModal}>Close</button>
                </div>
              }
            </div>
          }
        </div>
        <GlobalFooter />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Shipment))
