import React from 'react'
import {Link} from "react-router-dom";
import {addressCharLimits} from "../common/handleAddress";
import ButtonLoading from "./ButtonLoading";
import ModalAsync from "./ui/ModalAsync";

const ConsolidationModal = ({
  consolidationMode,
  conDiscard,
  consolidation,
  conPadding,
  consolidationPackages,
  conRequest,
  conCost,
  conOptionsCost,
  message,
  loading,
  setState,
  remOption,
  addOption,
  handleSubmitConsolidationRequest,
  cancelConsolidation
}) => {

  const content = (
    <div style={{padding: '0 10px'}}>
      <div className="modal-cons-toggle">
        <div className={"modal-cons-toggle-box"+(conDiscard ? '' : ' modal-cons-active')} onClick={conDiscard ? ()=>{setState({conDiscard: false})} : ()=>{}}>
          <div className="modal-cons-toggle-select"><i className="material-icons">{conDiscard ? 'check_box_outline_blank' : 'check_box'}</i></div>
          <div className="modal-cons-toggle-icon"><img src="/assets/images/consolidate-keep.png" alt="" style={{width: 44}} /></div>
          <div className="modal-cons-toggle-r">
            <div className="modal-cons-toggle-title">Keep original boxes</div>
            <div className="modal-cons-toggle-text">Box packages together without repacking</div>
          </div>
        </div>
        <div className={"modal-cons-toggle-box"+(conDiscard ? ' modal-cons-active' : '')} onClick={conDiscard ? ()=>{} : ()=>{setState({conDiscard: true})}}>
          <div className="modal-cons-toggle-select"><i className="material-icons">{conDiscard ? 'check_box' : 'check_box_outline_blank'}</i></div>
          <div className="modal-cons-toggle-icon"><img src="/assets/images/consolidate-discard.png" alt="" style={{width: 28}} /></div>
          <div className="modal-cons-toggle-r">
            <div className="modal-cons-toggle-title">Discard original boxes</div>
            <div className="modal-cons-toggle-text">Repack package contents into best fitting box</div>
          </div>
        </div>
      </div>
      <div className="modal-cons-packages">
        {consolidation.map(packageId => {
          let padSelected = conPadding.includes(packageId)
          let isConsolidation = consolidationPackages.map(p=>p.packageId).includes(packageId)
          return (
            <div className="modal-cons-pkg" key={'con'+packageId}>
              <div className="modal-cons-pkg-label">{isConsolidation ? 'Consolidation' : 'Package'} <Link to={`/package?pkg=${packageId.toString()}`}> #{packageId.toString().padStart(6, '0')} </Link> </div>
              {isConsolidation ?
                <div className="modal-cons-pkg-opts modal-cons-pkg-noopt">No options available for prior consolidations</div>
                :
                <div className="modal-cons-pkg-opts">
                  <div className={"modal-cons-pkg-opt"+(padSelected ? ' modal-cons-pkg-opt-active' : '')} onClick={padSelected ? ()=>{remOption(packageId, 'pad')} : ()=>{addOption(packageId, 'pad')}}><i className="material-icons">{padSelected ? 'check_box' : 'check_box_outline_blank'}</i> Extra Padding</div>
                </div>
              }
            </div>
          )
        })}
      </div>
      <div className="modal-cons-notes">
        <div className="modal-cons-notes-label">Comments</div>
        <textarea className="form-control modal-cons-notes-actual" onChange={(e) => setState({conRequest: e.target.value})} value={conRequest} maxLength={addressCharLimits.notes} />
      </div>
      <div className="modal-cons-charges">
        <div className="modal-cons-charge-label">Consolidation Fee</div>
        <div className="modal-cons-charge-amt">${conCost.toFixed(2)}</div>
      </div>
      {!!conOptionsCost &&
        <div className="modal-cons-charges">
          <div className="modal-cons-charge-label">Options</div>
          <div className="modal-cons-charge-amt">${conOptionsCost.toFixed(2)}</div>
        </div>
      }
      {!!message && !loading && <div className="payment-message">{message}</div>}
    </div>
  )

  const footer = (
    <button className="btn btn-primary mr-3" disabled={!!loading} onClick={handleSubmitConsolidationRequest}>{loading ? <ButtonLoading/> : 'Confirm'}</button>
  )

  return (
    <>
      {consolidationMode===2 &&
        <ModalAsync
          title="Consolidate"
          visible={consolidationMode===2}
          setVisible={cancelConsolidation}
          children={content}
          footer={footer}
        />
      }
    </>
  )
}

export default ConsolidationModal