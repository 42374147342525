import React, { useEffect, useState } from 'react';
import axios from 'axios'

function EmailUnsubscribe() {
  const [resub, setResub] = useState(false);
  const [hideNoEmail, setHideNoEmail] = useState(false);
  const [emailID, setEmailID] = useState('');
  const [url, setUrl] = useState('')

  const resubscribe = async () => {
    const urlPost = url

    axios.post(urlPost)
    .then((response) => {
      if (response.status === 200) {
        setResub(true);
      } else {
        alert('Error in Resubscribing. Please try again.');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Error in Resubscribing. Please try again.');
    });
  }

  useEffect(() => {
    const url = window.location.href;
    try {
      const email_id = decodeURIComponent(
        url.split('?')[1].split('&')[0].split('=')[1]
      );
      setHideNoEmail(false);
      setEmailID(email_id);
    } catch (err) {
      setHideNoEmail(true);
    }

    const urlPost = url + '&resub=1';
    setUrl(urlPost)

  }, []);

  return (

    <div>
      <div className="blank-order text-center">
        <div className="bl-card-image">
          <img src="/assets/images/logo.png" alt="" />
        </div>
        <div className="container">
            <h2
              className="md-title"
              style={{
                textAlign: 'center',
                color: '#6b6b6b',
              }}
            >
              {resub === false ? (
                'You have successfully unsubscribed.'
              ) : (
                'You have been resubscribed successfully!'
              )}
            </h2>
            
            {
              resub === false ? (
                <p
                  className="block-unsubscribe"
                  style={{
                    color: '#6b6b6b',
                    textAlign: 'center',
                    padding: '0px !important',
                  }}
                >
                As per your request,{' '}
                <b>{emailID === 'you' ? 'you' : emailID}</b>{' '}
                {emailID === 'you' ? 'have' : 'has'} been permanently removed from
                our mailing list
              </p>
            ): (
              <p
              className="block-unsubscribe"
              style={{
                color: '#6b6b6b',
                textAlign: 'center',
                paddingBottom: '10px !important',
                borderBottom: '1px solid #d6d6d6',
              }}
            >
              As per your request, <b>{emailID}</b> will continue to receive
              emails from us.
            </p>
            )}
          </div>
        <br />
      </div>
    </div>
  );
}

export default EmailUnsubscribe;
