import React, { useState } from 'react';
import { Descriptions, Input } from 'antd';

function EoriForm({ value, onChange, onSubmit }) {
  const [editable, setEditable] = useState(!value);

  const handleSubmit = () => {
    onSubmit();
    setEditable(false);
  };

  const handleChange = (event) => {
    const newEoriNumber = event.target.value;
    onChange(newEoriNumber);
  };

  return (

    <div className="eori-card-form">
      <div className="eori-card-form-input">
        {(editable) ? (
          <Input
            value={value}
            placeholder="Enter EORI number"
            autoSize={{ minRows: 1, maxRows: 3 }}
            maxLength={20}
            onChange={handleChange}
          />
        ) : (
          <Descriptions bordered size="small">
            <Descriptions.Item style={{ height: '32px', backgroundColor: '#ebebeb' }}>
              {value}
            </Descriptions.Item>
          </Descriptions>
        )}
      </div>
      {editable ? (
        <div className="btn-primary btn" onClick={handleSubmit}>Submit</div>
      ) : (
        <div className="btn-primary btn" onClick={() => setEditable(true)}>Edit</div>
      )}
    </div>
  );
}

export default EoriForm;
