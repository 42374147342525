import React, {Component}     from 'react'
import {connect}              from 'react-redux'
import {Link, withRouter}     from 'react-router-dom'
import {withGoogleReCaptcha}  from 'react-google-recaptcha-v3'
import axios                  from 'axios/index'
import {Helmet}               from 'react-helmet'
import ButtonLoading          from '../components/ButtonLoading'
import RecaptchaV2            from './RecaptchaV2/recaptchaV2'
import agent from '../api/agent'

const mapStateToProps = (globalStore) => {return {store: globalStore}}


class PublicForgot extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      loading: false,
      message: '',
      page: 1,
  
      email: '',
      isRecaptcha2Required: false, 
      recaptchaV2Response: '', 
    }
  }

   handleSubmit = async () => {
    try {
      this.setState({loading: true})

      const recaptchaV3Response = await this.props.googleReCaptchaProps.executeRecaptcha('forgotPassword')

      const requestBody = {
        email: this.state.email,
        recaptchaV3Response: recaptchaV3Response,
      }

      if (this.state.isRecaptcha2Required && this.state.recaptchaV2Response) {
        requestBody.recaptchaV2Response = this.state.recaptchaV2Response
      }

      await agent.email.passwordReset(requestBody)

      this.setState({
        page: 2
      })
    } catch(e) {
      this.setState({isRecaptcha2Required: true}) 
    } finally {
      this.setState({loading: false})
    }
  }

  onVerifyRecaptchaV2 = (response) => {
    this.setState({recaptchaV2Response: response})
  }

  render() {
    return (
      <div className="card-base-inner">
        <Helmet>
          <title>Reship - Reset Password</title>
          <meta name="description" content="Log into your mailbox hub, where you can view and ship packages received at your addresses in the UK, US and Canada." />
        </Helmet>
        {this.state.page===1 &&
          <>
            <h4 className="text-center">Reset Password</h4>
            <div className="form-group form-forgot">
              <input type="email" className="form-control" placeholder="Email Address" value={this.state.email} onChange={(e)=>{this.setState({email: e.target.value})}} required={true} onKeyPress={(event)=>{if(event.charCode===13) this.handleSubmit()}} />
            </div>
            {
              this.state.loading ? 
              <button className="btn btn-block btn-primary"> { <ButtonLoading /> } </button> : <button className="btn btn-block btn-primary" onClick={this.handleSubmit}>Recover Account</button>

            }
            {this.state.message && !this.state.loading && <div className="login-message">{this.state.message}</div>}
            <button className="btn btn-block btn-outline-primary" onClick={() => this.props.history.push('/login')}>
              Cancel
            </button>
            {!!this.state.isRecaptcha2Required && (
              <div className="form-group">
                <RecaptchaV2 onVerify={this.onVerifyRecaptchaV2}/>
              </div>
            )}
          </>
        }
        {this.state.page===2 &&
          <>
            <div className="card-base-inner bottom-link text-center">We have sent you an email with a link to reset your password. Please check your inbox.</div>
            <div className="card-base-inner bottom-link text-center">
              <Link className="login-link" to="/login">Return to Login</Link>
            </div>
          </>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(withGoogleReCaptcha(PublicForgot)))
