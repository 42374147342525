import React from 'react';

const labelInfo = [
  { itemName: 'UK export fee', text: 'Due to brexit, UK intl shipments require new export docs that Reship handles for our customers. We charge a nominal fee of US$1.00 per customs item for this service.' },
];

export const getShipLineLabel = (itemName) => {
  const label = labelInfo.find((l) => l.itemName === itemName);
  if (!label) return (<div className="srr-invoice-line-label">{itemName}</div>);

  return (
    <div className="srr-invoice-line-label hoverable">
      {itemName}
      <div className="srr-invoice-line-label-popup">{label.text}</div>
    </div>
  );
};
