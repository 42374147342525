import React, {useState} from "react";

const PhotoModal = ({packageInfo}) => {
  const [showModal, setShowModal] = useState(false)
  const [index, setIndex] = useState(0)
  const maxIndex = packageInfo.photoUrls.length - 1

  const clickRight = () => {
    setIndex(index < maxIndex ? index + 1 : maxIndex )
  }

  const clickLeft = () => {
    setIndex(index === 0 ? index : index - 1)
  }

  return (
    <>
      {!!packageInfo?.photoUrls.length &&
        <div className="package-photo-new" onClick={() => {setShowModal(true)}}>
          <div className="package-photo-number"> +{packageInfo?.photoUrls.length}</div>
          <div className="package-photo-image">
            <img src={packageInfo.photoUrls[0]} alt="package photo"/>
          </div>
        </div>
      }
      {showModal &&
        <div className="global-overlay">
          <div className="global-overlay-tapcapture global-overlay-tapcapture-grey" onClick={() => {setShowModal(false)}}></div>
          <div className="global-overlay-modal photo-modal-card flex-column">
            <i className="package-request-exit" onClick={() => setShowModal(false)}><svg class="feather feather-black"><use href="/assets/images/icons/feather-sprite.svg#x"/></svg></i> 
            <div className="flex align-items-center">
              <div className="view-photo-navigation-left" style={index !== 0 ?{opacity: '100', visibility: 'visible'} : {opacity: '0', visibility: 'hidden'}} onClick={clickLeft}><i><svg class="feather feather-black"><use href="/assets/images/icons/feather-sprite.svg#chevron-left"/></svg></i> </div>
              <div className="view-photo-card">
                <div className="view-photo-img" key={`photo-${index}`}>
                  <img src={packageInfo.photoUrls[index]} alt="Package thumbnail"/>
                </div>
              </div>
              <div className="view-photo-navigation-right" style={index < maxIndex ? {opacity: '100', visibility: 'visible'} : {opacity: '0', visibility: 'hidden'}} onClick={clickRight}><i><svg class="feather feather-black"><use href="/assets/images/icons/feather-sprite.svg#chevron-right"/></svg></i></div>
            </div>
          </div>
      </div>}
    </>
  )
}

export default PhotoModal