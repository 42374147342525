import React, {Component, Fragment} from 'react'
import {connect}                    from 'react-redux'
import {Link, withRouter}           from 'react-router-dom'
import {withGoogleReCaptcha}        from 'react-google-recaptcha-v3'
import axios                        from 'axios/index'
import {Helmet}                     from 'react-helmet'
import {CjConversionCode, constants} from '../common/constants'
import ButtonLoading                from '../components/ButtonLoading'
import DropdownCountry              from '../components/DropdownCountry'
import { filterStr }                from '../common/handleAddress'
import { Steps, message }           from 'antd'
import { isEmpty }                  from 'lodash'
import RecaptchaV2                  from './RecaptchaV2'
import agent from '../api/agent'
import TagManager from "react-gtm-module";
import {InputPhoneNumber} from "./InputPhoneNumber";

const { Step } = Steps

const mapStateToProps = (globalStore) => {return {store: globalStore}}
const mapDispatchToProps = (dispatch) => {return {
  login: (loginData)=>{dispatch({type: 'CUSTOMER_LOGIN', payload: loginData})},
  customerSubscription: (transactionRecord) => {dispatch({type: 'CUSTOMER_SUBSCRIPTION', payload: transactionRecord})}
}}

class PublicRegister extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      loading: false,
      message: '',
      page: 0,
      
      email: '',
      password: '',
      name: '',
      countryCode: '',
      phone: '',
      password2: '',
      referredBy: '',
      
      recaptchaV2Response: '',
      reinitAttempted: false,

      isEmailVerificationCodeSent: false, 
      emailCode: '', 
      emailToken: '', 
      isEmailRequestDisabled: false, 

      address: {
        name: '',
        street1: '',
        street2: '',
        city: '',
        notes: '',
        
        stateCode: '',
        countryCode: '',
        postalCode: '',
        postalCodeValid: false,
        phone: '',
        phoneValid: false,
        phoneFormatted: ''
      },

      customerId: '',
      transactionRecord: {},
    }
  }
  
  componentDidMount() { 
    if(isEmpty(this.props.match.params)) {
      this.props.history.push('/plans')
    }
  }

  doProceed = () => {
    this.setState({
      page: this.state.page + 1,
      message: ''
    })
  }

  handleCodeChange = async (e) => {
    this.setState({
      emailCode: e.target.value
    })
  }

  handleValidateReferralCode = async () => {
    try { 

      if(!this.state.referredBy.length) {
        //resolves to true, but only as an escape hatch out of this function
        return true
      }

      const recaptchaV3Response = await this.props.googleReCaptchaProps.executeRecaptcha('referralCode')

      const BODY = {
        recaptchaV3Response: recaptchaV3Response
      }

      await axios.post(`/web/customer/referral/${this.state.referredBy}`, BODY)
      return true

    } catch(e) {
      return false
    } 
  }

  getEmailVerificationCode = async () => {
    try {
      this.setState({
        loading: true
      })
      const recaptchaV3Response = await this.props.googleReCaptchaProps.executeRecaptcha('verifyMobile')
      
      const requestBody = {
        recaptchaV3Response: recaptchaV3Response
      }
  
      const response = await axios.post(`/web/verify/mobile/${this.state.email}`, requestBody)
  
      const emailToken = response.data.data
  
      this.setState({
        emailToken: emailToken,
        isEmailVerificationCodeSent: true
      })
      message.info('Please check your email for your verification code')

    } finally {
      this.setState({
        loading: false
      })
    } 
  }

  verifyEmailCode = async () => {
    try {
      this.setState({
        loading: true
      })
      const recaptchaV3Response = await this.props.googleReCaptchaProps.executeRecaptcha('verifyEmail')
      
      const requestBody = {
        token: this.state.emailToken, 
        code: this.state.emailCode,
        recaptchaV3Response: recaptchaV3Response
      }
  
      const response = await axios.post('/web/verify/mobile/email', requestBody)
  
      if(response.status === 200 && response.data.success) {
        this.doProceed()
        return;
      }

    } finally {
      this.setState({
        loading: false
      })
    }
  }


  handleRegistration = async () => {
    try {
      this.setState({
        isLoading: true 
      })

      if(!this.state.recaptchaV2Response) {
        this.setState({message: 'Please click "I\'m not a robot"!'})
        return; 
      }
      
      const IS_REFERRAL_CODE_VALID = await this.handleValidateReferralCode()

      if(!this.state.password) {
        this.setState({message: 'Please enter a password.'})  
        return; 
      }

      if(this.state.password.length < 8) {
        this.setState({message: 'Your password needs to be at least 8 characters long.'})
        return;
      }

      if(this.state.password!==this.state.password2) {
        this.setState({message: 'Passwords do not match'})
        return;
      }

      if(!this.state.phoneValid && this.state.phoneFormatted) {
        this.setState({message: 'Please provide a valid phone number'})
        return; 
      }

      if(!IS_REFERRAL_CODE_VALID) {
        this.setState({message: 'Please enter a valid referral code.'})
        return;
      }
      
      const recaptchaV3Response = await this.props.googleReCaptchaProps.executeRecaptcha('verifyEmail')

      const requestBody = {
        name: this.state.name,
        email: this.state.email,
        password: this.state.password,
        countryCode: this.state.countryCode,
        phone: this.state.phoneFormatted,
        referredBy: this.state.referredBy,
        recaptchaV3Response: recaptchaV3Response,
        recaptchaV2Response:  this.state.recaptchaV2Response
      }
      
      const response = await axios.post('/customer/register', requestBody)
      this.setState({customerId: response.data.customer.customerId})
      const recaptchaV3Response2 = await this.props.googleReCaptchaProps.executeRecaptcha('welcomeEmail')

      const requestBody2 = {
        email: this.state.email, 
        recaptchaV3Response: recaptchaV3Response2,
        recaptchaV2Response:  this.state.recaptchaV2Response
      }

      await agent.email.customerWelcome(requestBody2)

      if(this.props.planData && this.props.planData.plan.planCode !== 'free') {

        const recaptchaV3Response3 = await this.props.googleReCaptchaProps.executeRecaptcha('checkoutNewPlan')

        const requestObject = {
          planId: this.props.planData.plan.planId,
          paymentToken: this.props.planData.paymentToken,
          recaptchaV2Response: this.state.recaptchaV2Response,
          recaptchaV3Response: recaptchaV3Response3,
        }

        const headers = {
          Authorization: `Bearer ${response.data.token}`
        }

        const checkoutRes =  await axios.post(`/customer/plan/checkoutNew`, requestObject, { headers: headers })
        this.setState({transactionRecord: checkoutRes.data.data})
        this.props.customerSubscription(checkoutRes.data.data)
        this.handleSubscription()
      }

      const userConfigRes = await axios.get('/userConfig')

      window.Moengage.add_unique_user_id(this.state.email)
      .then(() => {
        window.Moengage.add_email(this.state.email)
      })

      this.handleSignup()
      this.handlelogin()

      this.props.login({
        customer: response.data.customer,
        token: response.data.token, 
        isFromRegister: true,
        userConfig: userConfigRes.data.userConfig,
        transactionRecord: this.state.transactionRecord
      })

    } catch(e) {      
      this.setState({
        message: e?.response?.data?.message || 'An error occurred. Please try again later.',
        recaptchaV2Response: '',
        password: '',
        password2: '',
        page: 0
      })

    } finally {
      this.setState({ loading: false })
    }
  }

  handleSubscription = () => {
    //Push subscription event to the dataLayer
    const tagManagerArgs = {
      dataLayer: {
        event: 'cj_subscription',
        pagePath: this.props.location.pathname,
        pageTitle: 'subscription success',
        pageType: 'conversionConfirmation',
        actionTrackerId: CjConversionCode.NEW_SUBSCRIPTION,
        tId: this.state.transactionRecord.tId,
        invoiceTotal: this.state.transactionRecord.tAmount,
      },
      dataLayerName: 'dataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  handleSignup = () => {

    const utmParams = {
      utm_id: localStorage.getItem('utm_id') || '',
      utm_source: localStorage.getItem('utm_source') || '',
      utm_medium: localStorage.getItem('utm_medium') || '',
      utm_campaign: localStorage.getItem('utm_campaign') || '',
    }

    const tagManagerArgs = {
      dataLayer: {
        event: 'sign_up',
        method: 'email',
        utm_id: utmParams.utm_id,
        utm_source: utmParams.utm_source,
        utm_medium: utmParams.utm_medium,
        utm_campaign: utmParams.utm_campaign
      },
      dataLayerName: 'dataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)
  }


  handlelogin = () => {

    const utmParams = {
      utm_id: localStorage.getItem('utm_id') || '',
      utm_source: localStorage.getItem('utm_source') || '',
      utm_medium: localStorage.getItem('utm_medium') || '',
      utm_campaign: localStorage.getItem('utm_campaign') || '',
    }

    const tagManagerArgs = {
      dataLayer: {
        event: 'login',
        method: 'email',
        utm_id: utmParams.utm_id,
        utm_source: utmParams.utm_source,
        utm_medium: utmParams.utm_medium,
        utm_campaign: utmParams.utm_campaign
      },
      dataLayerName: 'dataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  updateField = (e, field) => {
    let input = e.target.value
    let filteredInput = filterStr(input)
    
    if(field==='name')
      this.setState({ address: { ...this.state.address, name: filteredInput }})
    else if(field==='street1') 
      this.setState({ address: { ...this.state.address, street1: filteredInput} })
    else if(field==='street2')
      this.setState({ address: { ...this.state.address, street2: filteredInput} })
    else if(field==='city')
      this.setState({ address: { ...this.state.address, city: filteredInput} })
    
    if(filteredInput!==input)
      this.setState({messageSpecial: ['street1','street2'].includes(field) ? 'street' : field})
    else
      this.checkSpecial(field)
  }
  
  checkSpecial = (field) => {
    // This adds the warning to the next field needing the warning, or removes the warning. Passing field in as a bypass to prevent the warning from triggering for a field which already has no special characters but is still flagged because the state has not been updated
    let special = false
    if(filterStr(this.state.address.name)!==this.state.address.name && field!=='name')
      special = 'name'
    else if(filterStr(this.state.address.street1)!==this.state.address.street1 && field!=='street1')
      special = 'street'
    else if(filterStr(this.state.address.street2)!==this.state.address.street2 && field!=='street2')
      special = 'street'
    else if(filterStr(this.state.address.city)!==this.state.address.city && field!=='city')
      special = 'city'

    this.setState({
      messageSpecial: special
    })
  }
  
  render() {

    return (
      <>
        <div className="card-base-inner">
          <Helmet>
            <title>Reship - Create your account</title>
            <meta name="description" content="Sign up on Reship and get your personal mailing address in the US, UK and Canada!" />
          </Helmet>
          <div style={{marginBottom: 35}}>
            <Steps size="small" current={this.state.page}>
              <Step title="Info" />
              <Step title="Credentials" />
            </Steps>
          </div>
          {this.state.page===0 &&
            <Fragment>
              <h4 className="text-center">Sign Up</h4>
              <div className="form-group">
                <input className="form-control login-input" placeholder="Name" value={this.state.name} onChange={(e)=>{this.setState({name: e.target.value})}} required={true} />
              </div>
              <div className="form-group">
                <input type="email" className="form-control login-input" placeholder="Email Address" value={this.state.email} onChange={(e)=>{this.setState({email: e.target.value})}} required={true} />
              </div>
              <div className="form-group">
                <DropdownCountry className="form-control login-input" value={this.state.countryCode} onChange={(val)=>this.setState({countryCode: val})} />
              </div>
              <div className="form-group">
                <InputPhoneNumber value={this.state.phone} doUpdate={(v)=>{this.setState({phone: v.value, phoneValid: v.valid, phoneFormatted: v.formatted})}} countryCode={this.state.countryCode} />
              </div>
              {
                this.state.isEmailVerificationCodeSent && (
                  <div className="form-group">
                    <div className="address-form-label">Authentication Code</div>
                    <input className="form-control login-input" type="string" placeholder="OTP code" onChange={this.handleCodeChange}/>
                  </div>)
              }
              { !this.state.isEmailVerificationCodeSent ? 
                <button className="btn btn-primary" style={{ width: '100%' }} onClick={this.getEmailVerificationCode} disabled={this.state.loading}> Verify Email </button> : 
                <button className="btn btn-primary" style={{ width: '100%' }} onClick={this.verifyEmailCode} disabled={this.state.loading}> Next </button> 
              }
              {this.state.message && !this.state.loading && <div className="login-message">{this.state.message}</div>}
              <div className="card-base-inner bottom-link text-center">
                Already registered?
                <br />
                <Link className="login-link" to="/login">Log in</Link>
              </div>
            </Fragment>
          }
          {this.state.page===1 &&
            <Fragment>
              <h4 className="text-center">Sign Up</h4>
              <div className="form-group">
                <input type="password" className="form-control" placeholder="Password" value={this.state.password} onChange={(e)=>{this.setState({password: e.target.value})}} />
              </div>
              <div className="form-group">
                <input type="password" className="form-control" placeholder="Repeat password" value={this.state.password2} onChange={(e)=>{this.setState({password2: e.target.value})}} />
              </div>
              <div className="form-group">
                <input className="form-control login-input" placeholder="Referral code (optional)" value={this.state.referredBy} onChange={(e)=>{this.setState({referredBy: e.target.value})}} />
              </div>
              <div className="form-group">
                <RecaptchaV2 onVerify={(response) => this.setState({recaptchaV2Response: response})}/>
              </div>
              <button className="btn btn-primary" style={{ width: '100%' }} onClick={this.handleRegistration} disabled={this.state.loading}>Next</button>
              <div className="plan-payment-text text-center mt-1">By signing up, you agree to our <a target="_blank" rel="noopener noreferrer" href={constants.appHome+'/terms-conditions.html'}>terms of service</a></div>
              {this.state.message && !this.state.loading && <div className="login-message">{this.state.message}</div>}
            </Fragment>
          }
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withGoogleReCaptcha(PublicRegister)))
