import React from 'react';

function WarningMessage({ children, ...props }) {
  return (
    <div className="warning-message" {...props}>
      {children}
    </div>
  );
}

export default WarningMessage;
