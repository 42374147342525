import React, { Component }     from 'react'
import { connect }              from 'react-redux'
import { withRouter }           from 'react-router-dom'
import axios                    from 'axios/index'
import { withGoogleReCaptcha }  from 'react-google-recaptcha-v3'
import { Result, Button }       from 'antd';

const mapStateToProps = (globalStore) => {return {store: globalStore}}
const mapDispatchToProps = (dispatch) => {return {
  login: (loginData)=>{dispatch({type: 'CUSTOMER_LOGIN', payload: loginData})},
}}

class PublicVerify extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      loading: false,
      message: '',
      isVerified: null 
    }
  }

  componentDidMount() {
    this.verifyToken()
  }

  verifyToken = async () => {
    const { login } = this.props

    try {
      this.setState({
        loading: true 
      })
  
      const paramToken = this.props.match.params.token
      const paramEmail = this.props.match.params.email 
      
      const recaptchaV3Response = await this.props.googleReCaptchaProps.executeRecaptcha('verifyWebEmail')
      
      const requestBody = {
        email: paramEmail, 
        token: paramToken,
        recaptchaV3Response: recaptchaV3Response
      }

      const response = await axios.post(`/verify/web/email`, requestBody)
      const token = response.data.data 

      if(response.status === 200) {

        this.setState({
          loading: false, 
          isVerified: true           
        })

        setTimeout(async () => {
          await login({
            token: token,
            isFromRegister: false 
          })

          await axios.post(`/web/email/welcome/${paramEmail}`)
          
          this.props.history.push('/')
        }, 1500)
      }

    } catch(e) {
      this.setState({
        loading: false, 
        isVerified: false,
        message: e.response.data.message  
      })  
    }
  }

  renderVerificationResults = () => {
    if(this.state.isVerified === null) {
      return; 
    }
    
    else if(this.state.isVerified) {
      return (
        <Result
            status="success"
            title="You're Verified!"
            subTitle="You have successfully verified your account."
            // extra={[
            //   <Button style={{ backgroundColor: '#3582c4' }} type="primary" key="console" onClick={() => this.props.history.push('/login')}>
            //     Go to Login
            //   </Button>,
            // ]}
          >
        </Result>
      )
    }
    else {
      return (
        <Result
          status="error"
          title="Verification Failed"
          subTitle="Please contact customer service at support@reship.com."
          extra={[
            <Button style={{ backgroundColor: '#3582c4' }} type="primary" key="console" onClick={() => this.props.history.push('/login')}>
              Go to Login
            </Button>,
          ]}
        >
      </Result>
      )
    }
  }


  render() {

    return (
      <div className="card-base-inner">
        {
          this.state.loading && <h4 className="loading-text">Verifying Email... </h4>
        }
        { this.renderVerificationResults() }
        {this.state.message && !this.state.loading && <div className="login-message">{this.state.message}</div>}
        <div className="card-base-inner bottom-link text-center" style={{padding: 0, paddingTop: 10, borderTop: '1px solid #e0e0e0'}}>
          <a href={'https://www.reship.com/promo/'}>Try our app & get $10 OFF</a>
          <div style={{margin:12}}>
            <a href="https://apps.apple.com/us/app/reship/id1511596518" target="_blank"><img style={{width: 150, margin: 12}} src="/assets/images/badge-apple.svg"/></a>
            <a href="https://play.google.com/store/apps/details?id=com.reship&hl=en_CA&gl=US" target="_blank"><img style={{width: 150, margin: 12}} src="/assets/images/badge-google.svg"/></a>
          </div>
          <br />
        </div> 
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withGoogleReCaptcha(PublicVerify)))
