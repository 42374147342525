import React, {Component}     from 'react'
import {withRouter}           from 'react-router-dom'
import {connect}              from 'react-redux'
import axios                  from 'axios/index'
import GlobalFooter           from '../components/GlobalFooter'
import GlobalError            from '../components/GlobalError'
import GlobalNotices          from '../components/GlobalNotices'
import CustomerSidebar        from '../components/CustomerSidebar'
import CustomerLoading        from '../components/CustomerLoading'
import DropdownCountry        from '../components/DropdownCountry'
import DropdownRegion         from '../components/DropdownRegion'
import InputPostalCode        from '../components/InputPostalCode'
import {countryText, regionText, validateCountry, filterValidCountry, filterValidRegion, filterStr, filterNotes, specialCharacterMessage, addressCharLimits, displayPostalCode}
  from '../common/handleAddress'
import withAddressAutocomplete from '../hocs/withAddressAutocomplete'
import {InputPhoneNumber} from "../components/InputPhoneNumber";

const mapStateToProps = (globalStore) => {return {store: globalStore}}

class Addresses extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      initialLoading: 0,
      initialLoadingError: '',
      
      loading: false,
      message: '',
      messageSpecial: false,
      
      edit: false,
      add: false,
      
      addresses: [],
      
      activeAddress: {},
      addressId: '',
      name: '',
      street1: '',
      street2: '',
      city: '',
      notes: '',
      
      stateCode: '',
      countryCode: '',
      postalCode: '',
      postalCodeValid: false,
      phone: '',
      phoneValid: false,
      phoneFormatted: ''
    }
  }

  componentDidMount() {
    if(this.props.store.isLoggedIn && this.state.initialLoading===0)
      this.getAddressData()
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.store.isLoggedIn && this.state.initialLoading===0)
      this.getAddressData()
  }
  
  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.finalSelectedAddress) !== JSON.stringify(this.props.finalSelectedAddress)) {
      this.setState({
        street1: this.props.finalSelectedAddress.street1 || '',
        street2: this.props.finalSelectedAddress.street2 || '',
        city: this.props.finalSelectedAddress.city || '',
        stateCode: this.props.finalSelectedAddress.stateCode || '',
        countryCode: this.props.finalSelectedAddress.countryCode || '',
        postalCode: this.props.finalSelectedAddress.postalCode || '',
      })
    } 
  }

  getAddressData = () => {
    this.setState({initialLoading: 1})
    
    axios.get('/customer/addresses/getAddresses')
    .then((response) => {
      this.setState({
        initialLoading: 2,
        initialLoadingError: '',
        addresses: response.data.addresses,
      })
    })
    .catch((error) => {
      this.setState({
        initialLoading: 3,
        initialLoadingError: error.response ? error.response.data.message : error.message
      })
    })
  }
  
  // TODO add delete confirmation prompt
  
  updateField = (e, field) => {
    let input = e.target.value
    let filteredInput = filterStr(input)
    
    if(field==='name')
      this.setState({name: filteredInput})
    else if(field==='street1')
      this.setState({street1: filteredInput})
    else if(field==='street2')
      this.setState({street2: filteredInput})
    else if(field==='city')
      this.setState({city: filteredInput})
    
    if(filteredInput!==input)
      this.setState({messageSpecial: ['street1','street2'].includes(field) ? 'street' : field})
    else
      this.checkSpecial(field)
  }
  
  checkSpecial = (field) => {
    // This adds the warning to the next field needing the warning, or removes the warning. Passing field in as a bypass to prevent the warning from triggering for a field which already has no special characters but is still flagged because the state has not been updated
    let special = false
    if(filterStr(this.state.name)!==this.state.name && field!=='name')
      special = 'name'
    else if(filterStr(this.state.street1)!==this.state.street1 && field!=='street1')
      special = 'street'
    else if(filterStr(this.state.street2)!==this.state.street2 && field!=='street2')
      special = 'street'
    else if(filterStr(this.state.city)!==this.state.city && field!=='city')
      special = 'city'

    this.setState({
      messageSpecial: special
    })
  }
  
  closeEdit = () => {
    this.setState({
      loading: false,
      message: '',
      messageSpecial: false,
      edit: false,
      add: false,
      
      activeAddress: {},
      addressId: '',
      addressName: '',
      name: '',
      street1: '',
      street2: '',
      city: '',
      notes: '',
      
      stateCode: '',
      countryCode: '',
      postalCode: '',
      postalCodeValid: false,
      phone: '',
      phoneValid: false,
      phoneFormatted: '',
    })
  }
  
  openAddAddress = () => {
    this.setState({
      add: true,
      edit: false,
      loading: false,
      message: ''
    })
  }
  
  addAddress = () => {
    this.setState({message: ''})

    if(this.state.name==='')
      this.setState({message: 'Name required'})
    else if(this.state.street1==='')
      this.setState({message: 'Street address line 1 required'})
    else if(this.state.name.replace(/[^a-zA-Z]/g, '').length < 5)
      this.setState({message: 'Name must be at least 5 letters long'})
    else if(this.state.street1.length < 5 || !/[a-zA-Z]/g.test(this.state.street1))
      this.setState({message: 'Street address line 1 must be at least 5 characters long and contain letters'})
    else if(this.state.city==='')
      this.setState({message: 'City required'})
    else if(!validateCountry(this.state.countryCode))
      this.setState({message: 'Please select a valid country'})
    else if(!this.state.postalCodeValid)
      this.setState({message: 'Invalid postal code'})
    else if(!this.state.phoneValid)
      this.setState({message: 'Valid phone number required for shipping'})
    else if(this.state.phone.replace(/[^0-9]/g, '').length > 15)
      this.setState({message: 'Phone number should be 15 digits or shorter'})
    else if(!!this.state.messageSpecial
      || filterStr(this.state.name)!==this.state.name
      || filterStr(this.state.street1)!==this.state.street1
      || filterStr(this.state.street2)!==this.state.street2
      || filterStr(this.state.city)!==this.state.city
    )
      this.setState({message: 'Special characters are not supported in addresses'})
    else {
      this.setState({
        loading: true,
        messageSpecial: false
      })

      let postData = {
        name: this.state.name,
        street1: this.state.street1,
        street2: this.state.street2,
        city: this.state.city,
        stateCode: filterValidRegion(this.state.countryCode, this.state.stateCode),
        countryCode: this.state.countryCode,
        postalCode: this.state.postalCode,
        phone: this.state.phoneFormatted,
        notes: this.state.notes,
      }
      axios.post('/customer/addresses/addAddress', postData)
      .then((response) => {
        if(!response.data.success)
          this.setState({loading: false, message: 'Request failed.'})
        else {
          this.setState({
            loading: false,
            addresses: response.data.addresses,
          })
          this.closeEdit()
        }
      })
      .catch((error) => {this.setState({loading: false, message: error.response ? error.response.data.message : error.message})})
    }
  }
  
  openEditAddress = (addressId) => {
    let selectedAddress = this.state.addresses.filter(a=>{return a.addressId===addressId})[0]
    
    let name = (selectedAddress.name||'').substr(0,addressCharLimits.name)
    let street1 = (selectedAddress.street1||'').substr(0,addressCharLimits.streets)
    let street2 = (selectedAddress.street2||'').substr(0,addressCharLimits.streets)
    let city = (selectedAddress.city||'').substr(0,addressCharLimits.city)

    let special = false
    if(filterStr(name)!==name)
      special = 'name'
    else if(filterStr(city)!==city)
      special = 'city'
    else if(filterStr(street1)!==street1 || filterStr(street2)!==street2)
      special = 'street'
    
    this.props.setValue({
      street1 
    })

    this.setState({
      loading: false,
      edit: true,
      
      message: '',
      messageSpecial: special,
      
      activeAddress: selectedAddress,
      addressId: selectedAddress.addressId,
      name,
      street1,
      street2,
      city,
      notes: (selectedAddress.notes||''),
      stateCode: filterValidRegion(selectedAddress.countryCode, selectedAddress.stateCode),
      countryCode: filterValidCountry(selectedAddress.countryCode),
      postalCode: (selectedAddress.postalCode||''),
      phone: (selectedAddress.phone||'').substr(0,addressCharLimits.phone),
    })
  }
  
  editAddress = () => {
    this.setState({message: ''})

    if(this.state.name==='')
      this.setState({message: 'Name required'})
    else if(this.state.street1==='')
      this.setState({message: 'Street address line 1 required'})
    else if(this.state.name.replace(/[^a-zA-Z]/g, '').length < 5)
      this.setState({message: 'Name must be at least 5 letters long'})
    else if(this.state.street1.length < 5 || !/[a-zA-Z]/g.test(this.state.street1))
      this.setState({message: 'Street address line 1 must be at least 5 characters long and contain letters'})
    else if(this.state.city==='')
      this.setState({message: 'City required'})
    else if(!validateCountry(this.state.countryCode))
      this.setState({message: 'Please select a valid country'})
    // else if(!validateRegion(this.state.countryCode, this.state.stateCode))
    //   this.setState({message: 'Please select a valid state/region'})
    else if(!this.state.postalCodeValid)
      this.setState({message: 'Valid postal code required'})
    else if(!this.state.phoneValid)
      this.setState({message: 'Valid phone number required for shipping'})
    else if(!!this.state.messageSpecial
      || filterStr(this.state.name)!==this.state.name
      || filterStr(this.state.street1)!==this.state.street1
      || filterStr(this.state.street2)!==this.state.street2
      || filterStr(this.state.city)!==this.state.city
    )
      this.setState({message: 'Please remove special characters from address'})
    else if(this.state.name===this.state.activeAddress.name
      && this.state.street1===this.state.activeAddress.street1
      && this.state.street2===this.state.activeAddress.street2
      && this.state.city===this.state.activeAddress.city
      && this.state.stateCode===this.state.activeAddress.stateCode
      && this.state.countryCode===this.state.activeAddress.countryCode
      && this.state.postalCode===this.state.activeAddress.postalCode
      && this.state.phoneFormatted===this.state.activeAddress.phone
      && this.state.notes===this.state.activeAddress.notes
    )
      this.closeEdit()
    else {
      this.setState({
        loading: true,
        messageSpecial: false
      })

      let postData = {
        addressId: this.state.addressId,
        name: this.state.name,
        street1: this.state.street1,
        street2: this.state.street2,
        city: this.state.city,
        stateCode: filterValidRegion(this.state.countryCode, this.state.stateCode),
        countryCode: this.state.countryCode,
        postalCode: this.state.postalCode,
        phone: this.state.phoneFormatted,
        notes: this.state.notes
      }
      axios.post('/customer/addresses/updateAddress', postData)
      .then((response) => {
        if(!response.data.success)
          this.setState({loading: false, message: 'Request failed.'})
        else {
          this.setState({
            loading: false,
            addresses: response.data.addresses,
          })
          this.closeEdit()
        }
      })
      .catch((error) => {this.setState({loading: false, message: error.response ? error.response.data.message : error.message})})
    }
  }
  
  deleteAddress = (addressId) => {
    let postData = {
      addressId: addressId,
    }

    axios.post('/customer/addresses/deleteAddress', postData)
    .then((response) => {
      this.setState({
        addresses: response.data.addresses,
      })
    })
    .finally(() => {
      this.setState({
        loading: false
      })
    })
  }
  
  
  render() {
    if(this.state.initialLoading < 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <CustomerLoading />
        </div>
      )
    else if (this.state.initialLoading > 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <GlobalError />
        </div>
      )
    
    return (
      <div className="body body-customer" id="customer-addresses">
        <CustomerSidebar />
        <div className="content-area">
          <GlobalNotices { ...this.props }/>
          <div className="page-title">
            <h2>My Addresses</h2>
          </div>
          {!this.state.add && !this.state.edit &&
            <div className="card">
              <div className="card-title"><h4>Current Shipping Addresses</h4></div>
              <div className="customer-addresses">
                {this.state.addresses.map(a=>{
                  return (
                    <div className="card card-outline customer-address" key={a.addressId}>
                      <div className="customer-address-line customer-address-name">{a.name.substr(0,addressCharLimits.name)}</div>
                      <div className="customer-address-line">{a.street1.substr(0,addressCharLimits.streets)}</div>
                      {!!a.street2 && <div className="customer-address-line">{a.street2.substr(0,addressCharLimits.streets)}</div>}
                      <div className="customer-address-line">{a.city.substr(0,addressCharLimits.city)}</div>
                      {(!!a.stateCode || displayPostalCode(a.postalCode)) &&
                        <div className="customer-address-line">
                          {a.stateCode ? regionText(a.countryCode, a.stateCode) : ''}
                          {(!!a.stateCode && displayPostalCode(a.postalCode)) ? ' ' : ''}
                          {displayPostalCode(a.postalCode) ? a.postalCode.substr(0,addressCharLimits.postalCode) : ''}
                        </div>
                      }
                      <div className="customer-address-line">{countryText(a.countryCode)}</div>
                      <div className="customer-address-line customer-address-phone">Phone: {a.phone.substr(0,addressCharLimits.phone)}</div>
                      <div className="customer-address-action-row">
                        <div className="btn btn-primary btn-small ship-address-btn" onClick={()=>{this.openEditAddress(a.addressId)}}><i className="material-icons">edit</i>Edit</div>
                        <div className="btn btn-primary btn-small ship-address-btn" onClick={()=>{this.deleteAddress(a.addressId)}}><i className="material-icons">delete</i>Delete</div>
                      </div>
                      {!!this.state.message && <div className="error-text">{this.state.message}</div>}
                    </div>
                  )
                })}
                <div className="card card-outline customer-address customer-address-new" onClick={() => {this.props.clearAutocompleteValues(); this.openAddAddress()}}>
                  <div className="customer-address-new-symbol">+</div>
                  <div>New Address</div>
                </div>
              </div>
            </div>
          }
          {this.state.add && 
            <div className="card card-form">
              <div className="card-title"><h4>Add Address</h4></div>
              <div className="address-form-group">
                <div className="address-form-label">Full Name</div>
                <input className="form-control" autoComplete="name" name="name" value={this.state.name} onChange={(e)=>{this.updateField(e,'name')}} maxLength={addressCharLimits.name} />
                {this.state.messageSpecial==='name' && <div className="error-text-special">{specialCharacterMessage}</div>}
              </div>
              <div className="address-form-group">
                <div className="address-form-label">Street Address</div>
                <input 
                  className="form-control" 
                  autoComplete="address-line1" 
                  name="address-1"value={this.props.value.street1} 
                  onChange={(e) => {this.props.handleInput(e);this.updateField(e,'street1')}}  
                  maxLength={addressCharLimits.streets} 
                  onBlur={() => this.props.setShowSuggestions(false)}
                  onFocus={() => this.props.setShowSuggestions(true)}
                  disabled={!this.props.ready}  
                />                
                {this.props.renderedSuggestions}

                <input className="form-control" autoComplete="address-line2" name="address-2" value={this.state.street2} onChange={(e)=>{this.updateField(e,'street2')}} maxLength={addressCharLimits.streets} />
                {this.state.messageSpecial==='street' && <div className="error-text-special">{specialCharacterMessage}</div>}
              </div>
              <div className="address-form-group">
                <div className="address-form-label">City</div>
                <input className="form-control" autoComplete="address-level2" name="city" value={this.state.city} onChange={(e)=>{this.updateField(e,'city')}} maxLength={addressCharLimits.city} />
                {this.state.messageSpecial==='city' && <div className="error-text-special">{specialCharacterMessage}</div>}
              </div>
              <div className="address-form-group">
                <div className="address-form-label">Country</div>
                <DropdownCountry className="form-control" autoComplete="country" name="country" value={this.state.countryCode} onChange={(val)=>{if(val!==this.state.countryCode) this.setState({countryCode: val, stateCode: ''})}} />
                <DropdownRegion className="form-control" autoComplete="address-level1" name="region" country={this.state.countryCode} value={this.state.stateCode} onChange={(val)=>this.setState({stateCode: val})} showLabel={true} />
              </div>
              <InputPostalCode value={this.state.postalCode} doUpdate={(v)=>{this.setState({postalCode: v.value, postalCodeValid: v.valid})}} countryCode={this.state.countryCode} showLabel={true}  />
              <InputPhoneNumber value={this.state.phone} doUpdate={(v)=>{this.setState({phone: v.value, phoneValid: v.valid, phoneFormatted: v.formatted})}} countryCode={this.state.countryCode} />
              <div className="address-form-group">
                <div className="address-form-label">Delivery Notes/Instructions</div>
                <textarea className="form-control address-form-notes" onChange={(e) => this.setState({notes: filterNotes(e.target.value)})} value={this.state.notes} maxLength={addressCharLimits.notes} />
                <div className="address-form-label-sub">Delivery notes will be printed and added to package for courier/recipient where possible. Optional. Max 500 characters. </div>
              </div>
              {!!this.state.message && <div className="error-text">{this.state.message}</div>}
              <div className="button-row">
                <button className="btn btn-light" onClick={this.closeEdit}><i className="material-icons">close</i> Cancel</button>
                <button className="btn btn-primary" onClick={this.addAddress}><i className="material-icons">check</i> Submit</button>
              </div>
            </div>
          }
          {this.state.edit &&
            <div className="card card-form">
              <div className="card-title"><h4>Edit Address</h4></div>
              <div className="cb-form">
                <div className="address-form-group">
                  <div className="address-form-label">Full Name</div>
                  <input className="form-control" autoComplete="name" name="name" value={this.state.name} onChange={(e)=>{this.updateField(e,'name')}} maxLength={addressCharLimits.name} />
                  {this.state.messageSpecial==='name' && <div className="error-text-special">{specialCharacterMessage}</div>}
                </div>
                <div className="address-form-group">
                  <div className="address-form-label">Street Address</div>
                  {/* <input className="form-control" autoComplete="address-line1" name="address-1" placeholder="Street address" value={this.state.street1} onChange={(e)=>{this.updateField(e,'street1')}} maxLength={addressCharLimits.streets} /> */}
                  <input 
                    className="form-control" 
                    autoComplete="address-line1" 
                    name="address-1"value={this.props.value.street1} 
                    onChange={(e) => {this.props.handleInput(e);this.updateField(e,'street1')}}  
                    maxLength={addressCharLimits.streets} 
                    onBlur={() => this.props.setShowSuggestions(false)}
                    onFocus={() => this.props.setShowSuggestions(true)}
                    disabled={!this.props.ready}  
                  />                
                  {this.props.renderedSuggestions}
                  <input className="form-control" autoComplete="address-line2"  name="address-2" value={this.state.street2} onChange={(e)=>{this.updateField(e,'street2')}} maxLength={addressCharLimits.streets} />
                  {this.state.messageSpecial==='street' && <div className="error-text-special">{specialCharacterMessage}</div>}
                </div>
                <div className="address-form-group">
                  <div className="address-form-label">City</div>
                  <input className="form-control" autoComplete="address-level2" name="city" placeholder="City" value={this.state.city} onChange={(e)=>{this.updateField(e,'city')}} maxLength={addressCharLimits.city} />
                  {this.state.messageSpecial==='city' && <div className="error-text-special">{specialCharacterMessage}</div>}
                </div>
                <div className="address-form-group">
                  <div className="address-form-label">Country</div>
                  <DropdownCountry className="form-control" autoComplete="country" name="country" value={this.state.countryCode} onChange={(val)=>{if(val!==this.state.countryCode) this.setState({countryCode: val, stateCode: ''})}} />
                  <DropdownRegion className="form-control" autoComplete="address-level1" name="region" country={this.state.countryCode} value={this.state.stateCode} onChange={(val)=>this.setState({stateCode: val})} showLabel={true} />
                </div>
                <InputPostalCode value={this.state.postalCode} doUpdate={(v)=>{this.setState({postalCode: v.value, postalCodeValid: v.valid})}} countryCode={this.state.countryCode} showLabel={true} />
                <InputPhoneNumber value={this.state.phone} doUpdate={(v)=>{this.setState({phone: v.value, phoneValid: v.valid, phoneFormatted: v.formatted})}} countryCode={this.state.countryCode} />
                <div className="address-form-group">
                  <div className="address-form-label">Delivery Notes/Instructions</div>
                  <textarea className="form-control address-form-notes" onChange={(e) => this.setState({notes: filterNotes(e.target.value)})} value={this.state.notes} />
                  <div className="address-form-label-sub">Delivery notes will be printed and added to package for courier/recipient where possible. Optional. Max 500 characters. </div>
                </div>
                {!!this.state.message && <div className="error-text">{this.state.message}</div>}
                <div className="button-row">
                  <button className="btn btn-light" onClick={this.closeEdit}><i className="material-icons">close</i> Cancel</button>
                  <button className="btn btn-primary" onClick={this.editAddress}><i className="material-icons">check</i> Save</button>
                </div>
              </div>
            </div>
          }
        </div>
        <GlobalFooter />
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(withAddressAutocomplete(Addresses)))
