import React, {Fragment, PureComponent} from 'react'
import PropTypes                  from 'prop-types'
import countryRegionData          from '@monarkit/postal-country-region-data'

export default class DropdownRegion extends PureComponent {
	constructor(props) {
		super(props)
    this.state = {
			regions: this.getRegions(props.country)
		}
    this.getRegions = this.getRegions.bind(this)
  }

	componentWillReceiveProps(nextProps) {
		if(!nextProps.country)
		  this.setState({regions: []})
	  else if(nextProps.country !== this.props.country) {
      this.setState({
        regions: this.getRegions(nextProps.country)
      })
    }
  }

	getRegions = (country) => {
		if (!country)
			return []
    else {
      let countryMatch = countryRegionData.filter(c=>c.countryShortCode===country)
      if(countryMatch.length)
        return countryMatch[0].regions || []
      else
        return []
    }
  }

	render () {
		const {value, onChange, onBlur, id, name, className, showLabel, ...arbitraryProps} = this.props
    const attrs = {
			...arbitraryProps,
			name,
			value,
			onChange: (e) => onChange(e.target.value, e),
			onBlur: (e) => onBlur(e)
		}
    if (id) attrs.id = id
		if (className) attrs.className = className

		if (!this.state.regions.length)
		  return false
    else {
      return (
        <Fragment>
          {!!this.props.showLabel && <div className="address-form-label">State/Region</div>}
          <select {...attrs}>
            <option value="">Select state/region...</option>
            {this.state.regions.map(region=>(<option value={region.shortCode} key={region.shortCode}>{region.name}</option>))}
          </select>
        </Fragment>
      )
    }
  }
}

DropdownRegion.propTypes = {
	country: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string,
	id: PropTypes.string,
	className: PropTypes.string,
	showLabel: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
}
DropdownRegion.defaultProps = {
	country: '',
	value: '',
	name: 'rcrs-region',
	id: '',
	className: '',
  showLabel: false,
	onChange: () => {},
	onBlur: () => {},
}
