import React, { Component } from 'react';
import PropTypes from 'prop-types';
import countryRegionData from '@monarkit/postal-country-region-data';

const buildCountryList = (countryRegionData) => {
  const priorityCountries = ['AU', 'CA', 'GB', 'US', 'NZ'];

  const countryData = countryRegionData.map((c) => ({
    countryName: c.countryName,
    countryShortCode: c.countryShortCode,
  }));
  const priorityList = [];
  const mainList = [];
  countryData.forEach((c) => {
    if (priorityCountries.includes(c.countryShortCode)) priorityList.push(c);
    else mainList.push(c);
  });
  return [...priorityList, ...mainList];
};

export default class DropdownCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: buildCountryList(countryRegionData),
    };
  }

  render() {
    const {
      name, id, className, value, onChange, onBlur, ...arbitraryProps
    } = this.props;
    const attrs = {
      ...arbitraryProps,
      name,
      value,
      onChange: (e) => onChange(e.target.value, e),
      onBlur: (e) => onBlur(e),
    };
    if (id) attrs.id = id;
    if (className) attrs.className = className;

    return (
      <select {...attrs}>
        <option value="" key="default">Select country...</option>
        {this.state.countries.map((country) => (
          <option value={country.countryShortCode} key={country.countryShortCode}>
            {country.countryName}
          </option>
        ))}
      </select>
    );
  }
}

DropdownCountry.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
DropdownCountry.defaultProps = {
  value: '',
  name: 'rcrs-country',
  id: '',
  className: '',
  onChange: () => {},
  onBlur: () => {},
};
