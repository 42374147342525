import axios            from 'axios'
import { constants }    from '../common/constants'
import { handleError }  from '../common/utils'

const configureAxios = (store) => {
  axios.defaults.baseURL = constants.apiReshipEndpoint
  axios.defaults.headers.post['Content-Type'] = 'application/json'
  axios.defaults.withCredentials = true
  setupResponseInterceptors(store)
}

const setupResponseInterceptors = (store) => {
  axios.interceptors.response.use(
    async response => {
      const { dispatch } = store

      if(response.data._newToken) {
        dispatch({ type: 'CUSTOMER_LOGIN', payload: {
          token: response.data._newToken, 
          isFromRegister: false 
        }})

        response.config.headers['Authorization'] = 'Bearer ' + response.data._newToken;
        return axios.request(response.config)
      }

      return Promise.resolve(response)
    }, 
    async error => {
      const { dispatch } = store
      handleError(error)

      if (error.response?.status === 403) {
        dispatch({ type: 'CUSTOMER_LOGOUT', payload: { isAuthExpired: true } })
        return 
      } 
      
      if (error.response?.status === 409) {
        dispatch({ type: 'RESET' })
        return 
      } 

      return Promise.reject(error)
    }
  )
}

export default configureAxios

