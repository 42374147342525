import React, { Fragment } from 'react';
import { displayDate, convertToClientTimestamp } from './utils';

export const endpoint = () => {
  if (window.location.port === "3000") {
    return "http://" + window.location.hostname + ":4000";
  }

  if (window.location.hostname === "ship.reship.rocks") {
    return 'https://api-customer.reship.rocks/';
  }

  if (window.location.hostname === "ship.reship.com") {
    return "https://api-customer.reship.com/"
  }

  return 'https://api-customer.reship.rocks/';
};

const homepage = () => {
  if (window.location.port === "3000") {
    return "http://" + window.location.hostname + ":3000";
  }

  if (window.location.hostname === "ship.reship.rocks") {
    return "https://www.reship.rocks";
  }

  return "https://www.reship.com";
};

const recurlyKey = () => {
  if (window.location.hostname === "ship.reship.com") {
    return "ewr1-BGXxGzrYfIDilG7KKCF4Gs";
  }

  return "ewr1-BIwMZOcnTmFLxjjuE63SNV"; //joe's staging key
};

const paypalEnv = () => {
  if (window.location.hostname === "ship.reship.com") {
    return "production";
  }

  return "sandbox";
};

const recaptchaKey = () => {
  if (window.location.hostname === "ship.reship.com") {
    return "6LdQ-bcUAAAAAJO63qeQ8pFED2mBCcvHkio_82vv";
  }

  return "6LdLel4UAAAAAMoie1_OA3byyW_xyjzcKd9BAVxb";
};

const invisibleRecaptchaKey = () => {
  if (window.location.hostname === "ship.reship.com") {
    return "6Ldm-bcUAAAAALCOa-PjykEKSt2dNYcgkp24MutA";
  }

  return "6LcbOLAUAAAAAHc0raqXTaHZ5eLbKTuOex_otYBH";
};

const recaptchaThreeKey = () => {
  if (window.location.hostname === "ship.reship.com"){
    return "6LerxLMZAAAAAAHomLKh-DfYvz_flC3ZJwdOuHiz";
  }

  return "6LfVxLMZAAAAAEIlpsdkOV4y7RYo7_3kH1H34PL4";
};

const apiKey = () => {
  if (window.location.hostname === "ship.reship.com") {
    return "nc79XW3qumZI15zrRlZEkVpJOdhfSW00CpQz4JaxmNmKLP5pdJYPdFvHS5KG6a229Cqqfgo7mb3MNAqi";
  }

  return "IHaM4J9cS2nzq4lMJMRhCN2FQIdDc84YD3aPsfsskxSRsdqxVlr63sMcTcmGoZ0SaQZJdlK9YfDatgl5"; // Aware that hackers can retreive this key and impersonate client for authentication attempts. This is just a low-hanging-fruit barrier to block the bulk of bots that attempt to hit our auth endpoint without any headers.
};

const googlePayMerchantId = () => {
  if (window.location.hostname === "ship.reship.com") {
    return "BCR2DN6T7OAZVXLD";
  }

  return "";
};



export const constants = {
  debug: window.location.hostname !== 'ship.reship.com', // Enable debugging except for prod
  apiReshipEndpoint: endpoint(),
  appDomain: window.location.origin,
  appPublicKey: apiKey(),
  appHome: homepage(),
  recurlyPublicKey: recurlyKey(),
  ppEnv: paypalEnv(),
  recaptchaKey: recaptchaKey(),
  invisibleRecaptchaKey: invisibleRecaptchaKey(),
  recaptchaThreeKey: recaptchaThreeKey(),
  googlePayMerchantId: googlePayMerchantId(),
};

export const debug = (var1, var2) => {
  if (constants.debug) {
    if (var2) console.log(var1, var2);
    else console.log(var1);
  }
};

export const valueMapper = (array, input, inputType, outputType) => array.filter((a) => a[inputType] === input)[0][outputType];

export const objectMapper = (array, inputType, input) => array.filter((a) => a[inputType] === input)[0];

export const warehouses = [
  {
    id: 1,
    name: 'Reship US (Portland)',
    prefix: 'Suite #K',
    street1: '13820 NE Airport Way',
    street2: '',
    city: 'Portland',
    stateCode: 'OR',
    countryCode: 'US',
    countryLong: 'USA',
    countryLonger: 'Portland, USA',
    postalCode: '97251-1158', // Was 97251. Amazon began rejecting, so changed to 97230. USPS routing issues, so changed back to 97251-1158.
    flag: 'usa-flag.png',
    currency: 'USD',
    weightUnit: 'lb',
  }, {
    id: 2,
    name: 'Reship UK',
    prefix: 'Suite #D',
    street1: '68 Tanners Drive',
    street2: '',
    city: 'Blakelands',
    stateCode: 'MK',
    countryCode: 'GB',
    countryLong: 'United Kingdom',
    countryLonger: 'Blakelands, UK',
    postalCode: 'MK14 5BP',
    flag: 'uk-flag.png',
    currency: 'GBP',
    weightUnit: 'kg',
  }, {
    id: 3,
    name: 'Reship Canada (Surrey)',
    prefix: 'Suite #N',
    street1: '214 - 19138 26th Ave',
    street2: '',
    city: 'Surrey',
    stateCode: 'BC',
    countryCode: 'CA',
    countryLong: 'Canada',
    countryLonger: 'Surrey, Canada',
    postalCode: 'V3Z 3V7',
    flag: 'canada-flag.png',
    currency: 'CAD',
    weightUnit: 'kg',
  },
  /* ,{
    id: 99,
    name: 'Reship HQ',
    prefix: '',
    street1: '205-1688 152 Street',
    street2: '',
    city: 'Surrey',
    stateCode: 'BC',
    countryCode: 'CA',
    countryLong: 'Headquarters',
    countryLonger: 'HQ, Canada',
    postalCode: 'V4A 4N2',
    flag: 'canada-flag.png',
    currency: 'CAD'
  } */
];

export const warehouseData = (warehouse) => objectMapper(warehouses, 'id', warehouse);

const shipStatuses = [
  { id: 0, status: 20, text: 'Outgoing processing' }, // Blue Blue -
  { id: 1, status: 20, text: 'Outgoing processing' }, // Blue Blue Orange
  { id: 2, status: 25, text: 'Outgoing processing - on hold, contact us' }, // Blue Orange -
  { id: 3, status: 25, text: 'Shipment processed' }, // Blue Blue Orange
  { id: 4, status: 40, text: 'Shipped' }, // Overriden on shipments page
  { id: 10, status: 30, text: 'Shipped' }, // Lane only, not being used until our storefronts are enabled
  { id: 11, status: 40, text: 'Delivered to destination' }, // Lane only, not being used until our storefronts are enabled
  { id: 12, status: 40, text: 'Delivered' }, // Lane only, not being used until our storefronts are enabled
  { id: 21, status: 30, text: 'Ready for pickup' }, // Blue Blue Blue
  {
    id: 22,
    status: 40,
    text: <>
      <strong>Received</strong>
      {' '}
      by customer
          </>,
  }, // Green Green Green
  { id: -1, status: 5, text: 'Shipment cancelled' }, // Orange - -
];

export const shippedStatus = (status) => objectMapper(shipStatuses, 'id', status) || shipStatuses[0];

export const lockStatuses = [
  { id: 0, status: 5, text: 'Locked for processing' }, // Blue - -
  { id: 1, status: 5, text: 'Processing - receiving' }, // Recieivng item into facility - - -
  { id: 11, status: 5, text: 'Processing - receiving' }, // Checking & itemizing contents - - -
  { id: 12, status: 15, text: 'Fulfilling package request' }, // Fulfilling package request Blue Orange -
  { id: 20, status: 15, text: 'Pending consolidation' }, // Pending Consolidation Blue Orange -
  { id: 40, status: 25, text: 'Pending shipping' }, // Shipping item out Blue Blue -
  { id: 50, status: 25, text: 'Special handling - Please contact us' }, // Special handling Blue Orange -
];

export const lockedStatus = (lockReason) => objectMapper(lockStatuses, 'id', lockReason) || lockStatuses[0];

export const consolidationStatuses = [
  { id: 0, status: 15, text: 'Pending consolidation' },
  { id: 1, status: 20, text: 'Consolidated' },
  { id: 2, status: 15, text: 'Pending deconsolidation' },
  { id: 3, status: 20, text: 'Repacking completed' },
];

export const consolidationStatus = (status) => objectMapper(consolidationStatuses, 'id', status) || consolidationStatuses[0];

export const aprStatuses = [
  { id: -1, text: 'Expired' },
  { id: -2, text: 'Cancelled' },
  { id: -3, text: 'Cancelled' },
  { id: 0, text: 'Pending Quote' },
  { id: 1, text: 'Preparing Quote' },
  { id: 2, text: 'Please Review Quote' },
  { id: 3, text: 'Processing ayment' },
  { id: 4, text: 'Processing Order' },
  { id: 5, text: 'Ordered' },
  { id: 6, text: 'Vendor Shipped' },
  { id: 7, text: 'Completed' },
];

export const aprStatus = (status) => objectMapper(aprStatuses, 'id', status) || aprStatuses[0];

export const invoiceTypes = [
  { id: 0, text: 'Unknown', icon: 'question-circle' },
  { id: 1, text: 'Package & Shipping', icon: 'box' },
  { id: 2, text: 'Subscription', icon: 'credit-card' },
  { id: 3, text: 'APR Order', icon: 'shopping-cart' },
  { id: 4, text: 'Credit Purchase', icon: 'shopping-cart' },
];

export const invoiceType = (typeId) => objectMapper(invoiceTypes, 'id', typeId) || invoiceTypes[0];

export const parseStatus = (pkg) => {
  let status;

  if (pkg.shipped) status = shippedStatus(pkg.shipment.status);
  else if (pkg.consolidation && !pkg.locked) status = { status: 20, locked: false, text: pkg.status };
  else if (pkg.status === 'Package created') status= {status: 15, text: 'Inbound package notification completed'}
  else if (pkg.status === 'Waiting for receiving') status= {status: 25, text: 'Waiting for inbound package'}
  else if (pkg.locked) status = lockedStatus(pkg.lockReason);
  else status = { status: 10, locked: false, text: 'Ready to go' }; // Blue - -

  return status;
};

const errors = [
  { id: 500, message: 'Internal Server Error', description: 'Our servers were unable to process your request' },
  { id: 503, message: 'Unavailable', description: 'Our servers are not responding at the moment. They may be overloaded, or down for emergency maintenance.' },
  { id: 404, message: 'Not Found', description: 'You requested informaiton for a package or page that does not exist. Please go back and try another.' },
];

export const restrictionStatus = (restrictionCode) => {
  switch (restrictionCode) {
    case 22:
      return 'This package contains over 100 Wh of lithium batteries and may only be shipped by certain carriers and methods. Additional charges may apply.';
    case 23:
      return 'This package contains lithium batteries separate from a product and may only be shipped by certain carriers and methods. Additional charges may apply.';
    case 26:
      return 'Due to carrier restrictions, this package can only be shipped via surface delivery methods.';
    case 27:
      return 'Due to export restrictions, this package can only be shipped within the same country. Your shipping addresses have been filtered accordingly.';
    case 28:
      return 'Due to import restrictions, this package cannot be shipped to certain countries, your shipping addresses have been filtered accordingly. Please contact us for details.';
    case 11:
    case 12:
    case 30:
      return 'This package requires special shipping and/or handling. If we have not already reached out to you, please contact us.';
    default:
      return false;
  }
};

export const error = (errorCode) => objectMapper(errors, 'id', errorCode) || consolidationStatuses[0];

export const weightImperial = (weight) => (weight * 2.20462).toFixed(1);

export const dimensionsImperial = (dimension) => (dimension * 0.393701).toFixed(2);

export const shortDate = (date) => displayDate(convertToClientTimestamp(date), 'd MMM yyyy');

export const shortTime = (date) => displayDate(convertToClientTimestamp(date), 'h:mm a');

export const dateTime = (date) => displayDate(convertToClientTimestamp(date), 'd MMM yyyy at h:mm a');

export const customsGroups = {
  usCustomsRequired: ['AA', 'AE', 'AP', 'MP', 'VI', 'PW', 'MH', 'GU', 'FM', 'AS', 'PR'],
};

/** Oct 26, 2022 - 'Documents' label temporarily disabled. Shipping rates not showing when customers select it. */
export const customsTypes = [
  { label: 'Merchandise', value: 1 },
  { label: 'Return', value: 2 },
  // {label: 'Documents',        value: 3},
  { label: 'Gift', value: 4 },
];

export const carrierLabel = (carrier) => {
  switch (carrier) {
    case 'UPSMailInnovations':
      return 'UPS Mail Innovations';
    case 'InterlinkExpress':
      return 'DPD Local';
    case 'DHLExpress':
      return 'DHL Express';
    case 'CANADAPOST':
    case 'CanadaPost':
    case 'canadapost':
      return 'Canada Post';
    case 'RoyalMail':
    case 'UKMAIL':
      return 'Royal Mail';
    case 'fedex':
    case 'fed ex':
      return 'FedEx';
    case 'dhl':
      return 'DHL';
    case 'PARCELFORCE':
      return 'ParcelForce';
    case 'AMAZON LOGISTICS':
    case 'AMAZON':
    case 'Amazon':
      return 'Amazon Logistics';
    case 'HERMES':
      return 'Hermes';
    case ' UPS ground':
      return 'UPS';
    case 'unknown':
    case null:
    case '':
      return 'Unknown';
    default:
      return carrier;
  }
};

export const RESHIP_CONVERTABLE_CURRENCIES = [
  'AED', 'AFN', 'ALL', 'ARS', 'AUD', 'BDT', 'BGN', 'BHD', 'BOB', 'BRL', 'CAD', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CZK', 'DKK', 'DZD', 'EGP', 'EUR', 'FJD', 'GBP', 'GEL', 'GHS', 'HKD', 'HRK', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'ISK', 'JOD', 'JPY', 'KES', 'KRW', 'KWD', 'KZT', 'LBP', 'LKR', 'MAD', 'MDL', 'MMK', 'MNT', 'MOP', 'MXN', 'MYR', 'NGN', 'NOK', 'NZD', 'PEN', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'SAR', 'SEK', 'SGD', 'THB', 'TRY', 'TWD', 'TZS', 'UAH', 'USD', 'VEF', 'VND', 'ZAR',
];

export const RESHIP_SUPPORTED_CURRENCIES = [
  'CAD', 'USD', 'GBP',
];

export const EU_COUNTRY_CODES = [
  'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'EU',
];

/**
 * Countries that require Europe's Import Control System 2 (ICS2) customs filing requirements. For more information, visit: https://www.fedex.com/en-us/regulatory-news/ics2.html?cmp=EMT-1005960-65-2-200-1101010-GLBL-US-EN-FY23Q3ISC2REGN2&ET_CID=1197784&ET_RID=114698836&LINK=What_you_need_to_know
 */
export const ICS2_REQUIRED_COUNTRY_CODES = [
  ...EU_COUNTRY_CODES, 'NO', 'CH',
];

export const GREEN_STATUS = [
  'Ready to go', 'Ready to go, may require handling fee'
];

export const YELLOW_STATUS = [
  'Pending Consolidation', 'Special Handling - ready to ship', 'Awaiting return label',
];

export const RED_STATUS = [
  'Special Handling Required - Contact Us', 'Processing hazmat',
];

export const CjConversionCode = {
  NEW_SIGNUP: '444192',
  NEW_SUBSCRIPTION: '444193'
}