import React from "react";
import {convertToClientTimestamp, displayDate} from "../../common/utils";

const TrackingInfo = ({trackingData, title}) => {

  return (
    <>
      <div className="tracking-content">
        <div className='tracking-title'>
          <h4>{title}</h4>
          {trackingData?.length ?
            <div className="tracking-carrier">{`Carrier: ${trackingData[0].carrier}`}</div>
            :
            null}
        </div>
        <div className='tracking-detail timeline'>
          {trackingData?.length ?
            trackingData.map(point => {
              return (
                <div className='timeline-event'>
                  <div className='timeline-event-content'>
                    <div className='timeline-event-content-timestamp'>
                      <div>{`${displayDate(convertToClientTimestamp(point.timestamp), 'dd MMM yy, h:mm:ssaaa')}`}</div>
                    </div>
                    <div>
                      <div className='timeline-event-content-description'>{`${point.description}`}</div>
                      {point.location.city &&
                        <div
                          className='timeline-event-content-location'>{`${point.location.city}`} - {`${point.location.state}`} {point.location.country? `- ${point.location.country}` :``}</div>}
                    </div>
                  </div>

                </div>
              )
            })
            :
            <div className="card">Tracking information is not available</div>
          }
        </div>
      </div>
    </>
  )
}

export default TrackingInfo