import React, {Component, Fragment}   from 'react'
import { withRouter }                 from 'react-router-dom'
import {connect}                      from 'react-redux'
import axios                          from 'axios/index'
import {warehouses, shippedStatus }   from '../common/constants'
import GlobalFooter                   from '../components/GlobalFooter'
import GlobalError                    from '../components/GlobalError'
import GlobalNotices                  from '../components/GlobalNotices'
import CustomerSidebar                from '../components/CustomerSidebar'
import CustomerLoading                from '../components/CustomerLoading'
import { displayDate, convertToClientTimestamp } from '../common/utils'
import TrackingLink                   from '../components/TrackingLink'
import { Badge } from 'antd'

const mapStateToProps = (globalStore) => {return {store: globalStore}}

class Shipments extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      initialLoading: true,
      initialLoadingCalled: false,
      initialLoadingError: false,
      
      loading: false,
      message: '',
      
      shipments: []
    }
  }
  
  componentDidMount() {
    if(this.props.store.isLoggedIn===true)
      this.getShipmentsData()
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.store.isLoggedIn===true && !this.state.initialLoadingCalled)
      this.getShipmentsData()
  }
  
  getShipmentsData = () => {
    this.setState({initialLoading: 1})
    
    axios.get('/customer/packages/getShipments')
    .then((response) => {
      this.setState({
        initialLoading: 2,
        shipments: response.data.shipments.slice().sort((a,b)=>{
          let aStartDate = new Date(a.shipmentStart)
          let bStartDate = new Date(b.shipmentStart)
          let aRounded = new Date(aStartDate.getFullYear(), aStartDate.getMonth(), aStartDate.getDate()).valueOf()
          let bRounded = new Date(bStartDate.getFullYear(), bStartDate.getMonth(), bStartDate.getDate()).valueOf()
          return (aRounded===bRounded ? (new Date(a.shipmentDone) > new Date(b.shipmentDone) ? -1 : 1) : (aStartDate > bStartDate ? -1 : 1))
        })
      })
    })
    .catch((error) => {
      this.setState({
        initialLoading: 3,
        initialLoadingError: error.response ? error.response.data.message : error.message
      })
    })
  }
  
  render() {    
    if(this.state.initialLoading < 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <CustomerLoading />
        </div>
      )
    else if (this.state.initialLoading > 2)
      return (
        <div className="body">
          <CustomerSidebar />
          <GlobalError />
        </div>
      )
    
    return (
      <div className="body" id="customer-shipments">
        <CustomerSidebar />
        <div className="content-area">
          <GlobalNotices { ...this.props }/>
          <div className="page-title"><h2>My Shipments</h2></div>
          {this.state.shipments.length===0 ?
            <div className="card card-border p-0">
              <div className="blank-area">
                <img src="assets/images/group.png" alt="groups"/>
                <strong>No shipments yet!</strong>
              </div>
            </div>
            :
            <div className="card-lists">
              {this.state.shipments.map(cp=>{
                // TODO: Add shipping company logos
                
                let status = (cp.shipmentStatus===4) ?
                  {status: 40, text: (<Fragment><strong>Shipped</strong> on <strong>{displayDate(convertToClientTimestamp(cp.shipmentDone), 'dd MMM yyyy')}</strong>. {cp.tracking ? 'Tracking: '+cp.tracking : ''}</Fragment>)} // Blue Blue Blue
                  :
                  shippedStatus(cp.shipmentStatus)

                  const shipmentCard = (
                    <div className={`card card-package justify-content-between card-white`} onClick={()=>{this.props.history.push('/shipment?pkg='+cp.packageId)}} key={cp.packageId}>
                    <div className="package-info shipments-shipment-info">
                      <code style={{fontFamily: "Eruropa regular", fontSize: "16px"}}>{cp.consolidated ? 'Consolidation' : 'Package' } #{cp.packageId.toString().padStart(6, '0')}</code>
                      <div className="shipments-shipment-desc">{displayDate(convertToClientTimestamp(cp.shipmentStart), 'dd MMM yyyy')}</div>
                    </div>
                    <div className="shipments-shipment-icons">
                      <div className="shipments-shipment-flag"><img src={`assets/images/${warehouses.filter(w=>{return w.id===cp.warehouse})[0].flag}`} alt="flag" /></div>
                    </div>
                    <div className="package-info shipments-shipment-data">
                      <div className="shipments-shipment-carrier">{cp.shipmentService}</div>
                      {!!cp.shipmentAddress && <div className="shipments-shipment-destination">{cp.shipmentAddress}</div>}
                    </div>
                    
                    <div className="package-process">
                      <div className="form-row">
                        <div className="col"><div className={`package-step ${status.status > 0 ? (status.status > 9 ? (status.status > 39 ? 'step-success-1' : 'step-success-1') : 'step-incomplete') : ''}`}>&nbsp;</div></div>
                        <div className="col"><div className={`package-step ${status.status > 10 ? (status.status > 19 ? (status.status > 39 ? 'step-success-2' : 'step-success-2') : 'step-incomplete') : ''}`}>&nbsp;</div></div>
                        <div className="col"><div className={`package-step ${status.status > 20 ? (status.status > 29 ? (status.status > 39 ? 'step-success-3' : 'step-complete') : 'step-incomplete') : ''}`}>&nbsp;</div></div>
                      </div>
                      <small>{status.text}{!!cp.shipmentTracking && <Fragment> | <strong>Tracking</strong> {<TrackingLink trackingLink={cp.trackingLink} tracking={cp.shipmentTracking}/>}</Fragment>}</small>

                    </div>
                    <div className="pkg-next"><img src="assets/images/right.svg" alt="right"/></div>
                  </div>
                  )
                  return cp.canClaimInsurance ? (
                    <Badge.Ribbon text='Manage Insurance Claim' color='#36454F'>
                      {shipmentCard}
                    </Badge.Ribbon>
                  ) : (shipmentCard)
                  
                })}
           </div>
          }
        </div>
        <GlobalFooter />
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Shipments))
