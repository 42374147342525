import { message } from 'antd';
import { format } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';

export const parseNumber = (input) => Number(input) || 0;

export const tallyInputTotal = (arr, field) => (arr.length ? arr.reduce((acc, item) => acc + (parseNumber(field ? item[field] : item)), 0) || 0 : 0);

export const localStorageEnabled = () => {
  try {
    const key = 'localStorageTest';
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export const handleError = (error, comment = 'Can not the process request at this time. Please contact customer service.') => {
  // response status code falls out of the range of 2xx
  if (error.response) {
    console.error(error.response.data);
    error.response.data.message ? message.error(error.response.data.message) : message.error(comment);
  }
  // the request was made but no response was received
  else if (error.request) {
    console.error(error);
    message.error('Network Error');
  }
  // the code has an error
  else {
    console.error(error);
    message.error(comment);
  }
};

/**
 * rounds value to precision or to 2 decimal places.
 * if value is less than 1, then will return first 2 non-zero number
 *
 * @param {number} num
 * @param {number} precision
 * @returns
 */
export const round = (num, precision = 2) => {
  try {
    let value;

    if (isNaN(num)) {
      value = 0;
    }

    if (num > 1) {
      value = Number(`${Math.round(`${num}e+${precision}`)}e-${precision}`);
    } else {
      let precisionControl = 1 - Math.floor(Math.log(num) / Math.log(10));

      if (!isFinite(precisionControl)) {
        precisionControl = 2;
      }

      value = +num.toFixed(precisionControl);
    }

    return value;
  } catch (e) {
    console.log(e);
  }
};

const isValidDate = (d) => d instanceof Date && !isNaN(d);

export const displayDate = (timestamp, datePattern) => {
  if (!isValidDate(new Date(timestamp))) {
    return 'N/A';
  }

  return format(new Date(timestamp), datePattern);
};

/**
 * @param {string} date
 */
export const convertToClientTimestamp = (date) => {
  const timezoneOffset = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone, date);
  const timestamp = new Date(date).getTime() + timezoneOffset;
  return new Date(timestamp);
};

