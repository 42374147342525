import React from 'react'
import axios from 'axios'
import GlobalHeader from './GlobalHeader'
import { withRouter } from 'react-router-dom'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.logErrorToDataDog(error, errorInfo)
  }

  logErrorToDataDog = (error) => {
    axios.post('/error', {
      error: error.toString(),
      pathname: this.props.location.pathname
    })
  }

  goBack = () => {
    this.setState({
      hasError: false 
    }, () => this.props.history.goBack())
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <GlobalHeader />
          <div className="content-area">
            <div className="blank-order text-center">
              <div className="bl-card-image">
                <img src="/assets/images/box-error.png" alt="" />
              </div>
              <h4>{this.state.message}</h4>
              <p>Sorry, something went wrong.</p>
              <p>We are working on it and we will get it fixed as soon as we can.</p>
              <br />
              <button className="btn btn-secondary align-self-ctr" style={{width: 200}} onClick={this.goBack}>Go Back</button>
            </div>
          </div>
        </>
      )
    }

    return this.props.children; 
  }
}

export default withRouter(ErrorBoundary)
