import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import ButtonLoading from './ButtonLoading';
import ModalAsync from "./ui/ModalAsync";

function CancelConsolidationModal({ packageInfo, history }) {
  const [consolidationModal, setConsolidationModal] = useState(null);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const exitConsolidation = (value) => {
    setConsolidationModal(value);
    history.push('/packages');
  };

  const closeConsolidationModal = (value) => {
    setConsolidationModal(value);
    setIsLoading(value);
    setMessage('');
  };

  const doCancelConsolidation = async () => {
    try {
      setIsLoading(true)
      setMessage('')

      const postData = {
        packageId: packageInfo.packageId,
      }
      await axios.post('/customer/consolidation/cancel', postData)
      setConsolidationModal(2)
    } catch (e) {
      setConsolidationModal(3)
      setMessage(e.response ? e.response.data.message : e.message)
    } finally {
      setIsLoading(false)
    }
  };

  const confirmCancelFooter = (
    <button className="btn btn-sm btn-primary" onClick={isLoading ? () => {} : doCancelConsolidation}>{isLoading ? <ButtonLoading /> : 'Confirm'}</button>
  )

  const closeModalFooter = (
    <button className="btn btn-sm btn-outline-primary" onClick={exitConsolidation}>Close</button>
  )

  return (
    <>
      <div className="btn package-process-cancel consolidation-modal " onClick={() => setConsolidationModal(1)}>
        <i>
          <svg className="feather">
            <use href="/assets/images/icons/feather-sprite.svg#x" />
          </svg>
        </i>
        {' '}
        Cancel consolidation
      </div>
      {!!consolidationModal && (
        <ModalAsync
          title="Cancel Consolidation Request?"
          visible={consolidationModal === 1}
          setVisible={consolidationModal === 2 ? exitConsolidation : closeConsolidationModal}
          text="This will cancel the package consolidation request and make the individual packages available for other consolidations or shipping. You will not be charged."
          footer={confirmCancelFooter}
        />
      )}
      {consolidationModal === 2 && (
        <ModalAsync
          title="Consolidation Request Cancelled"
          visible={consolidationModal === 2}
          setVisible={exitConsolidation}
          text="Your package consolidation is cancelled."
          footer={closeModalFooter}
        />
      )}
      {consolidationModal === 3 && (
        <ModalAsync
          title="Consolidation Request Failed"
          visible={consolidationModal === 3}
          setVisible={exitConsolidation}
          footer={closeModalFooter}
          children={
            <p>
              Sorry, we could not cancel your consolidation.
              {message}
            </p>
          }
        />
      )}
    </>
  );
}

export default withRouter(CancelConsolidationModal);
