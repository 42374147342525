import React, {Component}     from 'react'
import {connect}              from 'react-redux'
import {Link, withRouter}     from 'react-router-dom'
import {withGoogleReCaptcha}  from 'react-google-recaptcha-v3'
import axios                  from 'axios/index'
import {Helmet}               from 'react-helmet'
import ButtonLoading          from '../components/ButtonLoading'
import TagManager from 'react-gtm-module';

const mapStateToProps = (globalStore) => {return {store: globalStore}}
const mapDispatchToProps = (dispatch) => {return {
  login: (loginData)=>{dispatch({type: 'CUSTOMER_LOGIN', payload: loginData})},
}}


class PublicLogin extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      loading: false,
      message: '',
      
      email: '',
      password: '',
    }
  }

  doStartLogin = async () => {
    if(!this.state.email) {
      this.setState({message: 'Please enter your email address'})
      return; 
    }

    if(!this.state.password) {
      this.setState({message: 'Please enter your password'})
      return; 
    }

    this.setState({ loading: true })
    
    this.props.googleReCaptchaProps.executeRecaptcha('login')
    .then(this.doSubmitLogin)
  }
  
  doSubmitLogin = async (recaptchaV3Response) => {
    if(!this.props.store.clientToken) { // This should hide error 22 by not sending if x-api-key could not be retrieved.
      this.setState({loading: false, message: 'Error: Login expired. Please verify your computer time and refresh the page.'})
      return; 
    }

    try {
      const postData = {
        emailAddress: this.state.email,
        password: this.state.password,
        recaptchaV3Response: recaptchaV3Response,
        loading: true
      }
  
      const response = await axios.post('/customer/web/login', postData)
      const userConfigRes = await axios.get('/userConfig')
 
      this.props.login({
        customer: response.data.data.customer,
        token: response.data.data.token, 
        isFromRegister: false,
        userConfig: userConfigRes.data.userConfig
      })

      window.Moengage.add_unique_user_id(this.state.email)
      .then(() => {
        window.Moengage.add_email(this.state.email)
      })
      .catch((e) => {
        //should log to sentry
        console.log(e)
      })

      this.handleSetUserId(response.data.data.customer.customerId)
      this.handleLogin()
    } 
    
    finally { 
      this.setState({ loading: false })
    }
  }

  handleSetUserId = (userId) => {

    const tagManagerArgs = {
      dataLayer: {
        event: 'userIdSet',
        user_id: userId
      },
      dataLayerName: 'dataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)
  } 

  handleLogin = () => {

    const utmParams = {
      utm_id: localStorage.getItem('utm_id') || '',
      utm_source: localStorage.getItem('utm_source') || '',
      utm_medium: localStorage.getItem('utm_medium') || '',
      utm_campaign: localStorage.getItem('utm_campaign') || '',
    }

    const tagManagerArgs = {
      dataLayer: {
        event: 'login',
        method: 'email',
        utm_id: utmParams.utm_id,
        utm_source: utmParams.utm_source,
        utm_medium: utmParams.utm_medium,
        utm_campaign: utmParams.utm_campaign
      },
      dataLayerName: 'dataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  render() {
    return (
      <div className="card-base-inner">
        <Helmet>
          <title>Reship - Log into your mailbox hub</title>
          <meta name="description" content="Log into your mailbox hub, where you can view and ship packages received at your addresses in the UK, US and Canada." />
        </Helmet>
        {/*<h4 className="text-center">Log In</h4>*/}
        <div className="login-logo">
          <img src="/assets/images/Reship_Logo_Pur.png" alt="logo"/>
        </div>

        <div className="form-group">
          <input type="email" className={`form-control ${this.state.email ? '' : ' login-input-invalid'}`} placeholder="Email Address" value={this.state.email} onChange={(e)=>{this.setState({email: e.target.value})}} onKeyPress={(event)=>{if(event.charCode===13) this.doStartLogin()}} />
        </div>
        <div className="form-group">
          <input type="password" className={`form-control ${this.state.password ? '' : ' login-input-invalid'}`} placeholder="Password" value={this.state.password} onChange={(e)=>{this.setState({password: e.target.value})}} onKeyPress={(event)=>{if(event.charCode===13) this.doStartLogin()}} />
        </div>
        <button className="btn btn-block btn-primary" onClick={this.state.loading ? ()=>{} : this.doStartLogin}>{this.state.loading ? <ButtonLoading /> : 'Login'}</button>
        {this.state.message && !this.state.loading && <div className="login-message">{this.state.message}</div>}
        <div className="card-base-inner bottom-link text-center">
          <Link className="login-link" to="/forgot">Forgot password?</Link>
          <br/>
          No account? <Link className="login-link" to="/plans">Sign up now!</Link>
        </div>
        <div className="card-base-inner bottom-link text-center" style={{padding: 0, paddingTop: 10}}>
          <a href={'https://www.reship.com/promo/'}>Try our app & get $10 OFF</a>
          <div style={{margin:12}}>
            <a href="https://apps.apple.com/us/app/reship/id1511596518" target="_blank"><img style={{width: 150, margin: 12}} src="/assets/images/badge-apple.svg"/></a>
            <a href="https://play.google.com/store/apps/details?id=com.reship&hl=en_CA&gl=US" target="_blank"><img style={{width: 150, margin: 12}} src="/assets/images/badge-google.svg"/></a>
          </div>
          <br />
        </div> 
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withGoogleReCaptcha(PublicLogin)))