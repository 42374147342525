import React, {useState} from "react";
import agent from "../../api/agent";
import ButtonLoading from "../ButtonLoading";
import {withRouter} from "react-router-dom";
import ModalAsync from "../ui/ModalAsync";

const CancelPrereceiveModal = ({ packageId, history }) => {
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleCancel = async () => {

    try {
      setMessage('')
      setIsLoading(true)

      const updatePackageRequestBody = {
        packageId: packageId,
        type: -1,
        disposition: -2
      }

      await agent.pkg.updatePackage(updatePackageRequestBody)

      const addTimelineRequestBody = {
        packageId: packageId,
        eventId: 'package-arriving-cancelled'
      }
      await agent.timeline.addTimeline(addTimelineRequestBody)

      setIsLoading(false)
      setShowModal(false)
      history.push(`/packages`)
    } catch (e) {
      setIsLoading(false)
      setMessage(e.response.data.message)
    }

  }

  const footer = (
    <button className="btn btn-primary" onClick={handleCancel}>{isLoading? <ButtonLoading /> : `Confirm`}</button>
  )

  return (
    <>
      <div className="btn cancel-prereceive-button" onClick={() => setShowModal(true)}>
        <i>
          <svg className="feather">
            <use href="/assets/images/icons/feather-sprite.svg#trash-2" />
          </svg>
        </i>
        Delete Pre Receive Package
      </div>
      {showModal &&
        <ModalAsync
          title="Delete the incoming package?"
          visible={showModal}
          setVisible={setShowModal}
          footer={footer}
          text="Your inbound package information will be removed."
          children={message ? <div className="error-text">{message}</div> : null}
        />
      }
    </>
  )
}

export default withRouter(CancelPrereceiveModal)