import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { error, debug } from '../common/constants';

class GlobalError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: 0,
      message: 'Error',
      description: "We couldn't load the information you were looking for. If the link was correct, please try again later!",
    };
  }

  componentDidMount() {
    if (this.props.error && this.props.error.errorCode) {
      const e = error(this.props.error.errorCode);
      this.setState({
        code: e.id,
        message: e.message,
        description: e.description,
      });
    } else if (this.props.error) {
      this.setState({
        message: this.props.error.message,
      });
    }
  }

  render() {
    return (
      <div className="content-area">
        <div className="blank-order text-center">
          <div className="bl-card-image">
            <img src="/assets/images/box-error.png" alt="" />
          </div>
          <h4>{this.state.message}</h4>
          <p>{this.state.description}</p>
          <br />
          <button className="btn btn-primary align-self-ctr" style={{ width: 200 }} onClick={() => { window.location.reload(true); }}>Retry</button>
        </div>
      </div>
    );
  }
}
export default withRouter(GlobalError);
