import React from 'react';
import Reaptcha from 'reaptcha';
import { constants } from '../../common/constants';

function RecaptchaV2({ onVerify }) {
  return (
    <Reaptcha sitekey={constants.recaptchaKey} onVerify={onVerify} />
  );
}

export default RecaptchaV2;
