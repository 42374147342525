import countryRegionData from '@monarkit/postal-country-region-data';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

export const countryText = (countryCode, forced) => {
  if (!countryCode) return '';

  const matchedCountry = countryRegionData.filter((c) => c.countryShortCode === countryCode);
  if (!matchedCountry.length) return forced ? countryCode : ''; // We can force it to just spit the code out for display purposes if its using obsolete data
  return matchedCountry[0].countryName.toUpperCase();
};

export const regionText = (countryCode, stateCode, forced) => {
  if (!countryCode || !stateCode) return ''; // No country or stateCode defined

  const matchedCountry = countryRegionData.filter((c) => c.countryShortCode === countryCode);
  if (!matchedCountry.length) return ''; // No such country
  if (!matchedCountry[0].regions.length) return ''; // This country has no regions.

  const matchedRegion = matchedCountry[0].regions.filter((r) => r.shortCode === stateCode);
  if (!matchedRegion.length) return forced ? stateCode : ''; // We can force it to just spit a deprecated statecode out for display purposes
  if (matchedRegion[0].addressText) return matchedRegion[0].addressText.toUpperCase();
  if (isNaN(matchedRegion[0].shortCode)) return matchedRegion[0].shortCode;
  return matchedRegion[0].name.toUpperCase();
};

export const regionPaypal = (countryCode, stateCode) => {
  if (!countryCode || !stateCode) return '';

  const matchedCountry = countryRegionData.filter((c) => c.countryShortCode === countryCode);
  if (!matchedCountry.length || !matchedCountry[0].regions || !matchedCountry[0].regions.length) return '';

  const matchedRegion = matchedCountry[0].regions.filter((r) => r.shortCode === stateCode);
  if (!matchedRegion.length) return '';
  if (matchedRegion[0].paypalCode) return matchedRegion[0].paypalCode;
  return matchedRegion[0].shortCode;
};

export const validateCountry = (countryCode) => countryText(countryCode) !== ''; // To be valid, the countrycode must be supplied and be matched with a country in the database.

export const validateRegion = (countryCode, stateCode) => {
  if (!countryCode) return false; // No country defined
  const matchedCountry = countryRegionData.filter((c) => c.countryShortCode === countryCode);
  if (!matchedCountry.length) return false; // No such country
  if (!matchedCountry[0].regions.length) {
    if (stateCode) return false; // A region is provided where the country has none
    return true; // No region provided and country has none. Valid.
  }
  // Country has regions
  const matchedRegion = matchedCountry[0].regions.filter((r) => r.shortCode === stateCode);
  if (!matchedRegion.length) return false; // This country has regions but no stateCode matched. Invalid.
  return true; // Region present in country list. Valid.
};

export const filterValidCountry = (countryCode) => (validateCountry(countryCode) ? countryCode : '');

export const filterValidRegion = (countryCode, stateCode) => (validateRegion(countryCode, stateCode) ? stateCode : '');

export const filterCode = (input, limit) => input.replace(/[^A-Za-z0-9\-\s]/g, '').slice(0, limit || 45);

// This regex filters out all non-latin characters as well as a few technical characters.
// This character space was derived from latin characters being generally supported by shipping carriers.
// The default behaviour on labels seems to be decomposing them to their base ASCII characters.
// Non-latin characters in addresses are replaced by ? or throw errors so they must not be allowed to be submitted.
// Instead, non-latin script like arabic and asian ideographs should have customer-explicitly-aware resolution pathways on the client-side.
export const filterStr = (input, limit) => (input ? input.replace(/[<>*"`\\]|[^ -\u024F]/g, '').slice(0, limit || 255) : '');

export const filterTxt = (input) => (input ? input.replace(/[<>{}*"`\\]/g, '') : '');

export const filterNotes = (input) => (input ? input.replace(/["`]/g, "'").replace(/[<>{}\\]/g, '').slice(0, 500) : '');

export const filterPhone = (input) => (input ? input.replace(/[^0-9xX+\-\s]/g, '').slice(0, addressCharLimits.phone) : '');

export const validatePhone = (input, countryCode) => {
  const pn = parsePhoneNumberFromString(input, countryCode || undefined);
  return !!pn && pn.isValid();
};

export const displayPostalCode = (postalCode) => !!postalCode && postalCode !== '00000';

export const specialCharacterMessage = 'Sorry, address fields cannot have special characters to prevent shipping issues. Foreign language address information can be added in the delivery notes.';

export const addressCharLimits = {
  name: 34, // DHL 35
  streets: 35, // Easypost 45. **Changed to 35 on Dec 20, 2022 because too many characters results in not being able to intelligibly describe the address to the carriers
  city: 28, // USPS 28
  phone: 15, // Easypost 15
  postalCode: 15, // Easypost 9 but seems too low. Use validation instead.
  notes: 500,
};

export const cleanBillingAddress = (txt) => txt.trim().replace(/[^a-zA-Z0-9/\-_.\s():;',"{}]/gi, '').trim();
