import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import GlobalHeader from '../components/GlobalHeader';
import GlobalFooter from '../components/GlobalFooter';
import PublicCalculator from '../components/PublicCalculator';

import TagManager from "react-gtm-module";

const mapStateToProps = (globalStore) => ({ store: globalStore });

class Calculator extends Component {

  componentDidMount() {
    this.handleCjPageType()
  }

  handleCjPageType = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'cj_page_view',
        pagePath: this.props.location.pathname,
        pageType: 'information',
      },
      dataLayerName: 'dataLayer'
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  render() {
    return (
      <div className="fullbody" id="calc">
        <GlobalHeader />
        <Helmet>
          <title>Reship - Shipping calculator - Get estimates of Reshipping costs</title>
          <meta name="description" content="Understand and calculate how much it would cost to ship your package with ReShip. We guarantee that our prices are the most competitive." />
        </Helmet>
        <div className="calc-header">
          <div className="calc-header-title">Shipping Calculator</div>
          <div className="calc-header-subtitle">Get estimates of shipping costs from our locations to you</div>
        </div>
        <PublicCalculator />
        <GlobalFooter />
      </div>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Calculator));
