import React, {Component}   from 'react'
import {withRouter}         from 'react-router-dom'
import {connect}            from 'react-redux'
import {withGoogleReCaptcha}from 'react-google-recaptcha-v3'
import axios                from 'axios/index'
import jwtDecode            from 'jwt-decode'
import GlobalHeader         from '../components/GlobalHeader'
import GlobalFooter         from '../components/GlobalFooter'
import ButtonLoading        from '../components/ButtonLoading'
import {Helmet}             from 'react-helmet'
import RecaptchaV2          from '../components/RecaptchaV2/recaptchaV2'   

const mapStateToProps = (globalStore) => {return {store: globalStore}}
const mapDispatchToProps = (dispatch) => {return {
  logout: () => {dispatch({ type: 'CUSTOMER_LOGOUT' })},
}}

class Recover extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      message: '',
      page: 0,
      
      recoveryCode: '',
      password: '',
      password2: '',
      isRecaptcha2Required: false, 
      recaptchaV2Response: '', 
    }
  }
  
  componentDidMount() {
    this.props.logout()
    
    let recoveryCode = this.props.match.params.recoverCode

    if(recoveryCode){
      let recoverObject = {}
      recoverObject = jwtDecode(recoveryCode)

      if(recoverObject.data.purpose==='reset' && recoverObject.data.email){
        if(recoverObject.exp*1000 < Date.now())
          this.setState({page: 1})
        else
          this.setState({page: 2, recoveryCode: recoveryCode })
      } 
    }
  }
  
  doSubmitNewPassword = async () => {
    if(!this.state.password)
      this.setState({message: 'Please enter a password.'})
    else if(this.state.password.length < 8)
      this.setState({message: 'Your password needs to be at least 8 characters long.'})
    else if(this.state.password!==this.state.password2)
      this.setState({message: 'Passwords do not match'})
    else {
      try {
        this.setState({loading: true})
  
        const recaptchaV3Response = await this.props.googleReCaptchaProps.executeRecaptcha('recoverPassword')
  
        let postData = {
          password: this.state.password,
          recoverCode: this.state.recoveryCode,
          recaptchaV3Response: recaptchaV3Response
        }
  
        
        if (this.state.isRecaptcha2Required && this.state.recaptchaV2Response) {
          postData.recaptchaV2Response = this.state.recaptchaV2Response
        }

        const response =  await axios.post('/customer/recoverPassword', postData)
  
        if(response.data.success){
          this.setState({
            loading: false,
            page: 3,
            password: '',
            password2: ''
          })
        } else {
          this.setState({loading: false, message: response.data.message})
        }

      } catch (e) {
        this.setState({loading: false })
      }
    }
  }

  onVerifyRecaptchaV2 = (response) => {
    this.setState({recaptchaV2Response: response})
  }
  
  render() {
    return (
      <div className="fullbody" id="login">
        <GlobalHeader />
        <Helmet>
          <title>Reship - Reset your password</title>
        </Helmet>
        <div id="login-container">
          <div className="left-area halfscreen d-flex">
            <div id="login-area">
              <div className="card card-base">
                {this.state.page===0 &&
                  <div className="card-base-inner">
                    <div className="card-base-inner bottom-link text-center">
                      Invalid password recovery email link
                      <br />
                      <div className="login-link" onClick={()=>{this.props.history.push('/login')}}>Return to Login</div>
                    </div>
                  </div>
                }
                {this.state.page===1 &&
                  <div className="card-base-inner">
                    <div className="card-base-inner bottom-link text-center">
                      Your password recovery email link has expired.
                      <br />Please try getting a new password reset email.
                      <br />
                      <br />
                      <div className="login-link" onClick={()=>{this.props.history.push('/login')}}>Return to Login</div>
                    </div>
                  </div>
                }
                {this.state.page === 2 &&
                  <div className="card-base-inner">
                    <div className="card-base-inner bottom-link text-center">Please provide a new password for your account.</div>
                    <div className="form-group">
                      <input type="password" className="form-control" placeholder="Password" value={this.state.password} onChange={(e)=>{this.setState({password: e.target.value})}} />
                    </div>
                    <div className="form-group">
                      <input type="password" className="form-control" placeholder="Repeat password" value={this.state.password2} onChange={(e)=>{this.setState({password2: e.target.value})}} />
                    </div>
                    <div className="btn btn-primary" onClick={this.state.loading ? ()=>{} : this.doSubmitNewPassword}>{this.state.loading ? <ButtonLoading/> : 'Set Password'}</div>
                    {this.state.message && !this.state.loading && <div className="login-message">{this.state.message}</div>}
                    {!!this.state.isRecaptcha2Required && (
                      <div className="form-group">
                        <RecaptchaV2 onVerify={this.onVerifyRecaptchaV2}/>
                      </div>
                    )}
                  </div>
                }
                {this.state.page === 3 &&
                  <div className="card-base-inner">
                    <div className="card-base-inner bottom-link text-center">Password reset successful! Please log in with your new password.</div>
                    <div className="card-base-inner bottom-link text-center">
                      <div className="login-link" onClick={()=>{this.props.history.push('/login')}}>Return to Login</div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="right-area halfscreen d-flex">
            <div className="half-container">
              <h3>Shop beyond boundaries</h3>
              <div className="card card-package card-black">
                <div className="package-icon"><img src="/assets/images/usa-flag.png" alt="usa" /></div>
                <div><code>#1683664</code><h4>Package 1 <span className="badge badge-light">1 item</span></h4></div>
              </div>
              <div className="card card-package card-black">
                <div className="package-icon"><img src="/assets/images/canada-flag.png" alt="uk" /></div>
                <div><code>#1683664</code><h4>Package 2 <span className="badge badge-light">1 item</span></h4></div>
              </div>
              <div className="card card-package card-black">
                <div className="package-icon"><img src="/assets/images/uk-flag.png" alt="usa" /></div>
                <div><code>#1683664</code><h4>Package 3 <span className="badge badge-light">2 item</span></h4></div>
              </div>
            </div>
          </div>
        </div>
        <GlobalFooter />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withGoogleReCaptcha(Recover)))
