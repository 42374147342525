import React from 'react';
import {Button, Form, Input} from 'antd';

function ConsigneeIDForm({addressValue, onSubmit}) {

  const onFinish = (values) => {
    onSubmit(values)
  };

  return (
    <div className="consignee-id-form">
      <Form
        name="basic"
        labelCol={{span: 6}}
        wrapperCol={{span: 16}}
        style={{textAlign: 'left', marginTop: '15px'}}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Consignee National ID"
          name="consigneeId"
          style={{ marginBottom: '8px' }}
          initialValue={addressValue.consigneeId}
          rules={[
            {
              required: true,
              message: 'Consignee National ID is required',
            },
            {
              pattern: /^\d{10}$/,
              message: 'Consignee National ID must be a 10-digit number',
            },
          ]}
        >
          <Input/>
        </Form.Item>

        {addressValue.countryCode === 'SA' && <Form.Item
          label="Consignee Birth Date"
          name="consigneeBirthDate"
          style={{ marginBottom: '8px' }}
          initialValue={addressValue.consigneeBirthDate}
        >
          <Input type="date"/>
        </Form.Item>}

        {addressValue.countryCode === 'SA' && <Form.Item
          label="National ID Expiry Date"
          name="consigneeIdExpiryDate"
          style={{ marginBottom: '8px' }}
          initialValue={addressValue.consigneeIdExpiryDate}
        >
          <Input type="date"/>
        </Form.Item>}

        <Form.Item
          wrapperCol={{offset: 19}}
          style={{ marginBottom: '3px' }}
        >
          <Button type="primary" htmlType="submit" style={{backgroundColor: '#84329B', borderColor: '#84329B', borderRadius: '4px'}} >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ConsigneeIDForm;
