import React, {useEffect, useState} from "react";
import agent from "../../api/agent";
import ButtonLoading from "../ButtonLoading";
import {Select} from "antd";
import ModalAsync from "../ui/ModalAsync";

const PrereceiveModal = ({getPackage, warehouse, carrierList}) => {
  const [showModal, setShowModal] = useState(false)
  const [preReceivePkg, setPreReceivePkg] = useState({trackingCode: null, carrier: null, warehouse: warehouse})
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const options = carrierList.map(carrier => {
    return {
      label: carrier.name,
      value: carrier.slug
    }
  })

  const warehouseOption = [
    {value: 1, label: 'US Mailbox'},
    {value: 2, label: 'UK Mailbox'},
    {value: 3, label: 'Canada Mailbox'}
  ]
  const handleInput = (e) => {
    const preReceivePkgClone = {...preReceivePkg}
    const name = e.target.name
    const value = e.target.value

    preReceivePkgClone[name] = value
    setPreReceivePkg(preReceivePkgClone)
  }

  const handleCarrierChange = (value) => {
    const preReceivePkgClone = {...preReceivePkg}
    preReceivePkgClone['carrier'] = value
    setPreReceivePkg(preReceivePkgClone)
  }

  const handleWarehouseChange = (value) => {
    const preReceivePkgClone = {...preReceivePkg}
    preReceivePkgClone['warehouse'] = value
    setPreReceivePkg(preReceivePkgClone)
  }

  const handleSubmit = async () => {
    if (!preReceivePkg.trackingCode) {
      setMessage('Please enter the tracking number')
      return
    }

    if (!preReceivePkg.warehouse) {
      setMessage('Please select destination warehouse')
      return
    }

    try {
      setMessage('')
      setIsLoading(true)

      const requestBody = {
        warehouse: preReceivePkg.warehouse,
        source: 3,
        incomingTrackingInfo : [{
          tracking: preReceivePkg.trackingCode,
          carrier: preReceivePkg.carrier
        }]
      }

      await agent.prereceiving.startPreReceiving(requestBody)
      await getPackage()

      setIsLoading(false)
      setShowModal(false)

    } catch (e) {
      setIsLoading(false)
      setMessage(e.response.data.message)
    }

  }

  useEffect(() => {
    setMessage('')
  }, [showModal])

  const content = (
    <div>
      <div className="form-group">
        <div className="address-form-label">Tracking Number</div>
        <input className="form-control login-input" type="string" name="trackingCode" value={preReceivePkg.trackingCode} onChange={event => handleInput(event)}/>
      </div>
      <div className="form-group">
        <div className="address-form-label">Carrier Name</div>
        <Select
          showSearch
          style={{ width: '100%' }}
          className="carrier"
          placeholder="Please select Carrier"
          onChange={handleCarrierChange}
          options={options}
        />
      </div>
      <div>
        <div className="address-form-label">Destination Mailbox</div>
        <Select
          showSearch
          style={{ width: '100%' }}
          placeholder="Please select mailbox"
          onChange={handleWarehouseChange}
          options={warehouseOption}
          defaultValue={warehouse}
        />
      </div>
      {!!message && <div className="error-text">{message}</div>}
    </div>
  )

  const footer = (
    <button className="btn btn-primary" onClick={handleSubmit}>{isLoading? <ButtonLoading /> : `Submit`}</button>
  )

  return (
    <>
      <div className="packages-buttons" onClick={() => setShowModal(true)}>
        <button className="btn btn-primary mr-1">INBOUND PACKAGE NOTIFICATION</button>
      </div>
      {showModal &&
        <ModalAsync
          title="Notify Us About an Incoming Package"
          visible={showModal}
          setVisible={setShowModal}
          footer={footer}
          text="Please provide details on the package that is on the way to Reship."
          children={content}
        />
      }
    </>
  )
}

export default PrereceiveModal