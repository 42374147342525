import React from 'react';
import WarningMessage from '../ui/WarningMessage';

const ICS2_MORE_INFO_LINK = 'https://taxation-customs.ec.europa.eu/customs-4/customs-security/import-control-system-2-ics2-0/import-control-system-2-release-2_en';

function EoriWarningMessage() {
  return (
    <WarningMessage>
      Please note that not including an EORI number may cause delays for shipments destined to the EU, Switzerland, and Norway.
      For more information,
      {' '}
      <a href={ICS2_MORE_INFO_LINK} target="_blank" rel="noreferrer">please click here.</a>
    </WarningMessage>
  );
}

export default EoriWarningMessage;
