import React, {Fragment} from "react";
import ModalAsync from "./ui/ModalAsync";
import {convertToClientTimestamp, displayDate} from "../common/utils";
import ButtonLoading from "./ButtonLoading";

const SwitchPlanModal = ({doPrompt, currentPlan, selectedPlan, subscription, isLoading, cancelPrompt, switchPlan }) => {

  const switchPlanContent = (
    <div>
      <div className="plan-prompt-p">You have chosen to switch from <span style={{fontWeight: 'bold'}}>{currentPlan.planName}</span> to <span style={{fontWeight: 'bold'}}>{selectedPlan.planName}</span>.</div>
      {selectedPlan.planCode==='FREE' &&
        <Fragment>
          <div className="plan-prompt-p">Your current plan will continue until it expires on {displayDate(convertToClientTimestamp(subscription.subscriptionExpiry), 'dd MMMM yyyy')} and will not renew. No further charges will be made.</div>
          <div className="plan-prompt-p">Are you sure you wish to end your subscription?</div>
        </Fragment>
      }
      {(selectedPlan.planPriority < currentPlan.planPriority && selectedPlan.planId!==1) &&
        <div className="plan-prompt-p">The new plan will take effect at the end of your current billing cycle, at which point you will be billed the new rate. Are you sure you wish to switch?</div>
      }
      {selectedPlan.planPriority >= currentPlan.planPriority &&
        <Fragment>
          <div className="plan-prompt-p">The switch will take effect immediately. If the new plan costs more than the existing plan, you will be charged the difference in costs, pro-rated to the amount of days left in your billing cycle.</div>
          <div className="plan-prompt-p">Do you wish to continue?</div>
        </Fragment>
      }
    </div>
  )

  const switchPlanFooter = (
    <div className="btn btn-sm btn-primary" onClick={switchPlan}>{isLoading ? <ButtonLoading/> : `Confirm`}</div>
  )

  return (
    <>
      {doPrompt === 1 &&
        <ModalAsync
          title="Confirm plan change"
          visible={doPrompt === 1}
          setVisible={cancelPrompt}
          children={switchPlanContent}
          footer={switchPlanFooter}
        />
      }
    </>
  )
}

export default SwitchPlanModal