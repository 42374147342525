import axios from 'axios';

const storageAvailable = (type) => {
  try {
    const storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * if client browser has localStorage disabled, or the check for it proves false then fall to catch block and return undefined
 * if redux rehydrates state with undefined, it will load the intialState defined in the reducer
 */
const loadState = () => {
  try {
    if (!storageAvailable('localStorage')) {
      throw new Error('localStorage is not available');
    }

    const serializedState = window.localStorage.getItem('session');

    if (serializedState === null) {
      return undefined;
    }

    // axios.defaults.headers['Authorization'] = 'Bearer ' + JSON.parse(serializedState).customerToken;
    axios.defaults.headers['x-api-key'] = JSON.parse(serializedState).clientToken;

    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

/**
 * if client browser has localStorage disabled or error occurs in setting item, then fall to catch block and
 * the state won't be persisted/cached in localStorage, session persistence will be disabled.
 */
const saveState = (state) => {
  try {
 
    if (!storageAvailable('localStorage')) {
      throw new Error('localStorage is not available');
    }

    const serializedState = JSON.stringify(state);
    localStorage.setItem('session', serializedState);
  } catch (e) {
    console.log(e.message);
  }
};

export { loadState, saveState };
