import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Tag, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

const PlanHeader = ({ title, price, isAnnual, type, component }) => {

  const renderTags = () => {
    if(type === 'grandfathered' || type === 'custom') {
      return <div className="pricing-table-info" style={{ textAlign: 'center' }}> { type } </div>
    } else if (isAnnual) {
      return (
        <div style={{display: 'flex', justifyContent: 'center' }}>
          <Tag color="#cd201f"> Save 2 Months </Tag>
        </div>
      )
    }

    return <div style={{ height: 20 }}/>

  }

  return (
    <th>
      <div style={{ minWidth: 100, width: 150 }}>
        <h1>{ title }</h1>
        { renderTags() }
        <p className="pricing">
            <b>${ price } </b>
            <span> { isAnnual ? '/yr' : '/mo' } </span>
        </p>
        { component }
      </div>
    </th>
  )
}

const PlanRow = ({ title, description, select, type, plans, tooltip}) => {
  return (
    <tr>
        <td> { title }  { tooltip ? <Tooltip title={tooltip}> <InfoCircleOutlined/> </Tooltip>  : null } <div className="pricing-table-info"> { description } </div></td>
        {
          plans.map((item, key) => {

            if(type === 'planFees') {
              const amount = item[type].find(item =>  item.name === select)?.amount 
              const isEnabled = item[type].find(item =>  item.name === select)?.enabled 

              if(!isEnabled) {
                return <td key={key}> - </td>
              }
              
              return (
                <td key={key}> { amount > 0 ? '$' + item[type].find(item =>  item.name === select)?.amount : 'FREE'} </td>
                )
            }
    
            if(type === 'planRules') {
              let check =  <div className="pricing-check"> <i><svg className="feather"><use href="/assets/images/icons/feather-sprite.svg#check"/></svg></i> </div>

              if (select === 'CAN_CONSOLIDATE_OVER_5') {
                const tooltipDesc = (
                  <div>
                    Conditions: 
                    <ul>
                      <li> - Resulting package can not exceed 70lbs (31.75 kg) </li>
                      <li> - Resulting package can not exceed linear dimensions  (L + W + H) greater than 203cm </li>
                      <li> - No more than 50 packages  </li>
                    </ul>
                  </div>
                )

                
                let additionalInfo = <> <span> Unlimited </span> <Tooltip title={tooltipDesc}>  <InfoCircleOutlined/> </Tooltip></> 

                if(!!item[type].find(item => item.enabled && item.name === 'CAN_CONSOLIDATE') && !!item[type].find(item => !item.enabled && item.name === 'CAN_CONSOLIDATE_OVER_5')) {
                  if (item.planCode === 'free') {
                    return (
                      <td key={key}> 2 </td>
                    )
                  }

                  return (
                    <td key={key}> 5 </td>
                  )
                }

                return (
                  <td key={key}> 
                    {
    
                      item[type].find(item =>  item.name === select)?.enabled ? 
                      additionalInfo
                      : '-'
  
                    }
                  </td>
                )
              } 
              else {
                return (
                  <td key={key}> 
                    {
    
                      item[type].find(item =>  item.name === select)?.enabled ? 
                      check
                      : '-'
  
                    }
                  </td>
                )
              }
            }

            

            return <td key={key}> - </td>
          })
        }
    </tr>
  )
}

const Plans = ({ plans, subscription, selectPlan }) => {


  const [ showPlan, setShowPlan ] = useState('')

  useEffect(() => {

  }, [showPlan])

  //specifically a selected plan that is grandfathered or custom 
  const customOrGrandfatheredPlan  = plans.find(item => item.type === 'grandfathered' || item.type === 'custom' || item.type === 'hidden')
  const defaultPlans = plans.filter(item => !['grandfathered', 'custom', 'hidden'].includes(item.type))
  
  const allPlans = [customOrGrandfatheredPlan ? customOrGrandfatheredPlan : null, ...defaultPlans ].filter(item => !!item)

  const free = defaultPlans.find(item => item.planCode === 'free')
  const gold = defaultPlans.find(item => item.planCode === 'gold')
  const goldyear = defaultPlans.find(item => item.planCode === 'goldyear')
  const platinum = defaultPlans.find(item => item.planCode === 'platinum')
  const platinumyear = defaultPlans.find(item => item.planCode === 'platinumyear')

  const renderSelectButton = (plan) => {
    if(plan.status === 'selected' && !['free', 'FREE', 'staff'].includes(plan.planCode)) {
      return (
        <div className="plan-button plan-btn-active" onClick={() => {selectPlan(plan.planId)}}> 
          Current/Update 
        </div>     
      )
    } else if (plan.status === 'selected' && ['free', 'FREE', 'staff'].includes(plan.planCode)) {
          return (
            <div className="plan-button plan-btn-active" style={{ width: '80%' }}> 
              Current 
            </div>    
          )
    }

    if(plan.status === 'pending') {
      return <div className="plan-button plan-btn-pending">Pending</div>
    }

    return (
      !!subscription?.pendingPlanId ? 
        <div className="plan-button plan-btn-disabled">Select</div> : 
        <div className="plan-button plan-btn-select" onClick={() => {selectPlan(plan.planId)}}>Select</div>
    )
  }

  const planFeeMap = {
    'RECEIVING_FEE': 'Receiving Fee',
    'OVERSIZED_RECEIVING_FEE': 'Oversized Receiving Fee', 
    'PALLET_SIZED_RECEIVING_FEE': 'Pallet-sized Receiving Fee',
    'CONSOLIDATION_FEE': 'Consolidation Fee', 
    'PHOTOGRAPH_FEE': 'Photograph Fee', 
    'EXTRA_PADDING_FEE': 'Extra Padding Fee', 
    'SETUP_FEE': 'Annual Maintenence Fee', 
    'REPACK_FEE': 'Repack Fee',
    'GIFT_WRAP_FEE': 'Gift Wrap Fee', 
    'REMOVE_INVOICE_FEE': 'Remove Invoice Fee', 
    'REQUEST_FEE': 'Request Fee',
    'CONSOLIDATION_PACKING_MATERIAL_FEE': 'Consolidation Packing Material Fee'
  }

  const planRuleMap = {
    CAN_CONSOLIDATE:'Consolidation',
    CAN_CONSOLIDATE_OVER_5: ' Consolidation Limit',
    CAN_ALLOW_COMPLEX_CONSOLIDATION: 'Complex Consolidations',
    CAN_RECEIVE_OVERSIZED_PACKAGES: 'Receive Oversized Packages',
    CAN_ALLOW_SPECIAL_REQUESTS: 'Special Requests',
    CAN_ALLOW_ADD_PADDING: 'Extra Padding', 
    CAN_ALLOW_PHOTOGRAPHS: 'Photographs '
  }

  const handleShowPlan = (planCode) => {
    if(showPlan === '' || !showPlan.length) {
      setShowPlan(planCode)
    } else {
      setShowPlan('')
    }
  }

  return (
    <div>
      <div className="gutter-lr elem-strached">
        <div>
          <section className="pricing-area">
            <div className="section-heading text-left">
            </div>
            <div className="mobile-pricing-table-container">
            {
              plans.map((item, i) => {
                const planFees = item.planFees
                const planRules = item.planRules
      
                return (
                  <div className="mobile-pricing-table" key={i}>
                    <h1> { item.planName.toUpperCase() } </h1>           
                    { item.planInterval === 2 ? <Tag color="#cd201f"> Save 2 Months </Tag> : null }
                    <p className="pricing">  <b>$ { item.planRate.toFixed(2) } </b> </p>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      { renderSelectButton(item) }
                    </div>
                    <div onClick={() => { handleShowPlan(item.planCode)}} style={{ marginTop: 15, cursor: 'pointer'  }}>+ Pricing Details</div>
                    <CSSTransition
                      in={item.planCode === showPlan}
                      timeout={300}
                      classNames="pricing-table"
                      unmountOnExit
                    >
                      <table className="pricing-table">
                        <tbody>
                            {
                              planFees.map((item, i) => {

                                if(item.name === 'REPACK_FEE') {
                                  return (
                                    null
                                  )
                                }

                                if(item.name === 'GIFT_WRAP_FEE') {
                                  return (
                                    null
                                  )
                                }

                                if(item.name === 'REMOVE_INVOICE_FEE') {
                                  return (
                                    null
                                  )
                                }

                                if(item.name === 'PHOTOGRAPH_FEE') {
                                  return (
                                    <tr key={i}>
                                      <td> <> <span> {planFeeMap[item.name]} </span></> </td>
                                      <td>{ item.enabled ? `$${item.amount}` : `-` }</td>
                                    </tr>
                                  )
                                }


                                if(item.name === 'RECEIVING_FEE') {
                                  return (
                                    <tr key={i}>
                                      <td> <> <span> {planFeeMap[item.name]} </span></>  <div className="pricing-table-info"> Per Package </div> </td>
                                      <td>{ item.enabled ? `$${item.amount}` : `-` }</td>
                                    </tr>
                                  )
                                }

                                if(item.name === 'CONSOLIDATION_FEE') {
                                  return (
                                    <tr key={i}>
                                      <td> <> <span> {planFeeMap[item.name]} </span></>  <div className="pricing-table-info"> Per Package Consolidated </div> </td>
                                      <td>{ item.enabled ? `$${item.amount}` : `-` }</td>
                                    </tr>
                                  )
                                }

                                if(item.name === 'CONSOLIDATION_PACKING_MATERIAL_FEE') {
                                  return (
                                    <tr key={i}>
                                      <td> <> <span> {planFeeMap[item.name]} </span></>  <div className="pricing-table-info"> Per Consolidation </div> </td>
                                      <td>{ item.enabled ? `$${item.amount}` : `-` }</td>
                                    </tr>
                                  )
                                }

                                if(item.name === 'OVERSIZED_RECEIVING_FEE') {
                                  return (
                                    <tr key={i}>
                                      <td> <> <span> Oversized Package Receiving Fee </span><Tooltip title={'Packages with a linear dimension (L + W + H) exceeding 203cm are considered oversized'}>  <InfoCircleOutlined/> </Tooltip > <div className="pricing-table-info"> Per Package </div></> </td>
                                      <td>{ item.enabled ? `$${item.amount}` : `-` }</td>
                                    </tr>
                                  )
                                }

                                if(item.name === 'SETUP_FEE') {
                                  return (
                                    <tr key={i}>
                                      <td> <span> {planFeeMap[item.name]} </span>  </td>
                                      <td>{ item.enabled && item.amount > 0 ? `$${item.amount}` : `FREE` }</td>
                                    </tr>
                                  )
                                }

                                return (
                                  <tr key={i}>
                                    <td> {planFeeMap[item.name]} </td>
                                    <td>{ item.enabled ? `$${item.amount}` : `-` }</td>
                                  </tr>
                                )
                              })
                            }
                             <tr className="pricing-table-title">
                                  <td colSpan="2">
                                      Services
                                  </td>
                              </tr>
                             {
                              planRules.map((item, i) => {
                                let check =  <div className="pricing-check"> <img src="/assets/images/plancheck.svg" alt=""/> </div>

                                if(item.name === 'CAN_ALLOW_SPECIAL_REQUESTS') {

                                  const tooltipDesc = (
                                    <div>
                                      Common special requests: 
                                      <p> </p>
                                      <ul>
                                        <li> - Deconsolidation </li>
                                        <li> - Splitting Packages </li>
                                        <li> - Reducing Package Size </li>
                                        <li> - Content Count & Description </li>
                                        <li> - Consolidation Restriction Bypass </li>
                                      </ul>
                                    </div>
                                  )

                                  
                                  return (
                                    <tr key={i}>
                                      <td>{ planRuleMap[item.name] } <Tooltip title={tooltipDesc}> <InfoCircleOutlined/> </Tooltip> </td>
                                      <td> 
                                      { item.enabled ? check : '-' }
                                      </td>
                                    </tr>
                                  )
                                }

                                if(item.name === 'CAN_CONSOLIDATE_OVER_5') {
                   
                                  if(item.planCode === 'gold' || item.planCode === 'goldyear' && !item.enabled) {
                                    return (
                                      <tr key={i}>
                                        <td> { planRuleMap[item.name] } <div className="pricing-table-info"> Maximum number of packages per consolidation </div> </td>
                                        <td> 5 </td>
                                      </tr>
                                    )
                                  }

                                  if(item.planCode === 'platinum' || item.planCode === 'platinumyear' && item.enabled) {
                                    const tooltipDesc = (
                                      <div>
                                        Conditions: 
                                        <ul>
                                          <li> - Resulting package can not exceed 70lbs </li>
                                          <li> - Resulting package can not exceed linear dimensions  (L + W + H) greater than 203cm </li>
                                          <li> - No more than 50 packages  </li>
                                        </ul>
                                      </div>
                                    )
                    
                                    let additionalInfo = <> <span> Unlimited </span> <Tooltip title={tooltipDesc}>  <InfoCircleOutlined/> </Tooltip></> 
                                    
                                    return (
                                      <tr key={i}>
                                        <td> { planRuleMap[item.name] } <div className="pricing-table-info"> Maximum number of packages per consolidation </div> </td>
                                        <td> { additionalInfo } </td>
                                      </tr>
                                    )
                                  }
                                  
                                  return (
                                    <tr key={i}>
                                      <td>{ planRuleMap[item.name] } </td>
                                      <td> 
                                      { item.enabled ? check : '-' }
                                      </td>
                                    </tr>
                                  )
                                }

                                if(item.name === 'CAN_RECEIVE_OVERSIZED_PACKAGES') {
                                  return;
                                }

                                return (
                                  <tr key={i}>
                                    <td>{ planRuleMap[item.name] } </td>
                                    <td> 
                                     { item.enabled ? check : '-' }
                                    </td>
                                  </tr>
                                )
                              })
                            }
                            <tr className="pricing-table-title">
                                <td colSpan="2">
                                    Storage
                                </td>
                            </tr>
                            <tr>
                                <td>Free - Regular Size Packages <div className="pricing-table-info"> Free Storage Days </div></td>
                                <td>{ item.storageFreeDays } Days</td>
                            </tr>
                            <tr>
                                <td>Free - Oversized Packages  <div className="pricing-table-info"> Free Storage Days </div></td>
                                <td> { item.storageOversizeFreeDays } Days</td>
                            </tr>
                            <tr>
                                <td>Free - Pallet-sized Packages  <div className="pricing-table-info"> Free Storage Days </div></td>
                                <td> { item.storagePalletFreeDays } Days</td>
                            </tr>
                            <tr>
                                <td>Regular Size Packages <Tooltip title={"Only applicable after the free storage period has ended"}> <InfoCircleOutlined/> </Tooltip><div className="pricing-table-info">Storage Fee / Day</div></td>
                                <td>${ item.storageDayFee }/day</td>
                            </tr>
                            <tr>
                                <td>Oversized Packages <Tooltip title={"Only applicable after the free storage period has ended"}> <InfoCircleOutlined/> </Tooltip><div className="pricing-table-info">Storage Fee / Day</div></td>
                                <td>${ item.storageOversizeDayFee }/day</td>
                            </tr>
                            <tr>
                                <td>Pallet Size Packages <Tooltip title={"Only applicable after the free storage period has ended"}> <InfoCircleOutlined/> </Tooltip><div className="pricing-table-info">Storage Fee / Day</div></td>
                                <td>${ item.storagePalletDayFee }/day</td>
                            </tr>
                        </tbody>
                      </table>
                    </CSSTransition>
                  </div>
                )
              })
            }
            </div>
              <div className="row">
                  <div className="col-md-12">
                    <div className='pricing-table-container'>
                      <table className="pricing-table">
                          <thead>
                            <tr>
                              <th></th>
                              {
                                allPlans.map((item, key) => {
                                  return (
                                    <PlanHeader 
                                      key={item.planCode + key}
                                      title={item.planName.toUpperCase()}
                                      price={item.planRate}
                                      type={item.type}
                                      isAnnual={item.planInterval === 2}
                                      component={ renderSelectButton(item) }
                                    />
                                  )
                                })
                              }
                            </tr>
                          </thead>
                          <tbody>
                            <PlanRow
                              title={'Annual Maintenance Fee'}
                              select='SETUP_FEE'
                              type="planFees"
                              plans={allPlans}
                            />
                            <PlanRow
                              title={'Receiving Fee'}
                              select='RECEIVING_FEE'
                              type="planFees"
                              description="Per Package"
                              plans={allPlans}
                            />
                            <PlanRow
                              title={<> <span> Oversized Package Receiving Fee </span><Tooltip title={'Packages with a linear dimension (L + W + H) exceeding 203cm are considered oversized'}>  <InfoCircleOutlined/> </Tooltip ></>}
                              select='OVERSIZED_RECEIVING_FEE'
                              type="planFees"
                              description="Per Package"
                              plans={allPlans}
                            />
                            <PlanRow
                              title={'Pallet-sized Package Receiving Fee'}
                              select='PALLET_SIZED_RECEIVING_FEE'
                              type="planFees"
                              description="Per Package"
                              plans={allPlans}
                            />
                            <PlanRow
                              title={'Consolidation Fee'}
                              select='CONSOLIDATION_FEE'
                              description='Per Package Consolidated'
                              type="planFees"
                              plans={allPlans}
                            />
                            <PlanRow
                              title={'Consolidation Packing Material Fee'}
                              select='CONSOLIDATION_PACKING_MATERIAL_FEE'
                              type="planFees"
                              description="Per Consolidation"
                              plans={allPlans}
                            />
                            <PlanRow
                              title={<> <span> Photograph Fee (per 3) </span></> }
                              select='PHOTOGRAPH_FEE'
                              type="planFees"
                              plans={allPlans}
                            />
                            <PlanRow
                              title={'Extra Padding Fee'}
                              select='EXTRA_PADDING_FEE'
                              type="planFees"
                              plans={allPlans}
                            />
                            <tr className="pricing-table-title">
                                <td colSpan="6">Services</td>
                            </tr>
                              <PlanRow
                                title={'Consolidation'}
                                select='CAN_CONSOLIDATE'
                                type="planRules"
                                plans={allPlans}
                              />
                              <PlanRow
                                title={'Complex Consolidations'}
                                description={'Combining consolidated packages'}
                                select='CAN_ALLOW_COMPLEX_CONSOLIDATION'
                                type="planRules"
                                plans={allPlans}
                              />
                              <PlanRow
                                title={'Consolidation Limit'}
                                description={'Maximum number of packages per consolidation'}
                                select='CAN_CONSOLIDATE_OVER_5'
                                type="planRules"
                                plans={allPlans}
                              />
                              <PlanRow
                                title={'Photos'}
                                select='CAN_ALLOW_PHOTOGRAPHS'
                                type="planRules"
                                plans={allPlans}
                              />
                              <PlanRow
                                title={'Extra Padding'}
                                select='CAN_ALLOW_ADD_PADDING'
                                type="planRules"
                                plans={allPlans}
                              />
                              <PlanRow
                                title={'Special Requests'}
                                select='CAN_ALLOW_SPECIAL_REQUESTS'
                                type="planRules"
                                plans={allPlans}
                                tooltip={
                                  <div>
                                    Common special requests: 
                                    <p> </p>
                                    <ul>
                                      <li> - Deconsolidation </li>
                                      <li> - Splitting Packages </li>
                                      <li> - Reducing Package Size </li>
                                      <li> - Content Count & Description </li>
                                      <li> - Consolidation Restriction Bypass </li>
                                    </ul>
                                  </div>
                                }
                              />
                              <tr className="pricing-table-title">
                                  <td colSpan="6">
                                      Storage
                                  </td>
                              </tr>
                              <tr>
                                  <td>Free - Regular Sized Packages <div className="pricing-table-info">Free Storage Days</div> </td>
                                  { customOrGrandfatheredPlan ? <td>{ customOrGrandfatheredPlan.storageFreeDays } </td> : null }
                                  <td>{ free.storageFreeDays } </td>
                                  <td>{ gold.storageFreeDays } </td>
                                  <td>{ goldyear.storageFreeDays } </td>
                                  <td>{ platinum.storageFreeDays } </td>
                                  <td>{ platinumyear.storageFreeDays } </td>
                              </tr>
                              <tr>
                                  <td>Free - Oversized Packages <div className="pricing-table-info">Free Storage Days</div></td>
                                  { customOrGrandfatheredPlan ? <td>{ customOrGrandfatheredPlan.storageOversizeFreeDays } </td> : null }
                                  <td>{ free.storageOversizeFreeDays } </td>
                                  <td>{ gold.storageOversizeFreeDays } </td>
                                  <td>{ goldyear.storageOversizeFreeDays } </td>
                                  <td>{ platinum.storageOversizeFreeDays } </td>
                                  <td>{ platinumyear.storageOversizeFreeDays } </td>
                              </tr>
                              <tr>
                                  <td>Free - Pallet-sized Packages <div className="pricing-table-info">Free Storage Days</div></td>
                                  { customOrGrandfatheredPlan ? <td>{ customOrGrandfatheredPlan.storagePalletFreeDays } </td> : null }
                                  <td>{ free.storagePalletFreeDays } </td>
                                  <td>{ gold.storagePalletFreeDays } </td>
                                  <td>{ goldyear.storagePalletFreeDays } </td>
                                  <td>{ platinum.storagePalletFreeDays } </td>
                                  <td>{ platinumyear.storagePalletFreeDays } </td>
                              </tr>
                              <tr>
                                  <td>Regular Size Packages <Tooltip title={"Only applicable after the free storage period has ended"}> <InfoCircleOutlined/> </Tooltip>  <div className="pricing-table-info">Storage Fee / Day </div></td>
                                  { customOrGrandfatheredPlan ? <td>${ customOrGrandfatheredPlan.storageDayFee.toFixed(2) } </td> : null }
                                  <td>${ free.storageDayFee } </td>
                                  <td>${ gold.storageDayFee } </td>
                                  <td>${ goldyear.storageDayFee } </td>
                                  <td>${ platinum.storageDayFee.toFixed(2) } </td>
                                  <td>${ platinumyear.storageDayFee.toFixed(2) } </td>
                              </tr>
                              <tr>
                                  <td>Oversized Packages <Tooltip title={"Only applicable after the free storage period has ended"}> <InfoCircleOutlined/> </Tooltip> <div className="pricing-table-info">Storage Fee / Day </div></td>
                                  { customOrGrandfatheredPlan ? <td>${ customOrGrandfatheredPlan.storageOversizeDayFee.toFixed(2) } </td> : null }
                                  <td>${ free.storageOversizeDayFee } </td>
                                  <td>${ gold.storageOversizeDayFee } </td>
                                  <td>${ goldyear.storageOversizeDayFee } </td>
                                  <td>${ platinum.storageOversizeDayFee.toFixed(2) } </td>
                                  <td>${ platinumyear.storageOversizeDayFee.toFixed(2) } </td>
                              </tr>
                              <tr>
                                  <td>Pallet-sized Packages <Tooltip title={"Only applicable after the free storage period has ended"}> <InfoCircleOutlined/> </Tooltip> <div className="pricing-table-info">Storage Fee / Day </div></td>
                                  { customOrGrandfatheredPlan ? <td>${ customOrGrandfatheredPlan.storagePalletDayFee.toFixed(2) } </td> : null }
                                  <td>${ free.storagePalletDayFee } </td>
                                  <td>${ gold.storagePalletDayFee.toFixed(2) } </td>
                                  <td>${ goldyear.storagePalletDayFee.toFixed(2) } </td>
                                  <td>${ platinum.storagePalletDayFee } </td>
                                  <td>${ platinumyear.storagePalletDayFee } </td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                  </div>
              </div>
          </section>
        </div>
    </div>
  </div>
  )
}

export default Plans