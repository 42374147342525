import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import axios from 'axios'
import { constants } from '../common/constants'
import { SignJWT, importJWK } from 'jose'
import CustomerLoading from '../components/CustomerLoading'
import TagManager from 'react-gtm-module'

const mapStateToProps = state => ({ store: state })
const mapDispatchToProps = dispatch => ({
  init: (data) => dispatch({ type: 'CLIENT_INIT', payload: data }),
  initFail: () => dispatch({ type: 'CLIENT_INIT_FAIL' }),
  logout: () => dispatch({ type: 'CUSTOMER_LOGOUT'})
})

const withInitData = WrappedComponent => {

  const HOC = class extends Component {

    constructor(props) {
      super(props)

      this.state = {
        hasInitialized: false
      }
    }

    componentDidMount = async () => {
      this.refreshInitData()
    }

    generateClientToken = async () => {

      try {
        const key = await importJWK({
          kty: 'oct',
          k: constants.appPublicKey,
        }, 'HS256')
      
        const token = await new SignJWT({})
          .setProtectedHeader({ alg: 'HS256' })
          .setExpirationTime('5m') // sets expiration time to 5 minutes from now
          .sign(key)
      
        return token
      } catch(e) {
        //catch CryptoKey is not defined error 
        //only throws in local development 
        console.log(e)
      }
    }
    
    getInitData = async () => {
      try {
        const jwt = this.generateClientToken()

        const response = await axios.get('/init', { headers: { 'x-api-key': jwt }})
    
        const data = {
          token: response.data?.data?.token, 
          announcements: response.data?.data?.announcements, 
        }

        axios.defaults.headers['x-api-key'] = response.data?.data?.token
        this.setState({ hasInitialized: true })

        return data

      } catch(e) {
        this.props.initFail()
        this.setState({ hasInitialized: false })
      }
    }

    refreshInitData = async () => {
      const { init } = this.props 
      
      const IS_INIT_DATA_EXPIRED = this.props.store.clientRefreshAt < Date.now()

      const tagManagerArgs = {
        dataLayer: {
          event: 'set_user_id',
          user_id: this.props.store?.customerSessionData?.customerId
        },
        dataLayerName: 'dataLayer'
      }
  
      TagManager.dataLayer(tagManagerArgs)

      if(!IS_INIT_DATA_EXPIRED) {
        this.setState({ hasInitialized: true })
      }

      if(!this.props.store.clientInit) {
        const data = await this.getInitData()

        init(data)
        return 
      }

      if(this.props.store.clientInit && IS_INIT_DATA_EXPIRED){
        const initData = await this.getInitData()
        init(initData) 
      }    
    }
    
    render() {
      return (
        <>
          {
            this.state.hasInitialized ? <WrappedComponent {...this.props} /> : < CustomerLoading />
          }
        </>
      )
    }
  }

  return HOC
}

const withInitDataWrapper = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withInitData
)

export default withInitDataWrapper