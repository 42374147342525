import React, {Component,}    from 'react'
import {withRouter }          from 'react-router-dom'
import connect                from 'react-redux/es/connect/connect'
import {debug}                from '../common/constants'

const noticeTypes = [
  {id: 1, className: 'notice-info',     icon: 'ℹ'},
  {id: 2, className: 'notice-warn',     icon: '⚠'},
  {id: 3, className: 'notice-critical', icon: '📣'}
]

const mapStateToProps = (globalStore) => {return {store: globalStore}}
const mapDispatchToProps = (dispatch) => {return {
  hideNotice: (noticeId)=>{dispatch({type: 'HIDE_NOTICE', payload: {noticeId: noticeId}})},
}}

class GlobalNotices extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      notices: [],
    }
  }
  
  componentDidMount() {
    this.aggregateNotices()
  }

  aggregateNotices = () => {
    const announcements = this.props.store.clientData.announcements ? this.props.store.clientData.announcements : []

    if(announcements.length){
      this.setState({
        notices: [ ...announcements ]
      })
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.store.clientData.announcements.length !== this.state.notices.length || nextProps.store.isLoggedIn !== this.props.store.isLoggedIn){
      let distributions = nextProps.store.isLoggedIn ? [1,2] : [1,3]
      this.setState({
        notices: nextProps.store.clientData.announcements.filter(a=>distributions.includes(a.distribution))
      })
    }
  }

  render() {
    const notices = (
      <div className="notices">
        {this.state.notices.map((n)=>{
          let noticeType = noticeTypes.filter(nt=>{return nt.id===n.type})[0]
          return (
            <div className={'notice '+noticeType.className} key={n.noticeId}>
              {!!noticeType.icon && <div className="notice-icon">{noticeType.icon}</div>}
              <div className="notice-content">{n.html}</div>
              <div className="notice-close" onClick={()=>{this.props.hideNotice(n.noticeId)}}>✖</div>
            </div>
          )
        })}
      </div>
    )
    
    return <> { notices } </>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GlobalNotices))