import React, {Component} from 'react'
import PropTypes from 'prop-types'

class LoadingSpinner extends Component {
  static propTypes = {
    icon: PropTypes.string.isRequired
  }
  
  render(){
    return (
      <div className="loading-spinner">
        <div className="spinner-circle">
          <div className="spinner-icon"><i className="material-icons">{this.props.icon}</i></div>
          <div className="spinner-track" />
          <div className="spinner-actual" />
        </div>
      </div>
      
    )
  }
}

export default LoadingSpinner